import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import performanceService from '../../../services/data/performance.service';
import { Rating } from 'primereact/rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export default function FormCreateItemStatus(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const [object, setObject] = useState('') //para editar
    const [objectType, setObjectType] = useState(props.objectType) // para nao mostrar rating se o tipo for pergunta
    const [hideRating, setHideRating] = useState(false)
    const [itemType, setItemType] = useState(props.itemType) // para filtrar se é step do gestor ou employee
    const [employeeId, setEmployeeId] = useState(props.employeeId)
    const [assessmentId, setAssessmentId] = useState(props.assessmentId)
    const [sessionId, setSessionId] = useState(props.sessionId)
    const [objectId, setObjectId] = useState(props.objectId) //informar o ID do goal / jobperformance / assessmentquestion
    const [stepId, setStepId] = useState(props.stepId)
    const [managerEvaluation, setManagerEvaluation] = useState('')
    const [employeeEvaluation, setEmployeeEvaluation] = useState('')
    const [managerComment, setManagerComment] = useState('')
    const [employeeComment, setEmployeeComment] = useState('')

    const [objectStatus, setObjectStatus] = useState(props.objectStatus) //se o ste for status === 'RE' não pode editar mais

    

    useMemo(() => {

        if (objectType === 'QE') {
            //pergunta nao faz rating
            setHideRating(true)
        }

    }, [objectType])

    useMemo(() => {

        if (object) {
            setObject(object)
            setManagerEvaluation(object.manager_evaluation)
            setEmployeeEvaluation(object.employee_evaluation)
            setManagerComment(object.manager_comment)
            setEmployeeComment(object.employee_comment)
        }

        if (props.object) {
            setObject(props.object)
            setManagerEvaluation(props.object.manager_evaluation)
            setEmployeeEvaluation(props.object.employee_evaluation)
            setManagerComment(props.object.manager_comment)
            setEmployeeComment(props.object.employee_comment)
        }

    }, [object, props])

    function ratingVal(val) {
        const lista = {
            1 : 'Não Iniciado',
            2 : 'Parcialmente Completo',
            3 : 'Dentro da Expectativa',
            4 : 'Acima da Expectativa',
            5 : 'Se Superou'
        }
        return lista[val]
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            manager_evaluation: managerEvaluation,
            employee_evaluation: employeeEvaluation,
            manager_comment: managerComment,
            employee_comment: employeeComment
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                employee_id: employeeId,
                assessment_id: assessmentId,
                session_id: sessionId,
                step_id: stepId,
                object_id: objectId,
                manager_evaluation: values.manager_evaluation,
                employee_evaluation: values.employee_evaluation,
                manager_comment: values.manager_comment,
                employee_comment: values.employee_comment
            }

            if (object) {

                data.id = object.id
                
                performanceService.updateItemStatus(data).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Status atualizado com sucesso', life: 3000 });
                    //setObject(response)
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });

            } else {

                performanceService.createItemStatus(data).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Status adicionado com sucesso', life: 3000 });
                    setObject(response)

                    if (props.updateStatusCount){
                        props.updateStatusCount(1)
                    }
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });

            }

        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            {
                itemType === 'AG' || itemType === 'RN' ?
                <>
                {
                    !hideRating ?
                    <div className="col">
                        <label className="form-label fw-bold">Status <span className="text-danger">*</span></label>
                        <div className='d-flex align-middle'>
                            {
                                objectStatus === 'RE'? 
                                <Rating value={managerEvaluation} readOnly cancel={false} stars={5} /> 
                                :
                                <>
                                <span className={ managerEvaluation === 0 ? 'me-2 font150 rating text-vermelho' : 'me-2 font150 rating text-grey-rating'} onClick={() => {formik.setFieldValue('manager_evaluation', 0); setManagerEvaluation(0)}} role='button'>
                                    <FontAwesomeIcon icon={solid('ban')} />
                                </span>
                                <Rating value={managerEvaluation} cancel={false} stars={5} onChange={(e) => {formik.setFieldValue('manager_evaluation', e.value); setManagerEvaluation(e.value)}} />
                                {
                                    managerEvaluation === 0 ?
                                    <span className='ps-2 pt-1'>
                                        Não Aplicável
                                    </span>
                                    : null
                                }
                                </>
                            }
                            
                            {
                                managerEvaluation ?
                                <>
                                {
                                    <>
                                    <span className='ps-2 pt-1'>{ratingVal(managerEvaluation)}</span>
                                    </>
                                }
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                    : null
                }

                <div className="my-3">
                    <label className="form-label fw-bold">Comentário <span className="text-danger">*</span></label>
                    {
                        objectStatus === 'RE'? 
                        <Editor
                            className='noToolbar'
                            name="manager_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={managerComment}
                            readOnly
                        />
                        :
                        <Editor
                            className='noToolbar'
                            name="manager_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={managerComment}
                            onTextChange={(e) => {
                                formik.setFieldValue('manager_comment', e.htmlValue);
                                setManagerComment(e.htmlValue)
                            }}
                        />
                    }
                </div>
                </>
                :
                null
            }

            {
                itemType === 'AA' || itemType === 'AS' ?
                <>
                {
                    !hideRating ?
                    <div className="col">
                        <label className="form-label fw-bold">Status <span className="text-danger">*</span></label>
                        <div className='d-flex align-middle'>
                            {
                                objectStatus === 'RE'? 
                                <Rating value={employeeEvaluation} readOnly cancel={false} stars={5} /> 
                                :
                                <>
                                <span className={ employeeEvaluation === 0 ? 'me-2 font150 rating text-vermelho' : 'me-2 font150 rating text-grey-rating'} onClick={() => {formik.setFieldValue('employee_evaluation', 0); setEmployeeEvaluation(0)}} role='button'>
                                    <FontAwesomeIcon icon={solid('ban')} />
                                </span>
                                <Rating value={employeeEvaluation} cancel={false} stars={5} onChange={(e) => {formik.setFieldValue('employee_evaluation', e.value); setEmployeeEvaluation(e.value)}} />
                                {
                                    employeeEvaluation === 0 ?
                                    <span className='ps-2 pt-1'>
                                        Não Aplicável
                                    </span>
                                    : null
                                }
                                </>
                            }
                            
                            {
                                employeeEvaluation ?
                                <>
                                <span className='ps-2 pt-1'>{ratingVal(employeeEvaluation)}</span>
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                    :
                    null
                }

                <div className="my-3">
                    <label className="form-label fw-bold">Comentário <span className="text-danger">*</span></label>
                    {
                        objectStatus === 'RE'? 
                        <Editor
                            className='noToolbar'
                            name="employee_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={employeeComment}
                            readOnly
                        />
                        :
                        <Editor
                            className='noToolbar'
                            name="employee_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={employeeComment}
                            onTextChange={(e) => {
                                formik.setFieldValue('employee_comment', e.htmlValue);
                                setEmployeeComment(e.htmlValue)
                            }}
                        />
                    }
                    
                </div>
                </>
                :
                null
            }
            {
                objectStatus === 'RE'?
                <></>
                :
                <div className="">
                    {
                        object ?
                        <>
                        <button className="btn btn-outline-success btn-sm btn-rounded px-4 font1em" type="submit">Atualizar</button>
                        </>
                        :
                        <>
                        <button className="btn btn-success btn-sm btn-rounded px-4 font1em" type="submit">Salvar</button>
                        </>
                    }
                    
                </div>
            }
        </form>
    )
}
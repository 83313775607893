import React, { useRef, useState } from "react";
import ProtectedRoute from "../../routes/protected";
import SideBarSettings from "../../components/sidebars/sidebarSettings"
import { useFormik } from 'formik';
import userService from "../../services/data/user.service";
import userData from "../../context/user";
import { Toast } from 'primereact/toast';
import FormUserPhoto from "../../components/settings/forms/formUserPhoto";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar } from 'primereact/avatar';
import NameInitials from "../../helpers/nameInitials";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import CorreiosService from "../../helpers/correios.service";
import { InputMask } from "primereact/inputmask";
import checkCPFouCNPJ from "../../helpers/checkCPFouCNPJ";


export default function editUser() {

    const {user, updateUser} = userData()
    const toast = useRef(null);

    const [step, setStep] = useState(1)

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    const validate = values => {

        const errors = {};

        if (values.legalId && values.legalId.length > 13) {

            var chId = checkCPFouCNPJ(values.legalId)

            if (chId) {
                return true
            }       
            errors.legalId = `CPF inválido`

        }

        return errors;
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            email2: user.email2,
            social_name: user.social_name,
            phone: user.phone,
            phone2: user.phone2,
            zipcode: user.zipcode,
            address: user.address,
            adddressnumber: user.adddressnumber,
            adddresscomp: user.adddresscomp,
            neighbourhood: user.neighbourhood,
            state: user.state,
            city: user.city,
            user_language: user.user_language,
            birthday: user.birthday,
            legalId: user.legalId,
            gender: user.gender,
            born_sex: user.born_sex,

        },
        validate,
        onSubmit: values => {
            var data = {
                first_name: values.first_name,
                last_name: values.last_name,
                username: user.username,
                email2: values.email2,
                social_name: values.social_name,
                zipcode: values.zipcode,
                address: values.address,
                adddressnumber: values.adddressnumber,
                adddresscomp: values.adddresscomp,
                neighbourhood: values.neighbourhood,
                state: values.state,
                city: values.city,
                user_language: values.user_language,
                birthday: values.birthday,
                legalId: values.legalId,
                gender: values.gender,
                born_sex: values.born_sex,
                phone: values.phone,
                phone2: values.phone2,
            }

            userService.updateUser(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
                updateUser()
            })
            .catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
            })
        },
    });

    return (
        <ProtectedRoute>
            <div className="container-fluid">

                <Toast ref={toast} position="top-right" />

                <div className="row flex-nowrap">
                    <SideBarSettings />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Meus Dados</span>
                        </div>

                        <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                            <div className="text-start my-2 ms-1" style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#addPhoto">
                                {
                                    user && user.first_name ?
                                    <>
                                    {
                                        user.photo ?
                                        <>
                                        <Avatar image={user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                        <FontAwesomeIcon icon={solid('camera')} className="btn btn-sm text-green divBorderClean p-1 bg-white rounded position-relative" style={{top: '20px', right: '20px', width:'20px'}} />
                                        </>  
                                        : 
                                        <>
                                        <Avatar label={ NameInitials(user.full_name)} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large"  />
                                        </>
                                    }
                                    <span className="fs-5 position-relative ms-1" >{ user.full_name }</span>
                                    </>
                                    :
                                    null
                                }
                            </div>

                            <ul className='nav nav-tabs px-3 mt-4'>
                                <li className='nav-item'>
                                    <span className={ step === 1 ? 'nav-link active' : 'nav-link text-green'} role="button" onClick={() => {setStep(1)}}>
                                        Dados Principais
                                    </span>
                                </li>
                                <li className='nav-item'>
                                    <span className={ step === 2 ? 'nav-link active' : 'nav-link text-green'} role="button" onClick={() => {setStep(2)}}>
                                        Dados de Contato
                                    </span>
                                </li>
                                <li className='nav-item'>
                                    <span className={ step === 3 ? 'nav-link active' : 'nav-link text-green'} role="button" onClick={() => {setStep(3)}}>
                                        Dados Pessoais
                                    </span>
                                </li>
                            </ul>

                            <div className='bg-white py-4 rounded'>
                                <div className="row mt-2">
                                    <div className="col-md-10 offset-md-1 formMarginBottom">
                                        
                                        <form className="g-2 p-4 rounded bg-default" onSubmit={formik.handleSubmit}>
                                            {
                                                step === 1 ?
                                                <>
                                                <div className="row g-2 px-0">
                                                    <div className="col">
                                                        <label className="form-label">Seu Nome <span className="text-danger">*</span></label>
                                                        <input name='first_name' type="text" className="form-control" required {...formik.getFieldProps('first_name')} />
                                                    </div>
                                                    <div className="col">
                                                        <label className="form-label">Sobrenome <span className="text-danger">*</span></label>
                                                        <input name='last_name' type="text" className="form-control" required {...formik.getFieldProps('last_name')} />
                                                    </div>
                                                </div>
                                                <div className="col mt-3">
                                                    <label className="form-label">Nome Social <span className="text-grey">(opcional)</span></label>
                                                    <input name='first_name' type="text" className="form-control" required {...formik.getFieldProps('social_name')} />
                                                </div>
                                                <div className="row g-2 px-0 mt-3">
                                                    <div className="col">
                                                        <label className="form-label noMarginBottom">
                                                            Email Principal <span className="text-danger">*</span>
                                                            <FontAwesomeIcon className="text-green ms-2 font110" role="button" icon={solid('circle-exclamation')} data-bs-custom-class="custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Para trocar seu email, entrar em contato com suporte" /> 
                                                        </label>
                                                        <input name="email" type="email" className="form-control" disabled {...formik.getFieldProps('email')} />
                                                    </div>
                                                    <div className="col">
                                                        <label className="form-label noMarginBottom">Email Alternativo <span className="text-grey">(opcional)</span></label>
                                                        <input name="email2" type="email" className="form-control" {...formik.getFieldProps('email2')} />
                                                    </div>
                                                </div>
                                                <div className="col-4 mt-3">
                                                    <label className="form-label">Idioma <span className="text-danger">*</span></label>
                                                    <select className="form-select" name="user_language" required {...formik.getFieldProps('user_language')} >
                                                        <option disabled value="">Selecione</option>
                                                        <option value="pt-br">Portugués</option>
                                                        <option value="es">Espanhol</option>
                                                        <option value="en">Inglês</option>
                                                    </select>
                                                </div>
                                                </>
                                                : null
                                            }
                                            {
                                                step === 2 ?
                                                <>
                                                <div className="row g-2 px-0 mt-3">
                                                    <div className="col-4">
                                                        <label className="form-label noMarginBottom">
                                                            Telefone Principal <span className="text-danger">*</span>
                                                        </label>
                                                        <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}} mask='(99) 9999-9999?9' value={formik.values.phone} onChange={(e) => formik.setFieldValue('phone', e.target.value)} />
                                                    </div>
                                                    <div className="col-4">
                                                        <label className="form-label noMarginBottom">Outro Telefone</label>
                                                        <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}} mask='(99) 9999-9999?9' value={formik.values.phone2} onChange={(e) => formik.setFieldValue('phone2', e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 col-md-4 mt-3">
                                                    <label className="form-label">CEP <span className="text-danger">*</span></label>
                                                    <InputMask mask='99999-999'value={formik.values.zipcode} className="form-control" onChange={
                                                        e => {

                                                            formik.setFieldValue('zipcode', e.target.value);

                                                            if (!(e.target.value).includes('_') && e.target.value.length > 0) {

                                                                formik.handleChange(e)

                                                                var data = {
                                                                    zipcode: e.target.value
                                                                }

                                                                CorreiosService.getZipcode(data).then((response) => {
                                                    
                                                                    formik.setFieldValue("address", response.data['endereco'])
                                                                    formik.setFieldValue("neighbourhood", response.data['bairro'])
                                                                    formik.setFieldValue("city", response.data['cidade'])
                                                                    formik.setFieldValue("state", response.data['estado'])
                                                        
                                                                }).catch((error) => {
                                                                    console.log('Error ', error.data)
                                                                })

                                                            }

                                                        }
                                                    } />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label">Endereço <span className="text-danger">*</span></label>
                                                    <input type="text" name="address" className="form-control" {...formik.getFieldProps('address')} />
                                                </div>

                                                <div className="row px-0">
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label">Nº <span className="text-danger">*</span></label>
                                                        <input type="number" name="adddressnumber" className="form-control" {...formik.getFieldProps('adddressnumber')} />
                                                    </div>

                                                    <div className="mb-3 col-md-8">
                                                        <label className="form-label">Complemento (opcional)</label>
                                                        <input type="text" name="adddresscomp" className="form-control" {...formik.getFieldProps('adddresscomp')} />
                                                    </div>
                                                </div>
                                                
                                                <div className="mb-3">
                                                    <label className="form-label">Bairro <span className="text-danger">*</span></label>
                                                    <input type="text" name="neighbourhood" className="form-control" {...formik.getFieldProps('neighbourhood')} />
                                                </div>

                                                <div className="mb-3 col-md-8">
                                                    <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                                    <input type="text" name="city" className="form-control" {...formik.getFieldProps('city')} />
                                                </div>

                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Estado <span className="text-danger">*</span></label>
                                                    <select className="form-select" name="state" {...formik.getFieldProps('state')} >
                                                        <option disabled value="">Selecione</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="DF">Distrito Federal</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RN">Rio Grande do Norte</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="RO">Rondônia</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                    </select>
                                                </div>
                                                </>
                                                : null
                                            }
                                            {
                                                step === 3 ?
                                                <>
                                                <div className="row g-2 px-0">
                                                    <div className="col-4">
                                                        <label className="form-label">CPF <span className="text-danger">*</span></label>
                                                        <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}}  value={formik.values.legalId} onChange={(e) => formik.setFieldValue('legalId', e.target.value)} mask="999.999.999-99" placeholder="999.999.999-99" />
                                                        {formik.errors.legalId ? (<span name="legalId" className="text-vermelho">{formik.errors.legalId}</span>) : null}
                                                    </div>
                                                    <div className="col-4">
                                                        <label className="form-label">Data Nascimento <span className="text-danger">*</span></label>
                                                        <input className="form-control" name='birthday' type="date" autoComplete="off" {...formik.getFieldProps('birthday')} />
                                                    </div>
                                                </div>

                                                <div className="row g-2 px-0 mt-3">
                                                    <div className="col-4">
                                                        <label className="form-label">Sexo de Nascimento <span className="text-danger">*</span></label>
                                                        <select className="form-select" name="born_sex" required {...formik.getFieldProps('born_sex')} >
                                                            <option disabled value="">Selecione</option>
                                                            <option value="MA">Masculino</option>
                                                            <option value="FE">Feminino</option>
                                                            <option value="OR">Outro</option>
                                                            <option value="NO">Não Informado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6 mt-3">
                                                        <label className="form-label">Gênero que se Identifica <span className="text-danger">*</span></label>
                                                        <select className="form-select" name="gender" required {...formik.getFieldProps('gender')} >
                                                            <option disabled value="">Selecione</option>
                                                            <option value="0">Prefiro não responder</option>
                                                            <option value="1">Mulher Cisgênero: pessoa que se reconhece pelo gênero que lhe foi atribuído ao nascer</option>
                                                            <option value="2">Mulher Transgênero: pessoa que reinvidica e se reconhece por outro gênero, diferente do que lhe foi atribuído ao nascer</option>
                                                            <option value="3">Homem Cisgênero: pessoa que se reconhece pelo gênero que lhe foi atribuído ao nascer</option>
                                                            <option value="4">Homem Trangênero: pessoa que reinvidica e se reconhece por outro gênero, diferente do que lhe foi atribuído ao nascer</option>
                                                            <option value="5">Não Binário: pessoa que se reconhece fora do sistema binário de gênero</option>
                                                            <option value="6">Sem Gênero: ausência de gênero</option>
                                                            <option value="7">Gênero Fluído</option>
                                                            <option value="8">Outro</option>
                                                        </select>
                                                    </div>
                                                
                                                </>
                                                : null
                                            }
                                            
                                            

                                            <div className=" btnMargin">
                                                <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">Atualizar</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <div id="addPhoto" className="modal fade">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Atualizar Foto</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <FormUserPhoto />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
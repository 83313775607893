import React, { useRef } from "react";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import http from '../../services/http';
import { Toast } from 'primereact/toast';


export default function PasswordRecover() {

    const toast = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: '',

        },
        validationSchema: Yup.object({
            email: Yup.string().email("Email inválido"),
        }),
        onSubmit: values => {
            var data = {
                email: values.email
            }

            http.post('password/reset/', data).then( (response) => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: response.data.message, life: 4000 });

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            })
            
        }
    });


    return (
        <div className="row mt-5">
            
            <Toast ref={toast} position="top-right" />

            <div className="col-md-4 offset-md-4">
                <form className="row g-2 divBorder formMarginBottom" onSubmit={formik.handleSubmit}>
                    <h1 className="text-center">Recuperar Senha</h1>
                    <div className="col-12">
                        <label className="form-label">Insira seu Email</label>
                        <input type="email" name="email" className="form-control" required {...formik.getFieldProps('email')} />
                        {formik.errors.email ? (<span name="email" className="text-vermelho">{formik.errors.email}</span>) : null}
                    </div>
                    <div className="d-grid mx-auto btnMargin">
                        <button className="btn btn-success btn-rounded" type="submit">Recuperar Senha</button>
                    </div>
                    <hr className="mt-4" />
                    <div className="noLogin text-center">
                        <p>Já possui cadastro?</p>
                        <div className="d-grid mx-auto col-4">
                            <Link className="btn btn-outline-success btn-rounded" to={'/'}>Login</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import companyService from '../../../services/data/company.service';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export default function CreatePayGrade() {

    const { id } = useParams()
    const navigate = useNavigate();
    const toast = useRef(null);
    const [payStructure, setPayStructure] = useState('')

    useEffect(() => {
        getPayStructure()
    }, []);

    function getPayStructure() {
        companyService.getPayStructure(id).then((response) => {
            setPayStructure(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const stepItems = [
        {
            label: 'Estrutura'
        },
        {
            label: 'Nível'
        },
        {
            label: 'Salário'
        }
    ];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: ''
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                structure_id: id,
            }

            companyService.createPayGrade(data).then((response) => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Nível criado com sucesso', life: 3000 });
                return navigate('/settings/salary-structure/management/' + id + '/' + response.id + '/level/create/')
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
            });
        }
    });

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>

                                <div className='my-3'>
                                    <Steps model={stepItems} activeIndex={1} />
                                </div>

                                <a href={`/settings/salary-structure/management/${id}/detail/`} className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                
                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Adicionar Nível à Estrutura
                                    </span>
                                    <form onSubmit={formik.handleSubmit}>

                                        <p className='alert alert-warning mt-2'>
                                            Estrutura: <strong>{payStructure.title}</strong>
                                        </p>

                                        <div className='mb-3'>
                                            <label className="form-label fw-bold">Código <span className="text-danger">*</span></label>
                                            <input name="title" maxLength={5} className='form-control' {...formik.getFieldProps('title')} />
                                        </div>
                                        <div className="d-grid mx-auto btnMargin">
                                            <button className="btn btn-success btn-sm btn-rounded" type="submit">
                                                Adicionar
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
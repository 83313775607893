import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import performanceService from '../../../services/data/performance.service';
import { Rating } from 'primereact/rating';
import { ProgressBar } from 'primereact/progressbar';
import http from '../../../services/http';


export default function FormCreatePerformanceStatus(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const [enableReinitialize, setEnableReinitialize] = useState(false)
    const [object, setObject] = useState('') //para editar
    const [itemType, setItemType] = useState(props.itemType) // para filtrar se é step do gestor ou employee
    const [employeeId, setEmployeeId] = useState(props.employeeId)
    const [assessmentId, setAssessmentId] = useState(props.assessmentId)
    const [objectStatus, setObjectStatus] = useState(props.objectStatus) //se o ste for status === 'RE' não pode editar mais
    
    const [managerEvaluation, setManagerEvaluation] = useState('')
    const [employeeEvaluation, setEmployeeEvaluation] = useState('')
    const [managerComment, setManagerComment] = useState('')
    const [employeeComment, setEmployeeComment] = useState('')
    const [potential, setPotential] = useState('')
    const [retention, setRetention] = useState('')
    const [loss, setLoss] = useState('')
    const [score, setScore] = useState('')

    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);


    useMemo(() => {

        if (object && object.step === props.step_id) {
            setEnableReinitialize(true)
            setObject(object)
            setManagerEvaluation(object.manager_evaluation)
            setEmployeeEvaluation(object.employee_evaluation)
            setManagerComment(object.manager_comment)
            setEmployeeComment(object.employee_comment)
            setPotential(object.potential)
            setRetention(object.retention_risk)
            setLoss(object.loss_impact)
            setScore(object.score)
        }

        if (props.object && props.object.step === props.step_id) {
            setEnableReinitialize(true)
            setObject(props.object)
            setManagerEvaluation(props.object.manager_evaluation)
            setEmployeeEvaluation(props.object.employee_evaluation)
            setManagerComment(props.object.manager_comment)
            setEmployeeComment(props.object.employee_comment)
            setPotential(props.object.potential)
            setRetention(props.object.retention_risk)
            setLoss(props.object.loss_impact)
            setScore(props.object.score)
        }

        if (props.score){
            setScore(props.score)
        }

        if (props.evaluation) {
            if (itemType === 'AG') {
                setManagerEvaluation(valRating(props.evaluation))
            }
            if (itemType === 'AA') {
                setEmployeeEvaluation(valRating(props.evaluation))
            }
        }

        if (props.employeeEvaluation) {
            setEmployeeEvaluation(props.employeeEvaluation)
        }

    }, [object, props])

    function valRating(val) {
        const lista = {
            'IN': 1,
            'BE': 2,
            'DE': 3,
            'AE': 4,
            'SE': 5
        }
        return lista[val]
    }

    function ratingVal(val) {
        const lista = {
            1 : 'Insatisfatório',
            2 : 'Precisa Melhorar',
            3 : 'Dentro da Expectativa',
            4 : 'Acima da Expectativa',
            5 : 'Se Superou'
        }
        return lista[val]
    }


    function submitFiles(object_id) {

        const sendFiles = document.getElementById('evidences').files

        setLoading(true);

        const formData = new FormData();

        for (var i=0; i < sendFiles.length; i++) {
            formData.append("files", sendFiles[i]);
        }
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("object_id", object_id);
        formData.append("model", "performancestatus");


        http.post("file/create/", formData, {
            onUploadProgress: (progressEvent) => {
                const progress_ = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                setProgress(progress_);
            }
        }).then((response) => {
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Processo enviado com sucesso', life: 4000 });

            console.log(response)
        })
        .catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });

        }).finally(() => {
            if (loading) {
                setLoading(false)
            }
        })
 
    }

    const formik = useFormik({
        enableReinitialize: enableReinitialize,
        initialValues: {
            manager_evaluation: managerEvaluation,
            employee_evaluation: employeeEvaluation,
            manager_comment: managerComment,
            employee_comment: employeeComment,
            potential: potential,
            retention_risk: retention,
            loss_impact: loss

        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                employee_id: employeeId,
                assessment_id: assessmentId,
                manager_evaluation: managerEvaluation,
                employee_evaluation: employeeEvaluation,
                manager_comment: values.manager_comment,
                employee_comment: values.employee_comment,
                potential: values.potential,
                retention_risk: values.retention_risk,
                loss_impact: values.loss_impact,
                score: score,
                step_id: props.step_id
            }

            performanceService.createPerformanceStatus(data).then((response) => {
                setObject(response)
                if (props.updateObject) {
                    props.updateObject()
                }

                if ( document.getElementById('evidences').files.length > 0 ) {
                    submitFiles(response.id)
                } else {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Processo finalizado com sucesso', life: 3000 })
                }

            }).catch((error) => {
                console.log(error)
            });

        },
    });

    return (
        <div className='col-md-6 p-3 bg-light rounded'>

            <Toast ref={toast} position="top-right" />
            {
                itemType === 'RN' ?
                <>
                {
                    objectStatus === 'RE' || object.id ?
                    <></>
                    :
                    <div className='mt-3 mb-4'> 
                        <form encType="multipart/form-data">
                            <label className="form-label fw-bold">Evidência(s)</label>
                            <input type="file" id='evidences' name='files[]' multiple required className="form-control" />
                        </form>
                    </div>
                }
                </>
                : null
            }
            <form onSubmit={formik.handleSubmit} className=''>

            {
                itemType === 'RN' ?
                <>
                <div className="my-3">
                    <label className="form-label fw-bold">Potencial <span className="text-danger">*</span></label>
                    <select className='form-select mb-3' {...formik.getFieldProps('potential')} required="required">
                        <option value=''>Selecionar</option>
                        <option value='BP'>Baixo Potencial</option>
                        <option value='MP'>Médio Potencial</option>
                        <option value='AP'>Alto Potencial</option>
                    </select>
                </div>
                <div className="my-3">
                    <label className="form-label fw-bold">Retenção <span className="text-danger">*</span></label>
                    <p className='mb-1'>Risco de o(a) colaborador(a) sair da companhia</p>
                    <select className='form-select mb-3' {...formik.getFieldProps('retention_risk')} required="required">
                        <option value=''>Selecionar</option>
                        <option value='NS'>Não Significativo</option>
                        <option value='PQ'>Baixo Risco</option>
                        <option value='MD'>Risco Moderado</option>
                        <option value='AR'>Alto Risco</option>
                        <option value='CR'>Crítico</option>
                    </select>
                </div>
                <div className="my-3">
                    <label className="form-label fw-bold">Impacto <span className="text-danger">*</span></label>
                    <p className='mb-1'>Impacto na operação se o(a) colaborador(a) sair da companhia</p>
                    <select className='form-select mb-3' {...formik.getFieldProps('loss_impact')} required="required">
                        <option value=''>Selecionar</option>
                        <option value='NS'>Não Significativo</option>
                        <option value='PQ'>Pouco Significativo</option>
                        <option value='IM'>Impacto Moderado</option>
                        <option value='AI'>Alto Impacto</option>
                        <option value='CR'>Crítico</option>
                    </select>
                </div>
                <div className='my-3'>
                    <label className="form-label fw-bold">Status Geral <span className="text-danger">*</span></label>
                        <div className='d-flex align-middle'>
                        <Rating value={managerEvaluation} cancel={false} stars={5} onChange={(e) => {formik.setFieldValue('manager_evaluation', e.value); setManagerEvaluation(e.value)}} />
                            
                            {
                                managerEvaluation ?
                                <>
                                {
                                    <>
                                    <span className='ps-2 pt-1'>{ratingVal(managerEvaluation)}</span>
                                    </>
                                }
                                </>
                                :
                                null
                            }
                        </div>
                </div>
                <div className="my-3">
                    <label className="form-label fw-bold">Comentário Geral <span className="text-danger">*</span></label>
                    {
                        objectStatus === 'RE'? 
                        <Editor
                            className='noToolbar'
                            name="manager_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={managerComment}
                            readOnly
                        />
                        :
                        <Editor
                            className='noToolbar'
                            name="manager_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={managerComment}
                            onTextChange={(e) => {
                                formik.setFieldValue('manager_comment', e.htmlValue);
                                setManagerComment(e.htmlValue)
                            }}
                        />
                    }
                </div>
                </>
                :
                null
            }

            {
                itemType === 'AA' ?
                <>
                <div className="my-3">
                    <label className="form-label fw-bold">Comentário Geral <span className="text-danger">*</span></label>
                    {
                        objectStatus === 'RE'? 
                        <Editor
                            className='noToolbar'
                            name="employee_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={employeeComment}
                            readOnly
                        />
                        :
                        <Editor
                            className='noToolbar'
                            name="employee_comment"
                            style={{ height: '80px' }}
                            headerTemplate={headerEditor}
                            value={employeeComment}
                            onTextChange={(e) => {
                                formik.setFieldValue('employee_comment', e.htmlValue);
                                setEmployeeComment(e.htmlValue)
                            }}
                        />
                    }
                </div>
                </>
                :
                null
            }
            
            {
                progress > 0 ?
                <ProgressBar value={progress}></ProgressBar>
                : null
            }
            
            {
                objectStatus === 'RE' || object.id ?
                <></>
                :
                <>
                <div className="">
                    <p>Para finalizar o processo, basta clicar no botão abaixo.</p>
                    <button className="btn btn-success btn-sm btn-rounded px-4 font1em" type="submit">Confirmar e Enviar</button>
                </div>
                </>
            }
            </form>
        </div>
    )
}
import React, {useMemo, useRef, useState} from 'react';
import { useFormik } from 'formik';
import careerService from '../../services/data/career.service';
import { Toast } from 'primereact/toast';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default function FormAddLanguage(props) {

    const toast = useRef(null);
    const userId = props.userId
    const [idiom, setIdiom] = useState('')
    const [level, setLevel] = useState('')
    const [writing, setWriting] = useState('')
    const [speaking, setSpeaking] = useState('')
    const [reading, setReading] = useState('')

    const modalRef = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useMemo(() => {

        getItem()

    }, [props])

    function getItem() {
        if (props.object) {
            setIdiom(props.object.idiom)
            setLevel(props.object.level)
            setWriting(props.object.writing)
            setSpeaking(props.object.speaking)
            setReading(props.object.reading)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idiom: idiom,
            level: level,
            writing: writing,
            speaking: speaking,
            reading: reading
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                user_id: userId,
                idiom: values.idiom,
                level: values.level,
                writing: values.writing,
                speaking: values.speaking,
                reading: values.reading
            }
            if (props.object) {

                data.id = props.object.id

                careerService.updateUserLanguage(data).then((response) => {

                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Idioma atualizado com sucesso', life: 3000 });

                    props.updateData()
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            } else {

                careerService.createUserLanguage(data).then((response) => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Idioma adicionado com sucesso', life: 3000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            }
        },
    });

    function deleteItem() {
        var data = {
            id: props.object.id
        }
        careerService.deleteUserLanguage(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Item apagado com sucesso', life: 3000 });

            props.updateData()

            setTimeout(() => {
                hideModal()
                props.showBar(false)
            }, 3000);
        })
    }

    return (
        <>
        <Toast ref={toast} position="top-right" />
        <form className='border rounded bg-light p-3' onSubmit={formik.handleSubmit}>
            <label className="fw-bold font110">Idioma</label>

            <div className='row mt-3'>
                <div className="col">
                    <label className="form-label">Idioma <span className="text-danger">*</span></label>
                    <select className="form-select" required="required" {...formik.getFieldProps('idiom')}>
                        <option value="">Selecionar</option>
                        <option value="DE">Alemão</option>
                        <option value="ZH">Chinês</option>
                        <option value="ES">Espanhol</option>
                        <option value="FR">Francês</option>
                        <option value="EN">Inglês</option>
                        <option value="IT">Italiano</option>
                        <option value="JA">Japonês</option>
                        <option value="PT">Português</option>
                        <option value="RU">Russo</option>
                        <option value="BG">Búlgaro</option>
                        <option value="HR">Croata</option>
                        <option value="CS">Checo</option>
                        <option value="DA">Dinamarquês</option>
                        <option value="NL">Neerlandês</option>
                        <option value="ET">Estónio</option>
                        <option value="FI">Finlandês</option>
                        <option value="EL">Grego</option>
                        <option value="HU">Húngaro</option>
                        <option value="GA">Irlandês</option>
                        <option value="LV">Letão</option>
                        <option value="LT">Lituano</option>
                        <option value="MT">Maltês</option>
                        <option value="PL">Polaco</option>
                        <option value="RO">Romeno</option>
                        <option value="SK">Eslovaco</option>
                        <option value="SL">Esloveno</option>
                        <option value="SV">Sueco</option>
                        <option value="LB">Libras</option>
                        <option value="HB">Hebreu</option>
                        <option value="TR">Turco</option>
                        <option value="CO">Coreano</option>
                    </select>
                </div>

                <div className='col'>
                    <label className="form-label">Nível <span className="text-danger">*</span></label>
                    <select className="form-select" required="required" {...formik.getFieldProps('level')}>
                        <option value="">Selecionar</option>
                        <option value="2">Básico</option>
                        <option value="3">Intermediário</option>
                        <option value="4">Avançado</option>
                        <option value="5">Fluente</option>
                        <option value="6">Nativo</option>
                    </select>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col'>
                    <label className="form-label">Leitura <span className="text-danger">*</span></label>
                    <select className="form-select" {...formik.getFieldProps('reading')}>
                        <option value="">Selecionar</option>
                        <option value="1">Não leio</option>
                        <option value="2">Básico</option>
                        <option value="3">Intermediário</option>
                        <option value="4">Avançado</option>
                        <option value="5">Fluente</option>
                        <option value="6">Nativo</option>
                    </select>
                </div>
                <div className='col'>
                    <label className="form-label">Escrita <span className="text-danger">*</span></label>
                    <select className="form-select" {...formik.getFieldProps('writing')}>
                        <option value="">Selecionar</option>
                        <option value="1">Não escrevo</option>
                        <option value="2">Básico</option>
                        <option value="3">Intermediário</option>
                        <option value="4">Avançado</option>
                        <option value="5">Fluente</option>
                        <option value="6">Nativo</option>
                    </select>
                </div>
                <div className='col'>
                    <label className="form-label">Fala <span className="text-danger">*</span></label>
                    <select className="form-select" {...formik.getFieldProps('speaking')}>
                        <option value="">Selecionar</option>
                        <option value="1">Não falo</option>
                        <option value="2">Básico</option>
                        <option value="3">Intermediário</option>
                        <option value="4">Avançado</option>
                        <option value="5">Fluente</option>
                        <option value="6">Nativo</option>
                    </select>
                </div>
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        props.object ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
        {
            props.object ?
            <>
            <div className="text-end btnMargin">
                <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar</button>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar este Idioma?
                            </p>
                            <p>
                                {props.object.idiom_display}
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            : null
        }
        </>
    )

}
import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import performanceService from '../../../services/data/performance.service';


export default function FormCreateGoalStatus(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const [employee, setEmployee] = useState('')
    const [goal, setGoal] = useState('')
    const [lastStatus, setLastStatus] = useState('NI')
    

    useMemo(() => {
        
        if (props.goal) {
            setGoal(props.goal)
        }

        if (props.employee) {
            setEmployee(props.employee)
        }

        if (props.lastStatus) {
            setLastStatus(props.lastStatus)
        }

    }, [props])


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: lastStatus
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                goal: goal,
                employee: employee,
                employee_comment: values.employee_comment,
                status: values.status
            }

            if (values.status === 'CS') {
                data.completed = true
                data.date_completed = values.date_completed
            }

            performanceService.createGoalStatus(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Status atualizado com sucesso', life: 3000 });
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
            });

        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            <div className="col">
                <label className="form-label fw-bold">Status <span className="text-danger">*</span></label>
                <select className='form-select mb-3' {...formik.getFieldProps('status')} required="required">
                    <option value='NI'>Não Iniciado</option>
                    <option value='FA'>1-25%</option>
                    <option value='FB'>25-50%</option>
                    <option value='FC'>50-75%</option>
                    <option value='FD'>75-99%</option>
                    <option value='CS'>Completado com Sucesso</option>
                </select>
            </div>

            {
                formik.values.status === 'CS' ?
                <div className='col'>
                    <label className="form-label fw-bold">Quando foi Completado com Sucesso? <span className="text-danger">*</span></label>
                    <input className="form-control" name='date_completed' type="date" required="required" autoComplete="off" {...formik.getFieldProps('date_completed')} />
                    
                </div>
                :
                null
            }

            <div className="my-3">
                <label className="form-label fw-bold">Adicionar Comentário</label>
                <Editor
                    name="employee_comment"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    onTextChange={(e) => {
                        formik.setFieldValue('employee_comment', e.htmlValue);
                    }}
                />
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">Atualizar</button>
            </div>
        </form>
    )
}
import React, {useState, useEffect, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from "moment";
import 'moment/locale/pt-br'
import VerifyPerms from '../../context/userAccess';
import FormCreateGoal from '../../components/performance/forms/formCreateGoal';
import employeeService from '../../services/data/employee.service';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import userData from '../../context/user';
import companyData from '../../context/company';


export default function ArchivedGoals() {

    const {user} = userData()
    const [itemsList, setItemsList] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [page, setPage] = useState(1)
    const canEdit = VerifyPerms(['change_goal'])
    const [orgGoals, setOrgGoals] = useState([]) //goals organizacionais vigentes para poder fazer o related_goal
    const [employeeId, setEmployeeId] = useState('')
    const navigate = useNavigate();
    const {rootUser, userPosition} = companyData()
    const [isManager, setIsManager] = useState(false)

    useEffect(() => {
        getEmployeeId()
        listOrgGoals()
    }, [])

    useEffect(() => {
        listItems()
    }, [employeeId])

    useEffect(() => {

        // verifica se o user tem subordinados para poder acessar "Minha Equipe" nos módulos
        if (!rootUser && userPosition && userPosition.childrenCount > 0) {
            setIsManager(true)
        }

        if (rootUser) {
            setIsManager(true)
        }
        
    }, [userPosition, rootUser]);

    function getEmployeeId() {
        employeeService.getUserEmployee().then((response) => {
            setEmployeeId(response.id)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listItems() {

        if (employeeId) {
            let data = {
                company_id: localStorage.getItem("companyId"),
                employees: employeeId,
                status: ['AR']
            }
    
            performanceService.listGoals(page, data).then((response) => {
                setItemsList(response)
                setItemsCount(response.count)
            }).catch((error) => {
                console.log(error.response)
            });
        }
    }

    function listOrgGoals() {
        let data = {
            goal_type: 'OG',
            status: ['AT']
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            
            const newObj = Object.entries(response.results).map(([key, value]) => ({
                category: key,
                items: value.map((vl) => {
                    return {
                        id: vl.id,
                        title: vl.title
                    }
                })
            }))
            setOrgGoals(newObj)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    function editItem(id) {
        return navigate(`/goal/${id}/edit/`, {state: {relatedGoalList: orgGoals}})
    }

    function editItemStatus(id) {
        return navigate(`/goal/${id}/edit/status/`, {state: {employeeId: employeeId}})
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Metas e Objetivos
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 rounded-top paddingBottom200' style={{minHeight: '500px'}}>

                    <ul className='nav nav-tabs px-3'>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Minhas Metas
                            </span>
                        </li>
                        {
                            isManager ?
                            <li className='nav-item'>
                                <a href='/my/team/goals/' className='nav-link text-green'>
                                    Minha Equipe
                                </a>
                            </li>
                            :
                            null
                        }
                    </ul>
                    <div className='bg-white pt-4 rounded'>

                        <ul className='nav nav-tabs px-3 mb-4'>
                            <li className='nav-item'>
                                <a href='/my/goals/' className='nav-link text-green cursorPointer'>
                                    Minhas Metas
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/goals/organizational/' className='nav-link text-green'>
                                    Metas Organizacionais
                                </a>
                            </li>
                            <li className='nav-item'>
                                <span className='nav-link active'>
                                    Arquivados
                                </span>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/goals/canceled/' className='nav-link text-green cursorPointer'>
                                    Cancelados
                                </a>
                            </li>
                        </ul>
                    {
                        itemsCount > 0 ? 
                        <>
                        <div className='px-3'>
                            <table className='table table-borderless thover text-default mt-3'>
                                <thead className='borderBottom bg-light'>
                                    <tr>
                                        <th>
                                            Objetivo
                                        </th>
                                        <th className='text-center'>
                                            Criado por
                                        </th>
                                        <th className='text-center'>
                                            Tipo
                                        </th>
                                        <th className='text-center'>
                                            Período
                                        </th>
                                        <th className='text-center'>
                                            Status
                                        </th>
                                        <th className='text-center'>
                                            Relacionado a
                                        </th>
                                        <th className='text-center'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemsList.results.map((item, index) =>
                                            <tr key={index} className=''>
                                                <td className="align-middle">
                                                    <span className='text-green fw-bold fs-6'>{item.title}</span>
                                                    <div>
                                                        <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {item.created_by.first_name} {item.created_by.last_name}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <Chip label={item.goal_type_display} />
                                                </td>
                                                <td className="align-middle text-center">
                                                    {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                </td>
                                                <td className="align-middle text-center">
                                                {
                                                    item.goalLastStatus.status ?
                                                    <>
                                                    {
                                                        item.goalLastStatus.status === 'CS' ?
                                                        <>
                                                        <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                        </>
                                                        :
                                                        <>{item.goalLastStatus.status_display}</>
                                                    }
                                                    </>
                                                    :
                                                    <>Não Iniciado</>
                                                }
                                                </td>
                                                <td className="align-middle text-center">
                                                {
                                                    item.related_goal ?
                                                    <>
                                                    {item.related_goal.title}
                                                    </>
                                                    :
                                                    <>-</>
                                                }
                                                </td>
                                                <td className="align-middle text-center">
                                                    <div className='dropdown'>
                                                        <button className='btn btn-sm rounded-circle border dropdown-toggle no-caret' data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon className='text-green' icon={solid('ellipsis')} />
                                                        </button>
                                                        <ul className='dropdown-menu border headerBorder font1em'>
                                                            {
                                                                canEdit || item.created_by.username === user.username ?
                                                                <li>
                                                                    <button className="dropdown-item text-default" onClick={() => editItem(item.id)}>
                                                                        Editar
                                                                    </button>
                                                                </li>
                                                                : null
                                                            }
                                                            <li>
                                                                <button className="dropdown-item text-default" onClick={() => editItemStatus(item.id)}>
                                                                    Alterar Status
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className='float-end'>
                                <nav>
                                    <ul className='pagination pagination-sm'>
                                        <li className='page-item'>
                                            { 
                                                itemsList.previous ? 
                                                <span onClick={subPage} className='page-link text-default'>
                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                </span>
                                                :
                                                <span className='page-link text-grey disabled'>
                                                    <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                </span>
                                            }
                                        </li>
                                        <li className='page-item'>
                                            {
                                                itemsList.next ?
                                                <span onClick={addPage} className='page-link text-default'>
                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                </span>
                                                :
                                                <span className='page-link text-grey disabled'>
                                                    <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                </span>
                                            }
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        </>
                        :
                        <p className='m-3 p-3'>Nenhum objetivo archivado</p>
                        
                    }
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
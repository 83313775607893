import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormAddCategory from './formAddCategory';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';


export default function FormAddCompetency(props) {
    
    const [showCategory, setShowCategory] = useState(false)
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();

    const [objId, setObjId] = useState('')
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [categoryList, setCategoryList] = useState('')
    const [description, setDescription] = useState('')
    
    useMemo(() => {
        if(props.obj) {
            setObjId(props.obj.id)
            setTitle(props.obj.title)
            setDescription(props.obj.description)
            if (props.obj.category) {
                setCategory(props.obj.category.id)
            }
        }
        if (props.categories && props.categories.results && props.categories.results.length > 0) {
            setCategoryList(props.categories.results)
        }
        if (props.categoriesList && props.categoriesList.length > 0) {
            setCategoryList(props.categoriesList)
        }
    }, [props])

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'competencies'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const handleShowCategory = () => {
        setShowCategory(!showCategory)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            category: category,
            description: description,
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                category_id: values.category,
                description: values.description,
            }

            if (objId) {

                data.id = objId
    
                companyService.updateCompetency(data).then(() => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência atualizada com sucesso', life: 3000 });

                    if (props.listItems) {
                        props.listItems()
                    }

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });

            } else {
                companyService.createCompetency(data).then(() => {
    
                    if (props.listItems) {
                        props.listItems()
                    }
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência criada com sucesso', life: 3000 });
                    
                    formik.resetForm()
                    setDescription('')

                    if (props.hideModal) {
                        setTimeout(() => {
                            props.hideModal()
                        }, 3000);
                    }
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }
            
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />
        
            <div className="col">
                <label className="form-label">Título da Competência <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Categoria</label>
                <button className="btn btn-sm" onClick={(e) => handleShowCategory(e)}>
                    <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                </button>
                
                {
                    showCategory ?
                    <>
                    <div className='mt-3 p-2 bg-light'>
                        <FormAddCategory model='competencies' setCategory={setCategory} listCategories={listCategories} handleShowCategory={handleShowCategory} />
                    </div>
                    <hr/>
                    </>
                    :
                    null
                }
                {
                    categoryList ?
                    <select className='form-select mb-3' {...formik.getFieldProps('category')}>
                        <option value=''>Sem Categoria</option>
                        {
                            categoryList.map((item, index) =>
                            <option key={index} value={item.id}>{item.title}</option>
                            )
                        }
                    </select>
                    :
                    null
                }
            </div>

            <div className="my-3">
                <label className="form-label">Descrição</label>
                <Editor
                    name="description"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    value={formik.values.description}
                    onTextChange={(e) => {
                        formik.setFieldValue('description', e.htmlValue);
                    }}
                />
            </div>
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        objId ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
    )
}
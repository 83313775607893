import React, {useState, useEffect, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import FormAddCategory from '../../../components/settings/forms/formAddCategory';
import moment from "moment";
import 'moment/locale/pt-br'


export default function FormAddPayStructure(props) {
    const [organizations, setOrganizations] = useState('')
    const [organizationList, setOrganizationList] = useState('')

    const [showCategory, setShowCategory] = useState(false)
    const [categoryList, setCategoryList] = useState('')

    //para edit
    const [object, setObject] = useState('')
    const [category, setCategory] = useState('')
    const [effectiveDate, setEffectiveDate] = useState('')
    const [dateInactive, setDateInactive] = useState('')

    const navigate = useNavigate();
    const toast = useRef(null);

    useEffect(() => {
        listCategories()
        listOrganizations()
    }, []);

    useMemo(() => {
        if(props.object) {
            setObject(props.object)
            if (props.object.category) {
                setCategory(props.object.category.id)
            }
            setEffectiveDate(moment(props.object.effective_date).format("YYYY-MM-DD"))
            if (props.object.date_inactive){
                setDateInactive(moment(props.object.date_inactive).format("YYYY-MM-DD")
            )}
            setOrganizations(
                props.object.subcompanies.map((item) => item.id)
            )
        }
    }, [props])

    function listOrganizations() {
        companyService.listSubCompany().then((response) => {

            if (response.results.length > 0) {
                setOrganizationList(response.results.map((item) => 
                {
                    return {
                        name: item.title + ' - ' + item.internal_code,
                        value: item.id
                    }
                }
            ))
               
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'paystructure'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const handleShowCategory = () => {
        setShowCategory(!showCategory)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: object.title,
            effective_date: effectiveDate,
            category: category,
            subcompanies: organizations,
            inactive: object.inactive,
            date_inactive: dateInactive

        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                category: values.category,
                effective_date: values.effective_date,
                subcompanies: organizations
            }

            if (values.inactive) {
                data.inactive = values.inactive
                data.date_inactive = values.date_inactive
            }

            if (object) {

                data.id = object.id

                companyService.updatePayStructure(data).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Estrutura atualizada com sucesso', life: 3000 });
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            } else {
                companyService.createPayStructure(data).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Estrutura criada com sucesso', life: 3000 });
                    return navigate(`/settings/salary-structure/management/${response.id}/grade/create/`)
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }

        }
    });

    return (
        
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} position="top-right" />                
            <div className='mt-3'>
                <label className="form-label fw-bold">
                    Organizações <span className="text-danger">*</span> { organizations.length > 0 ? <>({organizations.length})</> : null}
                </label>
                <p className='mb-1'>Informar as Organizações que esta Estrutura fará parte</p>
                <div className='p-inputgroup'>
                    <MultiSelect filter placeholder="Selecionar" value={organizations} optionLabel="name" onChange={(e) => setOrganizations(e.value)} options={organizationList} display="chip" className="rounded" />
                </div>
                
            </div>

            <div className="col mt-3">
                <label className="form-label fw-bold">Tipo / Sindicato</label>
                <button className="btn btn-sm" onClick={(e) => handleShowCategory(e)}>
                    <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                </button>
                
                {
                    showCategory ?
                    <>
                    <div className='mt-3 p-2 bg-light'>
                        <FormAddCategory model='paystructure' setCategory={setCategory} listCategories={listCategories} handleShowCategory={handleShowCategory} />
                    </div>
                    <hr/>
                    </>
                    :
                    null
                }
                {
                    categoryList ?
                    <select className='form-select mb-3' {...formik.getFieldProps('category')}>
                        <option value=''>Selecionar</option>
                        {
                            categoryList.map((item, index) =>
                            <option key={index} value={item.id}>{item.title}</option>
                            )
                        }
                    </select>
                    :
                    null
                }
            </div>

            <div className='mb-3'>
                <label className="form-label fw-bold">Nome da Estrutura <span className="text-danger">*</span></label>
                <input name="title" className='form-control' required="required" {...formik.getFieldProps('title')} />
            </div>

            <div className='mb-4 col-md-6'>
                <label className="form-label fw-bold">Ativa a partir de <span className="text-danger">*</span></label>
                <input className="form-control" name='effective_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('effective_date')} />
            </div>

            <div className="form-check form-switch mb-4">
                <label className="form-switch-label align-baseline">Estrutura Inativa?</label>
                <input className="form-check-input" type="checkbox" role="switch" name='inactive' aria-checked={formik.values.inactive} checked={formik.values.inactive} {...formik.getFieldProps('inactive')} />
            </div>

            {
                formik.values.inactive ?
                <>
                <div className='mb-4 col-md-6'>
                    <label className="form-label">Inativo a partir de</label>
                    <input className="form-control" name='date_inactive' type="date" required="required" autoComplete="off" {...formik.getFieldProps('date_inactive')} />
                </div>
                </>
                :
                null
            }

            <hr/>
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        object?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>

        </form>
    )
}
import React, {useState, useEffect, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../services/data/company.service';
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import { Sidebar } from 'primereact/sidebar';


export default function GoalPlanEditHistory() {
    
    const { id } = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const [itemsList, setItemsList] = useState([]);
    const [object, setObject] = useState('')
    const [finalWeight, setFinalWeight] = useState(0)
    const [page, setPage] = useState(1)

    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        getGoalPlan()
    }, [])

    useEffect(() => {
        listItems()
    }, [page])

    useMemo(() => {

        if (location && location.state.finalWeight) {
            setFinalWeight(location.state.finalWeight)
        }

    }, [location])

    function getGoalPlan() {
        performanceService.getGoalPlan(id).then((response) => {
            setObject(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listItems() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            //model: 'goalplan',
            object_id: id
        }
        companyService.listModified(data, page).then((response) => {
            console.log(response)
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function goBack() {
        return navigate(`/goal/plan/`)
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    function seeCascade() {
        return navigate(`/goal/plan/${id}/cascade/`, {state: {finalWeight: finalWeight}})
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5" role='button' onClick={() => goBack()}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    
                    <ul className='nav nav-tabs pt-4 px-3'>
                        <li className='nav-item'>
                            <a href={`/goal/plan/${id}/goals/`} className='nav-link text-green'>
                                Objetivos
                            </a>
                        </li>
                        {
                            finalWeight === 100 ?
                            <li className='nav-item'>
                                 <span className='nav-link text-green cursorPointer' onClick={() => seeCascade()}>
                                    Cascatear
                                </span>
                            </li>
                            : null
                        }
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Histórico de Alterações
                            </span>
                        </li>
                    </ul>
                    
                    {
                        itemsList && itemsList.results ?
                        <>
                        <div className='mt-3 px-3'>
                            <table className='table table-borderless thover text-default borderBottom'>
                                <thead className='borderBottom'>
                                    <tr>
                                        <th>
                                            Alterado em
                                        </th>
                                        <th className='text-center'>
                                            Alterado por
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemsList.results.map((item, index) =>
                                            <tr key={index}>
                                                <td className="align-middle">
                                                    <span  className='text-green fw-bold text-decoration-none cursorPointer' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                        {moment(item.created).format("DD-MMM-YYYY HH:mm")}
                                                    </span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    {item.created_by.first_name} {item.created_by.last_name}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <div className='float-end'>
                                <nav>
                                    <ul className='pagination pagination-sm'>
                                        <li className='page-item'>
                                            { 
                                                itemsList.previous ? 
                                                <span onClick={subPage} className='page-link text-default'>
                                                    <FontAwesomeIcon icon={solid('angle-left')} />
                                                </span>
                                                :
                                                <span className='page-link text-grey disabled'>
                                                    <FontAwesomeIcon icon={solid('angle-left')} />
                                                </span>
                                            }
                                        </li>
                                        <li className='page-item'>
                                            {
                                                itemsList.next ?
                                                <span onClick={addPage} className='page-link text-default'>
                                                    <FontAwesomeIcon icon={solid('angle-right')} />
                                                </span>
                                                :
                                                <span className='page-link text-grey disabled'>
                                                    <FontAwesomeIcon icon={solid('angle-right')} />
                                                </span>
                                            }
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        </>
                        :
                        null
                    }
                
                </div>


                <div className="card flex justify-content-center">
                    <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                    {
                            selection ?
                            <>
                            {
                            <div>
                                <div className='py-2'>
                                    <p className='fw-bold'>Antes</p>
                                    <pre>{JSON.stringify(selection.data_before,null, 2)}</pre>
                                </div>
                                <div className='py-2'>
                                    <p className='fw-bold'>Depois</p>
                                    <pre>{JSON.stringify(selection.data_after,null, 2)}</pre>
                                </div>
                            </div>
                            }
                            </>
                            :null
                        }
                    </Sidebar>
                </div>

                
            </div>
        </ProtectedRoute>
    )
}
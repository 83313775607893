import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NavLink } from 'react-router-dom';
import UsePerms from "../../context/userAccess";
import companyData from "../../context/company";


export default function SideBarCompanyManagement() {

    const VerifyPerms = UsePerms()
    const {rootUser} = companyData()

    return (
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 bg-white border-clean side-shadow">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 min-vh-100">
                <NavLink to="/painel/" className="nav-link text-center mt-3 ms-2">
                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                </NavLink>
                <hr className="border-clean2-dot w-100"/>
                <p className="mt-1 ps-3 fw-bold d-none d-sm-inline">Configurações Empresa</p>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="sideMenu">
                    {
                        VerifyPerms(['change_company']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/edit-company/" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('building-circle-check')} /> <span className="ms-1 d-none d-sm-inline">Editar Empresa</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_subcompany']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/subcompany-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('code-branch')} /> <span className="ms-1 d-none d-sm-inline">Organizações</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_benefits']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/benefits-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('cubes-stacked')} /> <span className="ms-1 d-none d-sm-inline">Benefícios</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_competencies']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/competencies-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('list-check')} /> <span className="ms-1 d-none d-sm-inline">Competências</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_internaljobtitle']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/internal-job-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('user-doctor')} /> <span className="ms-1 d-none d-sm-inline">Cargos</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_department']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/workstation-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('table')} /> <span className="ms-1 d-none d-sm-inline">Departamentos</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_costcenter']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/costcenter-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('list-ol')} /> <span className="ms-1 d-none d-sm-inline">Centros de Custo</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_paystructure']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/salary-structure/management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('wallet')} /> <span className="ms-1 d-none d-sm-inline">Estrutura de Salários</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['view_jobposition']) || rootUser ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/jobposition/management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('user-lock')} /> <span className="ms-1 d-none d-sm-inline">Posições</span>
                            </NavLink>
                        </li>
                        : null
                    }
                </ul>
            </div>
        </div>
    )
}
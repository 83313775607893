import React, {useState, useEffect} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormCreateGoalPlan from '../../components/performance/forms/formCreateGoalPlan';
import moment from "moment";
import 'moment/locale/pt-br'
import VerifyPerms from '../../context/userAccess';
import companyData from '../../context/company';
import { Chip } from 'primereact/chip';
import SkeletonGoals from './skeletonGoals';


export default function GoalPlanList() {

    const [pageLoad, setPageLoad] = useState(true)
    const {userPosition, rootUser} = companyData()
    const [itemsList, setItemsList] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [page, setPage] = useState(1)
    const [showForm, setShowForm] = useState(false)
    const canAdd = VerifyPerms(['add_goalplan'])
    const canEdit = VerifyPerms(['change_goalplan'])
    

    useEffect(() => {
        listItems()
    }, [userPosition])

    function listItems() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            status: ['AT', 'IN'] //ativos e inativos
        }

        if (!rootUser && userPosition && userPosition.subcompany) {
            data.subcompanies = userPosition.subcompany.id
        }

        performanceService.listGoalPlans(page, data).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Metas Organizacionais
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                {
                    pageLoad ?
                    <SkeletonGoals />
                    :
                    <>
                    {
                        itemsCount > 0 ? 
                        <>
                        {
                            showForm ?
                            <div className='col-6'>
                                <div className='ps-3'>
                                    <span role="button" className="nav-link mt-2 mb-3" onClick={() => setShowForm(false)}>
                                        <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                    </span>
                                    <FormCreateGoalPlan />
                                </div>
                            </div>
                            :
                            <>
                            <div className='pt-2 px-3'>
                                {
                                    canAdd ?
                                    <button className="btn btn-sm btn-success" onClick={() => setShowForm(true)}>
                                        <FontAwesomeIcon className='' icon={solid('plus-circle')} /> Novo Plano
                                    </button>
                                    : null
                                }
                            </div>
                            <div className='px-3'>
                                <table className='table table-borderless thover text-default borderBottom mt-3'>
                                    <thead className='borderBottom bg-light'>
                                        <tr>
                                            <th>
                                                Plano de Metas
                                            </th>
                                            <th className='text-center'>
                                                Objetivos
                                            </th>
                                            <th className='text-center'>
                                                Organizações
                                            </th>
                                            <th className='text-center'>
                                                Período
                                            </th>
                                            <th className='text-center'>
                                                Criado por
                                            </th>
                                            <th className='text-center'>
                                                Status
                                            </th>
                                            <th className='text-center'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemsList.results.map((item, index) =>
                                                <tr key={index} className=''>
                                                    <td className="align-middle">
                                                        <a href={`/goal/plan/${item.id}/goals/`} className='fs-6 text-green fw-bold text-decoration-none'>
                                                            {item.title}
                                                        </a>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {item.goalsCount}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {item.subcompaniesCount}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {item.created_by.first_name} {item.created_by.last_name}
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <Chip label={item.status_display} />
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {
                                                            canEdit ?
                                                            <a href={`/goal/plan/${item.id}/edit/`} className='btn btn-sm'>
                                                                <FontAwesomeIcon className='text-green' icon={solid('edit')} />
                                                            </a>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className='float-end'>
                                    <nav>
                                        <ul className='pagination pagination-sm'>
                                            <li className='page-item'>
                                                { 
                                                    itemsList.previous ? 
                                                    <span onClick={subPage} className='page-link text-default'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                    </span>
                                                    :
                                                    <span className='page-link text-grey disabled'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                    </span>
                                                }
                                            </li>
                                            <li className='page-item'>
                                                {
                                                    itemsList.next ?
                                                    <span onClick={addPage} className='page-link text-default'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                    </span>
                                                    :
                                                    <span className='page-link text-grey disabled'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                    </span>
                                                }
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            </>
                        }
                        </>
                        :
                        <>
                        
                        {
                            showForm ?
                            <div className='col-6'>
                                <div className='ps-3'>
                                    <span role="button" className="nav-link mt-2 mb-3" onClick={() => setShowForm(false)}>
                                        <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                    </span>
                                    <FormCreateGoalPlan />
                                </div>
                            </div>
                            
                            :
                            <>
                            {
                                canAdd ?
                                <div className="row justify-content-center">
                                    <div className='mx-auto col-md-2 divBorder rounded text-center'>
                                        <div className='mt-3'>
                                            <span className="fs-6">Adicionar</span><br/><span className="fs-6">Plano de Metas</span>
                                        </div>
                                        <div className="d-grid mt-3">
                                            <button className="btn btn-lg btnClean rounded-circle" onClick={() => setShowForm(true)}>
                                                <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <p className='m-3 p-3 alert alert-warning'>Nenhum Plano de Metas ainda</p>
                            }
                            </>
                        }
                        
                        </>
                        
                    }
                    </>
                }
                </div>
            </div>
        </ProtectedRoute>
    )
}
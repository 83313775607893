import React from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';
import FormCreatePerformanceReviewAll from '../../components/performance/forms/formCreatePerformanceReviewAll';

export default function CreatePerformanceReviewAll() {

    const navigate = useNavigate();
    
    return (
        <ProtectedRoute>

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5 text-decoration-none text-white" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Performance
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className='col-md-6 col-10'>
                        <div className='ps-3'>
                            <FormCreatePerformanceReviewAll />
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import { Toast } from 'primereact/toast';
import { useParams, useNavigate } from 'react-router-dom';
import SkeletonProcess from './skeletonProcess';
import ParticipantsSelect from '../../components/performance/participantsSelect';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import ContentTemplate from '../../components/performance/contentTemplate';
import UsePerms from '../../context/userAccess';
import companyData from '../../context/company';
import ContentTemplateSuperior from '../../components/performance/contentTemplateSuperior';
import TypeSteps from '../../components/performance/typeSteps';


export default function PerformanceTemplateProcess() {

    const VerifyPerms = UsePerms()
    const {rootUser} = companyData()

    const [pageLoad, setPageLoad] = useState(true)
    const toast = useRef(null);
    const { id } = useParams()
    const navigate = useNavigate()
    const [object, setObject] = useState('')
    const [showDetail, setShowDetail] = useState(false)
    const [showParticipants, setShowParticipants] = useState(true)
    const [showProcess, setShowProcess] = useState(false)
    const [showSummary, setShowSummary] = useState(false)
    const [showSuperior, setShowSuperior] = useState(false)

    const [dueDate, setDueDate] = useState('') // para enviar para 1ª etapa

    const canvaRef = useRef()

    const hideOffCanvas = () => {
        const elem = canvaRef.current
        const modal = bootstrap.Offcanvas.getInstance(elem)
        modal.hide()
    }

    useEffect(() => {

        getObject()

    }, [])

    function getObject() {
        performanceService.getPerformanceTemplate(id).then((response) => {
            setObject(response)
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        }).finally(() => {
            setPageLoad(false)
        })
    }
    
    function showSession(val) {

        if (val === 'DETAIL') {
            setShowDetail(true)
        } else {
            setShowDetail(false)
        }

        if (val === 'PARTICIPANTS') {
            setShowParticipants(true)
        } else {
            setShowParticipants(false)
        }

        if (val === 'PROCESS') {
            setShowProcess(true)
        } else {
            setShowProcess(false)
        }

        if (val === 'SUPERIOR') {
            setShowSuperior(true)
        } else {
            setShowSuperior(false)
        }

        if (val === 'SUMMARY') {
            setShowSummary(true)
        } else {
            setShowSummary(false)
        }
    }

    function importGoals() {
        return navigate(`/performance-template/${id}/import/goals/`, {state: {object: object}})
    }

    function importCompetencies() {
        return navigate(`/performance-template/${id}/import/competencies/`, {state: {object: object}})
    }

    function importQuestions() {
        return navigate(`/performance-template/${id}/import/questions/`, {state: {object: object}})
    }

    function importGoalsSuperior() {
        return navigate(`/performance-template/${id}/import/goals/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    function importCompetenciesSuperior() {
        return navigate(`/performance-template/${id}/import/competencies/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    function importQuestionsSuperior() {
        return navigate(`/performance-template/${id}/import/questions/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    function sendMailStartPerformance() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id,
            due_date: dueDate
        }

        performanceService.createReviewsFromPerformanceTemplate(data).then(() => {    
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Processo enviado com sucesso`, life: 5000 });
            getObject()
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        })

    }

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> { object && object.status === 'EC' ? <>Editar Ciclo</> : <>Ver Ciclo</> }
                        </a>
                    </div>
                </div>
                <div className='my-1 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    {
                        pageLoad ?
                        <SkeletonProcess />
                        :
                        <>
                        <div className='bg-white p-3 mb-2 rounded border-clean'>
                            <span className='fw-bold fs-6'>{object.title}</span>
                        </div>
                        <div className='d-flex bg-white rounded border-clean'>
                            
                            <div className='flex-column py-2 col-2 border-end'>
                                <nav role={ !showDetail ? 'button' : null} className={ showDetail ? 'nav flex-column' : 'nav flex-column bg-default navColHover'} onClick={() => showSession('DETAIL')}>
                                    <li className='nav-item py-2 border-bottom'>
                                        <span className={ showDetail ? 'nav-link text-default fw-bold' : 'nav-link text-grey'}>Detalhes</span>
                                    </li>
                                </nav>
                                <nav role={ !showParticipants ? 'button' : null} className={ showParticipants ? 'nav flex-column' : 'nav flex-column bg-default navColHover'} onClick={() => showSession('PARTICIPANTS')}>
                                    <li className='nav-item py-2 border-bottom'>
                                        <span className={ showParticipants ? 'nav-link text-default fw-bold' : 'nav-link text-grey'}>Participantes</span>
                                    </li>
                                </nav>
                                <nav role={ !showProcess ? 'button' : null} className={ showProcess ? 'nav flex-column' : 'nav flex-column bg-default navColHover'} onClick={() => showSession('PROCESS')}>
                                    <li className='nav-item py-2 border-bottom'>
                                        <span className={ showProcess ? 'nav-link text-default fw-bold' : 'nav-link text-grey'}>Conteúdo</span>
                                    </li>
                                </nav>
                                {
                                    object.assessment_type === 'SD' ?
                                    <nav role={ !showSuperior ? 'button' : null} className={ showSuperior ? 'nav flex-column' : 'nav flex-column bg-default navColHover'} onClick={() => showSession('SUPERIOR')}>
                                        <li className='nav-item py-2 border-bottom'>
                                            <span className={ showSuperior ? 'nav-link text-default fw-bold' : 'nav-link text-grey'}>Avaliar Superior</span>
                                        </li>
                                    </nav>
                                    : null
                                }
                                <nav role={ !showSummary ? 'button' : null} className={ showSummary ? 'nav flex-column' : 'nav flex-column bg-default navColHover'} onClick={() => showSession('SUMMARY')}>
                                    <li className='nav-item py-2 border-bottom'>
                                        <span className={ showSummary ? 'nav-link text-default fw-bold' : 'nav-link text-grey'}>Revisar e Confirmar</span>
                                    </li>
                                </nav>
                            </div>

                            <div className='flex-column col-10 paddingBottom200'>
                                <div className='p-4 mb-5'>
                                    {
                                        showDetail ?
                                        <>
                                        <p className='fs-6 fw-bold ps-3'>Detalhes</p>
                                        <hr/>
                                        <div className='ps-3'>
                                            <div>
                                                <strong>Título</strong>
                                                <p>{object.title}</p>
                                            </div>
                                            <div>
                                                <strong>Template</strong>
                                                <p>{object.assessmentTypeDisplay}</p>
                                            </div>
                                            <div>
                                                <strong>Criado em</strong>
                                                <p>{moment(object.created).format("DD-MMM-YYYY")}</p>
                                            </div>
                                            <div>
                                                <strong>Período</strong>
                                                <p>{moment(object.start_date).format("DD-MMM-YYYY")} - {moment(object.end_date).format("DD-MMM-YYYY")} </p>
                                            </div>
                                            <div>
                                                <strong>Status</strong>
                                                <p>{object.statusDisplay}</p>
                                            </div>
                                            <div>
                                                <strong>Considerar Resultado na Matriz 9Box?</strong>
                                                <p>
                                                    {
                                                        object.result_to_matrix ?
                                                        'Sim'
                                                        :
                                                        'Não'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        </>
                                        : null
                                    }
                                    {
                                        showParticipants ?
                                        <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='fs-6 fw-bold ps-3'>Participantes</div>
                                            <div>
                                                {
                                                    object && object.status === 'EC' ?
                                                    <button data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddPart" className='btn btn-sm btn-success btn-rounded px-3'>Adicionar</button>
                                                    :
                                                    <button className='btn btn-sm btn-success btn-rounded px-3 disabled'>Adicionar</button>
                                                }
                                                
                                            </div>
                                        </div>
                                        
                                        <hr/>
                                        {
                                            object.participants && object.participants.length > 0 ?
                                            <div className='px-3'>
                                                <p className='ps-1'>
                                                    <span className='fw-bold'>{object.participants.length}</span> Avaliadores Participando
                                                </p>
                                                <table className='table table-bordered text-default borderBottom mt-3'>
                                                    <tbody>
                                                    {
                                                        object.participants.map((item, index) =>
                                                        <>
                                                        {
                                                            item.managers.length > 0 ?
                                                            <>
                                                            {
                                                                item.managers.map((m, i) => 
                                                                <tr key={i} className='bg-light'>
                                                                    <td className="align-middle border-end-0" width='20px'>
                                                                        <span>
                                                                        {
                                                                            m.user.photo ?
                                                                            <Avatar title={m.user.full_name} image={m.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                                            :
                                                                            <Avatar title={m.user.full_name} label={NameInitials( m.user.full_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle'/>
                                                                        }
                                                                        </span>
                                                                    </td>
                                                                    <td className="align-middle border-start-0">
                                                                        <span className='fw-bold text-decoration-none'>
                                                                            {m.user.full_name}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                )
                                                            }
                                                            </>
                                                            : null
                                                        }
                                                        {
                                                            item.employees.length > 0 ?
                                                            <tr className='border-top border-end'>
                                                                <td colSpan='3'>
                                                                    <table className='table table-borderless'>
                                                                        <tbody>
                                                                        {
                                                                            item.employees.map((emp, idx) => 
                                                                            <tr key={idx}>
                                                                                <td className="align-middle" width='20px'>
                                                                                    {
                                                                                        emp.user.photo ?
                                                                                        <Avatar title={emp.user.full_name} image={emp.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                                                        :
                                                                                        <Avatar title={emp.user.full_name} label={NameInitials( emp.user.full_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle'/>
                                                                                    }
                                                                                </td>
                                                                                <td className="align-middle">
                                                                                    <span className='fw-bold text-decoration-none'>
                                                                                        {emp.user.full_name}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            )
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            : null
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <>
                                            <div className='alert alert-warning'>
                                                <label className='fw-bold pe-3'>Nenhum participante adicionado ainda</label>
                                            </div>
                                            </>
                                        }

                                        <div ref={canvaRef} className='offcanvas offcanvas-end' style={{'--bs-offcanvas-width': 'min(95vw, 90vw)'}} data-bs-backdrop="static" id='offCanvasAddPart' tabIndex={-1}>
                                            <div className='offcanvas-header border-bottom'>
                                                <h6 className='offcanvas-title'>Adicionar Participantes</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                            <div className='offcanvas-body'>
                                                <div className=''>
                                                    <ParticipantsSelect setObject={setObject} id={id} hideModal={hideOffCanvas} />
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        : null
                                    }
                                    {
                                        showProcess ?
                                        <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='fs-6 fw-bold ps-3'>Conteúdo</div>
                                            <div>
                                                {
                                                    object && object.status === 'EC' ?
                                                    <>
                                                    <div className='dropdown' role="group" style={{'fontSize': '12px'}}>
                                                        <button className='btn btn-sm btn-success btn-rounded px-3' data-bs-toggle="dropdown" aria-expanded="false">
                                                            Adicionar
                                                        </button>
                                                        <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                            <li>
                                                                <span className='dropdown-header'>Objetivos</span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddGoal">
                                                                    Adicionar Novo Objetivo
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importGoals()}}>
                                                                    Importar Objetivos
                                                                </span>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <span className='dropdown-header'>Competências</span>
                                                            </li>
                                                            {
                                                                VerifyPerms(['add_competencies']) || rootUser ?
                                                                <>
                                                                <li>
                                                                    <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddComp" >
                                                                        Adicionar Nova Competência
                                                                    </span>
                                                                </li>
                                                                </>
                                                                : null
                                                            }
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default'  onClick={() => {importCompetencies()}}>
                                                                    Importar Competência
                                                                </span>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <span className='dropdown-header'>Perguntas</span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddQuestion">
                                                                    Adicionar Nova Pergunta
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importQuestions()}}>
                                                                    Importar Perguntas
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </>
                                                    :
                                                    <button className='btn btn-sm btn-success btn-rounded px-3 disabled'>
                                                        Adicionar
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <hr/>
                                        <ContentTemplate object={object} updateObject={getObject} />
                                        </>
                                        : null
                                    }
                                    {
                                        showSuperior ?
                                        <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='fs-6 fw-bold ps-3'>Avaliar Superior</div>
                                            <div>
                                                {
                                                    object && object.status === 'EC' ?
                                                    <>
                                                    <div className='dropdown' role="group" style={{'fontSize': '12px'}}>
                                                        <button className='btn btn-sm btn-success btn-rounded px-3' data-bs-toggle="dropdown" aria-expanded="false">
                                                            Adicionar
                                                        </button>
                                                        <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                            <li>
                                                                <span className='dropdown-header'>Objetivos</span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddGoal">
                                                                    Adicionar Novo Objetivo
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importGoalsSuperior()}}>
                                                                    Importar Objetivos
                                                                </span>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <span className='dropdown-header'>Competências</span>
                                                            </li>
                                                            {
                                                                VerifyPerms(['add_competencies']) || rootUser ?
                                                                <>
                                                                <li>
                                                                    <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddComp" >
                                                                        Adicionar Nova Competência
                                                                    </span>
                                                                </li>
                                                                </>
                                                                : null
                                                            }
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default'  onClick={() => {importCompetenciesSuperior()}}>
                                                                    Importar Competência
                                                                </span>
                                                            </li>
                                                            <li><hr className="dropdown-divider" /></li>
                                                            <li>
                                                                <span className='dropdown-header'>Perguntas</span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddQuestion">
                                                                    Adicionar Nova Pergunta
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importQuestionsSuperior()}}>
                                                                    Importar Perguntas
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </>
                                                    :
                                                    <button className='btn btn-sm btn-success btn-rounded px-3 disabled'>
                                                        Adicionar
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <hr/>
                                        <ContentTemplateSuperior object={object} session={object.sessionSuperior} updateObject={getObject} />
                                        </>
                                        : null
                                    }
                                    {
                                        showSummary ?
                                        <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='fs-6 fw-bold ps-3'>Revisar e Confirmar</div>
                                            <div>
                                                {
                                                    object && object.status === 'EC' ?
                                                    <button className='float-end btn btn-sm btn-success btn-rounded px-3' onClick={() => {setDueDate(object.start_date)}} data-bs-toggle="offcanvas" data-bs-target="#offCanvasSend">Enviar</button>
                                                    :
                                                    <button className='float-end btn btn-sm btn-success btn-rounded px-3 disabled'>Enviar</button>
                                                }
                                            </div>
                                        </div>
                                        <hr/>

                                        <div className='row'>
                                            <div className='col'>
                                                
                                                <div className='border p-2 rounded m-1'>
                                                    <table className='table font1em mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{fontSize: '14px'}}>Detalhes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='pb-0'>
                                                                <td className='pb-0'>
                                                                    <div>
                                                                        <strong>Período</strong>
                                                                        <p>{moment(object.start_date).format("DD-MMM-YYYY")} - {moment(object.end_date).format("DD-MMM-YYYY")} </p>
                                                                    </div>
                                                                    <div>
                                                                        <strong>Status</strong>
                                                                        <p>{object.statusDisplay}</p>
                                                                    </div>
                                                                    <div>
                                                                        <strong>Considerar Resultado na Matriz 9Box?</strong>
                                                                        <p>
                                                                            {
                                                                                object.result_to_matrix ?
                                                                                'Sim'
                                                                                :
                                                                                'Não'
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                               
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col'>
                                                
                                                <div className='border p-2 rounded m-1'>
                                                    <table className='table font1em mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{fontSize: '14px'}}>{ object.participants ? <>({object.participants.length})</> : null} Participantes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='pb-0'>
                                                                <td className='pb-0'>
                                                                    {
                                                                        object.participants && object.participants.length > 0 ?
                                                                        <>
                                                                        {
                                                                            object.participants.map((item, index) =>
                                                                            <>
                                                                            {
                                                                                item.managers.length > 0 ?
                                                                                <>
                                                                                {
                                                                                    item.managers.map((m, i) => 
                                                                                    <tr key={i} className=''>
                                                                                        <td className="align-middle border-end-0 py-1" width='20px'>
                                                                                            <span>
                                                                                            {
                                                                                                m.user.photo ?
                                                                                                <Avatar title={m.user.full_name} image={m.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                                                                :
                                                                                                <Avatar title={m.user.full_name} label={NameInitials( m.user.full_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle'/>
                                                                                            }
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="align-middle ps-1 border-start-0">
                                                                                            <span className='fw-bold text-decoration-none'>
                                                                                                {m.user.full_name}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    )
                                                                                }
                                                                                </>
                                                                                : null
                                                                            }
                                                                            </>
                                                                            )
                                                                        }
                                                                        </>
                                                                        :
                                                                        <div className='alert alert-warning'>
                                                                            <label className='fw-bold pe-3'>Nenhum participante adicionado ainda</label>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                               
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col'>
                                                <div className='border p-2 rounded m-1'>
                                                    <table className='table font1em mb-0'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{fontSize: '14px'}}>Etapas</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            object ?
                                                            <div className='ps-1'>
                                                            <TypeSteps type={object.assessment_type} />
                                                            </div>
                                                            : null
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col'>
                                                
                                                <div className='border p-2 rounded m-1'>
                                                    <table className='table table-borderless font1em mb-0'>
                                                        <thead className='border-bottom'>
                                                            <tr>
                                                                <th style={{fontSize: '14px'}}>Conteúdo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='pt-2'>
                                                            {
                                                                object.goals.length > 0 ?
                                                                <>
                                                                <tr className='align-middle '>
                                                                    <td className=''><span className='fw-bold'>{object.goals.length}</span> - Objetivos</td>
                                                                </tr>
                                                                </>
                                                                : null
                                                            }
                                                            {
                                                                object.competencies.length > 0 ?
                                                                <>
                                                                <tr className='align-middle '>
                                                                    <td className=''><span className='fw-bold'>{object.competencies.length}</span> - Competências</td>
                                                                </tr>
                                                                </>
                                                                : null
                                                            }
                                                            {
                                                                object.questions.length > 0 ?
                                                                <>
                                                                <tr className='align-middle '>
                                                                    <td className=''><span className='fw-bold'>{object.questions.length}</span> - Perguntas</td>
                                                                </tr>
                                                                </>
                                                                : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                               
                                            </div>
                                        </div>

                                        {
                                            object.sessionSuperior ?
                                            <>
                                            <div className='row'>
                                                <div className='col'>
                                                    
                                                    <div className='border p-2 rounded m-1'>
                                                        <table className='table table-borderless font1em mb-0'>
                                                            <thead className='border-bottom'>
                                                                <tr>
                                                                    <th style={{fontSize: '14px'}}>Conteúdo para Avaliar Superior</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='pt-2'>
                                                                {
                                                                    object.sessionSuperior.goals.length > 0 ?
                                                                    <>
                                                                    <tr className='align-middle '>
                                                                        <td className=''><span className='fw-bold'>{object.sessionSuperior.goals.length}</span> - Objetivos</td>
                                                                    </tr>
                                                                    </>
                                                                    : null
                                                                }
                                                                {
                                                                    object.sessionSuperior.competencies.length > 0 ?
                                                                    <>
                                                                    <tr className='align-middle '>
                                                                        <td className=''><span className='fw-bold'>{object.sessionSuperior.competencies.length}</span> - Competências</td>
                                                                    </tr>
                                                                    </>
                                                                    : null
                                                                }
                                                                {
                                                                    object.sessionSuperior.questions.length > 0 ?
                                                                    <>
                                                                    <tr className='align-middle '>
                                                                        <td className=''><span className='fw-bold'>{object.sessionSuperior.questions.length}</span> - Perguntas</td>
                                                                    </tr>
                                                                    </>
                                                                    : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            </>
                                            : null
                                        }

                                        <div className='row my-2'>
                                            <div className='col'>
                                                {
                                                    object && object.status === 'EC' ?
                                                    <button className='float-end btn btn-sm btn-success btn-rounded px-3' onClick={() => {setDueDate(object.start_date)}} data-bs-toggle="offcanvas" data-bs-target="#offCanvasSend">Enviar</button>
                                                    : null
                                                }
                                            </div>
                                        </div>

                                        <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasSend' tabIndex={-1}>
                                            <div className='offcanvas-header'>
                                                <h6 className='offcanvas-title'>Enviar</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setDueDate('')}}></button>
                                            </div>
                                            <div className='offcanvas-body'>
                                                <div className='p-3 bg-light rounded'>
                                                    <p>Tem certeza de que deseja enviar para <span className='fw-bold'> { object.participants ? <>{object.participants.length}</> : null}</span> pessoas a avaliação <span className='fw-bold'>{object.title}</span> ?</p>
                                                        
                                                    <div className='mb-3'>
                                                        <label className="form-label fw-bold">Prazo <span className="text-danger">*</span></label>
                                                        <input className="form-control" type="date" required="required" autoComplete="off" min={object.start_date} max={object.end_date} value={dueDate} onChange={(e) => {setDueDate(e.target.value) }} />
                                                    </div>
                                                    <button className='btn btn-sm btn-success btn-rounded px-3' onClick={() => sendMailStartPerformance()}>
                                                        Sim, enviar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>

        </ProtectedRoute>
    )
}
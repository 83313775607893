import React from "react";
import { Skeleton } from 'primereact/skeleton';

export default function SkeletonMatrix() {
    return (
        <>
        <div className="d-flex justify-content-center">
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
        </div>

        <div className="d-flex justify-content-center">
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
            <div className="col m-2">
                <Skeleton height="10rem" className="m-2"></Skeleton>
            </div>
        </div>
        </>
    )
}
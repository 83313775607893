import React, {useState, useEffect, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../services/data/company.service';
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import { Chip } from 'primereact/chip';
import FormCreateGoal from '../../components/performance/forms/formCreateGoal';
import userData from '../../context/user';
import VerifyPerms from '../../context/userAccess';
import { useNavigate } from 'react-router-dom';
import SkeletonGoals from './skeletonGoals';


export default function MyTeamGoals() {

    const [pageLoad, setPageLoad] = useState(true)
    const [itemsList, setItemsList] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [page, setPage] = useState(1)
    const [currentEmployee, setCurrentEmployee] = useState('')
    const [goalsList, setGoalsList] = useState([]);
    const [showForm, setShowForm] = useState(false)
    const [orgGoals, setOrgGoals] = useState([]) //goals organizacionais vigentes para poder fazer o related_goal
    const canEdit = VerifyPerms(['change_goal'])
    const {user} = userData()
    const navigate = useNavigate();


    useEffect(() => {

        listItems()
        listOrgGoals()
        
    }, [])

    useMemo(() => {

        listGoals()

    }, [currentEmployee])

    function listItems() {
        
        companyService.getUserTeamJobPosition().then((response) => {    
            setItemsList(response)
            setItemsCount(response.count)

            if (response.count > 0) {
                setCurrentEmployee(response.results[0])
            }
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function listGoals() {

        if (currentEmployee) {
            let data = {
                company_id: localStorage.getItem("companyId"),
                employees: currentEmployee.id,
                status: ['AT']
            }
    
            performanceService.listGoals(page, data).then((response) => {
                setGoalsList(response)
            }).catch((error) => {
                console.log(error.response)
            });
        }
    }

    function listOrgGoals() {
        let data = {
            goal_type: 'OG',
            status: ['AT']
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            
            const newObj = Object.entries(response.results).map(([key, value]) => ({
                category: key,
                items: value.map((vl) => {
                    return {
                        id: vl.id,
                        title: vl.title
                    }
                })
            }))
            setOrgGoals(newObj)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function editItem(id) {
        return navigate(`/goal/${id}/edit/`, {state: {relatedGoalList: orgGoals}})
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Metas e Objetivos
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 rounded-top paddingBottom200' style={{minHeight: '500px'}}>

                    <ul className='nav nav-tabs px-3'>
                        <li className='nav-item'>
                            <a href='/my/goals/' className='nav-link text-green'>
                                Minhas Metas
                            </a>
                        </li>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Minha Equipe
                            </span>
                        </li>
                    </ul>
                    <div className='bg-white pt-4 rounded'>
                        {
                            pageLoad ?
                            <SkeletonGoals />
                            :
                            <>
                            <div className='row'>
                            {
                                itemsCount > 0 ? 
                                <>
                                <div className='col-3 border-end mb-3'>
                                    <table className='table table-borderless thover mt-3'>
                                        <tbody>
                                        {
                                            itemsList.results.map((item, index) => 
                                                <tr key={index} className={`cursorPointer align-middle ${ currentEmployee && currentEmployee.id === item.id ? 'active' : null} `} onClick={() => setCurrentEmployee(item)} >
                                                    <td width="20px">
                                                    {
                                                        item.user.photo ?
                                                        <Avatar image={item.user.photo} shape="circle" />
                                                        :
                                                        <Avatar label={NameInitials( item.user.first_name )} style={{ backgroundColor: '#9cc5ca', color: '#ffffff' }} shape="circle" />
                                                    }
                                                    </td>
                                                    <td className="">
                                                        <span className='fw-bold'>{item.user.first_name} {item.user.last_name}</span>
                                                        <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='col'>

                                {
                                    currentEmployee ?
                                    <>
                                    {
                                        showForm ?
                                        <div className='col-6'>
                                            <span role="button" className="nav-link mt-2 mb-3" onClick={() => setShowForm(false)}>
                                                <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                            </span>
                                            <FormCreateGoal related_goals={orgGoals} employee={currentEmployee.id} listItems={listGoals} sendMessage={true} />
                                        </div>
                                        :
                                        <div className='pt-2'>
                                            <button className="btn btn-sm btn-success btn-rounded px-3" onClick={() => setShowForm(true)}>
                                                <FontAwesomeIcon className='' icon={solid('plus-circle')} /> Novo Objetivo para {currentEmployee.user.first_name} {currentEmployee.user.last_name}
                                            </button>
                                        </div>
                                    }
                                    <hr className='hr-border'/>
                                    </>
                                    : null
                                }

                                {
                                    goalsList && goalsList.count > 0 ?
                                    <>
                                    <div className='fw-bold lead'>{currentEmployee.user.first_name} {currentEmployee.user.last_name}</div>
                                    <p className='text-grey'>{currentEmployee.jobposition.title}</p>
                                    <table className='table table-borderless thover text-default mt-3'>
                                        <thead className='borderBottom bg-light'>
                                            <tr>
                                                <th>
                                                    Objetivo
                                                </th>
                                                <th className='text-center'>
                                                    Criado por
                                                </th>
                                                <th className='text-center'>
                                                    Tipo
                                                </th>
                                                <th className='text-center'>
                                                    Período
                                                </th>
                                                <th className='text-center'>
                                                    Status
                                                </th>
                                                <th className='text-center'>
                                                    Relacionado a
                                                </th>
                                                <th className='text-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                goalsList.results.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className="align-middle">
                                                            <span className='text-green fw-bold fs-6'>{item.title}</span>
                                                            <div>
                                                                <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {item.created_by.first_name} {item.created_by.last_name}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <Chip label={item.goal_type_display} />
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                        </td>
                                                        <td className="align-middle text-center">
                                                        {
                                                            item.goalLastStatus.status ?
                                                            <>
                                                            {
                                                                item.goalLastStatus.status === 'CS' ?
                                                                <>
                                                                <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                                </>
                                                                :
                                                                <>{item.goalLastStatus.status_display}</>
                                                            }
                                                            </>
                                                            :
                                                            <>Não Iniciado</>
                                                        }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                        {
                                                            item.related_goal ?
                                                            <>
                                                            {item.related_goal.title}
                                                            </>
                                                            :
                                                            <>-</>
                                                        }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {
                                                                canEdit || item.created_by.username === user.username ?
                                                                <button className="btn btn-sm rounded-circle text-default" onClick={() => editItem(item.id)}>
                                                                    <FontAwesomeIcon className='text-green' icon={solid('edit')} />
                                                                </button>
                                                                : null
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    </>
                                    :
                                    <>
                                    {
                                        currentEmployee ?
                                        <>
                                        {
                                            !showForm ?
                                            <p className='alert alert-warning my-3'>Sem objetivos ainda para {currentEmployee.user.first_name} {currentEmployee.user.last_name}</p>
                                            : null
                                        }
                                        </>
                                        : null
                                    }
                                    </>
                                }
                                </div>
                                </>
                                :
                                <p>Não há colaboradores em sua equipe ainda</p>
                            }
                            </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, {useMemo, useRef, useState} from 'react';
import { useFormik } from 'formik';
import careerService from '../../services/data/career.service';
import { Toast } from 'primereact/toast';
import moment from "moment";
import 'moment/locale/pt-br'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function FormAddWorkExperience(props) {

    const toast = useRef(null);
    const userId = props.userId
    const [jobTitle, setJobTitle] = useState('')
    const [employer, setEmployer] = useState('')
    const [detail, setDetail] = useState('')
    const [monthStart, setMonthStart] = useState('')
    const [yearStart, setYearStart] = useState('')
    const [monthEnd, setMonthEnd] = useState('')
    const [yearEnd, setYearEnd] = useState('')
    const [inactive, setInactive] = useState(true)

    const modalRef = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useMemo(() => {

        getItem()

    }, [props])

    function getItem() {
        if (props.object) {
            setJobTitle(props.object.jobtitle)
            setEmployer(props.object.employer)
            setDetail(props.object.detail)
            setMonthStart(
                moment(props.object.start).format("M")
            )
            setYearStart(
                moment(props.object.start).format("Y")
            )

            if (props.object.end) {

                setInactive(false)

                setMonthEnd(
                    moment(props.object.end).format("M")
                )
                setYearEnd(
                    moment(props.object.end).format("Y")
                )
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            jobtitle: jobTitle,
            employer: employer,
            detail: detail,
            month_start: monthStart,
            year_start: yearStart,
            inactive: inactive,
            month_end: monthEnd,
            year_end: yearEnd,
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                user_id: userId,
                jobtitle: values.jobtitle,
                employer: values.employer,
                detail: values.detail,
                month_start: values.month_start,
                year_start: values.year_start,
            }
            if (values.month_end) {
                data.month_end = values.month_end
                data.year_end = values.year_end
            }

            if (props.object) {

                data.id = props.object.id

                careerService.updateUserWorkExperience(data).then((response) => {

                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Experiência atualizada com sucesso', life: 3000 });

                    props.updateData()
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            } else {

                careerService.createUserWorkExperience(data).then((response) => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Experiência adicionada com sucesso', life: 3000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            }
        },
    });

    function deleteItem() {
        var data = {
            id: props.object.id
        }
        careerService.deleteUserWorkExperience(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Item apagado com sucesso', life: 3000 });

            props.updateData()

            setTimeout(() => {
                hideModal()
                props.showBar(false)
            }, 3000);
        })
    }

    return (
        <>
        <Toast ref={toast} position="top-right" />
        <form className='border rounded bg-light p-3' onSubmit={formik.handleSubmit}>

            <label className="fw-bold font110">Experiência Profissional</label>
            
            <div className="col mt-3">
                <label className="form-label">Cargo <span className="text-danger">*</span></label>
                <input name='jobtitle' type="text" className="form-control text-capitalize" {...formik.getFieldProps('jobtitle')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Empresa <span className="text-danger">*</span></label>
                <input name='employer' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('employer')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Descrição <span className="text-danger">*</span></label>
                <textarea className='form-control' {...formik.getFieldProps('detail')} required></textarea>
            </div>

            <div className="form-check form-switch my-3">
                <label className="form-switch-label align-baseline">Este é meu cargo atual</label>
                <input className="form-check-input" type="checkbox" role="switch" name='inactive' aria-checked={formik.values.inactive} checked={formik.values.inactive} {...formik.getFieldProps('inactive')} />
            </div>

            <div className='row'>
                <div className='mb-4 ps-1'>
                    <label className="form-label">Data de Início</label>
                    <div className='row px-0'>
                        <div className='col'>
                            <select className="form-select" required="required" {...formik.getFieldProps('month_start')}>
                                <option value="">Mês</option>
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </select>
                        </div>
                        <div className='col'>
                            <input className="form-control" type='number' maxLength="4" min="1950" max={ new Date().getFullYear() } placeholder='Ano' name='year_start' required="required" autoComplete="off" {...formik.getFieldProps('year_start')} />
                        </div>
                    </div>
                </div>

                {
                    !formik.values.inactive ?
                    <>
                    <div className='mb-4 ps-1'>
                        <label className="form-label">Data de Término</label>
                        <div className='row px-0'>
                            <div className='col'>
                                <select className="form-select" required="required" {...formik.getFieldProps('month_end')}>
                                    <option value="">Mês</option>
                                    <option value="1">Janeiro</option>
                                    <option value="2">Fevereiro</option>
                                    <option value="3">Março</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Maio</option>
                                    <option value="6">Junho</option>
                                    <option value="7">Julho</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Setembro</option>
                                    <option value="10">Outubro</option>
                                    <option value="11">Novembro</option>
                                    <option value="12">Dezembro</option>
                                </select>
                            </div>
                            <div className='col'>
                                <input className="form-control" type='number' maxLength="4" min="1950" max={ new Date().getFullYear() } placeholder='Ano' name='year_end' required="required" autoComplete="off" {...formik.getFieldProps('year_end')} />
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    <>
                    </>
                }
            </div>

            <hr/>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        props.object ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
        {
            props.object ?
            <>
            <div className="text-end btnMargin">
                <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar</button>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar esta experiência?
                            </p>
                            <p>
                                {formik.values.jobtitle}
                                <br/>
                                {formik.values.employer}
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            : null
        }
        </>
    )
}
import React, {useState, useRef} from "react";
import companyData from "../../../context/company";
import http from "../../../services/http";
import { Toast } from 'primereact/toast';


export default function FormCompanyLogo() {

    const {company, updateCompany} = companyData()
    const [photo, setPhoto] = useState('')
    const toast = useRef(null);

    function onFileChange(e) {

        const logo = e.target.files[0]
        
        const formData = new FormData();
        formData.append("logo", logo);
        formData.append("id", company.id);

        http.post("company/logo/", formData).then((response) => {
            setPhoto(response.data.logo)
            updateCompany()
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Imagem atualizada com sucesso', life: 4000 });
        })
        .catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
        });
    }

    return (

        <div className="mt-2">
            
            <Toast ref={toast} position="top-right" />

            <h4>Atualizar Logo</h4>
            <form encType="multipart/form-data" id="formImg">
                <input type="file" name='logo' className="form-control" onChange={onFileChange} accept="image/*" />
            </form>
            
            <div className="col text-center mt-3 pt-3">
            {
                photo ?
                <>
                <img alt="logo" src={`${photo}`} className="img-fluid img-thumbnail rounded" width="80" />
                </>
                : null
            }
            </div>
        </div>

    )
}
import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function AddUpdateCostCenter() {

    const navigate = useNavigate()
    const modalRef = useRef()

    const location = useLocation();
    const [props, setProps] = useState('');
    const toast = useRef(null);

    const [objId, setObjId] = useState('')
    const [object, setObject] = useState('')
    
    const [parent, setParent] = useState('') // mesmo pk do objId mas apenas para adicionar novo
    const [jobTitle, setJobTitle] = useState('') // para mostrar qual cargo será superior ao novo
    const [reinitialize, setReinitialize] = useState(false)

    useEffect(() => {

        getObj()

    }, []);

    useMemo(() => {

        if (location && location.state) {
            if (location.state.selection) {

                setReinitialize(true)

                setProps(location.state.selection)
                if (location.state.selection.data) {
                    setObjId(location.state.selection.data.id)
                } else {
                    setObjId(location.state.selection.id)
                }
                
                getObj()
                
            }
            if (location.state.parent) {
                setParent(location.state.parent)
            }
            if (location.state.jobTitle) {
                setJobTitle(location.state.jobTitle)
            }
        }
    }, [location])

    function getObj() {
        if (objId) {
            companyService.getCostCenter(objId).then((response) => {
                
                console.log(response)
                setObject(response)

            }).catch((error) => {
                console.log(error.response)
            });
        }
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function delJob() {
        companyService.deleteDepartment(objId).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Centro de Custo apagado com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                return navigate('/settings/costcenter-management/')
              }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    const formik = useFormik({
        enableReinitialize: reinitialize,
        initialValues: {
            title: object.title,
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
            }

            if (objId) {
                
                data.id = objId

                companyService.updateCostCenter(data).then(() => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
                    //getObj()

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });

            } else {
                
                data.parent_id = parent

                companyService.createCostCenter(data).then((response) => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Centro de Custo criado com sucesso', life: 4000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }
            
        },
    });

    return (
    <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Centro de Custo</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>
                                <button onClick={() => navigate(-1)} className="btn nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </button>
                                <div className="p-3 bg-white rounded border-clean">

                                    {
                                        jobTitle ?
                                        <p className='alert alert-warning'>
                                            Adicionar Centro de Custo abaixo de <strong>{jobTitle}</strong>
                                        </p>
                                        : null
                                    }
                                
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label fw-bold">Título do Centro de Custo <span className="text-danger">*</span></label>
                                            <input name="title" className='form-control' {...formik.getFieldProps('title')} />
                                        </div>
                                        
                                        <div className=" mx-auto btnMargin">
                                            <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">
                                                {
                                                    objId ?
                                                    <span>Atualizar</span>
                                                    :
                                                    <span>Adicionar</span>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                
                                </div>
                                {
                                    objId ?
                                    <>
                                    <div className="text-end btnMargin">
                                        <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Centro de Custo</button>
                                    </div>

                                    <div id="formDel" className="modal fade" ref={modalRef}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Apagar Centro de Custo</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        props && props.childrenCount && props.childrenCount > 0 ?
                                                        <div className='text-center'>
                                                            <Message summary='true' severity="warn" text="Não é possível apagar Centro de Custo que possuem outros abaixo" />
                                                        </div>
                                                        :
                                                        <>
                                                        <p>
                                                            Tem certeza de que deseja apagar este Centro de Custo?
                                                        </p>
                                                        <button onClick={delJob} className="btn btn-sm btn-danger btn-rounded px-4">Sim, Apagar</button>
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )

}
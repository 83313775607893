import React from "react";
import { Skeleton } from 'primereact/skeleton';

export default function SkeletonProcess() {
    return (
        <>
        <div className=" m-3">
            <div className="col m-2">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        <div className=" m-3">
            <div className="col m-2">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        <div className=" m-3">
            <div className="col m-2">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        <div className=" m-3">
            <div className="col m-2">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        <div className=" m-3">
            <div className="col m-2">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        </>
    )
}
import React, { useEffect, useState, useMemo, useRef } from "react";
import ProtectedRoute from '../../routes/protected';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Editor } from 'primereact/editor';
import { renderHeaderEditor } from '../../helpers/editorToolbar';


export default function ImportQuestions() {
    
    const { id } = useParams() //review process ID
    const location = useLocation();
    const navigate = useNavigate();
    const [object, setObject] = useState('')
    const [session, setSession] = useState('')
    const toast = useRef(null)
    const [loading, setLoading] = useState(false);
    const [questList, setQuestList] = useState([]) // value to send
    const [listQuest, setListQuest] = useState([]) // options
    const [page, setPage] = useState(1)
    const headerEditor = renderHeaderEditor();

    useEffect(() => {

        listItens()

    }, [page])

    useMemo(() => {

        if (location && location.state) {

            if (location.state.object) {
                setObject(location.state.object)
            }

            if (location.state.session) {
                setSession(location.state.session)
            }
        }

    }, [location])

    function listItens() {

        let data = {
            company_id: localStorage.getItem("companyId")
        }

        performanceService.listAssessmentQuestions(page, data).then((response) => {    
            setListQuest(response)
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });

    }

    function addToList(item) {

        let inpt = document.getElementById(`check_${item.id}`)

        // check new checked state
        if (inpt.checked) {
            setQuestList([...questList, item])
        } else {
            removeFromList(item.id)
        }

    }

    function removeFromList(id) {

        const newList = questList.filter(item => item.id !== id)
        if (newList){
            setQuestList(newList)
        }
    }

    function checifInList(id) {

        let _item = questList.filter(item => item.id === id)[0]

        if (_item) {
            return true
        }
        return false

    }
        
    function rmCheck(id) {
        let inpt = document.getElementById(`check_${id}`)
        if (inpt){
            inpt.checked = false
        }
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    const load = () => {
        setLoading(true);
        formik.submitForm()
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            questions: null
        },
        onSubmit: () => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                assessment_id: id,
                session_id: session,
                questions: questList.map(item => item.question)
            }

            performanceService.importQuestionJobPerformance(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Pergunta(s) adicionada(s) com sucesso', life: 3000 });

                formik.resetForm()

                setTimeout(() => {
                    setLoading(false);
                    formik.resetForm()

                    return navigate(`/performance-review/${id}/process/`)
                }, 3000);

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
            }).finally(() => {
                setLoading(false)
            })

        },
    });

    return (

        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />
            <div className="container-fluid">
                <div className='row flex-nowrap paddingBottom200'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5 text-decoration-none text-white" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>

                <div className='d-flex my-4 mx-5 bg-white contentShadowFull rounded-top' style={{minHeight: '500px'}}>

                    <div className='flex-column col-5 paddingBottom200'>

                        <div className="p-4">
                            <div className="border-bottom">
                                <p className="font110 fw-bold mb-0">Lista de Perguntas</p>
                                <p>Escolha na lista abaixo as perguntas para adicionar ao processo</p>

                            </div>
                            

                            {
                                listQuest && listQuest.results ?
                                <>
                                
                                <div className='text-end mt-3 mb-1'>Mostrando <strong>{listQuest.results.length}</strong> de {listQuest.count} itens</div>
                                <div className="overflow-auto py-3 ps-2 pe-2 border rounded bg-light" style={{'height': '400px'}}>
                                {
                                    listQuest.results.map((item) =>
                                    <div className="input-group mb-1" key={item.id}>
                                        <div className="input-group-text bg-green-light">
                                            <input id={`check_${item.id}`} checked={ checifInList(item.id) ? 'checked' : null } type="checkbox" className="form-check-input mt-0" onClick={() => addToList(item)} />
                                        </div>
                                        <Editor
                                            className='noToolbar form-control p-0 border-0'
                                            style={{ minHeight: '50px' }}
                                            headerTemplate={headerEditor}
                                            value={item.question}
                                            readOnly
                                        />                                   
                                    </div>
                                    )
                                }
                                </div>
                                <div className='float-end mt-2'>
                                    <nav>
                                        <ul className='pagination pagination-sm'>
                                            <li className='page-item'>
                                                { 
                                                    listQuest.previous ? 
                                                    <span onClick={subPage} className='page-link text-default' role="button">
                                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                    </span>
                                                    :
                                                    <span className='page-link text-grey disabled'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                    </span>
                                                }
                                            </li>
                                            <li className='page-item'>
                                                {
                                                    listQuest.next ?
                                                    <span onClick={addPage} className='page-link text-default' role="button">
                                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                    </span>
                                                    :
                                                    <span className='page-link text-grey disabled'>
                                                        <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                    </span>
                                                }
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                </>
                                : null
                            }
                            
                        </div>
                        

                    </div>

                    <div className='flex-column col bg-light'>
                    <div className="p-4">
                            <p className="font110 fw-bold border-bottom pb-3">Perguntas Selecionadas</p>

                            {
                                questList ?
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className='text-end mt-3 mb-1'><strong>{questList.length}</strong> itens adicionados</div>
                                        {
                                            questList.map((item) =>
                                            <div className="input-group mb-1" key={item.id}>
                                                <Editor
                                                    className='noToolbar form-control p-0 border-0'
                                                    style={{ minHeight: '50px' }}
                                                    headerTemplate={headerEditor}
                                                    value={item.question}
                                                    readOnly
                                                />    
                                                <div className="input-group-text bg-light" role="button" onClick={() => {rmCheck(item.id); removeFromList(item.id)}}>
                                                    <FontAwesomeIcon className='text-vermelho font085' icon={solid('trash-can')} /> 
                                                </div>
                                            </div>
                                            )
                                        }
                                        {
                                            questList.length > 0 ?
                                            <>
                                            <hr/>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="btnMargin">
                                                    <Button size="small" className="px-3" rounded label="Adicionar" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
                                                </div>
                                            </form>
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                                : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>

    )
}
class TokenService {
    
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            return user.refresh;
        }
        return null
    }
  
    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            return user.access;
        }
        return null
    }
  
    updateLocalAccessToken(refresh, token) {
        let _user = {
            access: token,
            refresh: refresh
        }
        localStorage.setItem("user", JSON.stringify(_user));
    }
  
    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem("user");
    }
}

export default new TokenService();
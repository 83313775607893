import React, {useState, useEffect, useMemo, useRef} from 'react';
import ProtectedRoute from '../../routes/protected';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../services/data/company.service';
import performanceService from '../../services/data/performance.service';
import VerifyPerms from '../../context/userAccess';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';



export default function GoalPlanCascade() {
    
    const { id } = useParams()
    const toast = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [object, setObject] = useState('')
    const [finalWeight, setFinalWeight] = useState(0)
    const canEdit = VerifyPerms(['change_goalplan'])

    const [organizations, setOrganizations] = useState('')
    const [organizationList, setOrganizationList] = useState([])

    useEffect(() => {
        getGoalPlan()
        listSubCompanies()
    }, [])

    useMemo(() => {

        if (location && location.state && location.state.finalWeight) {
            setFinalWeight(location.state.finalWeight)
        }

    }, [location])

    function getGoalPlan() {
        performanceService.getGoalPlan(id).then((response) => {
            setObject(response)
            setOrganizations(response.subcompanies)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function goBack() {
        return navigate(`/goal/plan/`)
    }

    function seeHistory() {
        return navigate(`/goal/plan/${id}/edit/history/`, {state: {finalWeight: finalWeight}})
    }

    function listSubCompanies() {

        companyService.listSubCompany().then((response) => {
        
            let res = response.results.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setOrganizationList(res)

        }).catch((error) => {
            console.log(error.response)
        });

    }

    const formik = useFormik({
        initialValues: {
            organizations: organizations
        },
        onSubmit: values => {

            let data = {
                id: id,
                company_id: localStorage.getItem("companyId"),
                subcompanies: values.organizations
            }

            performanceService.updateGoalPlan(data).then(() => {
            
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Plano de Metas enviado com sucesso', life: 3000 });
    
            }).catch((error) => {
                console.log(error.response)
            });

        },
    });

    return (
        <ProtectedRoute>
            <div className="container-fluid">

                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5" role='button' onClick={() => goBack()}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    
                    <ul className='nav nav-tabs pt-4 px-3'>
                        <li className='nav-item'>
                            <a href={`/goal/plan/${id}/goals/`} className='nav-link text-green'>
                                Objetivos
                            </a>
                        </li>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Cascatear
                            </span>
                        </li>
                        <li className='nav-item'>
                            <span className='nav-link text-green cursorPointer' onClick={() => seeHistory()}>
                                Histórico de Alterações
                            </span>
                        </li>
                    </ul>

                    <div className='mt-3 px-3'>
                    {
                        canEdit ?
                        <>
                        <div className='col-5'>
                            <p className='lead fw-bold fs-6'>Enviar Plano de Metas para</p>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='mt-3'>
                                    <label className="form-label fw-bold">
                                        Organizações { organizations.length > 0 ? <>({organizations.length})</> : null}
                                    </label>
                                    <div className='p-inputgroup'>
                                        <MultiSelect filter placeholder="Selecionar" value={organizations} onChange={(e) => { formik.setFieldValue('organizations', e.value) ;setOrganizations(e.value)}} options={organizationList} optionLabel="title" display="chip" className="rounded" />
                                    </div>
                                    
                                </div>
                                <div className="mx-auto btnMargin">
                                    <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">
                                        <span>Atualizar</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        </>
                        :
                        null
                    }
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
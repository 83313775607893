import React, {useState, useEffect, useRef, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Chip } from 'primereact/chip';
import moment from "moment";
import 'moment/locale/pt-br'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormUpdatePayGrade from '../../../components/settings/forms/formUpdatePayGrade';


export default function PayStructureDetail() {
    const { id } = useParams()
    const toast = useRef(null);
    const [payStructure, setPayStructure] = useState('')
    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    // edit PayGrade
    const modalRef = useRef()
    const modalRef2 = useRef()
    const [currentGrade, setCurrentGrade] = useState('')
    const [currentGradeId, setCurrentGradeId] = useState('')


    useEffect(() => {
        getPayStructure()
        listPayGrades()
    }, []);

    useMemo(() => {
        listPayGrades()
    }, [page]);

    function getPayStructure() {
        companyService.getPayStructure(id).then((response) => {
            setPayStructure(response)

        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listPayGrades() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            structure_id:id
        }
        companyService.listPayGrade(page, _data).then((response) => {
            setItemsList(response)
            console.log(response)

        }).catch((error) => {
            console.log(error.response)
        });
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()

        setCurrentGradeId('')
    }

    const hideModal2 = () => {
        const modalEl = modalRef2.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()

    }

    function delGrade() {
        companyService.deletePayGrade(currentGrade.id).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Nível apagado com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal2()
                listPayGrades()
              }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row">
                            
                            <div className='m-2'>
                                <a href="/settings/salary-structure/management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                            </div>

                            <div className='col-5'>
                                <div className="p-3 bg-white rounded border-clean">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span className='font120 fw-bold'>{payStructure.title} <span className='fw-normal'>({payStructure.internal_code})</span></span>
                                        <a href={`/settings/salary-structure/management/${payStructure.id}/edit/`} className="btn btn-xs" >
                                            <FontAwesomeIcon className='text-green font095' icon={solid('edit')} />
                                        </a>
                                    </div>
                                    <hr className='mt-0'/>
                                    <table className='table table-borderless'>
                                        <tbody>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Criado em</td>
                                                <td>{moment(payStructure.created).format("DD-MMM-YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Criado por</td>
                                                <td>
                                                    {
                                                        payStructure.created_by ?
                                                        <>
                                                        {payStructure.created_by.first_name} {payStructure.created_by.last_name} | {payStructure.created_by.email}
                                                        </>
                                                        : null
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Ativo em</td>
                                                <td>{moment(payStructure.created).format("DD-MMM-YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Tipo / Sindicato</td>
                                                <td>
                                                {
                                                    payStructure.category ?
                                                    <>{payStructure.category.title}</>
                                                    :
                                                    <>-</>
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Organizações ({ payStructure.subcompanies ? payStructure.subcompanies.length : 0})</td>
                                                <td>
                                                {
                                                    payStructure.subcompanies ?
                                                    <>
                                                    {
                                                        payStructure.subcompanies.map((item, index) =>
                                                            <Chip key={index} label={item.title} className='m-1' />
                                                        )
                                                    }
                                                    </>
                                                    : 
                                                    <>Sem Organizações</>
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='fw-bold align-middle text-end p-1'>Status</td>
                                                <td>
                                                {
                                                    payStructure.inactive ?
                                                    <>
                                                    <Chip label="Inativo" className='bg-vermelho-light' />
                                                    </>
                                                    :
                                                    payStructure.deleted ?
                                                    <>
                                                    <Chip label="Apagado" className='bg-vermelho-light' />
                                                    </>
                                                    :
                                                    <Chip label="Ativo" />
                                                }
                                                </td>
                                            </tr>
                                            {
                                                payStructure.inactive ?
                                                <tr>
                                                    <td className='fw-bold align-middle text-end p-1'>Inativo em</td>
                                                    <td>{moment(payStructure.date_inactive).format("DD-MMM-YYYY")}</td>
                                                </tr>
                                                : null
                                            }
                                            {
                                                payStructure.deleted ?
                                                <tr>
                                                    <td className='fw-bold align-middle text-end p-1'>Apagado em</td>
                                                    <td>{moment(payStructure.date_deleted).format("DD-MMM-YYYY")}</td>
                                                </tr>
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='col-7'>
                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Níveis ({itemsList && itemsList.results ? itemsList.results.length : 0})
                                    </span>
                                    <a href={`/settings/salary-structure/management/${id}/grade/create/`} className="btn btn-sm">
                                        <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                    </a>
                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                                        <>
                                        <table className='table table-borderless thover borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th className='text-center'>
                                                        Nível
                                                    </th>
                                                    <th className='text-center'>
                                                        Criado em
                                                    </th>
                                                    <th className='text-center'>
                                                        Salários
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle text-center">
                                                                {item.title}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {moment(item.created).format("DD-MMM-YYYY")}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.countLevel}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <a href={`/settings/salary-structure/management/${id}/${item.id}/level/create/`} className="font095 btn btn-sm btn-outline-success btn-rounded" >
                                                                    Adicionar Salário
                                                                </a>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <button className="btn btn-xs btn-rounded" onClick={() => setCurrentGradeId(item.id)} data-bs-toggle="modal" data-bs-target="#formEditGrade">
                                                                    <FontAwesomeIcon className='text-green font095' icon={solid('edit')} />
                                                                </button>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <button className="btn btn-xs btn-rounded" onClick={() => setCurrentGrade(item)} data-bs-toggle="modal" data-bs-target="#formDelGrade">
                                                                    <FontAwesomeIcon className='text-danger font085' icon={solid('trash-can')} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className='d-flex justify-content-end'>
                                            <nav>
                                                <ul className='pagination pagination-sm'>
                                                    <li className='page-item'>
                                                        { 
                                                            itemsList.previous ? 
                                                            <span onClick={subPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                        }
                                                    </li>
                                                    <li className='page-item'>
                                                        {
                                                            itemsList.next ?
                                                            <span onClick={addPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                        }
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="formEditGrade" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Nível</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                currentGradeId ?
                                <FormUpdatePayGrade hideModal={hideModal} id={currentGradeId} listItems={listPayGrades} />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div id="formDelGrade" className="modal fade" ref={modalRef2}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar Nível</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                currentGrade ?
                                <>
                                <p>
                                    Tem certeza de que deseja apagar o Nível {currentGrade.title} ?
                                </p>
                                <button onClick={delGrade} className="btn btn-sm btn-danger btn-rounded px-4">Sim, Apagar</button>
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import CorreiosService from '../../../helpers/correios.service';
import CompanyLogoDefault from '../../../assets/images/company-logo-default.png'
import InputMask from "react-input-mask";
import http from '../../../services/http';
import checkCPFouCNPJ from '../../../helpers/checkCPFouCNPJ';


export default function AddUpdateSubCompany() {

    const navigate = useNavigate()

    const location = useLocation();
    const [props, setProps] = useState('');
    const toast = useRef(null);

    const [objId, setObjId] = useState('')
    const [object, setObject] = useState('')
    const [photo, setPhoto] = useState('') // para mostrar preview
    
    const [parent, setParent] = useState('') // mesmo pk do objId mas apenas para adicionar novo
    const [jobTitle, setJobTitle] = useState('') // para mostrar qual cargo será superior ao novo
    const [reinitialize, setReinitialize] = useState(false)

    const company_id = localStorage.getItem("companyId")
    const [isMainCompany, setMainCompany] = useState(false)


    useEffect(() => {

        getObj()
        checkIfMainCompany()

    }, []);

    useMemo(() => {

        if (location && location.state) {
            if (location.state.selection) {

                setReinitialize(true)

                setProps(location.state.selection)
                if (location.state.selection.data) {
                    setObjId(location.state.selection.data.id)
                } else {
                    setObjId(location.state.selection.id)
                }

                getObj()
                
            }
            if (location.state.parent) {
                setParent(location.state.parent)
            }
            if (location.state.jobTitle) {
                setJobTitle(location.state.jobTitle)
            }
        }
    }, [location])

    function getObj() {
        if (objId) {
            companyService.getSubCompany(objId).then((response) => {
            
                setObject(response)

            }).catch((error) => {
                console.log(error.response)
            });
        }
    }

    function delJob() {
        companyService.deleteSubCompany(objId).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Organização apagada com sucesso', life: 3000 });

            setTimeout(() => {
                // hide
                return navigate('/settings/subcompany-management/')
              }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    function onFileChange(e) {
            
        const formData = new FormData();
        formData.append("logo", e.target.files[0]);
        formData.append("id", objId);

        http.post("subcompany/logo/", formData).then((response) => {
            setPhoto(response.data.logo)
            getObj()
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Imagem atualizada com sucesso', life: 4000 });
        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    const validate = values => {

        const errors = {};

        if (values.legal_id && values.legal_id.length > 13) {

            var chId = checkCPFouCNPJ(values.legal_id)

            if (chId) {
                return true
            }
            var idText = 'CNPJ'

            if(values.size === '0') {
                idText = 'CPF'
            }
            errors.legal_id = `${idText} inválido`

        }

        return errors;
    };

    function checkIfMainCompany() { 
        if (object && object.company === company_id) {
            setMainCompany(true)
        }
    }

    const formik = useFormik({
        enableReinitialize: reinitialize,
        initialValues: {
            title: object.title,
            legal_id: object.legal_id,
            phone: object.phone,
            website: object.website,
            zipcode: object.zipcode,
            address: object.address,
            adddressnumber: object.adddressnumber,
            adddresscomp: object.adddresscomp,
            neighbourhood: object.neighbourhood,
            state: object.state,
            city: object.city,
        },
        validate,
        onSubmit: values => {

            let data = {
                company_id: company_id,
                title: values.title,
                legal_id: values.legal_id,
                phone: values.phone,
                website: values.website,
                zipcode: values.zipcode,
                address: values.address,
                adddressnumber: values.adddressnumber,
                adddresscomp: values.adddresscomp,
                neighbourhood: values.neighbourhood,
                state: values.state,
                city: values.city,
            }

            if (objId) {
                
                data.id = objId

                companyService.updateSubCompany(data).then(() => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
                    //getObj()

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });

            } else {
                
                data.parent_id = parent

                companyService.createSubCompany(data).then((response) => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Organização criada com sucesso', life: 4000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }
            
        },
    });

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Organização</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>
                                <a href="/settings/subcompany-management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">

                                    {
                                        jobTitle ?
                                        <p className='alert alert-warning'>
                                            Adicionar Unidade abaixo de <strong>{jobTitle}</strong>
                                        </p>
                                        :
                                        <div className="mb-4">
                                            <div className="text-start" style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#addPhoto">
                                                {
                                                    object.logo ?
                                                    <>
                                                    <img src={`${object.logo}`} alt='logo' className="img-thumbnail img-fluid rounded-circle border-0" width="50" />
                                                    <FontAwesomeIcon icon={solid('camera')} className="btn btn-sm text-green divBorderClean p-1 bg-white rounded position-relative" style={{top: '20px', right: '20px', width:'20px'}} />
                                                    <span className="fs-4 position-relative" style={{left:'-15px'}} >{ object.title }</span>
                                                    </>  
                                                    : 
                                                    <>
                                                    <img src={CompanyLogoDefault} alt="logo" className="img-fluid rounded border-0" width="50" />
                                                    <span className="fs-4 ms-2 position-relative" >{ object.title }</span>
                                                    </>
                                                }
                                                
                                            </div>
                                            <div id="addPhoto" className="modal fade">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Adicionar Logo</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="mt-2">
                                                                <h4>Adicionar Logo</h4>

                                                                <input type="file" className="form-control" onChange={onFileChange} accept="image/*" />
                                                                <div className="col text-center mt-3 pt-3">
                                                                {
                                                                    photo ?
                                                                    <>
                                                                    <img alt="logo" src={`${photo}`} className="img-fluid img-thumbnail rounded" width="80" />
                                                                    </>
                                                                    : null
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label fw-bold">Nome da Organização <span className="text-danger">*</span></label>
                                            <input name="title" className='form-control' {...formik.getFieldProps('title')} />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">CNPJ <span className="text-danger">*</span></label>
                                            <InputMask mask='99.999.999/9999-99' type="text" name="legal_id" className="form-control" required {...formik.getFieldProps('legal_id')}/>
                                            {formik.errors.legal_id ? (<span name="legal_id" className="text-vermelho">{formik.errors.legal_id}</span>) : null}
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">Telefone <span className="text-danger">*</span></label>
                                            <InputMask mask='(99) 9999-9999?' maskChar={null} formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} type="text" name="phone" className="form-control" required {...formik.getFieldProps('phone')} />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">Website <span className="text-danger">*</span></label>
                                            <input type="url" name="website" className="form-control" required {...formik.getFieldProps('website')} />
                                            {formik.errors.website ? (<span name="website" className="text-vermelho">{formik.errors.website}</span>) : null}
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">CEP <span className="text-danger">*</span></label>
                                            <InputMask mask='99999-999' type="text" name={formik.values.zipcode} className="form-control" required {...formik.getFieldProps('zipcode')} onChange={
                                                e => {

                                                    formik.handleChange(e)

                                                    if (e.target.value.length > 8) {

                                                        var data = {
                                                            zipcode: e.target.value
                                                        }

                                                        CorreiosService.getZipcode(data).then((response) => {
                                            
                                                            formik.setFieldValue("address", response.data['endereco'])
                                                            formik.setFieldValue("neighbourhood", response.data['bairro'])
                                                            formik.setFieldValue("city", response.data['cidade'])
                                                            formik.setFieldValue("state", response.data['estado'])
                                                
                                                        }).catch((error) => {
                                                            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                                                        })

                                                    }

                                                }
                                            } />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Endereço <span className="text-danger">*</span></label>
                                            <input type="text" name="address" className="form-control" required {...formik.getFieldProps('address')} />
                                        </div>

                                        <div className="row ps-0">
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Nº <span className="text-danger">*</span></label>
                                                <input type="number" name="adddressnumber" className="form-control" required {...formik.getFieldProps('adddressnumber')} />
                                            </div>

                                            <div className="mb-3 col-md-8">
                                                <label className="form-label">Complemento (opcional)</label>
                                                <input type="text" name="adddresscomp" className="form-control" {...formik.getFieldProps('adddresscomp')} />
                                            </div>
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label className="form-label">Bairro <span className="text-danger">*</span></label>
                                            <input type="text" name="neighbourhood" className="form-control" required {...formik.getFieldProps('neighbourhood')} />
                                        </div>

                                        <div className="mb-3 col-md-8">
                                            <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                            <input type="text" name="city" className="form-control" required {...formik.getFieldProps('city')} />
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Estado <span className="text-danger">*</span></label>
                                            <select className="form-select" name="state" required {...formik.getFieldProps('state')} >
                                                <option disabled value="">Selecione</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                            </select>
                                        </div>
                                        
                                        <div className=" mx-auto btnMargin">
                                            <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">
                                                {
                                                    objId ?
                                                    <span>Atualizar</span>
                                                    :
                                                    <span>Adicionar</span>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                
                                </div>
                                {
                                    !isMainCompany ?
                                    <>
                                    <div className="text-end btnMargin">
                                        <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Organização</button>
                                    </div>

                                    <div id="formDel" className="modal fade">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Apagar Organização</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        props && props.childrenCount && props.childrenCount > 0 ?
                                                        <div className='text-center'>
                                                            <Message summary='true' severity="warn" text="Não é possível apagar Organizações que possuem outras abaixo" />
                                                        </div>
                                                        :
                                                        <>
                                                        {
                                                            props && props.internal_code === '1' ?
                                                            <>
                                                            <div className='text-center'>
                                                                <Message summary='true' severity="warn" text="Não é possível apagar a Organização principal" />
                                                            </div>
                                                            </>
                                                            :
                                                            <>
                                                            <p>
                                                                Tem certeza de que deseja apagar esta Organização?
                                                            </p>
                                                            <button onClick={delJob} className="btn btn-sm btn-danger btn-rounded px-4">Sim, Apagar</button>
                                                            </>
                                                        }
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
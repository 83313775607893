import React from 'react';

export const renderHeaderEditor = () => {
    return (
        <>
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button className="ql-list" value="bullet" aria-label="Unordered List"></button>
        </span>
        </>
    );
};
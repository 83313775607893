import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from "moment";
import 'moment/locale/pt-br'
import FormCreateGoal from '../../components/performance/forms/formCreateGoal';
import companyService from '../../services/data/company.service';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import VerifyPerms from '../../context/userAccess';
import SkeletonGoals from './skeletonGoals';


export default function GoalPlanGoals() {
    
    const [pageLoad, setPageLoad] = useState(true)
    const { id } = useParams()
    const [itemsList, setItemsList] = useState([]);
    const [object, setObject] = useState('')
    const [categoryWeight, setCategoryWeight] = useState([])
    const [finalWeight, setFinalWeight] = useState(0)
    const [categories, setCategories] = useState('')
    const navigate = useNavigate();
    const modalRef = useRef()
    const canEdit = VerifyPerms(['change_goalplan'])

    useEffect(() => {
        getGoalPlan()
        listItems()
        listCategories()
    }, [])

    function getGoalPlan() {
        performanceService.getGoalPlan(id).then((response) => {
            setObject(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listItems() {
        let data = {
            goal_plan: id
        }
        performanceService.listGoalsByCategory(data).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'goalplan'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategories(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function goBack() {
        return navigate(`/goal/plan/`)
    }

    useEffect(() => {

        
        // somar pesos por categoria
        if (itemsList && itemsList.results) {

            let weit = []

            Object.keys(itemsList.results).forEach((key) => {
    
                if (itemsList.results[key].length > 0) {
                    let res = itemsList.results[key].map((item) => {
                        return item.weight
                    }).reduce((a, b) => a+b, 0)

                    weit.push(res)
                }

            })
            
            setCategoryWeight(weit)
        }

    }, [itemsList])

    useEffect(() => {
        if (categoryWeight.length > 0) {
            setFinalWeight(categoryWeight.reduce((a,b) => a+b))
        }
    }, [categoryWeight])

    function editItem(id) {
        return navigate(`/goal/${id}/edit/`)
    }

    function editItemStatus(id) {
        return navigate(`/goal/${id}/edit/status/`)
    }

    function seeCascade() {
        return navigate(`/goal/plan/${id}/cascade/`, {state: {finalWeight: finalWeight}})
    }

    function seeHistory() {
        return navigate(`/goal/plan/${id}/edit/history/`, {state: {finalWeight: finalWeight}})
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5" role='button' onClick={() => goBack()}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    
                {
                    VerifyPerms(['add_goalplan']) ?
                    <div className='pt-2 px-3'>
                        <button className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#formAdd">
                            <FontAwesomeIcon icon={solid('plus-circle')} /> Adicionar Objetivo
                        </button>
                    </div>
                    : null
                }

                {
                    canEdit ?
                    <ul className='nav nav-tabs pt-4 px-3'>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Objetivos
                            </span>
                        </li>
                        {
                            finalWeight === 100 ?
                            <li className='nav-item'>
                                 <span className='nav-link text-green cursorPointer' onClick={() => seeCascade()}>
                                    Cascatear
                                </span>
                            </li>
                            : null
                        }
                        <li className='nav-item'>
                            <span className='nav-link text-green cursorPointer' onClick={() => seeHistory()}>
                                Histórico de Alterações
                            </span>
                        </li>
                    </ul>
                    : null
                }
                {
                    pageLoad ?
                    <SkeletonGoals />
                    :
                    <>
                    {
                        itemsList && itemsList.results ?
                        <>
                        <div className='mt-4 px-4'>
                            <span className='fs-6 fw-bold text-default'>
                                Total: {finalWeight}%
                            </span>
                            { finalWeight !== 100 ? <p className='text-vermelho'>Favor calibrar os pesos para atingir sempre 100%</p> : <p></p> }
                        </div>
                        {
                            Object.keys(itemsList.results).map((key, index) => 
                                <div key={index} className='pt-1 px-3'>
                                    <div className='fs-6 fw-bold bg-green-light text-default p-2 rounded-top'>{key} ({categoryWeight[index]}%)</div>
                                    {
                                        itemsList.results[key].length > 0 ?
                                        <>
                                        <table className='table table-borderless thover text-default borderBottom'>
                                            <thead className='borderBottom bg-light'>
                                                <tr>
                                                    <th style={{width: '25%'}}>
                                                        Objetivo
                                                    </th>
                                                    <th className='text-center' style={{width: '30%'}}>
                                                        Métrica
                                                    </th>
                                                    <th className='text-center'>
                                                        Período
                                                    </th>
                                                    <th className='text-center'>
                                                        Status
                                                    </th>
                                                    <th className='text-center'>
                                                        Peso
                                                    </th>
                                                    <th className='text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                            itemsList.results[key].map((item, idx) =>
                                                <tr key={idx} className=''>
                                                    <td className="align-middle">
                                                        &#x2022; <span className='text-green fw-bold'>{item.title}</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                    </td>
                                                    <td className="align-middle text-center">
                                                    {
                                                        item.goalLastStatus.status ?
                                                        <>
                                                        {
                                                            item.goalLastStatus.status === 'CS' ?
                                                            <>
                                                            <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                            </>
                                                            :
                                                            <>{item.goalLastStatus.status_display}</>
                                                        }
                                                        </>
                                                        :
                                                        <>Não Iniciado</>
                                                    }
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {item.weight}%
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        {
                                                            canEdit ?
                                                            <div className='dropdown'>
                                                                <button className='btn btn-sm rounded-circle border dropdown-toggle no-caret' data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <FontAwesomeIcon className='text-green' icon={solid('ellipsis')} />
                                                                </button>
                                                                <ul className='dropdown-menu border headerBorder font1em'>
                                                                    <li>
                                                                        <button className="dropdown-item text-default" onClick={() => editItem(item.id)}>
                                                                            Editar
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button className="dropdown-item text-default" onClick={() => editItemStatus(item.id)}>
                                                                            Alterar Status
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            )
                        }
                        </>
                        :
                        null
                    }
                    </>
                }
                </div>

                <div id="formAdd" className="modal fade" ref={modalRef}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Adicionar Objetivo</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {
                                    categories ?
                                    <FormCreateGoal listItems={listItems} categoriesList={categories} listCategories={listCategories} goalPlan={id} hideModal={hideModal} />
                                    : null
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
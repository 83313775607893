import React, {useState, useEffect,} from 'react';
import ProtectedRoute from '../../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';


export default function CompanyReports() {

    const [dashUrl, setDashUrl] = useState('')


    useEffect(() => {
        getReport()
    }, []);

    function getReport() {
        companyService.companyBi().then((response) => {
            setDashUrl(response.data)
        }).catch((error) => {
            console.log(error.response)
        });
    }


    return (
        <ProtectedRoute>

            <script src="https://unpkg.com/amazon-quicksight-embedding-sdk@1.0.12/dist/quicksight-embedding-js-sdk.min.js"></script>

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Indicadores
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className="row m-3 p-3 justify-content-start">

                        <div className='col bg-white pb-5 ps-3'>
                           
                            {
                                dashUrl ?
                                <div className='pt-3'>
                                   <iframe src={dashUrl} className="quicksight-embedding-iframe" width="100%" height="700"></iframe>
                                </div>
                                : null
                            }
                            
                        </div>

                        </div>
                    
                </div>
            </div>
            

        </ProtectedRoute>
    )
}
import React, { useRef } from "react";
import ProtectedRoute from "../../routes/protected";
import SideBarCompanyManagement from "../../components/sidebars/sidebarCompanyManagement";
import { useFormik } from 'formik';
import companyService from "../../services/data/company.service";
import InputMask from "react-input-mask";
import companyData from "../../context/company";
import CorreiosService from "../../helpers/correios.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormCompanyLogo from '../../components/settings/forms/formCompanyLogo'
import CompanyLogoDefault from '../../assets/images/company-logo-default.png'
import { Toast } from 'primereact/toast';
import checkCPFouCNPJ from "../../helpers/checkCPFouCNPJ";
import VerifyPerms from "../../context/userAccess";


export default function editCompany() {

    const { company, updateCompany } = companyData()
    const companyId = localStorage.getItem('companyId')

    const toast = useRef(null);

    const validate = values => {

        const errors = {};

        if (values.legal_id && values.legal_id.length > 13) {

            var chId = checkCPFouCNPJ(values.legal_id)

            if (chId) {
                return true
            }
            var idText = 'CNPJ'

            if(values.size === '0') {
                idText = 'CPF'
            }
            errors.legal_id = `${idText} inválido`

        }

        return errors;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: company.name,
            legal_id: company.legal_id,
            phone: company.phone,
            website: company.website,
            zipcode: company.zipcode,
            address: company.address,
            adddressnumber: company.adddressnumber,
            adddresscomp: company.adddresscomp,
            neighbourhood: company.neighbourhood,
            state: company.state,
            city: company.city,
            size: company.size
        },
        validate,
        onSubmit: values => {
            var data = {
                id: companyId,
                name: values.name,
                legal_id: values.legal_id,
                phone: values.phone,
                website: values.website,
                zipcode: values.zipcode,
                address: values.address,
                adddressnumber: values.adddressnumber,
                adddresscomp: values.adddresscomp,
                neighbourhood: values.neighbourhood,
                state: values.state,
                city: values.city,
                size: values.size
            }
            companyService.updateCompany(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
                updateCompany()
            })
            .catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
            });
        },
    });

    return (
        <ProtectedRoute>
            <div className="container-fluid">

                <Toast ref={toast} position="top-right" />

                <div className="row flex-nowrap">
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Empresa</span>
                        </div>
                        {
                            VerifyPerms(['change_company']) ?
                            <>
                            <div className="row mt-5">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="mb-4">
                                        <div className="text-start" style={{cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#addPhoto">
                                            {
                                                company.logo ?
                                                <>
                                                <img src={`${company.logo}`} alt="logo" className="img-thumbnail img-fluid rounded-circle border-0" width="50" />
                                                <FontAwesomeIcon icon={solid('camera')} className="btn btn-sm text-green divBorderClean p-1 bg-white rounded position-relative" style={{top: '20px', right: '20px', width:'20px'}} />
                                                <span className="fs-4 position-relative" style={{left:'-15px'}} >{ company.name }</span>
                                                </>  
                                                : 
                                                <>
                                                <img src={CompanyLogoDefault} alt="logo" className="img-fluid rounded border-0" width="50" />
                                                <span className="fs-4 ms-2 position-relative" >{ company.name }</span>
                                                </>
                                            }
                                            
                                        </div>
                                        <div id="addPhoto" className="modal fade">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Atualizar Logo</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <FormCompanyLogo />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="row g-2 divBorder formMarginBottom" onSubmit={formik.handleSubmit}>
                                        
                                        <div className="mb-3">
                                            <label className="form-label">Nome da Empresa <span className="text-danger">*</span></label>
                                            <input type="text" name="name" className="form-control" required {...formik.getFieldProps('name')} />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">Tamanho da Empresa <span className="text-danger">*</span></label>
                                            <select className="form-select" name="size" required {...formik.getFieldProps('size')} >
                                                <option disabled value="">Selecione</option>
                                                <option value="0">Sou Autônomo</option>
                                                <option value="1">1-10 Funcionários</option>
                                                <option value="2">11-50 Funcionários</option>
                                                <option value="3">51-200 Funcionários</option>
                                                <option value="4">201-500 Funcionários</option>
                                                <option value="5">501-1.000 Funcionários</option>
                                                <option value="6">1.001-5.000 Funcionários</option>
                                                <option value="7">5.001-10.000 Funcionários</option>
                                                <option value="8">+ 10.000 Funcionários</option>
                                            </select>
                                        </div>
                                        
                                        {
                                            formik.values.size === '0' ?
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label">CPF <span className="text-danger">*</span></label>
                                                <InputMask mask='999.999.999-99' type="text" name="legal_id" className="form-control" required {...formik.getFieldProps('legal_id')}/>
                                                {formik.errors.legal_id ? (<span name="legal_id" className="text-vermelho">{formik.errors.legal_id}</span>) : null}
                                            </div>
                                            :
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label">CNPJ <span className="text-danger">*</span></label>
                                                <InputMask mask='99.999.999/9999-99' type="text" name="legal_id" className="form-control" required {...formik.getFieldProps('legal_id')}/>
                                                {formik.errors.legal_id ? (<span name="legal_id" className="text-vermelho">{formik.errors.legal_id}</span>) : null}
                                            </div>
                                        }

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">Telefone <span className="text-danger">*</span></label>
                                            <InputMask mask='(99) 9999-9999?' maskChar={null} formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} type="text" name="phone" className="form-control" required {...formik.getFieldProps('phone')} />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label className="form-label">Website <span className="text-danger">*</span></label>
                                            <input type="url" name="website" className="form-control" required {...formik.getFieldProps('website')} />
                                            {formik.errors.website ? (<span name="website" className="text-vermelho">{formik.errors.website}</span>) : null}
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">CEP <span className="text-danger">*</span></label>
                                            <InputMask mask='99999-999' type="text" name={formik.values.zipcode} className="form-control" required {...formik.getFieldProps('zipcode')} onChange={
                                                e => {

                                                    formik.handleChange(e)

                                                    if (!(e.target.value).includes('_') && e.target.value.length > 0) {

                                                        var data = {
                                                            zipcode: e.target.value
                                                        }

                                                        CorreiosService.getZipcode(data).then((response) => {
                                            
                                                            formik.setFieldValue("address", response.data['endereco'])
                                                            formik.setFieldValue("neighbourhood", response.data['bairro'])
                                                            formik.setFieldValue("city", response.data['cidade'])
                                                            formik.setFieldValue("state", response.data['estado'])
                                                
                                                        }).catch((error) => {
                                                            console.log('Error ', error.data)
                                                        })

                                                    }

                                                }
                                            } />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Endereço <span className="text-danger">*</span></label>
                                            <input type="text" name="address" className="form-control" required {...formik.getFieldProps('address')} />
                                        </div>

                                        <div className="row">
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Nº <span className="text-danger">*</span></label>
                                                <input type="number" name="adddressnumber" className="form-control" required {...formik.getFieldProps('adddressnumber')} />
                                            </div>

                                            <div className="mb-3 col-md-8">
                                                <label className="form-label">Complemento (opcional)</label>
                                                <input type="text" name="adddresscomp" className="form-control" {...formik.getFieldProps('adddresscomp')} />
                                            </div>
                                        </div>
                                        
                                        <div className="mb-3">
                                            <label className="form-label">Bairro <span className="text-danger">*</span></label>
                                            <input type="text" name="neighbourhood" className="form-control" required {...formik.getFieldProps('neighbourhood')} />
                                        </div>

                                        <div className="mb-3 col-md-8">
                                            <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                            <input type="text" name="city" className="form-control" required {...formik.getFieldProps('city')} />
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Estado <span className="text-danger">*</span></label>
                                            <select className="form-select" name="state" required {...formik.getFieldProps('state')} >
                                                <option disabled value="">Selecione</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                            </select>
                                        </div>

                                        <div className="d-grid mx-auto btnMargin">
                                            <button className="btn btn-success btn-rounded" type="submit">Atualizar</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </>
                            :
                            <p className='alert alert-warning m-3'>Você não tem permissão para acessar esta página</p>
                        }
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
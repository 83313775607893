import React, {useState, useEffect, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from "moment";
import 'moment/locale/pt-br'
import companyData from '../../context/company';
import SkeletonGoals from './skeletonGoals';


export default function EmployeeGoalsOrg() {

    const [pageLoad, setPageLoad] = useState(true)
    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [categoryWeight, setCategoryWeight] = useState([])
    const [finalWeight, setFinalWeight] = useState(0)
    const {userPosition, rootUser} = companyData()
    const [goalPlanList, setGoalPlanList] = useState([])
    const [planId, setPlanId] = useState('')
    const [isManager, setIsManager] = useState(false)

    useEffect(() => {
        listPlans()
    }, [])

    useEffect(() => {
        
        if (planId) {
            listItems()
        }
        
    }, [planId])

    useEffect(() => {

        // verifica se o user tem subordinados para poder acessar "Minha Equipe" nos módulos
        if (!rootUser && userPosition && userPosition.childrenCount > 0) {
            setIsManager(true)
        }

        if (rootUser) {
            setIsManager(true)
        }
        
    }, [userPosition, rootUser]);

    function listPlans() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            status: ['AT'] //ativos e inativos
        }

        if (!rootUser && userPosition && userPosition.subcompany) {
            data.subcompanies = userPosition.subcompany.id
        }

        performanceService.listGoalPlans(page, data).then((response) => {

            if (response && response.results.length > 0) {
                setGoalPlanList(
                    response.results.map((item) => {
    
                        return {
                            id: item.id,
                            title: item.title
                        }
                    })
                )

                let resId
    
                if (response.results.length > 1) {
                    resId = response.results.map((val, index, arr) => {
                        {
                            if (arr.length -1 === index) {
                                return val.id
                            } 

                            return null
                        }
                    })
                    setPlanId(resId.filter(function(val) { return val !== null; }).join(", ")) // remove null

                } else {
                    setPlanId(response.results.map((val) => {return val.id} )[0])
                }
            }

        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function listItems() {

        let data = {
            goal_plan: planId,
            goal_type: 'OG',
            //start_date: new Date().toISOString().slice(0, 10),
            //end_date: new Date().toISOString().slice(0, 10),
            canceled: false
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            if (response && response.results){
                setItemsList(response)
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    useEffect(() => {

        
        // somar pesos por categoria
        if (itemsList && itemsList.results) {

            let weit = []

            Object.keys(itemsList.results).forEach((key) => {
    
                if (itemsList.results[key].length > 0) {
                    let res = itemsList.results[key].map((item) => {
                        return item.weight
                    }).reduce((a, b) => a+b, 0)

                    weit.push(res)
                }

            })
            
            setCategoryWeight(weit)
        }

    }, [itemsList])

    useEffect(() => {
        if (categoryWeight.length > 0) {
            setFinalWeight(categoryWeight.reduce((a,b) => a+b))
        }
    }, [categoryWeight])

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Metas e Objetivos
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    
                    <ul className='nav nav-tabs px-3'>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Minhas Metas
                            </span>
                        </li>
                        {
                            isManager ?
                            <li className='nav-item'>
                                <a href='/my/team/goals/' className='nav-link text-green'>
                                    Minha Equipe
                                </a>
                            </li>
                            :
                            null
                        }
                    </ul>
                    <div className='bg-white pt-2 rounded'>
                        <ul className='nav nav-tabs px-3 mt-4'>
                            <li className='nav-item'>
                                <a href='/my/goals/' className='nav-link text-green '>
                                    Minhas Metas
                                </a>
                            </li>
                            <li className='nav-item'>
                                <span className='nav-link active'>
                                    Metas Organizacionais
                                </span>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/goals/archive/' className='nav-link text-green'>
                                    Arquivados
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/goals/canceled/' className='nav-link text-green'>
                                    Cancelados
                                </a>
                            </li>
                        </ul>
                        {
                            pageLoad ?
                            <SkeletonGoals />
                            :
                            <>
                            {
                                goalPlanList && goalPlanList.length > 1 && planId ?
                                <div className='col-3 mt-4 ms-3'>
                                    <select className='form-select' defaultValue={planId} onChange={(e) => setPlanId(e.target.value)}>
                                        {
                                            goalPlanList.map((item) =>
                                                <>
                                                <option key={item.id} value={item.id}>{item.title}</option>
                                                </>
                                            )
                                        }
                                    </select>
                                </div>
                                :
                                null
                            }
                            {
                                itemsList && itemsList.results ? 
                                <>
                                <div className='mt-4 px-4'>
                                    <span className='fs-6 fw-bold text-default'>
                                        Total: {finalWeight}%
                                    </span>
                                    { finalWeight !== 100 ? <p className='text-vermelho'>Favor calibrar os pesos para atingir sempre 100%</p> : <p></p> }
                                </div>
                                {
                                    Object.keys(itemsList.results).map((key, index) => 
                                        <div key={index} className='pt-1 px-3'>
                                            <div className='fs-6 fw-bold bg-green-light text-default p-2 rounded-top'>{key} ({categoryWeight[index]}%)</div>
                                            {
                                                itemsList.results[key].length > 0 ?
                                                <>
                                                <table className='table table-borderless thover text-default pb-4'>
                                                    <thead className='borderBottom bg-light'>
                                                        <tr>
                                                            <th style={{width: '25%'}}>
                                                                Objetivo
                                                            </th>
                                                            <th className='text-center' style={{width: '30%'}}>
                                                                Métrica
                                                            </th>
                                                            <th className='text-center'>
                                                                Período
                                                            </th>
                                                            <th className='text-center'>
                                                                Status
                                                            </th>
                                                            <th className='text-center'>
                                                                Peso
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                    itemsList.results[key].map((item) =>
                                                        <tr key={item.id}>
                                                            <td className="align-middle">
                                                                &#x2022; <span className='text-green fw-bold'>{item.title}</span>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                            </td>
                                                            <td className="align-middle text-center">
                                                            {
                                                                item.goalLastStatus.status ?
                                                                <>
                                                                {
                                                                    item.goalLastStatus.status === 'CS' ?
                                                                    <>
                                                                    <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                                    </>
                                                                    :
                                                                    <>{item.goalLastStatus.status_display}</>
                                                                }
                                                                </>
                                                                :
                                                                <>Não Iniciado</>
                                                            }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.weight}%
                                                            </td>
                                                        </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                                </>
                                                :
                                                null
                                            }
                                        </div>
                                    )
                                }
                                </>
                                :
                                <p className='m-3 p-3 alert alert-warning'>Nenhuma Meta ainda</p>
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
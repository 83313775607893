import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import moment from "moment";
import 'moment/locale/pt-br'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormImportPayStructureXls from '../../../components/settings/forms/formImportPayStructure';
import FormImportPayGradeXls from '../../../components/settings/forms/formImportPayGradeXls';
import FormImportPayLevelXls from '../../../components/settings/forms/formImportPayLevelXls';
import VerifyPerms from '../../../context/userAccess';


export default function PayStructureList() {
    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [itemsCount, setItemsCount] = useState(0);

    const [worksheetPayStructure, setWorksheetPayStructure] = useState('')
    const [worksheetPayGrade, setWorksheetPayGrade] = useState('')
    const [worksheetPayLevel, setWorksheetPayLevel] = useState('')

    const navigate = useNavigate()
    const modalRef = useRef()
    const modalRef2 = useRef()
    const modalRef3 = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    const hideModal2 = () => {
        const modalEl = modalRef2.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    const hideModal3 = () => {
        const modalEl = modalRef3.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useEffect(() => {
        listItems()
    }, [page]);

    const searchItems = (event) => {
        if (event.query.length > 2) {

            companyService.autoCompletePayStructure(event.query).then((response) => {
                
                let resultTitle = response.map((item) => item.title + ' - ' + item.internal_code)
                setItems([...resultTitle])
                
            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listItems() {
        companyService.listPayStructure(page).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function filterItems() {
        companyService.listPayStructure(page, search.split('-')).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function cleanSearch() {
        setSearch('')
        listItems() 
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    function seeItem() {
        return navigate(`/settings/salary-structure/management/${selection.id}/detail/`)
    }

    function editItem() {
        return navigate(`/settings/salary-structure/management/${selection.id}/edit/`)
    }

    useEffect(() => {

        getWorksheetPayStructure()
        getWorksheetPayGrade()
        getWorksheetPayLevel()

    }, [])

    function getWorksheetPayStructure() {

        let data = {
            key: 'templates/PayStructure.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheetPayStructure(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    function getWorksheetPayGrade() {

        let data = {
            key: 'templates/PayGrade.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheetPayGrade(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    function getWorksheetPayLevel() {

        let data = {
            key: 'templates/PayLevel.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheetPayLevel(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row m-3 p-3 justify-content-start">
                        {
                            VerifyPerms(['add_paystructure', 'change_paystructure', 'delete_paystructure', 'view_paystructure']) ?
                            <>
                            <div className='col bg-white border-clean pb-5 ps-3'>
                                
                                <div className="pt-4 d-flex mb-4">
                                    <div className='col'>
                                        <span className='font120 fw-bold '>
                                            Estrutura de Salários
                                        </span>
                                        <button id='dbm-addJob' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                        </button>
                                        <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addJob">
                                            <li>
                                                <a href='/settings/salary-structure/management/create/' className="dropdown-item text-default">
                                                    Adicionar Estrutura de Salários
                                                </a>
                                            </li>
                                            <li><hr className="hr-border mt-1 mb-0"/></li>
                                            <li><span className="dropdown-header">Importar</span></li>
                                            <li>
                                                <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport">
                                                    Importar Estruturas de Salários
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport2">
                                                    Importar Níveis de Estruturas
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport3">
                                                    Importar Faixas de Salários
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <div className='col'>
                                        <div className='d-flex'>

                                            <div className='p-inputgroup'>
                                                <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Estrutura pelo Título ou Código' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                <button className='btn btn-success button-search-radius' onClick={filterItems}>
                                                    <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                </button>
                                            </div>
                                            {
                                                search ?
                                                <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                    Limpar
                                                </button>
                                                : null
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                    <hr className='hr-border' />
                                </div>

                                <p className='text-end'><strong>{itemsCount}</strong> Estruturas cadastradas</p>

                                {
                                    itemsList && itemsList.results && itemsList.results.length > 0 ?
                                    <>
                                    <table className='table table-borderless thover borderBottom mt-3'>
                                        <thead className='borderBottom'>
                                            <tr>
                                                <th>
                                                    Título da Estrutura
                                                </th>
                                                <th className='text-center'>
                                                    Código
                                                </th>
                                                <th className='text-center'>
                                                    Ativo em
                                                </th>
                                                <th className='text-center'>
                                                    Organizações
                                                </th>
                                                <th className='text-center'>
                                                    Níveis
                                                </th>
                                                <th className='text-center'>
                                                    Salários
                                                </th>
                                                <th className='text-center'>
                                                    Tipo / Sindicato
                                                </th>
                                                <th className='text-center'>
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                itemsList.results.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className="align-middle">
                                                            <span className='text-green fw-bold text-decoration-none' style={{'cursor': 'pointer'}} onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                {item.title}
                                                            </span>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {item.internal_code}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {moment(item.date_active).format("DD-MMM-YYYY")} 
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {item.subcompanies.length}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {item.countGrade}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {item.countLevel}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {
                                                                item.category ?
                                                                <>{item.category.title}</>
                                                                :
                                                                <>-</>
                                                            }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {
                                                                item.inactive ?
                                                                <Chip label="Inativo" className='bg-vermelho-light' />
                                                                :
                                                                <Chip label="Ativo" />
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className='float-end'>
                                        <nav>
                                            <ul className='pagination pagination-sm'>
                                                <li className='page-item'>
                                                    { 
                                                        itemsList.previous ? 
                                                        <span onClick={subPage} className='page-link text-default'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                        </span>
                                                        :
                                                        <span className='page-link text-grey disabled'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                        </span>
                                                    }
                                                </li>
                                                <li className='page-item'>
                                                    {
                                                        itemsList.next ?
                                                        <span onClick={addPage} className='page-link text-default'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                        </span>
                                                        :
                                                        <span className='page-link text-grey disabled'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                        </span>
                                                    }
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    </>

                                    : null
                                }
                            </div>
                            <div className="card flex justify-content-center">
                                <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                                {
                                        selection ?
                                        <>
                                        {
                                        <div>
                                            <p className='lead fw-bold text-default'>
                                                {selection.title} <small className='text-grey fw-normal'>({selection.internal_code})</small>
                                            </p>
                                            
                                            <div>
                                                <button className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={seeItem}>
                                                    Ver Detalhes
                                                </button>
                                                <button className='btn btn-sm btn-outline-success btn-rounded px-3 py-1 m-1' onClick={editItem} >
                                                    <FontAwesomeIcon icon={solid('edit')} /> Editar
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className='py-2'>
                                                <strong>Ativo em:</strong> {moment(selection.date_active).format("DD-MMM-YYYY")} 
                                            </div>
                                            <div className='py-2'>
                                                <strong>Tipo / Sindicato</strong>
                                                {
                                                    selection.category ?
                                                    <p>{selection.category.title}</p>
                                                    :
                                                    <p>-</p>
                                                }
                                            </div>
                                        </div>
                                        }
                                        </>
                                        :null
                                    }
                                </Sidebar>
                            </div>
                            <div id="formImport" className="modal fade" ref={modalRef}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Importar Estrutura de Salários</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            
                                            <div className='mb-3'>
                                                <p>
                                                    Utilize e planilha modelo para importar 
                                                </p>
                                                <a href={worksheetPayStructure} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                    Planilha Modelo
                                                </a>
                                            </div>
                                            <FormImportPayStructureXls hideModal={hideModal} />
                                            <hr/>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="formImport2" className="modal fade" ref={modalRef2}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Importar Níveis de Estruturas</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            
                                            <div className='mb-3'>
                                                <p>
                                                    Utilize e planilha modelo para importar 
                                                </p>
                                                <a href={worksheetPayGrade} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                    Planilha Modelo
                                                </a>
                                            </div>
                                                <FormImportPayGradeXls hideModal={hideModal2} />
                                            <hr/>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="formImport3" className="modal fade" ref={modalRef3}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Importar Faixas de Salários</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            
                                            <div className='mb-3'>
                                                <p>
                                                    Utilize e planilha modelo para importar 
                                                </p>
                                                <a href={worksheetPayLevel} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                    Planilha Modelo
                                                </a>
                                            </div>
                                                <FormImportPayLevelXls hideModal={hideModal3} />
                                            <hr/>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <p className='alert alert-warning m-3'>Você não tem permissão para acessar esta página</p>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
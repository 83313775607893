import React, {useState, useEffect, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';
import FormAddPayStructure from '../../../components/settings/forms/formAddPayStructure';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function EditPayStructure() {
    const { id } = useParams()
    const navigate = useNavigate()
    const toast = useRef(null);
    const modalRef = useRef()
    const [payStructure, setPayStructure] = useState('')

    useEffect(() => {
        getPayStructure()
    }, []);

    function getPayStructure() {
        companyService.getPayStructure(id).then((response) => {
            setPayStructure(response)

        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function delJob() {
        companyService.deletePayStructure(id).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Estrutura apagada com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                return navigate('/settings/salary-structure/management/')
              }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }
   
    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>

                                <a href={`/settings/salary-structure/management/${id}/detail/`} className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>

                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Editar Estrutura
                                    </span>
                                    <FormAddPayStructure object={payStructure} />
                                </div>
                                <div className="text-end btnMargin">
                                    <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Estrutura</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    
                </div>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar Estrutura</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar esta Estrutura?
                            </p>
                            <button onClick={delJob} className="btn btn-sm btn-danger btn-rounded px-4">Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function EditGroup() {

    const { id } = useParams()
    const [groupName, setGroupName] = useState('')
    
    const [permsList, setPermsList] = useState([]) // base para multiselect
    const [permissions, setPermissions] = useState([])

    const navigate = useNavigate();
    const modalRef = useRef()
    const toast = useRef(null);

    useEffect(() => {
        getPerms()
        getGroup()
    }, []);

    function getGroup() {
        companyService.getCompanyGroup(id).then((response) => {
            //setGroup(response)
            setGroupName(response.name)

            if (response.permissions.length > 0) {
                let _perm = response.permissions.map((item) => item.id)
                setPermissions(_perm)
            }

        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getPerms() {
        companyService.listPermissions().then((response) => {
            
            let res = response.map((item) => {
                return {
                    name: item.name,
                    code: item.id
                }
            })
            setPermsList(res)
            
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            groupname: groupName
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                name: values.groupname,
                permissions: permissions,
                id: id
            }

            companyService.updateCompanyGroup(data).then((response) => {

                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });

                getGroup()
            }).catch((error) => {
                console.log(error.response)
            });
            
        },
    });

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function deleteItem(e)
    {
        companyService.deleteCompanyGroup(id).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Grupo apagado com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                return navigate('/settings/perms-management/')
            }, 3000);
              
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col p-0 formMarginBottom">

                        <Toast ref={toast} position="top-right" />

                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Grupo</span>
                        </div>
                        <div className="p-3 justify-content-center">
                            
                            <div className='col-6'>
                                <a href="/settings/perms-management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">
                                
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='form-group'>
                                            <label className="form-label fw-bold">Nome do Grupo <span className="text-danger">*</span></label>
                                            <input name="groupname"  className='form-control' {...formik.getFieldProps('groupname')} />
                                        </div>
                                    
                                        <div className='mt-3'>
                                            <label className="form-label fw-bold">
                                                Permissões { permissions.length > 0 ? <>({permissions.length})</> : null}
                                            </label>
                                            <div className='p-inputgroup'>
                                                <MultiSelect filter placeholder="Selecionar" optionLabel="name" optionValue='code' value={permissions} onChange={(e) => {setPermissions(e.value)}} options={permsList} display="chip" className="rounded" />
                                            </div>
                                        </div>

                                        <div className=" mx-auto btnMargin">
                                            <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">Atualizar</button>
                                        </div>
                                    </form>
                                
                                </div>

                                <div className="text-end btnMargin">
                                    <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Grupo</button>
                                </div>

                                <div id="formDel" className="modal fade" ref={modalRef}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Apagar Grupo</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>
                                                    Tem certeza de que deseja apagar este Grupo?
                                                </p>
                                                <button className="btn btn-sm btn-danger btn-rounded px-4" onClick={deleteItem}>Sim, Apagar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, {useEffect, useState, useRef} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';


export default function FormUpdatePayGrade(props) {

    const [object, setObject] = useState('')
    const toast = useRef(null);

    useEffect(() => {

        if (props && props.id){
            getPayGrade()
        }

    }, [props])

    function getPayGrade() {
        companyService.getPayGrade(props.id).then((response) => {
            setObject(response)
        }).catch((error) => {
            console.log(error)
        });
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: object.title
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                id: object.id
            }

            companyService.updatePayGrade(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Nível alterado com sucesso', life: 3000 });
                setTimeout(() => {
                    props.listItems()
                    props.hideModal()
                }, 3000);
            })
            .catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
                setTimeout(() => {
                    props.hideModal()
                }, 4000);
            });
        },
    });

    return (
        <>
        <Toast ref={toast} position="top-right" />

        <form onSubmit={formik.handleSubmit}>

            <div className="col">
                <label className="form-label">Código <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    Alterar
                </button>
            </div>
        </form>
        </>
    )
}
import http from "../services/http"

class CorreiosService {

    async getZipcode(data) {

        return await http.post('get/correios/', data)
    }
}

export default new CorreiosService()
import React, {useState, useEffect, useRef} from 'react';
import employeeService from '../../../services/data/employee.service';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import companyService from '../../../services/data/company.service';


export default function EditEmployeePerms() {

    const { id } = useParams()
    const toast = useRef(null);
    const [obj, setObj] = useState('')

    const groupPage = 1
    const [groupsList, setGroupsList] = useState([]) // base para autocomplete
    const [group, setGroup] = useState(null)

    const [permList, setPermList] = useState([]) // base para autocomplete
    const [permissions, setPermissions] = useState([])

    useEffect(() => {
        getGroups()
        getPerms()
        getEmployee()
    }, [])

    function getPerms() {
        companyService.listPermissions().then((response) => {
            let pList = response.map((item) => {
                return {
                    name: item.name,
                    code: item.id
                }
            })

            setPermList(pList)

        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getGroups() {
        companyService.listCompanyGroup(groupPage).then((response) => {
            setGroupsList(response.results.map((item) => {
                return {
                    name: item.name,
                    code: item.id
                }
            }))
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getEmployee() {

        employeeService.getEmployee(id).then((response) => {

            setObj(response)

            const eGroupPerms = []
            const ePerms = []

            //setGroups
            if (response.groups && response.groups.length > 0) {
                let _group = response.groups.map((item) => item.id)
                setGroup(_group[0])

                let _gPerms = response.groups.map((item) => item.permissions)
                eGroupPerms.push(_gPerms[0])

            }

            //setPermissions
            if (response.permissions && response.permissions.length > 0) {
                let _perm = response.permissions.map((item) => item.id)
                ePerms.push(_perm)
            }

            if (eGroupPerms.length > 0 && ePerms.length > 0) {

                let newArray = [...eGroupPerms[0], ...ePerms[0]]
                //ordenar
                newArray.sort(function(a, b) {
                    return a - b;
                });
                const unique = newArray.filter((val, id, array) => {
                    return array.indexOf(val) === id;  
                });
                setPermissions(unique)
                
            }

            if (eGroupPerms.length > 0) {
                setPermissions(eGroupPerms[0])
            }
            if (ePerms) {
                setPermissions(ePerms[0])
            }

            
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function groupUpdate (e) {

        companyService.listCompanyGroup(groupPage, e).then((response) => {
            let _res = response.results.map((item) => item.permissions)

            let _perms = _res.map((item) => {
                return item.map((e) => e.id)
            })
            setPermissions(_perms[0])
        }).catch((error) => {
            console.log(error.response)
        });
        
    }

    function updateEmployee() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id,
            permissions: permissions,
        }

        if (group) {
            data.groups = [group]
        }

        employeeService.updateEmployee(data).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Permissões atualizadas com sucesso', life: 3000 });
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Colaborador</span>
                        </div>
                        <div className="row m-3 p-3">
                            <a href="/settings/users-management/" className="nav-link mb-3">
                                <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                            </a>
                            <ul className='nav nav-tabs'>
                                <li className='nav-item'>
                                    <a href={`/settings/users-management/${id}/edit/`} className='nav-link text-green'>
                                        Colaborador
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <span className='nav-link active'>
                                        Grupos e Permissões
                                    </span>
                                </li>
                            </ul>
                            <div className='col-12 bg-white border-clean border-top-0 pb-5 ps-3'>

                                <p className='lead fw-bold mt-3'>
                                    {
                                        obj ?
                                        <>{obj.user.first_name} {obj.user.last_name}</>
                                        : null
                                    }
                                </p>
                                <div className='mt-3'>
                                    <label className="form-label fw-bold">
                                        Grupos
                                    </label>
                                    <p className='mb-1'>Adicionar grupos de permissões (opcional)</p>
                                    <div className='p-inputgroup'>
                                        <Dropdown filter placeholder="Selecionar" value={group} optionLabel="name" optionValue="code" options={groupsList} onChange={(e) => {setGroup(e.value); groupUpdate(e.value);} } className="rounded" />
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className="form-label fw-bold mb-0">
                                        Permissões { permissions && permissions.length > 0 ? <>({permissions.length})</> : null}
                                    </label>
                                    <p className='mb-1'>Adicionar permissões além das permissões nos grupos</p>
                                    <div className='p-inputgroup'>
                                        <MultiSelect filter optionLabel='name' optionValue='code' placeholder="Selecionar" value={permissions} onChange={(e) => {setPermissions(e.value);}} options={permList} display="chip" className="rounded" />
                                    </div>
                                </div> 
                                <div className="d-grid mx-auto btnMargin">
                                    <button className="btn btn-success btn-sm btn-rounded" onClick={updateEmployee}>
                                        Atualizar
                                    </button>
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </ProtectedRoute>
    )
}
import React, {useRef} from 'react';
import { useFormik } from 'formik';
import careerService from '../../services/data/career.service';
import { Editor } from 'primereact/editor';
import { renderHeaderEditor } from '../../helpers/editorToolbar';
import { Toast } from 'primereact/toast';


export default function FormAddCareerSummary(props) {

    const toast = useRef(null);
    const headerEditor = renderHeaderEditor()
    const careerObjective = props.careerObjective
    const userId = props.userId

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            career_objective: careerObjective.career_objective
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                user_id: userId,
                career_objective: values.career_objective
            }

            if (careerObjective && careerObjective.career_objective) {

                careerService.updateUserCareerSummary(data).then((response) => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo Profissional atualizado com sucesso', life: 3000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            } else {
            
                careerService.createUserCareerSummary(data).then((response) => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo Profissional atualizado com sucesso', life: 3000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });
            }
        },
    });

    return (
        <>
        <Toast ref={toast} position="top-right" />
        <form onSubmit={formik.handleSubmit}>

            <div className="col">
                <label className="form-label">Resumo e Objetivo Profissional <span className="text-danger">*</span></label>
                <Editor
                    className='noToolbar text-default'
                    name="career_objective"
                    headerTemplate={headerEditor}
                    style={{ height: '120px' }}
                    value={formik.values.career_objective}
                    onTextChange={(e) => {
                        formik.setFieldValue('career_objective', e.htmlValue);
                    }}
                />
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    Atualizar
                </button>
            </div>
        </form>
        </>
    )
}
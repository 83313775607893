import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import careerService from '../../services/data/career.service';
import userData from '../../context/user';
import FormAddWebsite from '../../components/career/formAddWebsite';


export default function CurriculumWebsites(props) {

    const toast = useRef(null);
    const userId = props.userId
    const {user} = userData()
    const [showSidebar, setShowSidebar] = useState(false);
    const [selection, setSelection] = useState('')
    const [itemsList, setItemsList] = useState([]);
    const [itemsCount, setItemsCount] = useState(0);
    const [page, setPage] = useState(1)
    const [object, setObject] = useState('')

    useEffect(() => {

        listItems()

    }, [])

    function listItems() {
        var data = {
            user_id: userId
        }
        careerService.listUserWebsite(page, data).then((response) => {

            setItemsList(response)
            setItemsCount(response.count)

        }).catch((error) => {
            console.log(error.response)
        });
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    return (
        <>
        <Toast ref={toast} position="top-right" />

        <div className='p-2 m-1'>
            <div className='font110 fw-bold'>
                Sites e Links 
                {
                    userId && user  ?
                    <>
                    {
                        userId === user.uid ?
                        <button className='btn btn-rounded btn-sm text-green ms-2 fs-5'  onClick={() => {setShowSidebar(true); setSelection('formAdd')}} >
                            <FontAwesomeIcon icon={solid('plus-circle')} />
                        </button>
                        : null
                    }
                    </>
                    : <></>
                }
            </div>
            <div className='mt-2'>
            {
                itemsList && itemsList.results && itemsList.results.length > 0 ?
                <>
                {
                    itemsList.results.map((item, index) => 
                    
                        <div key={index} className='mb-3 pb-2'>
                            <div className='fw-bold'>
                                {item.website} 
                                {
                                    userId === user.uid ?
                                    <button className='btn btn-link border btn-sm btn-rounded text-green ms-2'  onClick={() => {setShowSidebar(true); setSelection('formEdit'); setObject(item)}} >
                                        <FontAwesomeIcon className='font095' icon={solid('edit')} />
                                    </button>
                                    : null
                                }
                            </div>
                            <div>
                                <a href={item.link} target='_blank' rel="noopener noreferrer" className="text-green noDecoration">
                                    {item.link} <FontAwesomeIcon className='ms-1' icon={solid('external-link-alt')} />
                                </a>
                            </div>
                        </div>

                    )
                }
                {
                    itemsCount > 20 ?
                    <>
                    <div className='float-end'>
                        <nav>
                            <ul className='pagination pagination-sm'>
                                <li className='page-item'>
                                    { 
                                        itemsList.previous ? 
                                        <span onClick={subPage} className='page-link text-default'>
                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                        </span>
                                        :
                                        <span className='page-link text-grey disabled'>
                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                        </span>
                                    }
                                </li>
                                <li className='page-item'>
                                    {
                                        itemsList.next ?
                                        <span onClick={addPage} className='page-link text-default'>
                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                        </span>
                                        :
                                        <span className='page-link text-grey disabled'>
                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                        </span>
                                    }
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </>
                    : null
                }
                </>
                : null
            }
            </div>
        </div>

        <div className="card flex justify-content-center">
            <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false)}}>
            {
                selection && selection == 'formAdd' ?
                <FormAddWebsite userId={userId} updateData={listItems} />
                : null
            }
            {
                selection && selection == 'formEdit' ?
                <FormAddWebsite userId={userId} object={object} updateData={listItems} showBar={setShowSidebar} />
                : null
            }
            </Sidebar>
        </div>
        </>
    )

} 
import React, {useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import FormAddPayStructure from '../../../components/settings/forms/formAddPayStructure';


export default function CreatePayStructure() {

    const toast = useRef(null);


    const stepItems = [
        {
            label: 'Estrutura'
        },
        {
            label: 'Nível'
        },
        {
            label: 'Salário'
        }
    ];

   
    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>

                                <div className='my-3'>
                                    <Steps model={stepItems} activeIndex={0} />
                                </div>
                                
                                <a href="/settings/salary-structure/management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>

                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Adicionar Nova Estrutura
                                    </span>
                                    <FormAddPayStructure />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
import React, {useState, useRef, useMemo, useEffect} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import employeeService from '../../../services/data/employee.service';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { InputMask } from "primereact/inputmask";
import { Dropdown } from 'primereact/dropdown';


export default function FormAddEmployee(props) {
  
    const toast = useRef(null);
    const [isChecked, setIsChecked] = useState(true);
    const [subCompaniesList, setSubCompaniesList] = useState([]) // lista de subCompanies
    const [subcompany, setSubcompany] = useState('')
    const [jobPositionList, setJobPositionList] = useState([])
    const [jobPosition, setJobPosition] = useState('')

     // info do internaljobtitle para colocar no employee
    const [permissions, setPermissions] = useState([])
    const [groups, setGroups] = useState([])

    //paras edit
    const [objId, setObjId] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState(null)
    const [legalId, setLegalId] = useState(null)
    const [employeeId, setEmployeeId] = useState('')
    const [ reinitialize, setReinitialize] = useState(false)
    const [parent, setParent] = useState(null)
    const [parentList, setParentList] = useState([])

    useEffect(() => {
        listSubCompanies()
    }, [])

    useMemo(() => {

        if (props.obj) {
            setReinitialize(true)
            setObjId(props.obj.id)
            setFirstName(props.obj.user.first_name)
            setLastName(props.obj.user.last_name)
            if (props.obj.user.email) {
                setEmail(props.obj.user.email)
            } else {
                setIsChecked(false)
            }
            if (props.obj.user.legalId) {
                setLegalId(props.obj.user.legalId)
            }
            if (props.obj.employeeId) {
                setEmployeeId(props.obj.employeeId)
            }
            if (props.obj.jobposition) {
                setSubcompany(props.obj.jobposition.subcompany)
                setJobPosition(props.obj.jobposition.id)
            }
            if (props.obj.parent){
                setParent(props.obj.parent.id)
            }
        }

    }, [props])

    function listSubCompanies() {

        const getItem = ''

        companyService.autoCompleteSubCompany(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setSubCompaniesList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });

    }

    useMemo(() => {

        // items de acordo com subcompany
        if (subcompany){
            listJobPosition(subcompany)
        }

    }, [subcompany])

    function listJobPosition(subcompany_pk) {
        companyService.autoCompleteJobPosition(subcompany_pk).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setJobPositionList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    useMemo(() => {

        //update de grupos e perms do cargo para colocar no employee
        if (jobPosition){
            listJobGroupsPerms(jobPosition)
            listParent(jobPosition)
        }

    }, [jobPosition])

    function listParent(jobposition_pk) {
        
        employeeService.listParentEmployee(jobposition_pk).then((response) => {
            if (response) {
                setParentList(response)
            }
        })
    }

    function listJobGroupsPerms(jobposition_pk) {
        let data = {
            id: jobposition_pk
        }

        let permsList = []
        companyService.listPermsFromJobPosition(data).then((response) => {

            let _res = response.groups.map((item) => item.id)

            if(_res.length > 0) {
                setGroups(_res)
            }

            response.groups.map((item) => {
                if (item.permissions.length > 0) {
                    item.permissions.map((p) => {
                        permsList.push(p)
                    })
                }
            }) 

            response.perms.map((item) => {
                permsList.push(item.id)
            })

            if (permsList.length > 0) {
                setPermissions(permsList)
            }

        }).catch((error) => {
            console.log(error.response)
        })

    }

    const formik = useFormik({
        enableReinitialize: reinitialize,
        initialValues: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            legalId: legalId,
            employeeId: employeeId,
            subcompany: subcompany,
            jobposition: jobPosition,
            parent: parent
        },
        validate: (data) => {
            let errors = {};

            if (isChecked && !data.email) {
                errors.email = 'Favor preencher o campo Email'
            }

            if (!isChecked && !data.legalId) {
                errors.legalId = 'Favor preencher o campo CPF'
            }

            if (!data.subcompany) {
                errors.subcompany = 'Favor preencher o campo Organização';
            }

            if (!data.jobposition) {
                errors.jobposition = 'Favor preencher o campo Posição';
            }

            return errors;
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                legalId: values.legalId,
                employeeId: values.employeeId,
                subcompany: values.subcompany,
                jobposition: values.jobposition,
                groups: groups,
                permissions: permissions,
                parent: values.parent
            }

            if (!objId) {

                let username;

                if (values.email) {
                    username = values.email
                } else {
                    username = values.legalId
                }

                data.username = username

                employeeService.createEmployee(data).then(() => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Colaborador adicionado com sucesso', life: 3000 });

                    formik.resetForm()
                    //clean dropdown
                    setSubcompany('')
                    setJobPosition('')

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });
                
            } else {

                data.id = objId
                
                employeeService.updateEmployee(data).then(() => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });
            }
            

        }
    });

    return (
        
        <form onSubmit={formik.handleSubmit}>
            <Toast ref={toast} position="top-right" />                
            
            <div className="row p-0 my-3">
                <div className="col">
                    <label className="form-label fw-bold">Nome <span className="text-danger">*</span></label>
                    <input name='first_name' type="text" className="form-control" required {...formik.getFieldProps('first_name')} />
                </div>
                <div className="col">
                    <label className="form-label fw-bold">Sobrenome <span className="text-danger">*</span></label>
                    <input name='last_name' type="text" className="form-control" required {...formik.getFieldProps('last_name')} />
                </div>
            </div>

            <div className='border rounded'>
                <table className='align-middle table table-borderless mb-0 border-bottom'>
                    <tbody>
                        <tr>
                            <td>
                                <label className='fw-bold'>Colaborador possui email?</label> 
                            </td>
                            <td>
                                <InputSwitch className='float-end' checked={isChecked} onChange={(e) => setIsChecked(e.value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    isChecked ?
                    <>
                    <div className="col pt-3 p-2 bg-light">
                        <label className="form-label fw-bold noMarginBottom">Email <span className="text-danger">*</span></label>
                        <div className="text-grey">Não são permitidos emails @gmail, @hotmail, @yahoo e similares</div>
                        <input required name="email" type="email" className="form-control" {...formik.getFieldProps('email')} />
                        {formik.errors.email ? (<span name="email" className="text-vermelho">{formik.errors.email}</span>) : null}
                    </div>
                    </>
                    : null
                }
            </div>

            <div className="row p-0 my-3">
                <div className="col">
                    <label className="form-label fw-bold">CPF { !isChecked ? <span className="text-danger">*</span>: null}</label>
                    <InputMask className='form-control rounded' value={formik.values.legalId} onChange={(e) => {setLegalId(e.target.value); formik.setFieldValue('legalId', e.target.value)} } mask="999.999.999-99" placeholder="999.999.999-99" />
                    {formik.errors.legalId ? (<span name="legalId" className="text-vermelho">{formik.errors.legalId}</span>) : null}
                </div>

                <div className="col">
                    <label className="form-label fw-bold">Matrícula</label>
                    <input name='employeeId' type="text" className="form-control" {...formik.getFieldProps('employeeId')} />
                </div>
            </div>

            <div className="row p-0 my-3">
                <div className="col">
                    <label className="form-label fw-bold">Organização <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={subcompany} optionLabel="title" options={subCompaniesList} onChange={(e) => {setSubcompany(e.value); formik.setFieldValue('subcompany', e.value);} } className="rounded" />
                    </div>
                    {formik.errors.subcompany ? (<span name="subcompany" className="text-vermelho">{formik.errors.subcompany}</span>) : null}
                </div>

                <div className="col">
                    <label className="form-label fw-bold">Posição <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={jobPosition} optionLabel="title" options={jobPositionList} onChange={(e) => {setJobPosition(e.value); formik.setFieldValue('jobposition', e.value);} } className="rounded" />
                    </div>
                    {formik.errors.jobposition ? (<span name="jobposition" className="text-vermelho">{formik.errors.jobposition}</span>) : null}
                </div>
            </div>

            <div className="col">
                <label className="form-label fw-bold">Superior Imediato</label>
                <div className='p-inputgroup'>
                    <Dropdown filter placeholder="Selecionar" value={parent} optionLabel="title" options={parentList} onChange={(e) => {setParent(e.value); formik.setFieldValue('parent', e.value);} } className="rounded" />
                </div>
            </div>
            <hr/>
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                         objId ?
                         <>Atualizar</>
                         :
                         <>Adicionar</>
                    }
                </button>
            </div>

        </form>
    )
}
import React from 'react';
import { useParams } from 'react-router-dom';
import CareerSummary from './summary';


export default function TeamSummary() {

    const { id } = useParams()

    return (
        <CareerSummary id={id} />
    )

}
import React from "react";

let currentYear = new Date().getFullYear()

export default function Footer() {
    return (
        <footer className="footer contentShadowFull">
            <div className="container-fluid">
                <p className="text-center">
                    <a href="https://jobconvo.com/termos-de-servico/" target='_blank' rel="noopener noreferrer" className="text-green noDecoration"> Termos </a> e <a href="https://jobconvo.com/politica-de-privacidade/" target="_blank" rel="noopener noreferrer" className="text-green noDecoration">Política </a> | @{currentYear} {process.env.REACT_APP_SITE_NAME}
                </p>
            </div>
        </footer>
    );
};

import React, {useState, useRef, useMemo, useEffect} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';


export default function FormAddJobPosition(props) {

    const toast = useRef(null);
    const [object, setObject] = useState('')
    const [title, setTitle] = useState('')
    const [subCompaniesList, setSubCompaniesList] = useState([])
    const [subcompany, setSubcompany] = useState('')
    const [internaljobtitle, setInternalJobTitle] = useState('')
    const [internalJobTitleList, setInternalJobTitleList] = useState([])
    const [department, setDepartment] = useState('')
    const [deptList, setDeptList] = useState([])
    const [costcenter, setCostCenter] = useState('')
    const [costCenterList, setCostCenterList] = useState([]) // lista de centros de custo

    const [payStructure, setPayStructure] = useState('')
    const [payStructureList, setPayStructureList] = useState([])
    const [payGradeList, setPayGradeList] = useState([])
    const [payGrade, setPayGrade] = useState('')
    const [paylevel, setPayLevel] = useState('')
    const [payLevelList, setPayLevelList] = useState([])

    const [expectedPositions, setExpectedPositions] = useState('')
    const [enableReinitialize, setEnableReinitialize] = useState(false)

    const [benefitsList, setBenefitsList] = useState([]) // base para autocomplete
    const [benefits, setBenefits] = useState([])

    const navigate = useNavigate()

    useMemo(() => {
        if (props.obj){
            setEnableReinitialize(true)
            setObject(props.obj)
            setTitle(props.obj.title)
            setSubcompany(props.obj.subcompany.id)
            setInternalJobTitle(props.obj.internaljobtitle.id)
            setDepartment(props.obj.department.id)
            setCostCenter(props.obj.costcenter.id)
            setPayLevel(props.obj.paylevel.id)
            setPayGrade(props.obj.paylevel.grade.id)
            setPayStructure(props.obj.paylevel.structure.id)
            setExpectedPositions(props.obj.expected_positions)

            if (props.obj.benefits && props.obj.benefits.length > 0) {
                let _ben = props.obj.benefits.map((item) => item.id)
                setBenefits(_ben)
            }
            
        }

        if (props.subCompaniesList) {

            setSubCompaniesList(props.subCompaniesList)
        }

        if (props.internalJobTitleList) {

            setInternalJobTitleList(props.internalJobTitleList)
        }

        if (props.deptList) {
            setDeptList(props.deptList)
        }

        //setBenefits
        if (props.benefitsList) {
            let res = props.benefitsList.map((item) => {
                return {
                    title: item.title,
                    id: item.id
                }
            })
            setBenefitsList(res)
        }

    }, [props])

    useMemo(() => {

        // items de acordo com subcompany
        if (subcompany){
            listCostCenter(subcompany)
            listPayStructure(subcompany)
        }

    }, [subcompany])

    useMemo(() => {

        // items de acordo com payStructure
        if (payStructure){
            listPayGrade()
        }

    }, [payStructure])

    useMemo(() => {

        // items de acordo com payStructure
        if (payStructure && payGrade){
            listPayLevel()
        }

    }, [payStructure, payGrade])

    function listCostCenter(subcompany_pk) {

        companyService.autoCompleteCostCenter(subcompany_pk).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setCostCenterList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });

    }

    function listPayStructure(subcompany_pk) {

        companyService.autoCompletePayStructure(subcompany_pk).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setPayStructureList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function listPayGrade() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: '',
            structure_id: payStructure
        }
        companyService.autoCompletePayGrade(data).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setPayGradeList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function listPayLevel() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: '',
            structure_id: payStructure,
            grade_id: payGrade
        }
        companyService.autoCompletePayLevel(data).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setPayLevelList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }
    
    const formik = useFormik({
        enableReinitialize: enableReinitialize,
        initialValues: {
            title: title,
            subcompany: subcompany,
            internaljobtitle: internaljobtitle,
            department: department,
            costcenter: costcenter,
            paylevel: paylevel,
            expected_positions: expectedPositions
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                subcompany: values.subcompany,
                internaljobtitle: values.internaljobtitle,
                department: values.department,
                costcenter: values.costcenter,
                paylevel: values.paylevel,
                expected_positions: values.expected_positions,
            }

            if (benefits) {
                data.benefits = benefits
            }

            if (object) {
                data.id = object.id

                companyService.updateJobPosition(data).then(() => {
                
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            } else {
                
                data.parent_id = props.parent_id

                companyService.createJobPosition(data).then(() => {
                
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Posição criada com sucesso', life: 4000 });

                    setTimeout(() => {
                        return navigate(`/settings/jobposition/management/`)
                    }, 3000);
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }

            
            
        },
    });

    return (

        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />
        
            <div className='mb-3 row p-0'>
                <div className="col-9">
                    <label className="form-label">Título da Posição <span className="text-danger">*</span></label>
                    <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
                </div>
                {
                    object ?
                    <div className="col">
                        <label className="form-label">Código <span className="text-danger">*</span></label>
                        <input name='internal_code' type="text" className="form-control" value={object.internal_code} disabled />
                    </div>
                    :
                    null
                }
            </div>

            <div className='mb-3 row p-0'>
                <div className="col-4">
                    <label className="form-label">Nº de Posições Esperadas <span className="text-danger">*</span></label>
                    <input name='expected_positions' type="number" className="form-control" required {...formik.getFieldProps('expected_positions')} />
                </div>
            </div>

            <div className='mb-3 row p-0'>
                <div className="col-4">
                    <label className="form-label">Organização <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={subcompany} optionLabel="title" options={subCompaniesList} onChange={(e) => {setSubcompany(e.value); formik.setFieldValue('subcompany', e.value);} } className="rounded" />
                    </div>
                </div>

                <div className="col">
                    <label className="form-label">Cargo <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={internaljobtitle} optionLabel="title" options={internalJobTitleList} onChange={(e) => {setInternalJobTitle(e.value); formik.setFieldValue('internaljobtitle', e.value);} } className="rounded" />
                    </div>
                </div>
            </div>

            <div className='mb-3 row p-0'>
                <div className="col">
                    <label className="form-label">Departamento <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={department} optionLabel="title" options={deptList} onChange={(e) => {setDepartment(e.value); formik.setFieldValue('department', e.value);} } className="rounded" />
                    </div>
                </div>

                <div className="col">
                    <label className="form-label">Centro de Custo <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={costcenter} optionLabel="title" options={costCenterList} onChange={(e) => {setCostCenter(e.value); formik.setFieldValue('costcenter', e.value);} } className="rounded" />
                    </div>
                </div>
            </div>

            <div className='mb-3 row p-0'>
                <div className="col">
                    <label className="form-label">Estrutura de Salário <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={payStructure} optionLabel="title" options={payStructureList} onChange={(e) => {setPayStructure(e.value)} } className="rounded" />
                    </div>
                </div>
                <div className="col-3">
                    <label className="form-label">Nível <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={payGrade} optionLabel="title" options={payGradeList} onChange={(e) => {setPayGrade(e.value)} } className="rounded" />
                    </div>
                </div>
                <div className="col">
                    <label className="form-label">Faixa Salarial <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={paylevel} optionLabel="title" options={payLevelList} onChange={(e) => {setPayLevel(e.value); formik.setFieldValue('paylevel', e.value) }  } className="rounded" />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <label className="form-label fw-bold">
                    Benefícios { benefits.length > 0 ? <>({benefits.length})</> : null}
                </label>
                <div className='p-fluid p-inputgroup'>
                    <MultiSelect filter placeholder="Selecionar" optionLabel="title" optionValue='id' value={benefits} onChange={(e) => setBenefits(e.value)} options={benefitsList} display="chip" className="rounded" />
                </div>
                
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        object ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                    
                </button>
            </div>
        </form>
    )
}
import React from "react";
import { Skeleton } from 'primereact/skeleton';

export default function SkeletonSummary() {
    return (
        <>
        <div className="row mx-3 mt-3">
            <div className="d-flex justify-content-start">
                <div className="col-3 m-3">
                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="20rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="20rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="10rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="10rem" className="mb-2"></Skeleton>
                </div>
                <div className="col-1"></div>
                <div className="col-3 m-3">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="20rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="20rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="10rem" className="mb-2"></Skeleton>
                    <Skeleton height="2rem" width="10rem" className="mb-2"></Skeleton>
                </div>
            </div>
        </div>
        </>
    )
}
import React, {useState, useRef, useMemo, useEffect} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import moment from "moment";
import 'moment/locale/pt-br'
import performanceService from '../../../services/data/performance.service';
import * as Yup from 'yup';
import FormAddCategory from '../../settings/forms/formAddCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dropdown } from 'primereact/dropdown';
import companyService from '../../../services/data/company.service';
import userData from '../../../context/user';


export default function FormCreateGoal(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();

    const [objId, setObjId] = useState('')
    const [metric, setMetric] = useState('')
    const [goalType, setGoalType] = useState('')
    const [title, setTitle] = useState('')

    const [relatedGoal, setRelatedGoal] = useState('')
    const [relatedGoalList, setRelatedGoalList] = useState('')

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('AT')

    //if goal for goalplan
    const [goalPlan, setGoalPlan] = useState('')
    
    const [category, setCategory] = useState('')
    const [categoryList, setCategoryList] = useState('')
    const [showCategory, setShowCategory] = useState(false)
    
    const [weight, setWeight] = useState(0)

    const [enableReinitialize, setEnableReinitialize] = useState(false)

    const [employee, setEmployee] = useState('') //para criar goal do employee
    
    const {user} = userData();

    const [performanceSession, setPerformanceSession] = useState('')

    const [templateId, setTemplateID] = useState('')


    useMemo(() => {
        if(props.obj) {
            setEnableReinitialize(true)
            setObjId(props.obj.id)
            if (props.obj.related_goal) {
                setRelatedGoal(props.obj.related_goal)
            }
            setCategory(props.obj.category)
            setTitle(props.obj.title)
            setMetric(props.obj.metric)
            setGoalType(props.obj.goal_type)
            setWeight(props.obj.weight)
            setStartDate(
                moment(props.obj.start_date).format("YYYY-MM-DD")
            )
            setEndDate(
                moment(props.obj.end_date).format("YYYY-MM-DD")
            )
            setStatus(props.obj.status)
        }
        if (props.goalPlan) {
            setGoalPlan(props.goalPlan)
            setGoalType('OG') //organizacional
        }
        if (props.categoriesList && props.categoriesList.results.length > 0) {
            setCategoryList(props.categoriesList.results)
        }
        if (props.related_goals && props.related_goals.length > 0) {
            setRelatedGoalList(props.related_goals)
        }
        if (props.employee) {
            setEmployee(props.employee)
        }
        if (props.performanceSession) {
            setPerformanceSession(props.performanceSession)
        }

        if (props.startDate) {
            setStartDate(
                moment(props.startDate).format("YYYY-MM-DD")
            )
        }

        if (props.endDate) {

            setEndDate(
                moment(props.endDate).format("YYYY-MM-DD")
            )
        }
        if (props.templateId) {
            setTemplateID(props.templateId)
        }
    }, [props])

    const handleShowCategory = () => {
        setShowCategory(!showCategory)
    }

    const formik = useFormik({
        enableReinitialize: enableReinitialize,
        initialValues: {
            related_goal: relatedGoal,
            title: title,
            category: category,
            metric: metric,
            goal_type: goalType,
            weight: weight,
            start_date: startDate,
            end_date: endDate,
            status: status
        },
        validationSchema: Yup.object({
            start_date: Yup.date(),
            end_date: Yup.date().min(Yup.ref("start_date"), "Data de término tem de ser maior que a data de início"),
        }),
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                goal_plan: goalPlan,
                title: values.title,
                category: values.category,
                metric: values.metric,
                goal_type: values.goal_type,
                weight: values.weight,
                start_date: values.start_date,
                end_date: values.end_date,
                status: values.status
            }

            if (templateId) {
                data.template = true
            }

            if (goalPlan) {
                data.goal_type = goalType
            }

            if (values.related_goal) {
                if (values.related_goal.id){
                    data.related_goal = values.related_goal.id
                } else {
                    data.related_goal = values.related_goal
                }
            }

            if (employee) {
                data.employee = employee
            }

            if (performanceSession) {
                data.performanceSession = performanceSession
            }

            if (objId) {

                data.id = objId

                performanceService.updateGoal(data).then(() => {
    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo atualizado com sucesso', life: 3000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });

            } else {
                performanceService.createGoal(data).then((response) => {
    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo criado com sucesso', life: 3000 });

                    if (templateId) {
                        //add goal to performance template
                        let dataTemp = {
                            company_id: localStorage.getItem("companyId"),
                            goals: [response.id],
                            id: templateId
                        }
                        performanceService.updatePerformanceTemplateContent(dataTemp)
                    }
                    
                    if (props.listItems) {
                        props.listItems()
                    }

                    if (props.sendMessage) {

                        let mData = {
                            title: 'Um novo objetivo foi adicionado para você',
                            body: `${user.first_name} adicionou um novo objetivo para você: ${response.title}`,
                            employee: employee
                        }

                        companyService.createMessage(mData).catch((error) => {
                            console.log(error.response)
                        });
                    }

                    if (props.hideModal) {
                        setTimeout(() => {
                            props.hideModal()
                        }, 3000);
                    }

                    formik.resetForm()
                    setMetric('')
                    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });
            }
            
        },
    });

    useEffect(() => {
        
        if (endDate) {
            formik.setFieldValue('end_date', endDate)
        }

        if (startDate) {
            formik.setFieldValue('start_date', startDate)
        }

    }, [])

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            <div className="col">
                <label className="form-label fw-bold">Objetivo <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
            </div>

            <div className="my-3">
                <label className="form-label fw-bold">Métrica <span className="text-danger">*</span></label>
                <Editor
                    name="metric"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    value={formik.values.metric}
                    onTextChange={(e) => {
                        formik.setFieldValue('metric', e.htmlValue);
                    }}
                />
            </div>

            <div className='row mb-4 px-0'>
                <div className='col'>
                    <label className="form-label fw-bold">Data de Início <span className="text-danger">*</span></label>
                    <input className="form-control" name='start_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('start_date')} />
                    {formik.errors.start_date ? (<span name="start_date" className="text-vermelho">{formik.errors.start_date}</span>) : null}
                </div>

                <div className='col'>
                    <label className="form-label fw-bold">Data de Término <span className="text-danger">*</span></label>
                    <input className="form-control" name='end_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('end_date')} />
                    {formik.errors.end_date ? (<span name="end_date" className="text-vermelho">{formik.errors.end_date}</span>) : null}
                </div>
            </div>

            {
                goalPlan ?
                <>
                <div className="col mt-3">
                    <label className="form-label fw-bold">Categoria <span className="text-danger">*</span></label>
                    <button className="btn btn-sm" onClick={(e) => handleShowCategory(e)}>
                        <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                    </button>
                    
                    {
                        showCategory ?
                        <>
                        <div className='mt-3 p-2 bg-light'>
                            <FormAddCategory model='goalplan' setCategory={setCategory} listCategories={props.listCategories} handleShowCategory={handleShowCategory} />
                        </div>
                        <hr/>
                        </>
                        :
                        null
                    }
                    {
                        categoryList ?
                        <select className='form-select mb-3' {...formik.getFieldProps('category')} required="required">
                            <option value=''>Sem Categoria</option>
                            {
                                categoryList.map((item, index) =>
                                <option key={index} value={item.id}>{item.title}</option>
                                )
                            }
                        </select>
                        :
                        null
                    }
                </div>

                <div className='col-md-4 mb-4'>
                    <label className="form-label fw-bold">Peso <span className="text-danger">*</span></label>
                    <div className='input-group'>
                        <input className="form-control" name='weight' type="number" required="required" autoComplete="off" {...formik.getFieldProps('weight')} />
                        <span className='input-group-text'>%</span>
                    </div>
                </div>
                </>
                :
                <>
                <div className="col mb-4">
                    <label className="form-label fw-bold">Tipo <span className="text-danger">*</span></label>
                    <select className='form-select mb-3' {...formik.getFieldProps('goal_type')} required="required">
                        <option value=''>Selecionar</option>
                        <option value='DP'>Desenvolvimento Pessoal</option>
                        <option value='PE'>Performance</option>
                    </select>
                </div>
                </>
            }

            {
                relatedGoalList ?
                <>
                <div className="col mb-4">
                    <label className="form-label fw-bold">
                        Relacionado a
                        <p className='fw-normal mb-0'>Este objetivo pode estar relacionado a algum objetivo organizacional</p>
                    </label>
                    <div className='p-inputgroup'>
                        <Dropdown filter placeholder="Selecionar" value={relatedGoal} optionLabel="title" options={relatedGoalList} optionGroupLabel="category" optionGroupChildren="items" onChange={(e) => {setRelatedGoal(e.value); formik.setFieldValue('related_goal', e.value.id);} } className="rounded" />
                    </div>
                </div>
                </>
                :
                null
            }

            {
                objId ?
                <>
                <div className="col mb-4">
                    <label className="form-label fw-bold">Status <span className="text-danger">*</span></label>
                    <select className='form-select mb-3' {...formik.getFieldProps('status')} required="required">
                        <option value='AT'>Ativo</option>
                        <option value='AR'>Arquivar</option>
                        <option value='CA'>Cancelar</option>
                    </select>
                </div>
                </>
                :
                null
            }
            
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        objId ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
    )
}
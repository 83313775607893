import React, {useEffect, useState, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import FormCreateGoal from '../../components/performance/forms/formCreateGoal';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import NameInitials from '../../helpers/nameInitials';
import moment from "moment";
import 'moment/locale/pt-br'
import { Chip } from 'primereact/chip';
import FormCreateJobPerformance from '../../components/performance/forms/formCreateJobPerformance';
import companyService from '../../services/data/company.service';
import { renderHeaderEditor } from '../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import FormAddCompetency from '../../components/settings/forms/formAddCompetency';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormCreateAssessmentQuestion from '../../components/performance/forms/formCreateAssessmentQuestion';
import UsePerms from '../../context/userAccess'
import companyData from '../../context/company';
import SkeletonProcess from './skeletonProcess';


export default function PerformanceReviewProcess() {

    const [pageLoad, setPageLoad] = useState(true)

    const VerifyPerms = UsePerms()
    const {rootUser} = companyData()
    
    const { id } = useParams()
    const toast = useRef(null)
    const navigate = useNavigate();
    const [object, setObject] = useState('')
    const [currentSession, setCurrentSession] = useState('')
    const [employee, setEmployee] = useState('')

    const [employeeList, setEmployeeList] = useState('') // quando a performance review tiver multiplos

    const [addGoal, setAddGoal] = useState(false)
    const [orgGoals, setOrgGoals] = useState([]) //goals organizacionais vigentes para poder fazer o related_goal
    const [addCompetency, setAddCompetency] = useState(false)
    const [categoryList, setCategoryList] = useState('') // para enviar para o form de criar jobperformance
    const headerEditor = renderHeaderEditor()
    const [currentDescription, setCurrentDescription] = useState('')

    //superior
    const [addGoalSuperior, setAddGoalSuperior] = useState(false)
    const [addCompetencySuperior, setAddCompetencySuperior] = useState(false)
    const [addQuestionSuperior, setAddQuestionSuperior] = useState(false)

    //listar ids das competencias da jobposition, 
    //se tiver o id aqui, não pode editar
    //só permitir editar se a comeptencia foi adicionada sem estar no jobposition
    const [jobCompetencies, setJobCompetencies] = useState('') 
    //pega competencia para atualizar
    const [currentCompetency, setCurrentCompetency] = useState('')
    const modalRef = useRef()
    const modalRef2 = useRef()
    const canvaRef = useRef()
    const canvaRef2 = useRef()
    const [addQuestion, setAddQuestion] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState('')
    
    const [showDetail, setShowDetail] = useState(false)
    const [showParticipants, setShowParticipants] = useState(false)
    const [showProcess, setShowProcess] = useState(true)
    const [showSuperior, setShowSuperior] = useState(false)
    const [showSummary, setShowSummary] = useState(false)

    const [dueDate, setDueDate] = useState('') // para enviar para 1ª etapa

    useEffect(() => {

        getObject()
        listCategories()

    }, [])

    function getObject() {
        
        performanceService.getPerformanceReview(id).then((response) => {
            setObject(response)
            if (response.sessions.length > 0){
                setCurrentSession(response.sessions[0])
            }
            if (response.employeesCount === 1) {
                setEmployee(response.employees[0])

                if (response.employees[0].jobposition.internaljobtitle.competencies.length > 0) {
        
                    setJobCompetencies(response.employees[0].jobposition.internaljobtitle.competencies.map((item) => item.id))
                }
            }

            if (response.employeesCount > 0) {
                setEmployeeList(response.employees)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function updateItems() {
        
        performanceService.getPerformanceReview(id).then((response) => {
            setObject(response)

            if (currentSession) {
                response.sessions.filter((val) => val.id === currentSession.id).map(obj => 
                    setCurrentSession(obj)
                )
            } else {
                if (response.sessions.length > 0){
                    setCurrentSession(response.sessions[0])
                }
            }
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        });

    }

    useMemo(() => {

        if (addGoal) {
            listOrgGoals()
        }

    }, [addGoal])

    function listOrgGoals() {
        let data = {
            goal_type: 'OG',
            status: ['AT']
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            
            const newObj = Object.entries(response.results).map(([key, value]) => ({
                category: key,
                items: value.map((vl) => {
                    return {
                        id: vl.id,
                        title: vl.title
                    }
                })
            }))
            setOrgGoals(newObj)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'competencies'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function showSession(val) {

        if (val === 'DETAIL') {
            setShowDetail(true)
        } else {
            setShowDetail(false)
        }

        if (val === 'PARTICIPANTS') {
            setShowParticipants(true)
        } else {
            setShowParticipants(false)
        }

        if (val === 'PROCESS') {
            setShowProcess(true)
        } else {
            setShowProcess(false)
        }

        if (val === 'SUPERIOR') {
            setShowSuperior(true)
        } else {
            setShowSuperior(false)
        }

        if (val === 'SUMMARY') {
            setShowSummary(true)
        } else {
            setShowSummary(false)
        }
    }

    function showForm(val) {

        if (val === 'GO') {
            setAddGoal(!addGoal)
        } else {
            setAddGoal(false)
        }

        if (val === 'CO') {
            setAddCompetency(!addCompetency)
        } else {
            setAddCompetency(false)
        }

        if (val === 'QE') {
            setAddQuestion(!addQuestion)
        } else {
            setAddQuestion(false)
        }
    }

    function showFormSuperior(val) {

        if (val === 'GO') {
            setAddGoalSuperior(!addGoalSuperior)
        } else {
            setAddGoalSuperior(false)
        }

        if (val === 'CO') {
            setAddCompetencySuperior(!addCompetencySuperior)
        } else {
            setAddCompetencySuperior(false)
        }

        if (val === 'QE') {
            setAddQuestionSuperior(!addQuestionSuperior)
        } else {
            setAddQuestionSuperior(false)
        }
    }

    function editGoal(id) {
        return navigate(`/goal/${id}/edit/`, {state: {relatedGoalList: orgGoals}})
    }

    function updateJobPerformance(id, description) {

        if (description) {

            let data = {
                id: id,
                description: description,
                company_id: localStorage.getItem("companyId")
            }

            performanceService.updateJobPerformance(data).then((response) => {
    
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência atualizada com sucesso', life: 3000 });


            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
            });

        } else {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Não foi possível salvar as alterações', life: 4000 });
        }
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }
    const hideModal2 = () => {
        const modalEl = modalRef2.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function deleteComp() {
        
        let data = {
            id: currentCompetency.id,
            company_id: localStorage.getItem("companyId")
        }

        performanceService.deleteJobPerformance(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência apagada com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                updateItems()
            }, 3000);
        })
    }

    function deleteQuestion() {
        let data = {
            id: currentQuestion.id,
            company_id: localStorage.getItem("companyId")
        }

        performanceService.deleteAssessmentQuestion(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Pergunta apagada com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal2()
                updateItems()
            }, 3000);
        })
    }

    function sendMailStartPerformance() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id: object.nextStep.id
        }

        if (object.nextMultipleStep) {
            data.next_steps = object.nextMultipleStep
        }

        if (dueDate) {
            data.due_date = dueDate
        }

        performanceService.messageTakePerformanceReview(data).then(() => {    
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Processo enviado com sucesso`, life: 5000 });
            updateItems()
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
        
    }

    function importCompetencies() {
        return navigate(`/performance-review/${id}/import/competencies/`, {state: {object: object, session: currentSession.id}})
    }

    function importQuestions() {
        return navigate(`/performance-review/${id}/import/questions/`, {state: {object: object, session: currentSession.id}})
    }

    function importGoals() {
        return navigate(`/performance-review/${id}/import/goals/`, {state: {object: object, session: currentSession.id}})
    }

    function importGoalsSuperior() {
        return navigate(`/performance-review/${id}/import/goals/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    function importCompetenciesSuperior() {
        return navigate(`/performance-review/${id}/import/competencies/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    function importQuestionsSuperior() {
        return navigate(`/performance-review/${id}/import/questions/`, {state: {object: object, session: object.sessionSuperior.id}})
    }

    const hideOffCanvas = () => {
        const elem = canvaRef.current
        const modal = bootstrap.Offcanvas.getInstance(elem)
        modal.hide()
    }

    const hideOffCanvas2 = () => {
        const elem = canvaRef2.current
        const modal = bootstrap.Offcanvas.getInstance(elem)
        modal.hide()
    }

    function removeSession(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }

        performanceService.deletePerformanceSession(data).then(() => { 
            hideOffCanvas()
            setCurrentSession('')
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Etapa apagada com sucesso`, life: 5000 });
            updateItems()
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    function deletePerformance() {

        performanceService.deletePerformanceReview(id).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Processo apagado com sucesso`, life: 5000 });

            setTimeout(() => {
                hideOffCanvas2()
                return navigate('/painel/')
            }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap paddingBottom200'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5 text-decoration-none text-white" role='button' onClick={() => navigate('/my/team/performance/')}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                {
                    pageLoad ?
                    <SkeletonProcess />
                    :
                    <div className='p-3 my-4 mx-5'>

                        <div className='d-flex justify-content-around bg-white rounded border-clean'>
                            <div role='button' className={ showDetail ? 'p-3 bg-green-light fw-bold' : 'p-3 text-grey' } onClick={() => showSession('DETAIL')}>
                                Detalhes
                            </div>
                            <div role='button' className={ showParticipants ? 'p-3 bg-green-light fw-bold' : 'p-3 text-grey' } onClick={() => showSession('PARTICIPANTS')}>
                                Participantes
                            </div>
                            <div role='button' className={ showProcess ? 'p-3 bg-green-light fw-bold' : 'p-3 text-grey' } onClick={() => showSession('PROCESS')}>
                                Conteúdo
                            </div>
                            {
                                object.assessment_type === 'SD' ?
                                <div role='button' className={ showSuperior ? 'p-3 bg-green-light fw-bold' : 'p-3 text-grey' } onClick={() => showSession('SUPERIOR')}>
                                    Avaliar Superior
                                </div>
                                : null
                            }
                            
                            {
                                object.status == 'AT' ?
                                <>
                                <div role='button' className={ showSummary ? 'p-3 bg-green-light fw-bold' : 'p-3 text-grey' } onClick={() => showSession('SUMMARY')}>
                                    Revisar e Enviar
                                </div>
                                </>
                                :
                                null
                            }
                        </div>

                        <div className='d-flex bg-white my-4 contentShadowFull rounded-top' style={{minHeight: '500px'}}>
                            
                            {
                                showDetail ?
                                <div className='flex-column col-12 paddingBottom200 pt-3'>
                                    <p className='fs-6 fw-bold ps-3'>Detalhes</p>
                                    <hr/>
                                    <div className='ps-3'>
                                        <div>
                                            <strong>Título</strong>
                                            <p>{object.title}</p>
                                        </div>
                                        {
                                            object.template ?
                                            <div>
                                                <strong>Ciclo</strong>
                                                <p>
                                                    <a href={`/performance-template/${object.template.id}/process/`} className='text-green fw-bold text-decoration-none'>
                                                        {object.template.title}
                                                    </a>
                                                </p>
                                            </div>
                                            : null
                                        }
                                        <div>
                                            <strong>Template</strong>
                                            <p>{object.assessmentTypeDisplay}</p>
                                        </div>
                                        <div>
                                            <strong>Criado em</strong>
                                            <p>{moment(object.created).format("DD-MMM-YYYY")}</p>
                                        </div>
                                        <div>
                                            <strong>Período</strong>
                                            <p>{moment(object.start_date).format("DD-MMM-YYYY")} - {moment(object.end_date).format("DD-MMM-YYYY")} </p>
                                        </div>
                                        <div>
                                            <strong>Status</strong>
                                            <p>{object.statusDisplay}</p>
                                        </div>
                                        <div>
                                            <strong>Considerar Resultado na Matriz 9Box?</strong>
                                            <p>
                                                {
                                                    object.result_to_matrix ?
                                                    'Sim'
                                                    :
                                                    'Não'
                                                }
                                            </p>
                                        </div>
                                        {
                                            object.status == 'AT' ?
                                            <div className='p-2'>
                                                <span className='font095 text-vermelho btn btn-sm float-end' data-bs-toggle="offcanvas" data-bs-target="#offCanvasDelProcess" >Apagar Processo?</span>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {
                                showParticipants ?
                                <div className='flex-column col-12 paddingBottom200 pt-3'>
                                    <p className='fs-6 fw-bold ps-3'>Participantes</p>
                                    <hr/>
                                    <div className='ps-3'>
                                        <div>
                                            <strong>{object ? <> { object.managers.length > 1 ? <>Gestores ({object.managers.length})</> : 'Gestor'}</> : null}</strong>
                                            {
                                                object.managers.length > 0 ?
                                                <table className='table table-borderless mt-1'>
                                                    <tbody>
                                                    {
                                                        object.managers.map((item, index) =>
                                                        {
                                                            return item.user.photo ?
                                                            <tr key={index} className='align-middle'>
                                                                <td width="20px">
                                                                    <Avatar title={item.user.full_name} image={item.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                                </td>
                                                                <td className='ms-1'>
                                                                    <span className='fw-bold'>{item.user.full_name}</span>
                                                                    <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                                </td>
                                                            </tr>
                                                            :
                                                            <tr key={index} className='align-middle'>
                                                                <td width="20px">
                                                                    <Avatar title={item.user.full_name} label={NameInitials( item.user.first_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                                </td>
                                                                <td className='ms-1'>
                                                                    <span className='fw-bold'>{item.user.full_name}</span>
                                                                    <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                                </td>
                                                            </tr>
                                                        }
                                                        )
                                                        
                                                    }
                                                    </tbody>
                                                </table>
                                                : 
                                                <p>Nenhum gestor adicionado</p>
                                            }
                                        </div>
                                        <div className='mt-1'>
                                            <strong>Avaliados {employeeList ? <>({employeeList.length})</> : null}</strong>
                                            {
                                                employeeList ?
                                                <table className='table table-borderless mt-1'>
                                                    <tbody>
                                                    {
                                                        employeeList.map((item, index) =>
                                                        {
                                                            return item.user.photo ?
                                                            <tr key={index} className='align-middle'>
                                                                <td width="20px">
                                                                    <Avatar title={item.user.full_name} image={item.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                                </td>
                                                                <td className='ms-1'>
                                                                    <span className='fw-bold'>{item.user.full_name}</span>
                                                                    <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                                </td>
                                                            </tr>
                                                            :
                                                            <tr key={index} className='align-middle'>
                                                                <td width="20px">
                                                                    <Avatar title={item.user.full_name} label={NameInitials( item.user.first_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                                </td>
                                                                <td className='ms-1'>
                                                                    <span className='fw-bold'>{item.user.full_name}</span>
                                                                    <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                                </td>
                                                            </tr>
                                                        }
                                                        )
                                                        
                                                    }
                                                    </tbody>
                                                </table>
                                                :
                                                <p>Nenhum participante adicionado</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            
                            
                            {
                                showProcess ?
                                <div className='col-12 paddingBottom200 pt-3'>
                                    <p className='fs-6 fw-bold ps-3'>Conteúdo</p>
                                    <hr className='mb-0' />
                                    <div className='d-flex'>
                                        <div className='flex-column col-2 bg-light'>
                                        {
                                            object ?
                                            <>
                                            <nav className='nav flex-column'>
                                            {
                                                object.sessions.map((item, index) => 
                                                <>
                                                {
                                                    currentSession.id === item.id ?
                                                    <li key={index} className='nav-item bg-white py-2 border-bottom'>
                                                        <span className='nav-link text-default fw-bold'>{index+1} - {item.title}</span>
                                                    </li>
                                                    :
                                                    <li key={index} className='nav-item py-2 border-bottom' type='button' onClick={() => {setCurrentSession(item); showForm()}}>
                                                        <span className='nav-link text-default'>{index+1} - {item.title}</span>
                                                    </li>
                                                }
                                                </>
                                                )
                                            }    
                                            </nav>
                                            </>
                                            :
                                            <>Carregando...</>
                                        }
                                        </div>
                                        
                                        <div className='flex-column col-10'>
                                            <div className='p-3 mb-5'>
                                                <div className='d-flex justify-content-between'>
                                                        <div className='fs-6 fw-bold'>
                                                        {currentSession.title} 
                                                        {
                                                            currentSession.session_type === 'GO' && currentSession.goals.length > 0 ?
                                                            <span className='mx-1'>({currentSession.goals.length})</span>
                                                            : null
                                                        }
                                                        {
                                                            currentSession.session_type === 'CO' && currentSession.competencies.length > 0 ?
                                                            <span className='mx-1'>({currentSession.competencies.length})</span>
                                                            : null
                                                        }
                                                        {
                                                            currentSession.session_type === 'QE' && currentSession.questions.length > 0 ?
                                                            <span className='mx-1'>({currentSession.questions.length})</span>
                                                            : null
                                                        }
                                                        {
                                                            object.status == 'AT' ?
                                                            <>
                                                            {
                                                                currentSession.session_type === 'CO' ?
                                                                <>
                                                                <div className='dropdown d-inline' role="group" style={{'fontSize': '12px'}}>
                                                                    <button className='btn btn-sm' data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                                                    </button>
                                                                    <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                                    {
                                                                        VerifyPerms(['add_competencies']) || rootUser ?
                                                                        <>
                                                                        <li>
                                                                            <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAdd"  onClick={() => showForm(currentSession.session_type)}>
                                                                                Adicionar Nova Competência
                                                                            </span>
                                                                        </li>
                                                                        </>
                                                                        : null
                                                                    }
                                                                        <li>
                                                                            <span role='button' className='dropdown-item text-default' onClick={() => {importCompetencies()}}>
                                                                                Importar Competência
                                                                            </span>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                                </>
                                                                :
                                                                <>
                                                                {
                                                                    currentSession.session_type === 'QE' ?
                                                                    <>
                                                                    <div className='dropdown d-inline' role="group" style={{'fontSize': '12px'}}>
                                                                        <button className='btn btn-sm' data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                                                        </button>
                                                                        <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                                            <li>
                                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAdd"  onClick={() => showForm(currentSession.session_type)}>
                                                                                    Adicionar Nova Pergunta
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importQuestions()}} >
                                                                                    Importar Perguntas
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    </>
                                                                    :
                                                                    <div className='dropdown d-inline' role="group" style={{'fontSize': '12px'}}>
                                                                        <button className='btn btn-sm' data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                                                        </button>
                                                                        <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                                            <li>
                                                                                <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAdd"  onClick={() => showForm(currentSession.session_type)}>
                                                                                    Adicionar Novo Objetivo
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <span role='button' className='dropdown-item text-default' onClick={() => {importGoals()}} >
                                                                                    Importar Objetivos
                                                                                </span>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                }
                                                                </>
                                                            }
                                                            </>
                                                            : null
                                                        }
                                                        </div>
                                                        {
                                                            object.status == 'AT' && currentSession ?
                                                            <div className='p-2'>
                                                                <span className='font095 text-vermelho btn btn-sm' data-bs-toggle="offcanvas" data-bs-target="#offCanvasDelSession" >Remover Etapa?</span>
                                                            </div>
                                                            :null
                                                        }
                                                    </div>
                                                    <hr/>

                                                    <div ref={canvaRef} className='offcanvas offcanvas-end offcanvas-size-l' data-bs-backdrop="static" id='offCanvasDelSession' tabIndex={-1}>
                                                        <div className='offcanvas-header'>
                                                            <h6 className='offcanvas-title'>Remover Etapa</h6>
                                                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                        </div>
                                                        <div className='offcanvas-body'>
                                                            <div className='p-3 bg-light rounded'>
                                                                {
                                                                    currentSession ?
                                                                    <>
                                                                    <p className='lead'>
                                                                        Tem certeza de que deseja apagar a etapa <strong>{currentSession.title}</strong> ?
                                                                    </p>
                                                                    <p>
                                                                        Se sim, todos os itens da etapa também serão apagados.
                                                                    </p>
                                                                    <span className='btn btn-danger btn-sm' onClick={() => removeSession(currentSession.id)}>Sim, remover etapa</span>
                                                                    </>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAdd' tabIndex={-1}>
                                                        {
                                                            addCompetency ?
                                                            <>
                                                            <div className='offcanvas-header'>
                                                                <h6 className='offcanvas-title'>Adicionar Competência</h6>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showForm(currentSession.session_type)}></button>
                                                            </div>
                                                            <div className='offcanvas-body'>
                                                                <div className='p-3 bg-light rounded'>
                                                                    <FormCreateJobPerformance categoryList={categoryList} assessment={id} session={currentSession.id} listItems={updateItems} />
                                                                </div>
                                                            </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                        {
                                                            addGoal ?
                                                            <>
                                                            <div className='offcanvas-header'>
                                                                <h6 className='offcanvas-title'>Adicionar Objetivo</h6>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showForm(currentSession.session_type)}></button>
                                                            </div>
                                                            <div className='offcanvas-body'>
                                                                <div className='p-3 bg-light rounded'>
                                                                    <FormCreateGoal startDate={object.start_date} endDate={object.end_date} related_goals={orgGoals} employee={employee.id} performanceSession={currentSession.id} listItems={updateItems} />
                                                                </div>
                                                            </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                        {
                                                            addQuestion ?
                                                            <>
                                                            <div className='offcanvas-header'>
                                                                <h6 className='offcanvas-title'>Adicionar Pergunta</h6>
                                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showForm(currentSession.session_type)}></button>
                                                            </div>
                                                            <div className='offcanvas-body'>
                                                                <div className='p-3 bg-light rounded'>
                                                                    <FormCreateAssessmentQuestion assessment={id} session={currentSession.id} listItems={updateItems} />
                                                                </div>
                                                            </div>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                        
                                                    {
                                                        currentSession.session_type === 'GO' ?
                                                        <>
                                                        {
                                                            currentSession.goals.length > 0 ?
                                                            <>
                                                            <table className='table table-borderless thover text-default mt-3'>
                                                                <thead className='borderBottom bg-light'>
                                                                    <tr>
                                                                        <th>
                                                                            Objetivo
                                                                        </th>
                                                                        <th className='text-center'>
                                                                            Criado por
                                                                        </th>
                                                                        <th className='text-center'>
                                                                            Tipo
                                                                        </th>
                                                                        <th className='text-center'>
                                                                            Período
                                                                        </th>
                                                                        <th className='text-center'>
                                                                            Status
                                                                        </th>
                                                                        <th className='text-center'>
                                                                            Relacionado a
                                                                        </th>
                                                                        <th className='text-center'></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        currentSession.goals.map((item, index) =>
                                                                            <tr key={index} className=''>
                                                                                <td className="align-middle">
                                                                                    <span className='text-green fw-bold text-capitalize fs-6'>{item.title}</span>
                                                                                    <div>
                                                                                        <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {item.created_by.first_name} {item.created_by.last_name}
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    <Chip label={item.goal_type_display} />
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                {
                                                                                    item.goalLastStatus.status ?
                                                                                    <>
                                                                                    {
                                                                                        item.goalLastStatus.status === 'CS' ?
                                                                                        <>
                                                                                        <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                                                        </>
                                                                                        :
                                                                                        <>{item.goalLastStatus.status_display}</>
                                                                                    }
                                                                                    </>
                                                                                    :
                                                                                    <>Não Iniciado</>
                                                                                }
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                {
                                                                                    item.related_goal ?
                                                                                    <>
                                                                                    {item.related_goal.title}
                                                                                    </>
                                                                                    :
                                                                                    <>-</>
                                                                                }
                                                                                </td>
                                                                                <td className="align-middle text-center">
                                                                                    {
                                                                                        object.status == 'AT' ?
                                                                                        <button className='btn btn-sm rounded-circle border' onClick={() => editGoal(item.id)}>
                                                                                            <FontAwesomeIcon className='text-green' icon={solid('pencil')} />
                                                                                        </button>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            </>
                                                            :
                                                            <>
                                                            {
                                                                !addGoal ?
                                                                <p>Nenhum objetivo adicionado ainda</p>
                                                                : null
                                                            }
                                                            </>
                                                        }
                                                        </>
                                                        : null
                                                    }
                                                    {
                                                        currentSession.session_type === 'CO' ?
                                                        <>
                                                        {
                                                            currentSession.competencies.length > 0 ?
                                                            <>
                                                            {
                                                                currentSession.competencies.map((obj, idx) =>
                                                                <div key={idx} className='my-4'>
                                                                    <div className='font120 fw-bold pb-2'>
                                                                        {obj.competency.title}
                                                                        {
                                                                            jobCompetencies.includes(obj.competency.id) ?
                                                                            null
                                                                            :
                                                                            <>
                                                                            {
                                                                                object.status == 'AT' ?
                                                                                <>
                                                                                {
                                                                                    VerifyPerms(['change_competencies']) || rootUser ?
                                                                                    <button className='ms-1 btn btn-sm rounded-circle' data-bs-toggle="offcanvas" data-bs-target="#offCanvasComp" onClick={() => {setCurrentCompetency(obj.competency)}}>
                                                                                        <FontAwesomeIcon className='text-green font095' icon={solid('pencil')} />
                                                                                    </button>
                                                                                    : null
                                                                                }
                                                                                </>
                                                                                : null
                                                                            }
                                                                            </> 
                                                                        }
                                                                    </div>

                                                                    <label className="form-label">Descrição sobre como a competência deve ser avaliada <span className="text-danger">*</span></label>
                                                                    <Editor
                                                                        className='noToolbar text-default'
                                                                        name="description"
                                                                        style={{ height: '80px' }}
                                                                        headerTemplate={headerEditor}
                                                                        value={obj.description}
                                                                        onTextChange={(e) => {
                                                                            setCurrentDescription(e.htmlValue)
                                                                        }}
                                                                    />
                                                                    {
                                                                        object.status == 'AT' ?
                                                                        <button className='btn btn-sm btn-success font1em mt-2' onClick={() => updateJobPerformance(obj.id, currentDescription)}>Salvar</button>
                                                                        : null
                                                                    }
                                                                    <hr className='hr-border'/>
                                                                </div>
                                                                )
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            {
                                                                !addCompetency ?
                                                                <p>Nenhuma competência adicionada ainda</p>
                                                                : null
                                                            }
                                                            </>
                                                        }
                                                        </>
                                                        : null
                                                    }

                                                    <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasComp' tabIndex={-1}>
                                                        <div className='offcanvas-header'>
                                                            <h6 className='offcanvas-title'>Editar Competência</h6>
                                                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setCurrentCompetency('') } }></button>
                                                        </div>
                                                        <div className='offcanvas-body'>
                                                            <div className='p-3 bg-light rounded'>
                                                                <FormAddCompetency obj={currentCompetency} categoriesList={categoryList} listItems={updateItems} />
                                                            </div>
                                                            <div className="text-end btnMargin">
                                                                <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDelComp">Apagar Competência</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div id="formDelComp" className="modal fade" ref={modalRef}>
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Apagar Competência</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>
                                                                        Tem certeza de que deseja apagar esta Competência?
                                                                    </p>
                                                                    <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={() => deleteComp()}>Sim, Apagar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    {
                                                        currentSession.session_type === 'QE' ?
                                                        <>
                                                        {
                                                            currentSession.questions.length > 0 ?
                                                            <>
                                                            {
                                                                currentSession.questions.map((obj, idx) =>
                                                                <div key={idx} className='my-4'>
                                                                    
                                                                    <table className='table'>
                                                                        <tbody>
                                                                            <td style={{width: '10px'}}>
                                                                                <p className='fw-bold'>{idx+1}.</p>
                                                                            </td>
                                                                            <td style={{width: '80%'}}>
                                                                                <div className='text-capitalize' dangerouslySetInnerHTML={{__html: obj.question}}></div>
                                                                            </td>
                                                                            <td className='text-center align-middle'>
                                                                                {
                                                                                    object.status == 'AT' ?
                                                                                    <button className='btn btn-sm rounded-circle' data-bs-toggle="offcanvas" data-bs-target="#offCanvasQuestion" onClick={() => {setCurrentQuestion(obj)}}>
                                                                                        <FontAwesomeIcon className='text-green' icon={solid('pencil')} />
                                                                                    </button>
                                                                                    : null
                                                                                }
                                                                            </td>
                                                                        </tbody>
                                                                    </table>
                                                                    <hr className='hr-border'/>
                                                                </div>
                                                                )
                                                            }
                                                            </>
                                                            :
                                                            <p>Nenhuma pergunta adicionada ainda</p>
                                                        }
                                                        </>
                                                        : null
                                                    }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {
                                showSuperior ?
                                <div className='flex-column col-12 paddingBottom200 pt-3'>
                                    <p className='fs-6 fw-bold ps-3'>Avaliar Superior</p>
                                    <hr/>
                                    
                                    <div className='ps-3'>
                                        <div className='mb-3'>
                                            <div className='dropdown dropend' role="group" style={{'fontSize': '12px'}}>
                                                <button className='btn btn-sm btn-success btn-rounded px-3' data-bs-toggle="dropdown" aria-expanded="false">
                                                    Adicionar
                                                </button>
                                                <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                    <li>
                                                        <span className='dropdown-header'>Objetivos</span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddSuperior" onClick={() => showFormSuperior('GO')}>
                                                            Adicionar Novo Objetivo
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => {importGoalsSuperior()}} >
                                                            Importar Objetivos
                                                        </span>
                                                    </li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li>
                                                        <span className='dropdown-header'>Competências</span>
                                                    </li>
                                                    {
                                                        VerifyPerms(['add_competencies']) || rootUser ?
                                                        <>
                                                        <li>
                                                            <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddSuperior"  onClick={() => showFormSuperior('CO')} >
                                                                Adicionar Nova Competência
                                                            </span>
                                                        </li>
                                                        </>
                                                        : null
                                                    }
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => {importCompetenciesSuperior()}}>
                                                            Importar Competência
                                                        </span>
                                                    </li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li>
                                                        <span className='dropdown-header'>Perguntas</span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' data-bs-toggle="offcanvas" data-bs-target="#offCanvasAddSuperior"  onClick={() => showFormSuperior('QE')}>
                                                            Adicionar Nova Pergunta
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => {importQuestionsSuperior()}} >
                                                            Importar Perguntas
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            object && object.sessionSuperior && object.sessionSuperior.goals.length > 0 ?
                                            <>
                                            <div className='pt-3'>
                                                <p className='fs-6 fw-bold'> Metas ({object.sessionSuperior.goals.length})</p>
                                            </div>
                                            <table className='table table-borderless thover text-default mt-3'>
                                                <thead className='borderBottom bg-light'>
                                                    <tr>
                                                        <th>
                                                            Objetivo
                                                        </th>
                                                        <th className='text-center'>
                                                            Criado por
                                                        </th>
                                                        <th className='text-center'>
                                                            Tipo
                                                        </th>
                                                        <th className='text-center'>
                                                            Período
                                                        </th>
                                                        <th className='text-center'>
                                                            Status
                                                        </th>
                                                        <th className='text-center'>
                                                            Relacionado a
                                                        </th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        object.sessionSuperior.goals.map((item, index) =>
                                                            <tr key={index} className=''>
                                                                <td className="align-middle">
                                                                    <span className='text-green fw-bold text-capitalize fs-6'>{item.title}</span>
                                                                    <div>
                                                                        <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {item.created_by.first_name} {item.created_by.last_name}
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    <Chip label={item.goal_type_display} />
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                {
                                                                    item.goalLastStatus.status ?
                                                                    <>
                                                                    {
                                                                        item.goalLastStatus.status === 'CS' ?
                                                                        <>
                                                                        <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                                                        </>
                                                                        :
                                                                        <>{item.goalLastStatus.status_display}</>
                                                                    }
                                                                    </>
                                                                    :
                                                                    <>Não Iniciado</>
                                                                }
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                {
                                                                    item.related_goal ?
                                                                    <>
                                                                    {item.related_goal.title}
                                                                    </>
                                                                    :
                                                                    <>-</>
                                                                }
                                                                </td>
                                                                <td className="align-middle text-center">
                                                                    {
                                                                        object.status == 'AT' ?
                                                                        <button className='btn btn-sm rounded-circle border' onClick={() => editGoal(item.id)}>
                                                                            <FontAwesomeIcon className='text-green' icon={solid('pencil')} />
                                                                        </button>
                                                                        :
                                                                        null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            object && object.sessionSuperior && object.sessionSuperior.competencies.length > 0 ?
                                            <>
                                            <div className='pt-3'>
                                                <p className='fs-6 fw-bold'> Competências ({object.sessionSuperior.competencies.length})</p>
                                            </div>
                                            {
                                                object.sessionSuperior.competencies.map((obj, idx) =>
                                                <div key={idx} className='my-4'>
                                                    <div className='font120 fw-bold pb-2'>
                                                        {obj.competency.title}
                                                        {
                                                            object.status == 'AT' ?
                                                            <>
                                                            {
                                                                VerifyPerms(['change_competencies']) || rootUser ?
                                                                <button className='ms-1 btn btn-sm rounded-circle' data-bs-toggle="offcanvas" data-bs-target="#offCanvasComp" onClick={() => {setCurrentCompetency(obj.competency)}}>
                                                                    <FontAwesomeIcon className='text-green font095' icon={solid('pencil')} />
                                                                </button>
                                                                : null
                                                            }
                                                            </>
                                                            : null
                                                        }
                                                    </div>

                                                    <label className="form-label">Descrição sobre como a competência deve ser avaliada <span className="text-danger">*</span></label>
                                                    <Editor
                                                        className='noToolbar text-default'
                                                        name="description"
                                                        style={{ height: '80px' }}
                                                        headerTemplate={headerEditor}
                                                        value={obj.description}
                                                        onTextChange={(e) => {
                                                            setCurrentDescription(e.htmlValue)
                                                        }}
                                                    />
                                                    {
                                                        object.status == 'AT' ?
                                                        <button className='btn btn-sm btn-success font1em mt-2' onClick={() => updateJobPerformance(obj.id, currentDescription)}>Salvar</button>
                                                        : null
                                                    }
                                                    <hr className='hr-border'/>
                                                </div>
                                                )
                                            }
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            object && object.sessionSuperior && object.sessionSuperior.questions.length > 0 ?
                                            <>
                                            <div className='pt-3'>
                                                <p className='fs-6 fw-bold'> Perguntas ({object.sessionSuperior.questions.length})</p>
                                            </div>
                                            {
                                                object.sessionSuperior.questions.map((obj, idx) =>
                                                <div key={idx} className='my-4'>
                                                    
                                                    <table className='table'>
                                                        <tbody>
                                                            <td style={{width: '10px'}}>
                                                                <p className='fw-bold'>{idx+1}.</p>
                                                            </td>
                                                            <td style={{width: '80%'}}>
                                                                <div className='text-capitalize' dangerouslySetInnerHTML={{__html: obj.question}}></div>
                                                            </td>
                                                            <td className='text-end align-middle pe-4'>
                                                                {
                                                                    object.status == 'AT' ?
                                                                    <button className='btn btn-sm rounded-circle' data-bs-toggle="offcanvas" data-bs-target="#offCanvasQuestion" onClick={() => {setCurrentQuestion(obj)}}>
                                                                        <FontAwesomeIcon className='text-green' icon={solid('pencil')} />
                                                                    </button>
                                                                    : null
                                                                }
                                                            </td>
                                                        </tbody>
                                                    </table>
                                                    <hr className='hr-border'/>
                                                </div>
                                                )
                                            }
                                            </>
                                            :
                                            null
                                        }
                                    </div>

                                    <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddSuperior' tabIndex={-1}>
                                        {
                                            addCompetencySuperior ?
                                            <>
                                            <div className='offcanvas-header'>
                                                <h6 className='offcanvas-title'>Adicionar Competência</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showFormSuperior('')}></button>
                                            </div>
                                            <div className='offcanvas-body'>
                                                <div className='p-3 bg-light rounded'>
                                                    <FormCreateJobPerformance categoryList={categoryList} assessment={id} session={object.sessionSuperior.id} listItems={updateItems} />
                                                </div>
                                            </div>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            addGoalSuperior ?
                                            <>
                                            <div className='offcanvas-header'>
                                                <h6 className='offcanvas-title'>Adicionar Objetivo</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showFormSuperior('')}></button>
                                            </div>
                                            <div className='offcanvas-body'>
                                                <div className='p-3 bg-light rounded'>
                                                    <FormCreateGoal startDate={object.start_date} endDate={object.end_date} related_goals={orgGoals} employee={employee.id} performanceSession={object.sessionSuperior.id} listItems={updateItems} />
                                                </div>
                                            </div>
                                            </>
                                            :
                                            null
                                        }
                                        {
                                            addQuestionSuperior ?
                                            <>
                                            <div className='offcanvas-header'>
                                                <h6 className='offcanvas-title'>Adicionar Pergunta</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => showFormSuperior('')}></button>
                                            </div>
                                            <div className='offcanvas-body'>
                                                <div className='p-3 bg-light rounded'>
                                                    <FormCreateAssessmentQuestion assessment={id} session={object.sessionSuperior.id} listItems={updateItems} />
                                                </div>
                                            </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {
                                showSummary ?
                                <div className='flex-column col-12 paddingBottom200 pt-3'>
                                {
                                    object ?
                                    <>
                                    <p className='fs-6 fw-bold ps-3'>Revisar e Enviar</p>
                                    <hr/>
                                    {
                                        employeeList ?
                                        <div className='row mb-3'>
                                            <div className='col-4'>
                                                <div className='ps-1'>
                                                    <div className='fw-bold mb-2'>
                                                        Enviando para:
                                                    </div>
                                                    <AvatarGroup>
                                                    {
                                                        employeeList.map((item, index) => 
                                                            {
                                                                return item.user.photo ?
                                                                <Avatar key={index} title={item.user.first_name + ' ' + item.user.last_name} image={item.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                                :
                                                                <Avatar key={index} title={item.user.first_name + ' ' + item.user.last_name} label={NameInitials( item.user.first_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size="large" />
                                                            }
                                                        )
                                                    }
                                                    </AvatarGroup>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='border p-2 rounded m-1'>
                                                <table className='table font1em'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{fontSize: '14px'}}>Etapas</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        object.steps.map((item, index) => 
                                                        <>
                                                        <tr key={index} className='border-bottom align-middle'>
                                                            <td className='text-default'>
                                                                {index+1} - {item.title}
                                                                <div className='text-grey font095'><span className='fw-bold'>Status:</span> {item.statusDisplay}</div>
                                                            </td>
                                                            
                                                            <td>
                                                            {
                                                                !object.messageSentNextStep && object.nextStep.id === item.id ?
                                                                <>
                                                                <button className='btn btn-sm btn-success btn-rounded px-3 font1em' onClick={() => {setDueDate(object.start_date)}} data-bs-toggle="offcanvas" data-bs-target="#offCanvas">Enviar</button>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                            </td>
                                                        </tr>
                                                        </>
                                                        )
                                                    }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='col-8 ms-auto'>
                                            <div className='border p-2 rounded m-1'>
                                                <h6 className='py-2 fw-bold border-bottom mb-0' style={{fontSize: '14px'}}>Processo</h6>
                                                <table className='table'>
                                                    <tbody>
                                                    {
                                                        object.sessions.map((session, index) => 
                                                        <>
                                                        <tr className='border-bottom bg-green-light align-middle'>
                                                            <td className='fw-bold'>{session.title}</td>
                                                        </tr>
                                                        {
                                                        session.goals.length > 0 ?
                                                        <>
                                                        {
                                                                session.goals.map((item, index) =>
                                                                    <tr key={index} className=''>
                                                                        <td className="align-middle">
                                                                            <span className='text-green fw-bold'>{index+1} - {item.title}</span>
                                                                            <div>
                                                                                <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                        }
                                                        {
                                                        session.competencies.length > 0 ?
                                                        <>
                                                        {
                                                                session.competencies.map((item, index) =>
                                                                    <tr key={index} className=''>
                                                                        <td className="align-middle">
                                                                            <span className='text-green fw-bold'>{index+1} - {item.competency.title}</span>
                                                                            <div>
                                                                                <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                        }
                                                        {
                                                        session.questions.length > 0 ?
                                                        <>
                                                        {
                                                                session.questions.map((item, index) =>
                                                                    <tr key={index} className=''>
                                                                        <td className="align-middle">
                                                                            <span className='text-green fw-bold'>{index+1}.</span>
                                                                            <div>
                                                                                <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>Carregando...</>
                                }
                                </div>
                                : null
                            }
                        </div>
                    
                    </div>
                }
            </div>

            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasQuestion' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Editar Pergunta</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setCurrentQuestion('') } }></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        <FormCreateAssessmentQuestion obj={currentQuestion} listItems={updateItems} />
                    </div>
                    <div className="text-end btnMargin">
                        <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDelQues">Apagar Pergunta</button>
                    </div>
                </div>
            </div>

            <div id="formDelQues" className="modal fade" ref={modalRef2}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar Pergunta</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar esta Pergunta?
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={() => deleteQuestion()}>Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={canvaRef2} className='offcanvas offcanvas-end offcanvas-size-l' data-bs-backdrop="static" id='offCanvasDelProcess' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Apagar Processo</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        {
                            object ?
                            <>
                            <p className='lead'>
                                Tem certeza de que deseja apagar o processo <strong>{object.title}</strong> ?
                            </p>
                            <span className='btn btn-danger btn-sm' onClick={() => deletePerformance()}>Sim, apagar</span>
                            </>
                            : null
                        }
                    </div>
                </div>
            </div>
            
            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvas' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Enviar</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setDueDate('')}}></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        {
                            employee || employeeList ?
                            <>
                            {
                                employee ?
                                <p>Tem certeza de que deseja enviar para <span className='fw-bold'>{employee.user.first_name}</span> a avaliação <span className='fw-bold'>{object.title}</span> ?</p>
                                : null
                            }
                            {
                                employeeList ?
                                <p>Tem certeza de que deseja enviar para <span className='fw-bold'>{employeeList.length}</span> pessoas a avaliação <span className='fw-bold'>{object.title}</span> ?</p>
                                : null
                            }
                            
                            <div className='mb-3'>
                                <label className="form-label fw-bold">Prazo <span className="text-danger">*</span></label>
                                <input className="form-control" type="date" required="required" autoComplete="off" min={object.start_date} max={object.end_date} value={dueDate} onChange={(e) => {setDueDate(e.target.value) }} />
                            </div>
                            <button className='btn btn-sm btn-success btn-rounded px-3' onClick={() => sendMailStartPerformance()}>
                                Sim, enviar
                            </button>
                            </>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
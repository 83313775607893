import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NavLink } from 'react-router-dom';


export default function SideBarMessage() {

    return (
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 bg-white border-clean">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 min-vh-100">
                <NavLink to="/painel/" className="nav-link text-center mt-3">
                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                </NavLink>
                <hr className="border-clean2-dot w-100"/>
                <p className="mt-1 ps-3 fw-bold d-none d-sm-inline">Mensagens</p>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="sideMenu">
                    <li className="nav-item w-100">
                        <NavLink to="/messages/inbox/" className="nav-link align-middle">
                            <FontAwesomeIcon className='text-green fs-6' icon={solid('circle-user')} /> <span className="ms-1 d-none d-sm-inline">Recebidas</span>
                        </NavLink>
                    </li>
                    <li className="nav-item w-100">
                        <NavLink to="/messages/sent/" className="nav-link align-middle">
                            <FontAwesomeIcon className='text-green fs-6' icon={solid('users')} /> <span className="ms-1 d-none d-sm-inline">Enviadas</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}
import React, {useMemo, useRef, useState} from 'react';
import { useFormik } from 'formik';
import careerService from '../../services/data/career.service';
import { Toast } from 'primereact/toast';
import moment from "moment";
import 'moment/locale/pt-br'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function FormAddEducation(props) {

    const toast = useRef(null);
    const userId = props.userId
    const [title, setTitle] = useState('')
    const [school, setSchool] = useState('')
    const [level, setLevel] = useState('')
    const [status, setStatus] = useState('')
    const [monthStart, setMonthStart] = useState('')
    const [yearStart, setYearStart] = useState('')
    const [monthEnd, setMonthEnd] = useState('')
    const [yearEnd, setYearEnd] = useState('')

    const modalRef = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useMemo(() => {

        getItem()

    }, [props])

    function getItem() {
        if (props.object) {
            setTitle(props.object.title)
            setSchool(props.object.school)
            setLevel(props.object.level)
            setStatus(props.object.status)
            setMonthStart(
                moment(props.object.start).format("M")
            )
            setYearStart(
                moment(props.object.start).format("Y")
            )

            if (props.object.end) {

                setMonthEnd(
                    moment(props.object.end).format("M")
                )
                setYearEnd(
                    moment(props.object.end).format("Y")
                )
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            school: school,
            level: level,
            status: status,
            month_start: monthStart,
            year_start: yearStart,
            month_end: monthEnd,
            year_end: yearEnd,
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                user_id: userId,
                title: values.title,
                school: values.school,
                level: values.level,
                status: values.status,
                month_start: values.month_start,
                year_start: values.year_start,
            }
            if (values.month_end) {
                data.month_end = values.month_end
                data.year_end = values.year_end
            }

            if (props.object) {

                data.id = props.object.id

                careerService.updateUserEducation(data).then((response) => {

                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Formação atualizada com sucesso', life: 3000 });

                    props.updateData()
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            } else {

                careerService.createUserEducation(data).then((response) => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Formação adicionada com sucesso', life: 3000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            }
        },
    });

    function deleteItem() {
        var data = {
            id: props.object.id
        }
        careerService.deleteUserEducation(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Item apagado com sucesso', life: 3000 });

            props.updateData()

            setTimeout(() => {
                hideModal()
                props.showBar(false)
            }, 3000);
        })
    }

    const levelLista = { 
        "0" : "Até 5º ano do Ensino Fundamental",
        "1" : "Do 6º ao 9º ano do Ensino Fundamental",
        "2" : "Ensino Fundamental",
        "3" : "Ensino Médio"
    }

    const hasTitle = useMemo(() => {

        if ( formik.values.level ) {

            if ( parseInt(formik.values.level) < 4 ) {

                setTitle(
                    levelLista[formik.values.level]
                )
                
                return false
            }
    
            return true

        }

        return false

    }, [formik.values.level])

    return (
        <>
        <Toast ref={toast} position="top-right" />
        <form className='border rounded bg-light p-3' onSubmit={formik.handleSubmit}>

            <label className="fw-bold font110">Formação Acadêmica</label>

            <div className="col mt-3">
                <label className="form-label">Nível <span className="text-danger">*</span></label>
                <select className="form-select" required="required" {...formik.getFieldProps('level')}>
                    <option value="">Selecionar</option>
                    <option value="0">Até 5º ano do Ensino Fundamental</option>
                    <option value="1">Do 6º ao 9º ano do Ensino Fundamental</option>
                    <option value="2">Ensino Fundamental</option>
                    <option value="3">Ensino Médio</option>
                    <option value="4">Curso Técnico</option>
                    <option value="5">Tecnólogo</option>
                    <option value="6">Ensino Superior</option>
                    <option value="7">Pós Graduação</option>
                    <option value="8">Mestrado</option>
                    <option value="9">Doutorado</option>
                    <option value="10">Curso</option>
                </select>
            </div>

            {
                hasTitle ?
                <>
                <div className="col mt-3">
                    <label className="form-label">Curso <span className="text-danger">*</span></label>
                    <input name='title' type="text" className="form-control text-capitalize" {...formik.getFieldProps('title')} />
                </div>
                </>
                : null
            }

            <div className="col mt-3">
                <label className="form-label">Instituição <span className="text-danger">*</span></label>
                <input name='school' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('school')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Status <span className="text-danger">*</span></label>
                <select className="form-select" required="required" {...formik.getFieldProps('status')}>
                    <option value="">Selecionar</option>
                    <option value="2">Cursando</option>
                    <option value="1">Concluído</option>
                    <option value="3">Incompleto</option>
                    { props.object ? <option value="4">Desconhecido</option> : null}
                </select>
            </div>

            <div className='row mt-3'>
                <div className='mb-4 ps-1'>
                    <label className="form-label">Data de Início</label>
                    <div className='row px-0'>
                        <div className='col'>
                            <select className="form-select" required="required" {...formik.getFieldProps('month_start')}>
                                <option value="">Mês</option>
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </select>
                        </div>
                        <div className='col'>
                            <input className="form-control" type='number' maxLength="4" min="1950" max={ new Date().getFullYear() } placeholder='Ano' name='year_start' required="required" autoComplete="off" {...formik.getFieldProps('year_start')} />
                        </div>
                    </div>
                </div>

                {
                    formik.values.status !== "3" ?
                    <>
                    <div className='mb-4 ps-1'>
                        <label className="form-label">Data Término / Previsão</label>
                        <div className='row px-0'>
                            <div className='col'>
                                <select className="form-select" required="required" {...formik.getFieldProps('month_end')}>
                                    <option value="">Mês</option>
                                    <option value="1">Janeiro</option>
                                    <option value="2">Fevereiro</option>
                                    <option value="3">Março</option>
                                    <option value="4">Abril</option>
                                    <option value="5">Maio</option>
                                    <option value="6">Junho</option>
                                    <option value="7">Julho</option>
                                    <option value="8">Agosto</option>
                                    <option value="9">Setembro</option>
                                    <option value="10">Outubro</option>
                                    <option value="11">Novembro</option>
                                    <option value="12">Dezembro</option>
                                </select>
                            </div>
                            <div className='col'>
                                <input className="form-control" type='number' maxLength="4" min="1950" placeholder='Ano' name='year_end' required="required" autoComplete="off" {...formik.getFieldProps('year_end')} />
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    <>
                    </>
                }
            </div>

            <hr/>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        props.object ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
        {
            props.object ?
            <>
            <div className="text-end btnMargin">
                <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar</button>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar esta formação?
                            </p>
                            <p>
                                {formik.values.title}
                                <br/>
                                {formik.values.school}
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            : null
        }
        </>
    )
}
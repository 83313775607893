import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate } from 'react-router-dom';
import FormAddBenefit from '../../../components/settings/forms/formAddBenefit';
import { Chip } from 'primereact/chip';
import moment from "moment";
import 'moment/locale/pt-br'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormImportBenefitsXls from '../../../components/settings/forms/formImportBenefitsXls';
import VerifyPerms from '../../../context/userAccess';


export default function BenefitsList() {
    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [itemsCount, setItemsCount] = useState(0);

    const [categories, setCategories] = useState('')

    const [worksheet, setWorksheet] = useState('')

    const navigate = useNavigate()
    const modalRef = useRef()
    const modalRef2 = useRef()

    useEffect(() => {
        listItems()
        listCategories()
    }, [page]);

    const searchItems = (event) => {
        if (event.query.length > 2) {

            companyService.autoCompleteBenefits(event.query).then((response) => {

                let resultTitle = response.results.map((item) => item.title + ' - ' + item.internal_code)
                let resultSupplier = response.results.map((item) => item.supplier)
                resultSupplier = new Set(resultSupplier) // remove duplicados

                let resultCategory = response.results.map((item) => item.category.title)
                resultCategory = new Set(resultCategory) //remove duplicados
                
                setItems([...resultTitle, ...resultSupplier, ...resultCategory])
            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listItems() {
        companyService.listBenefits(page).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function filterItems() {
        companyService.listBenefits(page, search.split('-')).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function cleanSearch() {
        setSearch('')
        listItems() 
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'benefits'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategories(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    const hideModal2 = () => {
        const modalEl = modalRef2.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function editItem() {
        return navigate(`/settings/benefit/edit/`, {state: {selection: selection, categoriesList: categories.results}})
    }

    useEffect(() => {

        getWorksheet()

    }, [])

    function getWorksheet() {

        let data = {
            key: 'templates/Benefits.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheet(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Lista de Benefícios</span>
                        </div>
                        <div className="row m-3 p-3 justify-content-start">
                            <div className='col bg-white border-clean pb-5 ps-3'>
                                {
                                    VerifyPerms(['add_benefits', 'change_benefits', 'delete_benefits', 'view_benefits']) ?
                                    <>
                                    <div className="pt-4 d-flex mb-4">
                                        <div className='col'>
                                            <span className='font120 fw-bold '>
                                                Benefícios
                                            </span>
                                            <button id='dbm-addJob' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                            </button>
                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addJob">
                                                <li>
                                                    <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formAdd">
                                                        Adicionar Benefício
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport">
                                                        Adicionar Múltiplos Benefícios
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className='col'>
                                            <div className='d-flex'>

                                                <div className='p-inputgroup'>
                                                    <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Benefício pelo Nome, Código, Fornecedor ou Categoria' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                    <button className='btn btn-success button-search-radius' onClick={filterItems}>
                                                        <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                    </button>
                                                </div>
                                                {
                                                    search ?
                                                    <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                        Limpar
                                                    </button>
                                                    : null
                                                }
                                                
                                            </div>
                                            
                                        </div>
                                        <hr className='hr-border' />
                                    </div>

                                    <p className='text-end'><strong>{itemsCount}</strong> Benefícios cadastrados</p>

                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                                        <>
                                        <table className='table table-borderless thover text-default borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th>
                                                        Benefício
                                                    </th>
                                                    <th className='text-center'>
                                                        Código
                                                    </th>
                                                    <th className='text-center'>
                                                        Fornecedor
                                                    </th>
                                                    <th className='text-center'>
                                                        Categoria
                                                    </th>
                                                    <th className='text-center'>
                                                        Ativado em
                                                    </th>
                                                    <th className='text-center'>
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                <span className='text-green fw-bold text-decoration-none cursorPointer' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                    {item.title}
                                                                </span>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.internal_code}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.supplier}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    item.category ?
                                                                    <>{item.category.title}</>
                                                                    :
                                                                    <>-</>
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {moment(item.date_active).format("DD-MMM-YYYY")} 
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {
                                                                    item.inactive ?
                                                                    <>
                                                                    <Chip className='bg-vermelho-light' label="Inativo" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                    <Chip label="Ativo" />
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className='float-end'>
                                            <nav>
                                                <ul className='pagination pagination-sm'>
                                                    <li className='page-item'>
                                                        { 
                                                            itemsList.previous ? 
                                                            <span onClick={subPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                        }
                                                    </li>
                                                    <li className='page-item'>
                                                        {
                                                            itemsList.next ?
                                                            <span onClick={addPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                        }
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        </>

                                        : null
                                    }
                                    <div id="formAdd" className="modal fade" ref={modalRef2}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Adicionar Benefício</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <FormAddBenefit categories={categories} listCategories={listCategories} listItems={listItems} hideModal={hideModal2} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="formImport" className="modal fade" ref={modalRef}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Importar Múltiplos Benefícios</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    
                                                    <div className='mb-3'>
                                                        <p>
                                                            Utilize e planilha modelo para importar seus benefícios 
                                                        </p>
                                                        <a href={worksheet} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                            Planilha Modelo
                                                        </a>
                                                    </div>
                                                    <hr/>
                                                    <FormImportBenefitsXls hideModal={hideModal} />
                                                </div>
                                                <div className='modal-footer'>
                                                    <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <p className='alert alert-warning mt-3'>Você não tem permissão para acessar esta página</p>
                                }
                            </div>
                            <div className="card flex justify-content-center">
                                <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                                {
                                        selection ?
                                        <>
                                        {
                                        <div>
                                            <p className='lead fw-bold text-default'>
                                                {selection.title} <small className='text-grey fw-normal'>({selection.internal_code})</small>
                                            </p>
                                            
                                            <div>
                                                {
                                                    selection.inactive ?
                                                    <>
                                                    <Chip className='bg-vermelho-light px-4 py-1 me-2' label="Inativo" />
                                                    </>
                                                    :
                                                    <>
                                                    <Chip className='px-4 py-1 me-2' label="Ativo" />
                                                    </>
                                                }
                                                <button className='btn btn-sm btn-outline-success btn-rounded px-3 py-1 m-1' onClick={editItem} >
                                                    <FontAwesomeIcon icon={solid('edit')} /> Editar
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className='py-2'>
                                                <strong>Descrição</strong>
                                                {
                                                    selection.description ?
                                                    <p dangerouslySetInnerHTML={{__html: selection.description}}></p>
                                                    :
                                                    <p>
                                                    Nenhuma descrição adicionada
                                                    </p>
                                                }
                                            </div>
                                            <div className='py-2'>
                                                <strong>Fornecedor</strong>
                                                {
                                                    selection.supplier ?
                                                    <p>{selection.supplier}</p>
                                                    :
                                                    <p>-</p>
                                                }
                                            </div>
                                            <div className='py-2'>
                                                <strong>Categoria</strong>
                                                {
                                                    selection.category ?
                                                    <p>{selection.category.title}</p>
                                                    :
                                                    <p>-</p>
                                                }
                                            </div>
                                            <div className='py-2'>
                                                <strong>Ativado em</strong>
                                                {
                                                    selection.date_active ?
                                                    <p>{moment(selection.date_active).format("DD-MMM-YYYY")}</p>
                                                    :
                                                    <p>-</p>
                                                }
                                            </div>
                                            {
                                                selection.inactive ?
                                                <div className='py-2'>
                                                    <strong>Inativo em</strong>
                                                    <p>{moment(selection.date_inactive).format("DD-MMM-YYYY")}</p>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        }
                                        </>
                                        :null
                                    }
                                </Sidebar>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
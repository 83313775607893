import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import performanceService from '../../services/data/performance.service';
import { Button } from 'primereact/button';
import NameInitials from '../../helpers/nameInitials';
import { Sidebar } from 'primereact/sidebar';
import { useFormik } from 'formik';
import moment from "moment";
import 'moment/locale/pt-br'
import SkeletonMatrix from './skeletonMatrix';
import UsePerms from "../../context/userAccess";
import companyData from '../../context/company';


export default function TeamMatrix() {

    const VerifyPerms = UsePerms()
    const {rootUser} = companyData()

    const [pageLoad, setPageLoad] = useState(true)
    const toast = useRef(null);
    const [itemsList, setItemsList] = useState([]);
    const [empty, setEmpty] = useState(false)

    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        listMatrix()
        
    }, [])

    function listMatrix() {
        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        performanceService.PerformanceMatrixGet(data).then((response) => {   
            
            if (response.length > 0) {
                setItemsList(response)
            } else {
                setEmpty(true)
            }
    
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })
    }

    const load = () => {
        setLoading(true);
        generateMatrix()
    };

    function generateMatrix() {
        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        performanceService.createPerformanceMatrix(data).then((response) => {   
            
            if (response.length > 0) {
                setItemsList(response)
                setEmpty(false)
            } else {
                setEmpty(true)
            }
            
        }).catch((error) => {
            console.log(error.response)
        });

        setLoading(false)
    }

    const box1 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 1 && item.score.box[1] == 1)
            return res
        }

    }, [itemsList])

    const box2 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 1 && item.score.box[1] == 2)
            return res
        }

    }, [itemsList])

    const box3 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 1 && item.score.box[1] == 3)
            return res
        }

    }, [itemsList])

    const box4 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 2 && item.score.box[1] == 1)
            return res
        }

    }, [itemsList])

    const box5 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 2 && item.score.box[1] == 2)
            return res
        }

    }, [itemsList])

    const box6 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 2 && item.score.box[1] == 3)
            return res
        }

    }, [itemsList])

    const box7 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 3 && item.score.box[1] == 1)
            return res
        }

    }, [itemsList])

    const box8 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 3 && item.score.box[1] == 2)
            return res
        }

    }, [itemsList])

    const box9 = useMemo(() => {

        if (itemsList) {

            let res = itemsList.filter((item) => item.score.box[0] == 3 && item.score.box[1] == 3)
            return res
        }

    }, [itemsList])

    const currentBox = useMemo(() => {

        if (selection) {

            if (selection.score.box[0] == 1 && selection.score.box[1] == 1) {
                return 'Detratores'
            }
            
            if (selection.score.box[0] == 1 && selection.score.box[1] == 2) {
                return 'Questionáveis'
            }
            
            if (selection.score.box[0] == 1 && selection.score.box[1] == 3) {
                return 'Latentes'
            }
            
            if (selection.score.box[0] == 2 && selection.score.box[1] == 1) {
                return 'Eficazes'
            }
            
            if (selection.score.box[0] == 2 && selection.score.box[1] == 2) {
                return 'Transitórios'
            }
            
            if (selection.score.box[0] == 2 && selection.score.box[1] == 3) {
                return 'Emergentes'
            }

            if (selection.score.box[0] == 3 && selection.score.box[1] == 1) {
                return 'Comprometidos'
            }

            if (selection.score.box[0] == 3 && selection.score.box[1] == 2) {
                return 'Emergentes'
            }
            
            if (selection.score.box[0] == 3 && selection.score.box[1] == 3) {
                return 'Estrelas'
            }
        }

    }, [selection])

    function truncateStr(str) {
        return str.length > 3 ? str.substring(0, 1): str;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            box: '',
            reason: '',
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                manual: true,
                assessment: selection.assessment,
                employee: selection.employee.id,
                box: values.box,
                related_matrix: selection.id,
                reason: values.reason
            }

            performanceService.calibratePerformanceMatrix(data).then(() => {    
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Processo enviado com sucesso`, life: 5000 });
                listMatrix()

                setShowSidebar(false)
                setSelection('')
                formik.resetForm()

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
            });
        },
    });

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Performance
                        </a>
                    </div>
                </div>
                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    <ul className='nav nav-tabs px-3'>
                        {/* 
                        <li className='nav-item'>
                            <a href='/my/performance/' className='nav-link text-green'>
                                Minha Performance
                            </a>
                        </li>
                        */}
                        
                        <li className='nav-item'>
                            <a href='/my/team/performance/' className='nav-link text-green'>
                            Minha Equipe
                            </a>
                        </li>
                        <li className='nav-item'>
                            <span className='nav-link active'>
                                Matriz
                            </span>
                        </li>
                        <li className='nav-item'>
                            <a href='/my/performance/list/' className='nav-link text-green'>
                                Lista de Avaliações
                            </a>
                        </li>
                    </ul>
                    <div className='bg-white py-4 rounded'>

                        {
                            pageLoad ?
                            <SkeletonMatrix />
                            :
                            <>
                            <div className='row mt-3'>
                                <div className='col-10 offset-1'>

                                    {
                                        empty ?
                                        <>
                                        <div className='text-center'>
                                            <p className='alert'>Nenhuma Matriz gerada ainda.</p>
                                            <div className="btnMargin d-none">
                                                <Button rounded label="Gerar Matriz" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <>
                                        
                                        </>
                                    }

                                    <div className='boxs'>

                                        <div className='w-100 d-flex'>
                                            <div className='verticalTxtBox'>
                                                <span className='text-center'>Acima da Expectativa</span>
                                            </div>
                                            
                                            <div className="box p-2 boxBlue">
                                                <p className='text-center'>Comprometidos</p>
                                                <div id='box7' className='d-flex flex-wrap justify-content-center'>
                                                    {
                                                        box7.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box7.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box7.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxGreenLight">
                                                <p className='text-center'>Emergentes</p>
                                                <div id='box8' className='d-flex flex-wrap justify-content-center'>
                                                    {
                                                        box8.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box8.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box8.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxGreen">
                                                <p className='text-center'>Estrelas</p>
                                                <div id='box9' className='d-flex flex-wrap justify-content-center'>
                                                    {
                                                        box9.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box9.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box9.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-flex'>
                                            <div className='verticalTxtBox'>
                                                <span>Dentro da Expectativa</span>
                                            </div>
                                            
                                            <div className="box p-2 boxYellow">
                                                <p className='text-center'>Eficazes</p>
                                                <div id='box4' className='d-flex flex-wrap justify-content-center'>
                                                    {
                                                        box4.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box4.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                        <Avatar className="shadow"  label={truncateStr(`+${box4.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxBlue">
                                                <p className='text-center'>Transitórios</p>
                                                <div id='box5' className='d-flex flex-wrap justify-content-center'>
                                                {
                                                        box5.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box5.length > 10 ?
                                                        <span role='button' className='mx-1'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box5.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxGreenLight">
                                                <p className='text-center'>Emergentes</p>
                                                <div id='box6' className='d-flex flex-wrap justify-content-center'>
                                                {
                                                        box6.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box6.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                        <Avatar className="shadow"  label={truncateStr(`+${box6.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-flex'>
                                            <div className='verticalTxtBox'>
                                                <span>Abaixo da Expectativa</span>
                                            </div>
                                            
                                            <div className="box p-2 boxRed">
                                                <p className='text-center'>Detratores</p>
                                                <div id='box1' className='d-flex flex-wrap justify-content-center'>
                                                    {
                                                        box1.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box1.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box1.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxYellow">
                                                <p className='text-center'>Questionáveis</p>
                                                <div id='box2' className='d-flex flex-wrap justify-content-center'>
                                                {
                                                        box2.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box2.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box2.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="box p-2 boxBlue">
                                                <p className='text-center'>Latentes</p>
                                                <div id='box3' className='d-flex flex-wrap justify-content-center'>
                                                {
                                                        box3.slice(0,10).map((item) => 
                                                        <>
                                                        {
                                                            item.employee.user.photo ?
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  image={item.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                            :
                                                            <span role='button' className='mx-1' onClick={() => {setSelection(item); setShowSidebar(true)}}>
                                                                <Avatar className="shadow"  label={NameInitials( item.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                            </span>
                                                        }
                                                        </>
                                                        )
                                                    }
                                                    {
                                                        box3.length > 10 ?
                                                        <div className='m-2 border-top pt-3'>
                                                        <span role='button'>
                                                            <Avatar className="shadow"  label={truncateStr(`+${box3.length}`)} style={{ backgroundColor: '#5ab1b8', color: '#ffffff', fontSize: '1rem' }} shape='circle' size='large' />
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='w-100 d-flex'>
                                            <div className='verticalTxtBox'>
                                                <span></span>
                                            </div>

                                            <div className='horizontalTxtBox'>
                                                <span>Baixo Potencial</span>
                                            </div>
                                            <div className='horizontalTxtBox'>
                                                <span>Médio Potencial</span>
                                            </div>
                                            <div className='horizontalTxtBox'>
                                                <span>Alto Potencial</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            </>
                        }
                        
                    </div>

                    <div className="card flex justify-content-center">
                        <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                        {
                                selection ?
                                <>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className='d-block align-content-start'>
                                                {
                                                    selection.employee.user.photo ?
                                                    <Avatar image={selection.employee.user.photo} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                    :
                                                    <Avatar label={NameInitials( selection.employee.user.name )} style={{ backgroundColor: '#57c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' size='large' />
                                                }
                                                </td>
                                                <td className='ps-2 lh-sm'>
                                                    <div className='fs-5 fw-bold'>
                                                        {selection.employee.user.name} {selection.employee.user.last_name}
                                                    </div>
                                                    <div className='fs-6 text-grey fw-normal'>{selection.jobposition.title}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-2'>
                                    <strong>Quadrante:</strong> {currentBox}
                                </div>
                                <div className='mt-2'>
                                    <strong>Calibração:</strong> {selection.is_manual ? 'Manual' : 'Automática' }
                                </div>
                                <div className='mt-2'>
                                    <strong>Criado em:</strong> {moment(selection.created).format("DD-MMM-YYYY")}
                                </div>
                                {
                                    selection.reason ?
                                    <div className='mt-2'>
                                        <strong>Motivo:</strong> <span dangerouslySetInnerHTML={{ __html: selection.reason }}></span>
                                    </div>
                                    : null
                                }
                                {
                                    VerifyPerms(['add_performancematrix']) || rootUser ?
                                    <div className='mt-2 border-top pt-3'>
                                        <p className='fw-bold'>
                                            Deseja realizar a calibração manual deste profissional?
                                        </p>

                                        <div>
                                            <form onSubmit={formik.handleSubmit}>

                                                <div className="my-3">
                                                    <label className="form-label">Escolher Quadrante <span className="text-danger">*</span></label>
                                                    <select className='form-select mb-3' {...formik.getFieldProps('box')} required>
                                                        <option value=''>Selecionar</option>
                                                        <option value='1'>Quadrante 1 - Detratores</option>
                                                        <option value='2'>Quadrante 2 - Questionáveis</option>
                                                        <option value='3'>Quadrante 3 - Latentes</option>
                                                        <option value='4'>Quadrante 4 - Eficazes</option>
                                                        <option value='5'>Quadrante 5 - Transitórios</option>
                                                        <option value='6'>Quadrante 6 - Emergentes</option>
                                                        <option value='7'>Quadrante 7 - Comprometidos</option>
                                                        <option value='8'>Quadrante 8 - Emergentes</option>
                                                        <option value='9'>Quadrante 9 - Estrelas</option>
                                                    </select>
                                                </div>
                                                <div className="my-3">
                                                    <label className="form-label">Motivo <span className="text-danger">*</span></label>
                                                    <textarea className='form-control' {...formik.getFieldProps('reason')} required></textarea>
                                                </div>

                                                <div className="btnMargin">
                                                    <button className="btn btn-success btn-sm btn-rounded px-3" type="submit">
                                                        Enviar
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    : null
                                }
                                </>
                                :null
                            }
                        </Sidebar>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
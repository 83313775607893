import React from 'react';

export default function NotFound() {

    return (

        <div className="row mt-5 fullHeight">
            <div className="col-lg-6 mx-auto text-center">

                <div className='text-vermelho' style={{fontSize: '8em'}}>
                    Ops!
                </div>
                <div className='text-grey' style={{fontSize: '6em'}}>
                    Página não encontrada
                </div>

            </div>
        </div>
    );
};
import React, {useEffect, useState} from 'react';
import userData from '../../context/user';
import { useNavigate } from "react-router-dom";
import employeeService from '../../services/data/employee.service'
import companyService from '../../services/data/company.service';
import companyData from '../../context/company';
import CompanyLogoDefault from '../../assets/images/company-logo-default.png';


export default function Home(props) {
    const {user} = userData()
    const [employeeList, setEmployeeList] = useState([])
    const { updateCompany, company } = companyData()
    const companyId = localStorage.getItem('companyId')
    const navigate = useNavigate();
    const url = new URL(window.location.href);

    useEffect(() => {

        // se não tem employee ainda, registrar company
        employeeService.listEmployee().then( (data) => {

            if (data.length > 1) {
                //se tem mais de uma empresa, escolher
                setEmployeeList(data)

            } else if (data.length > 0) {
                // se so tem uma empresa, avançar automatico
                if (!companyId) {
                    localStorage.setItem('companyId', data[0].company)
                    updateCompany()
                }
                redirectUser()
            } 
            else {
                //navigate to register company
                return navigate('/register/company/')
            }

        }).catch((error) => {
            console.log('error ', error.response)
            return navigate('/')
        })
        
    }, []);

    function redirectUser() {

        if (url.search.includes("?next=")) {
            window.location.href = url.search.replace("?next=", "");
            return <></>;
        }
        return navigate('/painel/')
    }

    function handleClick(e) {
            
        //console.log('click ', e.currentTarget.getAttribute('data-value'))
        //console.log('click ', e.currentTarget.getAttribute('data-company'))
        const companyId = e.currentTarget.getAttribute('data-value')

        companyService.getCompany(companyId).then( (data) => {
            localStorage.setItem('companyId', data.id)
            updateCompany(data)
            redirectUser()
        })
    }

    return (
        <div className="container-fluid fullHeight">
            <div className="row flex-nowrap">
            
                <div className="col py-3">

                    <div className="row mt-3">
                        <div className="col-md-6 offset-md-3">
                            <h1 className="text-center">
                                Bem vindo(a) <span className="text-green">{user.first_name}</span>!
                            </h1>
                        </div>
                        {
                            employeeList && employeeList.length > 0 ?
                            <>
                            <p className='text-center lead'>Escolha uma organização para acessar</p>
                            <div className="row justify-content-center">
                            
                                {employeeList.map((item, index) => 
                                    <div className='col-2 m-1 divBorder text-center' key={index}>
                                        <span className="fs-5">
                                            {
                                                item.companyLogo ?
                                                <img src={`${item.companyLogo}`} alt="logo" className="img-fluid rounded border-0" width="40" />
                                                :
                                                <img src={CompanyLogoDefault} alt="logo" className="img-fluid rounded border-0" width="40" />
                                            }
                                        </span>
                                        <div className='mt-2'>
                                            <span className="fs-6 p-2">{ item.companyName }</span>
                                        </div>
                                        <div className="d-grid mt-3">
                                            <button className="btn btn-success btn-sm btn-rounded" data-value={item.company} data-company={item.companyName} onClick={handleClick}>Acessar</button>
                                        </div>
                                    </div>
                                )}
                            
                            </div>
                            </>
                            : null
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}
import React from "react";
import Logo from "../assets/images/logo.png"
import userService from "../services/data/user.service";
import userData from "../context/user";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Navigate } from "react-router";
import { Avatar } from 'primereact/avatar';
import NameInitials from "../helpers/nameInitials";


export default function Header() {

    const {user} = userData();

    function logOut() {
        localStorage.clear()
        userService.logoutUser().then( () => {
            return <Navigate to="/" />
        })
    }

    return (
        <header className="d-flex flex-wrap justify-content-md-between headerBorder bg-white">
            <div className="container" style={{maxWidth: '1280px'}}>
                <div className="container-fluid d-grid gap-3 hgrd">
                    <a className="d-flex align-items-center col-md-3 mb-md-0 text-decoration-none" href="/">
                        <img src={Logo} alt={process.env.REACT_APP_SITE_NAME} height="50" />
                    </a>
                    {
                        user.first_name ?
                        <div className="dropdown p-2">
                            <button className="btn btn-link dropdown-toggle font1em text-default text-decoration-none" id="dropdown1" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    user.photo ?
                                    <Avatar className="position-relative align-middle me-1" image={user.photo} shape="circle" />
                                    :
                                    <Avatar label={NameInitials( user.first_name )} style={{ backgroundColor: '#fbb042', color: '#ffffff', fontWeight: 'bold', marginRight: '5px' }} shape="circle" />
                                }
                                 Olá { user.social_name ? <>{user.social_name.split(" ")[0]}</> : <>{user.first_name}</> }
                            </button>
                            <ul className="dropdown-menu border-clean headerBorder font1em mt-1" aria-labelledby="dropdownMenu1">
                            <li>
                                    <a href="/terms/" className="dropdown-item text-default">
                                        <FontAwesomeIcon className='px-2' icon={solid('user-shield')} /> Termos e LGPD
                                    </a>
                                </li>
                                <li>
                                    <a href="/policy/" className="dropdown-item text-default">
                                        <FontAwesomeIcon className='px-2' icon={solid('shield-halved')} /> Política
                                    </a>
                                </li>
                                <li>
                                    <a href="/support/"  className="dropdown-item text-default">
                                        <FontAwesomeIcon className='px-2' icon={solid('headphones-simple')} /> Suporte
                                    </a>
                                </li>
                                <li><hr style={{borderTop: '1px solid #2d60655f', height:'0', margin:'1em 0'}} /></li>
                                <li>
                                    <a className="dropdown-item text-default" onClick={logOut} href="/">
                                        <FontAwesomeIcon className='px-2 text-default' icon={solid('right-from-bracket')} />Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        : null
                    }
                </div>
            </div>
        </header>
    );
};
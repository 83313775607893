import React, { useEffect, useState, createContext, useContext } from "react";
import userService from "../services/data/user.service";


const UserContext = createContext();

export function UserProvider({children}) {
    const [user, setUser] = useState({})

    function updateUser() {
        userService.getUser().then( (data) => {
            setUser(data)
        }).catch(() => {
            //localStorage.clear()
        })
    }

    useEffect(() => {
        updateUser()
    }, []);

    return (
        <UserContext.Provider value={{user, updateUser}}>
            {children}
        </UserContext.Provider>
    )
}

export default function userData() {
    return useContext(UserContext);
}
import http from "../http"

class EmployeeService {

    async listEmployee() {
        const rqst = await http.get('employee/list/')
        return rqst.data
    }

    async listCompanyEmployee(page, getItem) {
        var data = {
            company_id: localStorage.getItem("companyId"),
        }
        if (getItem) {
            data.getItem = getItem
        }
        const rqst = await http.post('/company/employee/list/?page=' + page, data)
        return rqst.data
    }

    async createEmployee(data) {
        const rqst = await http.post('/company/employee/create/', data)
        return rqst.data
    }

    async getEmployee(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/company/employee/get/', data)
        return rqst.data
    }

    async getUserEmployee() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/company/employee/user/get/', data)
        return rqst.data
    }

    async updateEmployee(data) {
        const rqst = await http.post('/company/employee/update/', data)
        return rqst.data
    }

    async deleteEmployee(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/company/employee/delete/', data)
        return rqst.data
    }

    async listParentEmployee(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/company/employee/parent/', data)
        return rqst.data
    }

    async listEmployeePerms() {
        let data = {
            company_id: localStorage.getItem("companyId"),
        }
        const rqst = await http.post('/company/employee/perms/', data)
        return rqst.data
    }

    async autoCompleteEmployee(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/company/employee/autocomplete/', data)
        return rqst.data
    }

    async autoCompleteEmployeeManager(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/company/employee-manager/autocomplete/', data)
        return rqst.data
    }

    async importXlsEmployee(data) {
        const rqst = await http.post('/company/employee/import-xls/', data)
        return rqst.data
    }

    async listEmployeeOrg(item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/company/employee/list/org/', data)
        return rqst.data
    }

    async listManagers(page, data) {
        const rqst = await http.post('/company/employee/manager/list/?page=' + page, data)
        return rqst.data
    }
}

export default new EmployeeService()
import React, {useState, useEffect} from 'react';
import FormCreateGoal from './forms/formCreateGoal';
import performanceService from '../../services/data/performance.service';
import companyService from '../../services/data/company.service';
import FormCreateJobPerformance from './forms/formCreateJobPerformance';
import FormCreateAssessmentQuestion from './forms/formCreateAssessmentQuestion';


export default function ContentTemplateSuperior(props) {

    const object = props.object
    const session = props.session
    const [orgGoals, setOrgGoals] = useState([]) //goals organizacionais vigentes para poder fazer o related_goal
    const [categoryList, setCategoryList] = useState('') // para enviar para o form de criar jobperformance

    useEffect(() => {
        listOrgGoals()
        listCategories()
    }, [])

    function listOrgGoals() {
        let data = {
            goal_type: 'OG',
            status: ['AT']
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            
            const newObj = Object.entries(response.results).map(([key, value]) => ({
                category: key,
                items: value.map((vl) => {
                    return {
                        id: vl.id,
                        title: vl.title
                    }
                })
            }))
            setOrgGoals(newObj)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'competencies'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    return (
        <div className='col-12 paddingBottom200'>
            {
                object && session ?
                <>
                <div className='d-flex'>
                
                    <div className='col'>
                        <div className='p-3 pt-0 mb-5'>
                            <div className=''>
                                <table className='table'>
                                    <tbody>
                                    {
                                        session.goals.length > 0 ?
                                        <>
                                        <tr className='border-bottom bg-green-light align-middle'>
                                            <td className='fw-bold'>Objetivos ({session.goals.length})</td>
                                        </tr>
                                        {
                                                session.goals.map((item, index) =>
                                                    <tr key={index} className=''>
                                                        <td className="align-middle">
                                                            <span className='text-green fw-bold'>{index+1} - {item.title}</span>
                                                            <div>
                                                                <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    {
                                        session.competencies.length > 0 ?
                                        <>
                                        <tr className='border-bottom bg-green-light align-middle'>
                                            <td className='fw-bold'>Competências ({session.competencies.length})</td>
                                        </tr>
                                        {
                                                session.competencies.map((item, index) =>
                                                    <tr key={index} className=''>
                                                        <td className="align-middle">
                                                            <span className='text-green fw-bold'>{index+1} - {item.competency.title}</span>
                                                            <div>
                                                                <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    {
                                        session.questions.length > 0 ?
                                        <>
                                        <tr className='border-bottom bg-green-light align-middle'>
                                            <td className='fw-bold'>Perguntas ({session.questions.length})</td>
                                        </tr>
                                        {
                                                session.questions.map((item, index) =>
                                                    <tr key={index} className=''>
                                                        <td className="align-middle">
                                                            <span className='text-green fw-bold'>{index+1}.</span>
                                                            <div>
                                                                <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddGoal' tabIndex={-1}>
                    <div className='offcanvas-header'>
                        <h6 className='offcanvas-title'>Adicionar Objetivo</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                    </div>
                    <div className='offcanvas-body'>
                        <div className='p-3 bg-light rounded'>
                            <FormCreateGoal performanceSession={session.id} startDate={object.start_date} endDate={object.end_date} related_goals={orgGoals} listItems={props.updateObject} />
                        </div>
                    </div>
                </div>

                <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddComp' tabIndex={-1}>
                    <div className='offcanvas-header'>
                        <h6 className='offcanvas-title'>Adicionar Competência</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                    </div>
                    <div className='offcanvas-body'>
                        <div className='p-3 bg-light rounded'>
                            <FormCreateJobPerformance session={session.id} categoryList={categoryList} listItems={props.updateObject} />
                        </div>
                    </div>
                </div>

                <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddQuestion' tabIndex={-1}>
                    <div className='offcanvas-header'>
                        <h6 className='offcanvas-title'>Adicionar Pergunta</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                    </div>
                    <div className='offcanvas-body'>
                        <div className='p-3 bg-light rounded'>
                            <FormCreateAssessmentQuestion session={session.id} listItems={props.updateObject} />
                        </div>
                    </div>
                </div>
                </>
                : null
            }
        </div>
    )

}
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { NavLink } from 'react-router-dom';
import UsePerms from "../../context/userAccess";


export default function SideBarSettings() {

    const VerifyPerms = UsePerms()

    return (
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 bg-white border-clean side-shadow">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 min-vh-100">
                <NavLink to="/painel/" className="nav-link text-center mt-3">
                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                </NavLink>
                <hr className="border-clean2-dot w-100"/>
                <p className="mt-1 ps-3 fw-bold d-none d-sm-inline">Configurações</p>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="sideMenu">
                    <li className="nav-item w-100">
                        <NavLink to="/settings/edit-user/" className="nav-link align-middle">
                            <FontAwesomeIcon className='text-green fs-6' icon={solid('circle-user')} /> <span className="ms-1 d-none d-sm-inline">Meus Dados</span>
                        </NavLink>
                    </li>
                    {
                        VerifyPerms(['view_companygroup', 'add_companygroup', 'change_companygroup', 'delete_companygroup']) ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/perms-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('users')} /> <span className="ms-1 d-none d-sm-inline">Grupos e Permissões</span>
                            </NavLink>
                        </li>
                        : null
                    }

                    {
                        VerifyPerms(['view_userprofile', 'add_userprofile', 'change_userprofile', 'delete_userprofile']) ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/users-management" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('users')} /> <span className="ms-1 d-none d-sm-inline">Colaboradores</span>
                            </NavLink>
                        </li>
                        : null
                    }
                    {
                        VerifyPerms(['change_company', 'change_companyconfig']) ?
                        <li className="nav-item w-100">
                            <NavLink to="/settings/edit-company/" className="nav-link align-middle">
                                <FontAwesomeIcon className='text-green fs-6' icon={solid('sitemap')} /> <span className="ms-1 d-none d-sm-inline">Gestão Empresa</span>
                            </NavLink>
                        </li>
                        : null
                    }
                </ul>
            </div>
        </div>
    )
}
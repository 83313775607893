import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { AutoComplete } from "primereact/autocomplete";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormAddGroup from '../../../components/settings/forms/formAddGroup';
import VerifyPerms from '../../../context/userAccess';


export default function GroupsList() {

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [itemsCount, setItemsCount] = useState(0);
    const modalRef = useRef()

    useEffect(() => {
        listItems()
    }, [page]);

    const searchItems = (event) => {
        if (event.query.length > 2) {

            companyService.autoCompleteCompanyGroup(event.query).then((response) => {

                let resultTitle = response.map((item) => item.name)
                setItems([...resultTitle])
            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listItems() {
        companyService.listCompanyGroup(page).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function filterItems() {
        companyService.listCompanyGroup(page, search).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function cleanSearch() {
        setSearch('')
        listItems() 
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Grupos e Permissões</span>
                        </div>
                        <div className="row m-3 p-3 justify-content-start">
                            <div className='col bg-white border-clean pb-5 ps-3'>

                                {
                                    VerifyPerms(['view_companygroup', 'add_companygroup', 'change_companygroup', 'delete_companygroup']) ?
                                    <>
                                    <div className="pt-4 d-flex mb-4">
                                        <div className='col'>
                                            <span className='font120 fw-bold '>
                                            Grupos
                                            </span>
                                            <button id='dbm-addJob' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                            </button>
                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addJob">
                                                <li>
                                                    <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formAdd">
                                                        Adicionar Grupo de Permissões
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className='col'>
                                            <div className='d-flex'>

                                                <div className='p-inputgroup'>
                                                    <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Grupo pelo Título' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                    <button className='btn btn-success button-search-radius' onClick={filterItems}>
                                                        <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                    </button>
                                                </div>
                                                {
                                                    search ?
                                                    <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                        Limpar
                                                    </button>
                                                    : null
                                                }
                                                
                                            </div>
                                            
                                        </div>
                                        <hr className='hr-border' />
                                    </div>

                                    <p className='text-end'><strong>{itemsCount}</strong> Grupos cadastrados</p>

                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                                        <>
                                        <table className='table table-borderless thover text-default borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th>
                                                        Grupo
                                                    </th>
                                                    <th className='text-center'>
                                                        Colaboradores
                                                    </th>
                                                    <th className='text-center'>
                                                        Permissões
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                <a href={`/settings/perms-management/${item.id}/edit/`} className='text-green fw-bold text-decoration-none'>
                                                                    {item.name}
                                                                </a>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.users}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.permissions.length}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className='float-end'>
                                            <nav>
                                                <ul className='pagination pagination-sm'>
                                                    <li className='page-item'>
                                                        { 
                                                            itemsList.previous ? 
                                                            <span onClick={subPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                        }
                                                    </li>
                                                    <li className='page-item'>
                                                        {
                                                            itemsList.next ?
                                                            <span onClick={addPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                        }
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        </>

                                        : null
                                    }

                                    <div id="formAdd" className="modal fade" ref={modalRef}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Grupos</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <FormAddGroup hideModal={hideModal} />
                                                </div>
                                                <div className='modal-footer'>
                                                    <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <p className='alert alert-warning mt-3'>Você não tem permissão para acessar esta página</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
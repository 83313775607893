import React, {useRef} from "react";
import ProtectedRoute from "../../routes/protected";
import { Link } from "react-router-dom";
import userData from '../../context/user';
import companyData from "../../context/company";
import http from '../../services/http';
import CompanyLogoDefault from '../../assets/images/company-logo-default.png';
import { Toast } from 'primereact/toast';


export default function UpdateLogo() {

    const {user} = userData()
    const {company, updateCompany} = companyData()

    const toast = useRef(null);

    function onFileChange(e) {
            
        const formData = new FormData();
        formData.append("logo", e.target.files[0]);
        formData.append("id", company.id);

        http.post("company/logo/", formData).then((response) => {
            updateCompany()
        })
        .catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
        });
    }

    return (

        <ProtectedRoute>
            <div className="row mt-5">
            
                <Toast ref={toast} position="top-right" />

                <div className="col-lg-4 offset-lg-4">
                    <h1>
                        Olá <span className="text-green">{ user.first_name }</span>!
                    </h1>
                    <hr/>

                    <h2 className="text-center pt-3">
                        {
                            company.logo ?
                            <img src={`${company.logo}`} alt="logo" className="img-fluid rounded" width="50" />
                            :
                            <img src={CompanyLogoDefault} alt="logo" className="img-fluid rounded" width="50" />
                        }
                        
                        <span className="ms-1">{ company.name }</span>
                    </h2>

                    <hr className='borderNone' />
                    
                    <div className="mt-2">
                        <h4>Adicionar Logo</h4>
                        <input type="file" className="form-control" onChange={onFileChange} />
                        <Link className="btn btn-outline-success btn-rounded mt-3" to='/home/'>Avançar</Link>
                    </div>
                    
                </div>
            </div>
        </ProtectedRoute>

    )
}
import React, {useState, useEffect, useMemo, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { OrganizationChart } from 'primereact/organizationchart'; //https://primereact.org/organizationchart/
import { AutoComplete } from "primereact/autocomplete";
import employeeService from '../../../services/data/employee.service';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';
import SkeletonOrgEmp from './skeletonOrgEmp';


export default function EmployeeOrg() {

    const [pageLoad, setPageLoad] = useState(true)
    const [tree, setTree] = useState()
    const [selection, setSelection] = useState();

    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete

    const [showSidebar, setShowSidebar] = useState(false);
    
    const toast = useRef(null);

    useEffect(() => {
        listTitles()
    }, []);

    const searchItems = (event) => {
        if (event.query.length > 0) {

            employeeService.autoCompleteEmployee(event.query).then((response) => {
                
                let resultTitle = response.map((item) => {
                    return {
                        user: item.user,
                        id: item.id
                    }
                })
                setItems([...resultTitle])

            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listTitles() {
        employeeService.listEmployeeOrg().then((response) => {

            if (response.length > 0) {
                setTree(response)
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function getJobTree() {

        const lookup = items.filter(
            (val) => val.user === search
        )[0]

        employeeService.listEmployeeOrg(lookup.id).then((response) => {

            if (response.length > 0) {
                setTree(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Colaborador não encontrado', life: 4000 });
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
             
    }

    function clicJobTree(val) {

        employeeService.listEmployeeOrg(val).then((response) => {

            if (response.length > 0) {
                setTree(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Colaborador não encontrado', life: 4000 });
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
             
    }

    function cleanSearch() {
        setSearch('')
        listTitles() 
    }

    const nodeTemplate = (node) => {
        return (
            <div className="d-flex">
                <div className="p-3 pt-1">
                    {
                        node.data.photo ?
                        <Avatar image={node.data.photo} shape='circle' size='xlarge' />
                        :
                        <Avatar icon={<FontAwesomeIcon icon={regular('user')} />} size='large' style={{ backgroundColor: '#7498a9', color: '#ffffff' }} shape="circle" />
                    }
                    <div className="fw-bold mt-2">{node.data.title}</div>
                    {
                        node.jobposition ?
                        <div className="">{node.jobposition.title}</div> 
                        : null
                    }
                    {
                        node.childrenCount > 0 ?
                        <span className='text-secondary showTooltip'> 
                            <Badge value={node.childrenCount} data-pr-tooltip="Pessoas Abaixo"></Badge>
                        </span>
                        : null
                    }
                    {
                        node.children.length < 1 && node.childrenCount > 0 ?
                        <button id={node.data.id} className='add-toggler' data-user={node.data.title} data-code={node.data.id} onClick={(e) => { clicJobTree(e.currentTarget.getAttribute('data-code')); setSearch(e.currentTarget.getAttribute('data-user'))} } >
                            <FontAwesomeIcon className='font150 p-node-toggler-icon add-toggle-icon' icon={solid('plus-circle')} />
                        </button>
                        :null
                    }
                </div>
            </div>
        );
    };

    const showTree = useMemo(() => {

        if (tree) {
            return <OrganizationChart className='container-fluid' value={tree} selectionMode="single" selection={selection} onSelectionChange={(e) => {setSelection(e.data); setShowSidebar(true)}} nodeTemplate={nodeTemplate} />
        }
        else {
            return false
        }
        
    }, [tree])

    return (
        <ProtectedRoute>

            <Tooltip target=".showTooltip" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Organograma
                        </a>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className="row m-3 p-3 justify-content-start">

                        <div className='col bg-white pb-5 ps-3'>
                            
                            {
                                pageLoad ?
                                <SkeletonOrgEmp />
                                :
                                <>
                                <div className="pt-4 d-flex mb-4">
                                    <div className='col'>
                                        <div className='d-flex'>

                                            <div className='p-inputgroup'>
                                                <AutoComplete inputClassName='input-search-radius' placeholder='Buscar pelo Nome' value={search} suggestions={items.map((i) => i.user)} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                <button className='btn btn-success button-search-radius' onClick={getJobTree}>
                                                    <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                </button>
                                            </div>
                                            {
                                                search ?
                                                <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                    Limpar
                                                </button>
                                                : null
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                    <hr className='hr-border' />
                                </div>
                                {
                                    showTree ?
                                    <div className='pt-3 bg-org rounded'>
                                        {showTree}
                                    </div>
                                    : null
                                }
                                </>
                            }
                            
                        </div>

                        <div className="card flex justify-content-center">
                            <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                                {
                                    selection ?
                                    <>
                                    {
                                    <div>
                                        <p className='lead fw-bold text-default mb-0'>
                                            {selection.data.title}
                                        </p>
                                        
                                        {
                                            selection.jobposition && selection.jobposition.title ?
                                            <p>
                                                {selection.jobposition.title}
                                            </p>
                                            : null
                                        }
                                        <div>
                                            <span className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={()=>window.open(`/career/summary/${selection.data.id}/`,'_self', 'rel=noopener noreferrer')} role='button' >
                                                Ver Perfil
                                            </span>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    :null
                                }
                            </Sidebar>
                        </div>

                    </div>
                    
                </div>
            </div>
            

        </ProtectedRoute>
    )
}
import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import performanceService from '../../../services/data/performance.service';


export default function FormCreateAssessmentQuestion(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const [objId, setObjId] = useState('')
    const [question, setQuestion] = useState('')
    const [templateId, setTemplateID] = useState('') // performance template

    useMemo(() => {

        if(props.obj) {
            setObjId(props.obj.id)
            setQuestion(props.obj.question)
        }
        if (props.templateId) {
            setTemplateID(props.templateId)
        }
    }, [props])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            question: question,
        },
       
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                question: values.question
            }

            if (props.assessment) {
                data.assessment_id = props.assessment
            }

            if (props.session) {
                data.session_id = props.session
            }

            if (objId) {

                data.id = objId

                performanceService.updateAssessmentQuestion(data).then(() => {
    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Pergunta atualizada com sucesso', life: 3000 });

                    if (props.listItems) {
                        props.listItems()
                    }
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });

            } else {

                if (templateId) {
                    data.template = true
                }

                performanceService.createAssessmentQuestion(data).then((response) => {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Pergunta criada com sucesso', life: 3000 });
                    
                    if (templateId) {
                        //add question to performance template
                        let dataTemp = {
                            company_id: localStorage.getItem("companyId"),
                            questions: [response.question],
                            id: templateId
                        }
                        if (props.session) {
                            dataTemp.session_id = props.session
                        }
                        performanceService.updatePerformanceTemplateContent(dataTemp)
                    }

                    if (props.listItems) {
                        props.listItems()
                    }

                    formik.resetForm()


                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            <div className="my-3">
                <label className="form-label fw-bold">Pergunta <span className="text-danger">*</span></label>
                <Editor
                    name="question"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    value={formik.values.question}
                    onTextChange={(e) => {
                        formik.setFieldValue('question', e.htmlValue);
                    }}
                />
            </div>
            
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                {
                    objId ?
                    <>Atualizar</>
                    :
                    <>Adicionar</>
                }
                </button>
            </div>
        </form>
    )
}
import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import moment from "moment";
import 'moment/locale/pt-br'
import performanceService from '../../../services/data/performance.service';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';


export default function FormCreateGoalPlan(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const navigate = useNavigate();

    const [objId, setObjId] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('AT')

    useMemo(() => {
        if(props.obj) {
            setObjId(props.obj.id)
            setTitle(props.obj.title)
            setDescription(props.obj.description)
            setStartDate(
                moment(props.obj.start_date).format("YYYY-MM-DD")
            )
            setEndDate(
                moment(props.obj.end_date).format("YYYY-MM-DD")
            )
            setStatus(props.obj.status)
        }
    }, [props])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            description: description,
            start_date: startDate,
            end_date: endDate,
            status: status
        },
        validationSchema: Yup.object({
            start_date: Yup.date(),
            end_date: Yup.date().min(Yup.ref("start_date"), "Data de término tem de ser maior que a data de início"),
        }),
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                description: values.description,
                start_date: values.start_date,
                end_date: values.end_date,
                status: values.status
            }

            if (objId) {
                data.id = objId

                performanceService.updateGoalPlan(data).then(() => {
    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Plano de Metas atualizado com sucesso', life: 3000 });
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });

            } else {
                performanceService.createGoalPlan(data).then((response) => {
    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Plano de Metas criado com sucesso', life: 3000 });
                    //navigate add goals
                    return navigate(`/goal/plan/${response.id}/goals/`)
    
                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
                });
            }
            
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            <div className="col">
                <label className="form-label">Título do Plano de Metas <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
            </div>

            <div className="my-3">
                <label className="form-label">Descrição <span className="text-danger">*</span></label>
                <Editor
                    name="description"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    value={description}
                    onTextChange={(e) => {
                        formik.setFieldValue('description', e.htmlValue);
                    }}
                />
            </div>

            <div className="col">
                <label className="form-label fw-bold">Status <span className="text-danger">*</span></label>
                <select className='form-select mb-3' {...formik.getFieldProps('status')} required="required">
                    <option value='AT'>Ativo</option>
                    <option value='IN'>Inativo</option>
                    {
                        objId ?
                        <>
                        <option value='AR'>Arquivar</option>
                        <option value='CA'>Cancelar</option>
                        </>
                        :
                        null
                    }
                </select>
            </div>

            <div className='row mb-4 px-0'>
                <div className='col'>
                    <label className="form-label">Data de Início <span className="text-danger">*</span></label>
                    <input className="form-control" name='start_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('start_date')} />
                    {formik.errors.start_date ? (<span name="start_date" className="text-vermelho">{formik.errors.start_date}</span>) : null}
                </div>

                <div className='col'>
                    <label className="form-label">Data de Término <span className="text-danger">*</span></label>
                    <input className="form-control" name='end_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('end_date')} />
                    {formik.errors.end_date ? (<span name="end_date" className="text-vermelho">{formik.errors.end_date}</span>) : null}
                </div>
            </div>
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    {
                        objId ?
                        <>Atualizar</>
                        :
                        <>Adicionar</>
                    }
                </button>
            </div>
        </form>
    )
}
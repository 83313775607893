import React, {useState, useEffect, useRef} from 'react';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import FormAddJobPosition from '../../../components/settings/forms/formAddJobPosition';


export default function AddJobPosition() {
    
    const { id } = useParams()
    const toast = useRef(null);
    const [obj, setObj] = useState('');
    
    //para editar
    const [subCompaniesList, setSubCompaniesList] = useState('') // lista de subCompanies
    const [internalJobTitleList, setInternalJobTitleList] = useState([]) // lista de cargos
    const [deptList, setDeptList] = useState([]) // lista de deptos
    const [benefitsList, setBenefitsList] = useState([]) // lista de beneficios

    useEffect(() => {
        getObject()
        listSubCompanies()
        listJobTitles()
        listDepts()
        getBenefits()
    }, []);

    function getObject() {
        companyService.getJobPosition(id).then((response) => {
            setObj(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listSubCompanies() {

        const getItem = ''

        companyService.autoCompleteSubCompany(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setSubCompaniesList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });

    }

    function listJobTitles() {
        const getItem = ''

        companyService.autoCompleteInternalJobTitles(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setInternalJobTitleList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function listDepts() {
        const getItem = ''

        companyService.autoCompleteDepartment(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setDeptList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function getBenefits() {
        companyService.listBenefits().then((response) => {
            let res = response.results.map((item) => {
                return {
                    title: item.title,
                    id: item.id
                }
            })
            setBenefitsList(res)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">

                        <Toast ref={toast} position="top-right" />

                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Adicionar Posição</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-8 mx-2'>
                                <a href="/settings/jobposition/management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">
                                    <p className='alert alert-warning'>
                                        Adicionar Posição abaixo de: <strong className='text-capitalize'>{ obj.title} ({obj.internal_code})</strong>
                                    </p>
                                    
                                    <FormAddJobPosition parent_id={obj.id} subCompaniesList={subCompaniesList} internalJobTitleList={internalJobTitleList} deptList={deptList} benefitsList={benefitsList} />
                                
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
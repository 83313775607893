import React, {useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import FormAddEmployee from '../../../components/settings/forms/formAddEmployee';


export default function AddEmployee() {

    const toast = useRef(null);
   
    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Gestão de Colaboradores</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>
                                
                                <a href="/settings/users-management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>

                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Adicionar Colaborador
                                    </span>
                                    <hr/>
                                    <FormAddEmployee />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
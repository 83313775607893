import React, {useState, useEffect, useRef} from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import companyService from '../../../services/data/company.service';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import currencies from './currencies.json';
import { Dropdown } from 'primereact/dropdown';
import FormAddCategory from '../../../components/settings/forms/formAddCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { InputNumber } from 'primereact/inputnumber';
import currencyFormat from '../../../helpers/currencyFormat';
import * as Yup from 'yup';


export default function CreatePayLevel() {

    const { id, grade_id } = useParams()
    const toast = useRef(null);
    const [payStructure, setPayStructure] = useState('')
    const [payGrade, setPayGrade] = useState('')
    const [selectedCurrency, setSelectedCurrency] = useState('')
    const [showCategory, setShowCategory] = useState(false)
    const [categoryList, setCategoryList] = useState('')
    const [amount, setAmount] = useState(
        parseFloat(1000, 2).toFixed(2)
    )
    const [currency, setCurrency] = useState('BRL')
    const [range, setRange] = useState([])
    const [itemsList, setItemsList] = useState([]);

    useEffect(() => {
        getPayStructure()
        getPayGrade()
        listCategories()
        setRange([
            {
                'range': 80,
                'value': parseFloat(800, 2).toFixed(2)
            },
            {
                'range': 100,
                'value': parseFloat(1000, 2).toFixed(2)
            },
            {
                'range': 120,
                'value': parseFloat(1200, 2).toFixed(2)
            }
        ])
        listItems()
    }, []);

    function listItems() {

        let plData = {
            company_id: localStorage.getItem("companyId"),
            structure_id: id,
            grade_id: grade_id
        }
        companyService.listPayLevel(plData).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getPayStructure() {
        companyService.getPayStructure(id).then((response) => {
            setPayStructure(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getPayGrade() {
        companyService.getPayGrade(grade_id).then((response) => {
            setPayGrade(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'paylevel'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const handleShowCategory = () => {
        setShowCategory(!showCategory)
    }
    
    const stepItems = [
        {
            label: 'Estrutura'
        },
        {
            label: 'Nível'
        },
        {
            label: 'Salário'
        }
    ];

    function updateAmount(value) {
        setAmount(value)
        // update 100%
        rangeUpdateAll(value)
    }

    // para update do 100% e recalcular os demais
    function rangeUpdateAll(value) {

        const updRange = [
            {
                'range': 80,
                'value': parseFloat(value*0.80, 2).toFixed(2)
            },
            {
                'range': 100,
                'value': parseFloat(value, 2).toFixed(2)
            },
            {
                'range': 120,
                'value': parseFloat(value*1.20, 2).toFixed(2)
            }
        ]

        setRange(updRange)
    }

    function rangePercent(value1, value2) {
        return value1/value2*100
    }

    // para update de 80% e 120%
    function rangeUpdate(value, index) {

        const newPerct = rangePercent(parseFloat(value, 2), amount)

        const newRange = range.map((item, i) => {
            if (index === i) {
                // Update

                if (item.range === 100) {
                    return {
                        range: 100,
                        value: parseFloat(value, 2).toFixed(2)
                    }
                }
                return {
                    range: newPerct,
                    value: parseFloat(value, 2).toFixed(2)
                }
            } else {
                return item;
            }
        })
        setRange(newRange)
    }

    //update via percent
    function percentUpdate(value, index) {

        const newRange_ = range.map((item, i) => {
            if (index === i) {
                // Update
                return {
                    range: value,
                    value: parseFloat(item.value*value/100, 2).toFixed(2)
                }
            } else {
                return item;
            }
        })
        setRange(newRange_)
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            currency: '',
            frequency: '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Campo Título é obrigatório"),
            currency: Yup.string().required("Campo Moeda é obrigatório"),
            frequency: Yup.string().required("Campo Frequência é obrigatório"),
            //amount: Yup.string().required("Campo Valor é obrigatório"),
        }),
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                structure_id: id,
                grade_id: grade_id,
                currency: values.currency,
                frequency: values.frequency,
                amount: amount,
                range: range
            }

            companyService.createPayLevel(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Salário adicionado com sucesso', life: 3000 });
                listItems()
                //formik.resetForm()

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
            });

        }
    });

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Estrutura de Salários</span>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className='col-xl-6'>
                                <Steps model={stepItems} activeIndex={2} />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <a href={`/settings/salary-structure/management/${id}/detail/`} className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                            </div>
                            <div className='col-5'>
                                <div className="p-3 bg-white rounded border-clean">
                                    <span className='font120 fw-bold'>
                                        Adicionar Salário(s)
                                    </span>
                                    <form onSubmit={formik.handleSubmit}>

                                        <table className='table table-bordered mt-2 alert-warning border'>
                                            <thead>
                                                <tr className='text-center'>
                                                    <th>
                                                    Estrutura
                                                    </th>
                                                    <th>
                                                    Nível
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='text-center'>
                                                    <td>
                                                        {payStructure.title}
                                                    </td>
                                                    <td>
                                                        {payGrade.title}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className='mb-3'>
                                            <label className="form-label fw-bold">Título <span className="text-danger">*</span></label>
                                            <input name="title" className='form-control' required {...formik.getFieldProps('title')} />
                                            {formik.errors.title ? (<span name="title" className="text-vermelho">{formik.errors.title}</span>) : null}
                                        </div>

                                        <div className="row p-0 my-3">
                                            <div>
                                                <label className="form-label fw-bold">Frequência <span className="text-danger">*</span></label>
                                                <button className="btn btn-sm" onClick={(e) => handleShowCategory(e)}>
                                                    <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                                </button>
                                            </div>
                                            {
                                                showCategory ?
                                                <>
                                                <div className='my-3 bg-light'>
                                                    <FormAddCategory model='paylevel' listCategories={listCategories} handleShowCategory={handleShowCategory} />
                                                </div>
                                                <hr/>
                                                </>
                                                :
                                                null
                                            }
                                            <div className='col-md-8'>
                                            {
                                                categoryList ?
                                                <select className='form-select col-md-4' required {...formik.getFieldProps('frequency')}>
                                                    <option value=''>Selecionar</option>
                                                    {
                                                        categoryList.map((item, index) =>
                                                        <option key={index} value={item.id}>{item.title}</option>
                                                        )
                                                    }
                                                </select>
                                                :
                                                null
                                            }
                                            {formik.errors.frequency ? (<span name="frequency" className="text-vermelho">{formik.errors.frequency}</span>) : null}
                                            </div>
                                        </div>

                                        <div className='mt-3 row p-0'>
                                            <div className='col'>
                                                <label className="form-label fw-bold">
                                                    Moeda <span className="text-danger">*</span>
                                                </label>
                                                <div className='p-inputgroup'>
                                                    <Dropdown filter placeholder="Selecionar" value={selectedCurrency} optionLabel="code" options={currencies} onChange={(e) => {setSelectedCurrency(e.value); setCurrency(e.value.code); formik.setFieldValue('currency', e.value.code);} } className="rounded" />
                                                </div>
                                                {formik.errors.currency ? (<span name="currency" className="text-vermelho">{formik.errors.currency}</span>) : null}
                                            </div>

                                            <div className='col'>
                                                <label className="form-label fw-bold">
                                                    Valor Base <span className="text-danger">*</span>
                                                </label>
                                                <div className='p-inputgroup'>
                                                    <InputNumber mode="currency" currency={currency} value={amount} onValueChange={(e) => {updateAmount(e.value)}} className="rounded" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-3 col'>
                                            <label className="form-label fw-bold">
                                                Faixa Salarial <span className="text-danger">*</span>
                                            </label>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                    {
                                                        range.map((item, index) => 
                                                            <th key={index} className="text-center text-default">
                                                                {
                                                                    index !== 1?
                                                                    <div className='p-inputgroup'>
                                                                        <InputNumber inputClassName="text-center" value={item.range} suffix="%" onValueChange={(e) => {percentUpdate(e.value, index)}} className="rounded" />
                                                                    </div>
                                                                    :
                                                                    <div className='p-inputgroup'>
                                                                        <InputNumber inputClassName="text-center" disabled suffix="%" value={item.range} className="rounded" />
                                                                    </div>
                                                                }
                                                            </th>
                                                        )
                                                    }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    {
                                                        range.map((item, index) => 
                                                            <td key={index}>
                                                                <div className='p-inputgroup'>
                                                                    {
                                                                        index === 1? 
                                                                        <InputNumber inputClassName="text-center" mode="currency" currency={currency} disabled value={item.value} className="rounded" /> 
                                                                        :
                                                                        <InputNumber inputClassName="text-center" mode="currency" currency={currency} value={item.value} onValueChange={(e) => {rangeUpdate(e.value, index)}} className="rounded" />
                                                                    }
                                                                    
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-grid mx-auto btnMargin">
                                            <button className="btn btn-success btn-sm btn-rounded" type="submit">
                                                Adicionar
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div className='col-7'>
                                <div className="p-3 bg-white rounded border-clean">
                                    <p><strong>{itemsList && itemsList.results ? itemsList.results.length : 0}</strong> Salários cadastrados</p>
                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                                        <>
                                        <table className='table table-borderless thover borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th className='text-center'>
                                                        Salários
                                                    </th>
                                                    <th className='text-center'>
                                                        Moeda
                                                    </th>
                                                    <th className='text-center'>
                                                        Frequência
                                                    </th>
                                                    <th className='text-center'>
                                                        Faixa Salarial
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle text-center">
                                                                {item.title}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.currency}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.frequency}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <table className='table table-bordered'>
                                                                    <thead>
                                                                        <tr>
                                                                        {
                                                                            item.range.map((item, index) => 
                                                                                <th key={index} className="text-center">
                                                                                    {item.range}%
                                                                                </th>
                                                                            )
                                                                        }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                        {
                                                                            item.range.map((item, index) => 
                                                                                <td key={index}>
                                                                                    {currencyFormat(item.value)}
                                                                                </td>
                                                                            )
                                                                        }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormAddJobPosition from '../../../components/settings/forms/formAddJobPosition';


export default function EditJobPosition() {
    
    const { id } = useParams()
    const toast = useRef(null);
    const [obj, setObj] = useState('');
    const navigate = useNavigate()
    const modalRef = useRef()

    //para editar
    const [subCompaniesList, setSubCompaniesList] = useState('') // lista de subCompanies
    const [internalJobTitleList, setInternalJobTitleList] = useState([]) // lista de cargos
    const [deptList, setDeptList] = useState([]) // lista de deptos
    const [benefitsList, setBenefitsList] = useState([]) // lista de beneficios

    useEffect(() => {
        getObject()
        listSubCompanies()
        listJobTitles()
        listDepts()
        getBenefits()

    }, []);

    function getObject() {
        companyService.getJobPosition(id).then((response) => {
            setObj(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listSubCompanies() {

        const getItem = ''

        companyService.autoCompleteSubCompany(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setSubCompaniesList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });

    }

    function listJobTitles() {
        const getItem = ''

        companyService.autoCompleteInternalJobTitles(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setInternalJobTitleList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function listDepts() {
        const getItem = ''

        companyService.autoCompleteDepartment(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })

            setDeptList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function getBenefits() {
        companyService.listBenefits().then((response) => {
            let res = response.results.map((item) => {
                return {
                    title: item.title,
                    id: item.id
                }
            })
            setBenefitsList(res)
        }).catch((error) => {
            console.log(error.response)
        });
    }


    function deleteItem(e) {
        companyService.deleteJobPosition(obj.id).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Posição apagada com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                navigate(`/settings/jobposition/management/`)
            }, 3000);
        })
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">

                        <Toast ref={toast} position="top-right" />

                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Posição</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-8 mx-2'>
                                <a href="/settings/jobposition/management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">
                                    {
                                        obj.parent ?
                                        <p className='alert alert-warning'>
                                            Posição superior: <strong className='text-capitalize'>{ obj.parent.title} ({obj.parent.internal_code})</strong>
                                        </p>
                                        : null
                                    }
                                    
                                    <FormAddJobPosition obj={obj} subCompaniesList={subCompaniesList} internalJobTitleList={internalJobTitleList} deptList={deptList} benefitsList={benefitsList} />
                                
                                </div>

                                <div className="text-end btnMargin">
                                    <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Posição</button>
                                </div>

                                <div id="formDel" className="modal fade" ref={modalRef}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Apagar Posição</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>
                                                    Tem certeza de que deseja apagar esta Posição?
                                                </p>
                                                <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
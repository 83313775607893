import React from 'react';
import Header from './components/header'
import Footer from './components/footer';
import AppRoutes from './routes';
import { UserProvider } from './context/user';
import { CompanyProvider } from './context/company';
import { DomainProvider } from './context/domain';


function App() {

  return (
    <DomainProvider>
      <UserProvider>
        <CompanyProvider>
            <Header />
              <AppRoutes />
            <Footer />
        </CompanyProvider>
      </UserProvider>
    </DomainProvider>
    
  );
}

export default App;

import React, {useState, useRef, useMemo} from 'react';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Toast } from 'primereact/toast';
import FormAddCompetency from '../../../components/settings/forms/formAddCompetency';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function EditCompetency(props) {
    
    const toast = useRef(null);
    const location = useLocation();
    const [obj, setObj] = useState('');
    const [categories, setCategories] = useState('')
    const navigate = useNavigate()
    const modalRef = useRef()

    useMemo(() => {

        if (location && location.state) {
            if (location.state.selection) {
                setObj(location.state.selection)
            }
            if (location.state.categoriesList) {
                setCategories(location.state.categoriesList)
            }
        }
    }, [location])

    function deleteItem(e) {
        companyService.deleteCompetency(obj.id).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência apagada com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                navigate(`/settings/competencies-management/`)
            }, 3000);
        })
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">

                        <Toast ref={toast} position="top-right" />

                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Competência</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>
                                <a href="/settings/competencies-management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">
                                
                                    <FormAddCompetency obj={obj} categoriesList={categories} />
                                
                                </div>

                                <div className="text-end btnMargin">
                                    <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Competência</button>
                                </div>

                                <div id="formDel" className="modal fade" ref={modalRef}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Apagar Competência</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p>
                                                    Tem certeza de que deseja apagar esta Competência?
                                                </p>
                                                <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
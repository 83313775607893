import http from "../http"

class CompanyService {

    async createMessage(data) {
        const rqst = await http.post('/message/create/', data)
        return rqst.data
    }

    async listMessage(data, page) {
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/message/list/?page=' + page, data)
        return rqst.data
    }

    async updateMessage(data) {
        const rqst = await http.post('/message/update/', data)
        return rqst.data
    }

    async getCompany(companyId) {

        if (!companyId) {
            
            const rqstID = await http.get('get/company/')
            return rqstID.data

        } else {

            let config = {
                params: {
                  id: companyId
                },
            }
            const rqst = await http.get('get/company/', config)
            return rqst.data
        }
    }

    async getCompanyDomains(domain) {

        let config = {
            params: {
                domain: domain
            },
        }
        const rqst = await http.get('get/company/domains/', config)
        // setar domain para API
        http.defaults.baseURL = `${rqst.data.api_subdomain}`
        return rqst.data
    }

    async updateCompany(data) {
        const rqst = await http.put('/company/update/', data)
        return rqst.data
    }

    async createCompanyGroup(data) {
        const rqst = await http.post('/company/group/create/', data)
        return rqst.data
    }

    async listCompanyGroup(page, getItem) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: getItem
        }
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/company/group/list/?page=' + page, data)
        return rqst.data
    }

    async getCompanyGroup(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/company/group/get/', data)
        return rqst.data
    }

    async autoCompleteCompanyGroup(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/company/group/autocomplete/', data)
        return rqst.data
    }

    async listPermissions(item) {
        let data = {}
        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/permission/list/', data)
        return rqst.data
    }

    async updateCompanyGroup(data) {
        const rqst = await http.post('/company/group/update/', data)
        return rqst.data
    }

    async deleteCompanyGroup(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/company/group/delete/', data)
        return rqst.data
    }

    async getInternalJobTitle(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/internal-job-title/get/', data)
        return rqst.data
    }

    async listInternalJobTitlesOrg(job) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(job) {
            data.getJob = job
        }
        
        const rqst = await http.post('/internal-job-title/list/org/', data)
        return rqst.data
    }

    async listInternalJobTitles(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }

        if (!page) {
            page = 1
        }
        
        const rqst = await http.post('/internal-job-title/list/?page=' + page, data)
        return rqst.data
    }

    async autoCompleteInternalJobTitles(job) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getJob: job
        }
        const rqst = await http.post('/internal-job-title/autocomplete/', data)
        return rqst.data
    }

    async createInternalJobTitle(data) {
        const rqst = await http.post('/internal-job-title/create/', data)
        return rqst.data
    }

    async updateInternalJobTitle(data) {
        const rqst = await http.post('/internal-job-title/update/', data)
        return rqst.data
    }

    async deleteInternalJobTitle(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/internal-job-title/delete/', data)
        return rqst.data
    }

    async countInternalJobTitle() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/internal-job-title/count/', data)
        return rqst.data
    }

    async importXlsInternalJobTitle(data) {
        const rqst = await http.post('/internal-job-title/import-xls/', data)
        return rqst.data
    }

    async createBenefit(data) {
        const rqst = await http.post('/benefits/create/', data)
        return rqst.data
    }

    async listBenefits(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }

        if (!page) {
            page = 1
        }
        
        const rqst = await http.post('/benefits/active/list/?page=' + page, data)
        return rqst.data
    }

    async autoCompleteBenefits(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/benefits/active/list/', data)
        return rqst.data
    }

    async updateBenefit(data) {
        const rqst = await http.post('/benefits/update/', data)
        return rqst.data
    }

    async deleteBenefit(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/benefits/delete/', data)
        return rqst.data
    }

    async importXlsBenefits(data) {
        const rqst = await http.post('/benefits/import-xls/', data)
        return rqst.data
    }

    async createCategory(data) {
        const rqst = await http.post('/category/create/', data)
        return rqst.data
    }

    async listCategory(page, data) {

        if (!page) {
            page = 1
        }

        const rqst = await http.post('/category/list/?page=' + page, data)
        return rqst.data
    }
    
    async listCompetencies(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/competencies/list/?page=' + page, data)
        return rqst.data
    }

    async autoCompleteCompetencies(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/competencies/list/', data)
        return rqst.data
    }

    async createCompetency(data) {
        const rqst = await http.post('/competencies/create/', data)
        return rqst.data
    }

    async updateCompetency(data) {
        const rqst = await http.post('/competencies/update/', data)
        return rqst.data
    }

    async deleteCompetency(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/competencies/delete/', data)
        return rqst.data
    }

    async importXlsCompetencies(data) {
        const rqst = await http.post('/competencies/import-xls/', data)
        return rqst.data
    }

    async listSubCompany(page,item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }

        if (!page) {
            page = 1
        }

        const rqst = await http.post('/subcompany/list/?page=' + page, data)
        return rqst.data
    }

    async listSubCompanyOrg(item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/subcompany/list/org/', data)
        return rqst.data
    }

    async getSubCompany(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/subcompany/get/', data)
        return rqst.data
    }

    async autoCompleteSubCompany(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/subcompany/autocomplete/', data)
        return rqst.data
    }

    async createSubCompany(data) {
        const rqst = await http.post('/subcompany/create/', data)
        return rqst.data
    }

    async updateSubCompany(data) {
        const rqst = await http.post('/subcompany/update/', data)
        return rqst.data
    }

    async deleteSubCompany(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/subcompany/delete/', data)
        return rqst.data
    }

    async countSubCompany() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/subcompany/count/', data)
        return rqst.data
    }

    async importXlsSubCompany(data) {
        const rqst = await http.post('/subcompany/import-xls/', data)
        return rqst.data
    }

    async listDepartment(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/dept/list/?page=' + page, data)
        return rqst.data
    }

    async listDepartmentSimple() {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        const rqst = await http.post('/dept/list/simple/', data)
        return rqst.data
    }

    async listDepartmentOrg(item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/dept/list/org/', data)
        return rqst.data
    }

    async getDepartment(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/dept/get/', data)
        return rqst.data
    }

    async autoCompleteDepartment(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/dept/autocomplete/', data)
        return rqst.data
    }

    async createDepartment(data) {
        const rqst = await http.post('/dept/create/', data)
        return rqst.data
    }

    async updateDepartment(data) {
        const rqst = await http.post('/dept/update/', data)
        return rqst.data
    }

    async deleteDepartment(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/dept/delete/', data)
        return rqst.data
    }

    async countDepartment() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/dept/count/', data)
        return rqst.data
    }

    async importXlsDepartment(data) {
        const rqst = await http.post('/dept/import-xls/', data)
        return rqst.data
    }

    async listCostCenter(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/costcenter/list/?page=' + page, data)
        return rqst.data
    }

    async listCostCenterOrg(item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/costcenter/list/org/', data)
        return rqst.data
    }

    async getCostCenter(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/costcenter/get/', data)
        return rqst.data
    }

    async autoCompleteCostCenter(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/costcenter/autocomplete/', data)
        return rqst.data
    }

    async createCostCenter(data) {
        const rqst = await http.post('/costcenter/create/', data)
        return rqst.data
    }

    async updateCostCenter(data) {
        const rqst = await http.post('/costcenter/update/', data)
        return rqst.data
    }

    async deleteCostCenter(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/costcenter/delete/', data)
        return rqst.data
    }

    async countCostCenter() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/costcenter/count/', data)
        return rqst.data
    }

    async importXlsCostCenter(data) {
        const rqst = await http.post('/costcenter/import-xls/', data)
        return rqst.data
    }

    async createPayStructure(data) {
        const rqst = await http.post('/salary/structure/create/', data)
        return rqst.data
    }

    async listPayStructure(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }

        if (!page) {
            page = 1
        }
        const rqst = await http.post('/salary/structure/list/?page=' + page, data)
        return rqst.data
    }

    async listInactivePayStructure(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }

        if (!page) {
            page = 1
        }
        const rqst = await http.post('/salary/structure/list/inactive/?page=' + page, data)
        return rqst.data
    }

    async autoCompletePayStructure(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/salary/structure/autocomplete/', data)
        return rqst.data
    }

    async getPayStructure(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/structure/get/', data)
        return rqst.data
    }

    async updatePayStructure(data) {
        const rqst = await http.post('/salary/structure/update/', data)
        return rqst.data
    }

    async deletePayStructure(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/structure/delete/', data)
        return rqst.data
    }

    async importXlsPayStructure(data) {
        const rqst = await http.post('/salary/structure/import-xls/', data)
        return rqst.data
    }

    async createPayGrade(data) {
        const rqst = await http.post('/salary/grade/create/', data)
        return rqst.data
    }

    async listPayGrade(page, data) {

        if (!page) {
            page = 1
        }
        const rqst = await http.post('/salary/grade/list/?page=' + page, data)
        return rqst.data
    }

    async autoCompletePayGrade(data) {
        const rqst = await http.post('/salary/grade/autocomplete/', data)
        return rqst.data
    }

    async getPayGrade(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/grade/get/', data)
        return rqst.data
    }

    async updatePayGrade(data) {
        const rqst = await http.post('/salary/grade/update/', data)
        return rqst.data
    }

    async deletePayGrade(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/grade/delete/', data)
        return rqst.data
    }

    async importXlsPayGrade(data) {
        const rqst = await http.post('/salary/grade/import-xls/', data)
        return rqst.data
    }

    async createPayLevel(data) {
        const rqst = await http.post('/salary/level/create/', data)
        return rqst.data
    }

    async listPayLevel(data, page) {

        if (!page) {
            page = 1
        }
        const rqst = await http.post('/salary/level/list/?page=' + page, data)
        return rqst.data
    }

    async autoCompletePayLevel(data) {
        const rqst = await http.post('/salary/level/autocomplete/', data)
        return rqst.data
    }

    async getPayLevel(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/level/get/', data)
        return rqst.data
    }

    async updatePayLevel(data) {
        const rqst = await http.post('/salary/level/update/', data)
        return rqst.data
    }

    async deletePayLevel(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/salary/level/delete/', data)
        return rqst.data
    }

    async importXlsPayLevel(data) {
        const rqst = await http.post('/salary/level/import-xls/', data)
        return rqst.data
    }

    async listJobPosition(page, item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        if (!page) {
            page = 1
        }
        const rqst = await http.post('/jobposition/list/?page=' + page, data)
        return rqst.data
    }

    async listJobPositionOrg(item) {

        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        if(item) {
            data.getItem = item
        }
        const rqst = await http.post('/jobposition/list/org/', data)
        return rqst.data
    }

    async getJobPosition(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/jobposition/get/', data)
        return rqst.data
    }

    async getUserJobPosition() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/jobposition/get/user/', data)
        return rqst.data
    }

    async getUserTeamJobPosition(manager_id) {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        if (manager_id) {
            data.manager_id = manager_id
        }
        const rqst = await http.post('/jobposition/manager/team/', data)
        return rqst.data
    }

    async autoCompleteJobPosition(item) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            getItem: item
        }
        const rqst = await http.post('/jobposition/autocomplete/', data)
        return rqst.data
    }

    async createJobPosition(data) {
        const rqst = await http.post('/jobposition/create/', data)
        return rqst.data
    }

    async updateJobPosition(data) {
        const rqst = await http.post('/jobposition/update/', data)
        return rqst.data
    }

    async deleteJobPosition(id) {
        let data = {
            company_id: localStorage.getItem("companyId"),
            id: id
        }
        const rqst = await http.post('/jobposition/delete/', data)
        return rqst.data
    }

    async countJobPosition() {
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        const rqst = await http.post('/jobposition/count/', data)
        return rqst.data
    }

    async importXlsJobPosition(data) {
        const rqst = await http.post('/jobposition/import-xls/', data)
        return rqst.data
    }

    async listPermsFromJobPosition(data) {
        const rqst = await http.post('/jobposition/list/groups-perms/', data)
        return rqst.data
    }

    async listModified(data, page) {

        if (!page) {
            page = 1
        }
        const rqst = await http.post('/modified/list/?page=' + page, data)
        return rqst.data
    }

    async downloadObject(data) {
        const rqst = await http.post('/get-object/', data)
        return rqst.data
    }

    async companyBi() {
        let data = {
            company_id: localStorage.getItem("companyId"),
        }
        const rqst = await http.post('/company/bi/', data)
        return rqst.data
    }
}

export default new CompanyService()
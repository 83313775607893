import { useCallback } from "react";
import companyData from "./company";

export default function UsePerms(permissions) {

    const {userPerms, rootUser} = companyData()
    
    const chkPerms = useCallback((permissions) => {
        if (rootUser) {
            return true
        }

        if (!permissions) {
            return false
        }
        
        return permissions.every( (val) => { return userPerms.find(x => x === val ) } )
    }, [userPerms, rootUser])

    return chkPerms
}
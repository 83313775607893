import React, {useState, useEffect, useRef} from 'react';
import employeeService from '../../../services/data/employee.service';
import { Toast } from 'primereact/toast';
import { useParams, useNavigate } from 'react-router-dom';
import ProtectedRoute from '../../../routes/protected';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormAddEmployee from '../../../components/settings/forms/formAddEmployee';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function EditEmployee() {

    const { id } = useParams()
    const toast = useRef(null);
    const navigate = useNavigate()
    const modalRef = useRef()
    const [obj, setObj] = useState('')

    useEffect(() => {
        getEmployee()
    }, [])

    function getEmployee() {
        employeeService.getEmployee(id).then((response) => {
            setObj(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function deleteItem(e) {
        employeeService.deleteEmployee(id).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Colaborador removido com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                return navigate('/settings/users-management/')
              }, 3000);
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });
    }
    

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Colaborador</span>
                        </div>
                        <div className="row m-3 p-3">
                            <a href="/settings/users-management/" className="nav-link mb-3">
                                <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                            </a>
                            <ul className='nav nav-tabs'>
                                <li className='nav-item'>
                                    <span className='nav-link active'>
                                        Colaborador
                                    </span>
                                </li>
                                <li className='nav-item'>
                                    <a href={`/settings/users-management/${id}/edit/perms/`} className='nav-link text-green'>
                                        Grupos e Permissões
                                    </a>
                                </li>
                            </ul>
                            <div className='col-12 bg-white border-clean border-top-0 pb-5 ps-3'>
                                
                                <div className='col-lg-6'>
                                    <FormAddEmployee obj={obj} />
                                    <div className="text-end btnMargin">
                                        <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Remover Colaborador</button>
                                    </div>
                                </div>
                                                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Remover Colaborador</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja remover este Colaborador?
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4" onClick={deleteItem}>Sim, Remover</button>
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
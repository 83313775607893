import React, {useState} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import { useNavigate } from 'react-router-dom';


export default function FormAddGroup(props) {

    const [message, setMessage] = useState('')
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: ''
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                name: values.name
            }
            
            companyService.createCompanyGroup(data).then((response) => {
                props.hideModal()
                return navigate(`/settings/perms-management/${response.id}/edit/`)

            }).catch((error) => {
                setMessage(error.response.data.name)
            });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className='divBorder'>

            {
                message ?
                <p className='alert alert-warning'>
                    {message}
                </p>
                : null
            }

            <div className="col">
                <label className="form-label">Nome do Grupo <span className="text-danger">*</span></label>
                <input name='name' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('name')} />
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    Adicionar
                </button>
            </div>
        </form>
    )
}
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import moment from "moment";
import 'moment/locale/pt-br'
import FormCreateItemStatus from '../../components/performance/forms/formCreateItemStatus';


export default function TakePerformanceSuperior(props) {

    const toast = useRef(null);
    const [object, setObject] = useState('')
    const [sessions, setSessions] = useState('')
    const [itemsCount, setItemsCount] = useState(0) //quantidade de goals/competencia/questions
    const [statusCount, setStatusCount] = useState(0) //quantidade de status de goals/competencia/questions da session
    const [showBtn, setShowBtn] = useState(false)

    useEffect(() => {

        if (props) {
            setObject(props.object)
            setSessions(props.object.sessionSuperior[0])
        }
    }, [props])

    useMemo(() => {
        if (sessions) {
            const gc = sessions.goals.length
            const cc = sessions.competencies.length
            const qc = sessions.questions.length

            setItemsCount(gc+cc+qc)

            let currentStatus = 0

            if (sessions.goals.length > 0) {

                sessions.goals.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === object.id) {
                                currentStatus += 1
                            }
                        })
                    }
                })

            }

            if (sessions.competencies.length > 0) {

                sessions.competencies.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === object.id) {
                                currentStatus += 1
                            }
                        })
                    }
                })

            }

            if (sessions.questions.length > 0) {

                sessions.questions.map((item) => {
                    if (item.itemStatus && item.itemStatus.length > 0) {
                        item.itemStatus.map((sts) => {
                            if (sts.created_by === object.action_for && sts.step === object.id) {
                                currentStatus += 1
                            }
                        })
                    }
                })

            }

            setStatusCount(currentStatus)

        }

    }, [sessions])

    useMemo(() => {

        if (statusCount === itemsCount) {
            setShowBtn(true)
        } else {
            setShowBtn(false)
        }

    }, [itemsCount, statusCount])

    function updateStatusCount(val) {
        setStatusCount(statusCount + val)
    }

    function statusEdit(item) {
        
        const hasStatus = item.itemStatus.find((sts => sts.created_by === object.action_for && sts.step === object.id ))
        if (hasStatus) {
            return true
        }
        return false
    }

    function submitForm() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            step_id: object.id,
        }
        performanceService.updatePerformanceStepSuperior(data).then((response) => {

            setObject(response)

            if (props.updateObject) {
                props.updateObject()
            }
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Processo finalizado com sucesso', life: 3000 })

        }).catch((error) => {
            console.log(error.response.data)
            toast.current.show({ severity: 'warning', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 5000 })
        });
    }

    return (
        <>
        
        <Toast ref={toast} position="top-right" />

        {
            sessions ?
            <>
            <div className='d-flex justify-content-between border-bottom pb-3 mb-4 align-items-center'>
                <div className='fs-6 fw-bold'>Avaliar Superior</div>
                {
                    showBtn && object.status !== 'RE' ?
                    <div>
                        <button className='btn btn-success btn-sm btn-rounded px-4 ms-2' onClick={() => submitForm()} >Finalizar e Enviar</button>
                    </div>
                    : null
                }
            </div>
            <div className='col'>
            {
                sessions.goals.length > 0 ?
                <>
                <div className='fs-6 pb-2'>
                    <FontAwesomeIcon className='text-green' icon={solid('arrows-to-dot')} /> Você previsa avaliar <span className='fw-bold'>{sessions.goals.length}</span> objetivos
                </div>
                <div className='ms-2 ps-3 verticalBorderLeft'>
                {
                    sessions.goals.map((item, index) => 
                    <div key={index} className='border rounded mb-2'>
                        <div className='p-3 pb-0'>
                            <p className='fw-bold mb-0'>{index+1}.</p>
                            <span className='text-green fw-bold fs-6'>{item.title}</span>
                            <br/>
                            <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                        </div>
                        <table className='table table-sm mb-0 border-top'>
                            <thead className='bg-green-light'>
                                <tr>
                                    <th className='text-center'>
                                        Tipo
                                    </th>
                                    <th className='text-center'>
                                        Período
                                    </th>
                                    <th className='text-center'>
                                        Status
                                    </th>
                                    <th className='text-center'>
                                        Relacionado a
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">
                                        <Chip label={item.goal_type_display} />
                                    </td>
                                    <td className="align-middle text-center">
                                        {moment(item.start_date).format("DD-MMM-YYYY")} - {moment(item.end_date).format("DD-MMM-YYYY")} 
                                    </td>
                                    <td className="align-middle text-center">
                                    {
                                        item.goalLastStatus && item.goalLastStatus.status ?
                                        <>
                                        {
                                            item.goalLastStatus.status === 'CS' ?
                                            <>
                                            <FontAwesomeIcon className='text-green' icon={solid('circle-check')} /> {item.goalLastStatus.status_display}
                                            </>
                                            :
                                            <>{item.goalLastStatus.status_display}</>
                                        }
                                        </>
                                        :
                                        <>Não Iniciado</>
                                    }
                                    </td>
                                    <td className="align-middle text-center">
                                    {
                                        item.related_goal ?
                                        <>
                                        {item.related_goal.title}
                                        </>
                                        :
                                        <>-</>
                                    }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='p-3 bg-light'>
                            <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                            <div className=''>
                                {
                                    item.itemStatus && item.itemStatus.length > 0  ?
                                    <>
                                    {
                                        statusEdit(item) ?
                                        <>
                                        {
                                            item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === object.id )).map((sts) => {
                                                return <FormCreateItemStatus stepId={object.id} objectStatus={object.status} object={sts} objectType='GO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                            })
                                        }
                                        </>
                                        :
                                        <>
                                        <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='GO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                        </>
                                    }
                                    </>
                                    :
                                    <>
                                    <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='GO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    )
                }
                </div>
                <hr className='pb-3 borderNone'/>
                </>
                : null
            }
            {
                sessions.competencies.length > 0 ?
                <>
                <div className='fs-6 pb-2'>
                    <FontAwesomeIcon className='text-green' icon={solid('arrows-to-dot')} /> Você previsa avaliar <span className='fw-bold'>{sessions.competencies.length}</span> competências
                </div>
                <div className='ms-2 ps-3 verticalBorderLeft'>
                {
                    sessions.competencies.map((item, index) => 
                    <div key={index} className='border rounded mb-2'>
                        <div className='p-3'>
                            <p className='fw-bold mb-0'>{index+1}.</p>
                            <span className='text-green fw-bold fs-6'>{item.competency.title}</span>
                            <br/>
                            <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                        </div>
                        <div className='p-3 bg-light'>
                            <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                            <div className=''>
                                {
                                    item.itemStatus && item.itemStatus.length > 0  ?
                                    <>
                                    {
                                        statusEdit(item) ?
                                        <>
                                        {
                                            item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === object.id )).map((sts) => {
                                                return <FormCreateItemStatus stepId={object.id} objectStatus={object.status} object={sts} objectType='CO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                            })
                                        }
                                        </>
                                        :
                                        <>
                                        <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='CO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                        </>
                                    }
                                    </>
                                    :
                                    <>
                                    <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='CO' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    )
                }
                </div>
                <hr className='pb-3 borderNone'/>
                </>
                : null
            }
            {
                sessions.questions.length > 0 ?
                <>
                <div className='fs-6 pb-2'>
                    <FontAwesomeIcon className='text-green' icon={solid('arrows-to-dot')} /> Você previsa responder <span className='fw-bold'>{sessions.questions.length}</span> perguntas
                </div>
                <div className='ms-2 ps-3 verticalBorderLeft'>
                {
                    sessions.questions.map((item, index) => 
                    <div key={index} className='border rounded mb-2'>
                        <div className='p-3'>
                            <p className='fw-bold mb-0'>{index+1}.</p>
                            <span className='fs-6 fw-normal' dangerouslySetInnerHTML={{ __html: item.question }}></span>
                        </div>
                        <div className='p-3 bg-light'>
                            <p className='fw-bold font110 border-bottom'>Sua avaliação</p>
                            <div className=''>
                                {
                                    item.itemStatus && item.itemStatus.length > 0  ?
                                    <>
                                    {
                                        statusEdit(item) ?
                                        <>
                                        {
                                            item.itemStatus.filter((sts => sts.created_by === object.action_for && sts.step === object.id )).map((sts) => {
                                                return <FormCreateItemStatus stepId={object.id} objectStatus={object.status} object={sts} objectType='QE' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                            })
                                        }
                                        </>
                                        :
                                        <>
                                        <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='QE' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                        </>
                                    }
                                    </>
                                    :
                                    <>
                                    <FormCreateItemStatus stepId={object.id} updateStatusCount={updateStatusCount} objectStatus={object.status} objectType='QE' itemType={object.step_type} employeeId={object.employee.id} assessmentId={object.performance.id} sessionId={sessions.id} objectId={item.id} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    )
                }
                </div>
                <hr className='pb-3 borderNone'/>
                </>
                : null
            }
            <div className='d-flex flex-row-reverse pb-3 mb-4 align-items-center'>
                {
                    showBtn && object.status !== 'RE' ?
                    <div>
                        <button className='btn btn-success btn-sm btn-rounded px-4 ms-2' onClick={() => submitForm()} >Finalizar e Enviar</button>
                    </div>
                    : null
                }
            </div>
            </div>
            </>
            : null
        }
        </>
    )
}
import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormImportDeptXls from '../../../components/settings/forms/formImportDeptXls';
import VerifyPerms from '../../../context/userAccess';


export default function DeptsListManagement() {

    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [itemsCount, setItemsCount] = useState(0);
    
    const toast = useRef(null);

    const [parent, setParent] = useState('')
    const [jobTitle, setJobTitle] = useState('')

    const [worksheet, setWorksheet] = useState('')

    const navigate = useNavigate()

    const modalRef = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useEffect(() => {
        listItems()
    }, [page]);

    const searchItems = (event) => {
        if (event.query.length > 0) {

            companyService.autoCompleteDepartment(event.query).then((response) => {
                
                let resultTitle = response.map((item) => item.title + ' - ' + item.internal_code)
                setItems([...resultTitle])

            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listItems() {
        companyService.listDepartment(page).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function filterItems() {
        
        companyService.listDepartment(page, search.split('-')).then((response) => {

            if (response.results.length > 0) {
                setItemsList(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Departamento não encontrado', life: 4000 });
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
             
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    function cleanSearch() {
        setSearch('')
        listItems() 
    }

    function editItem() {

        return navigate('/settings/workstation-management/add-update/', {state: {selection}})
        
    }

    function addItem() {

        return navigate('/settings/workstation-management/add-update/', {state: {parent, jobTitle}})
        
    }

    useEffect(() => {

        getWorksheet()

    }, [])

    function getWorksheet() {

        let data = {
            key: 'templates/Department.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheet(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Departamentos</span>
                        </div>
                        {
                            VerifyPerms(['add_department', 'change_department', 'delete_department', 'view_department']) ?
                            <>
                            <div className="row m-3 p-3 justify-content-start">
                                <ul className='nav nav-tabs'>
                                    <li className='nav-item'>
                                        <span className='nav-link active'>
                                            Lista
                                        </span>
                                    </li>
                                    <li className='nav-item'>
                                        <a href='/settings/workstation-management/org/' className='nav-link text-green'>
                                            Organograma
                                        </a>
                                    </li>
                                </ul>
                                <div className='col bg-white border-clean border-top-0 pb-5 ps-3'>
                                    
                                    <div className="pt-4 d-flex mb-4">
                                        <div className='col'>
                                            <span className='font120 fw-bold '>
                                                Departamentos
                                            </span>
                                            <button id='dbm-addJob' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                            </button>
                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addJob">
                                                <li>
                                                    <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport">
                                                        Adicionar Múltiplos Departamentos
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        <div className='col'>
                                            <div className='d-flex'>

                                                <div className='p-inputgroup'>
                                                    <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Departamento pelo Nome ou Código' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                    <button className='btn btn-success button-search-radius' onClick={filterItems}>
                                                        <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                    </button>
                                                </div>
                                                {
                                                    search ?
                                                    <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                        Limpar
                                                    </button>
                                                    : null
                                                }
                                                
                                            </div>
                                            
                                        </div>
                                        <hr className='hr-border' />
                                    </div>

                                    <p className='text-end'><strong>{itemsCount}</strong> Departamentos cadastrados</p>
                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                                        <>
                                        <table className='table table-borderless thover text-default borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th>
                                                        Departamento
                                                    </th>
                                                    <th className='text-center'>
                                                        Código
                                                    </th>
                                                    <th className='text-center'>
                                                        Items Abaixo
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemsList.results.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="align-middle">
                                                                <span className='text-green fw-bold text-decoration-none' style={{'cursor': 'pointer'}} onClick={() => {setSelection(item);  setParent(item.id); setJobTitle(item.title); setShowSidebar(true)}}>
                                                                    {item.title}
                                                                </span>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.internal_code}
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                {item.childrenCount}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className='float-end'>
                                            <nav>
                                                <ul className='pagination pagination-sm'>
                                                    <li className='page-item'>
                                                        { 
                                                            itemsList.previous ? 
                                                            <span onClick={subPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                        }
                                                    </li>
                                                    <li className='page-item'>
                                                        {
                                                            itemsList.next ?
                                                            <span onClick={addPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                        }
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        </>

                                        : null
                                    }
                                    
                                </div>
                                <div className="card flex justify-content-center">
                                    <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                                        {
                                            selection ?
                                            <>
                                            {
                                            <div>
                                                <p className='lead fw-bold text-default'>
                                                    {selection.title} <small className='text-grey fw-normal'>({selection.internal_code})</small>
                                                </p>
                                                
                                                <div>
                                                    <button className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={editItem} >
                                                        <FontAwesomeIcon icon={solid('edit')} /> Editar
                                                    </button>
                                                    
                                                    <button className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={addItem}>
                                                        <FontAwesomeIcon icon={solid('plus-circle')} /> Adicionar Departamento Abaixo
                                                    </button>
                                                </div>
                                                <hr/>
                                                <div className='py-2'>
                                                    <strong>Departamentos Abaixo: </strong>
                                                    {
                                                        selection.childrenCount > 0 ?
                                                        <span>{selection.childrenCount}</span>
                                                        :
                                                        <span>Nenhuma</span>
                                                    }
                                                </div>
                                            </div>
                                            }
                                            </>
                                            :null
                                        }
                                    </Sidebar>
                                </div>
                            </div>

                            <div id="formImport" className="modal fade" ref={modalRef}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Importar Múltiplos Departamentos</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            
                                            <div className='mb-3'>
                                                <p>
                                                    Utilize e planilha modelo para importar Departamentos 
                                                </p>
                                                <a href={worksheet} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                    Planilha Modelo
                                                </a>
                                            </div>
                                            <hr/>
                                            <FormImportDeptXls hideModal={hideModal} />
                                        </div>
                                        <div className='modal-footer'>
                                            <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <p className='alert alert-warning m-3'>Você não tem permissão para acessar esta página</p>
                        }
                        
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
import React from "react";
import { Skeleton } from 'primereact/skeleton';

export default function SkeletonTeam() {
    return (
        <>
        <div className="d-flex justify-content-between">
            <div className="col-6">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem" width="10rem" className="mb-2"></Skeleton>
            </div>
        </div>

        <div className="d-flex mt-5">
            <div className="col">
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="50rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem"  width="30rem" className="mb-2"></Skeleton>
            </div>
        </div>
        </>
    )
}
import React, {useState, useEffect, useMemo, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';
import { OrganizationChart } from 'primereact/organizationchart'; //https://primereact.org/organizationchart/
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { useNavigate } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import FormImportDeptXls from '../../../components/settings/forms/formImportDeptXls';


export default function DeptsManagement() {
    const [tree, setTree] = useState()
    const [selection, setSelection] = useState();
    const [showSidebar, setShowSidebar] = useState(false);

    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [itemsCount, setItemsCount] = useState(0);

    const [worksheet, setWorksheet] = useState('')
    
    const toast = useRef(null);

    const [parent, setParent] = useState('')
    const [jobTitle, setJobTitle] = useState('')

    const navigate = useNavigate()

    const modalRef = useRef()

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useEffect(() => {
        listTitles()
        countItems()
    }, []);

    const searchItems = (event) => {
        if (event.query.length > 0) {

            companyService.autoCompleteDepartment(event.query).then((response) => {
                
                let resultTitle = response.map((item) => item.title + ' - ' + item.internal_code)
                setItems([...resultTitle])

            })
            .catch((error) => {
                console.log(error.response.data)
            });

        }
    }

    function listTitles() {
        companyService.listDepartmentOrg().then((response) => {

            if (response.length > 0) {
                setTree(response)
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function countItems() {
        companyService.countDepartment().then((response) => {
            setItemsCount(response)
        })
        .catch((error) => {
            console.log(error.response.data)
        });
    }

    function getJobTree() {
        
        companyService.listDepartmentOrg(search.split('-')).then((response) => {

            if (response.length > 0) {
                setTree(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Departamento não encontrada', life: 4000 });
            }
            
        })
        .catch((error) => {
            console.log(error.response.data)
        });
             
    }

    function cleanSearch() {
        setSearch('')
        listTitles() 
    }

    const nodeTemplate = (node) => {
        return (
            <div className="d-flex">
                <div className="p-3">
                    <div className="fw-bold mb-2">{node.data.title}</div>
                    {
                        node.childrenCount > 0 ?
                        <span className='text-secondary'> 
                            <Badge value={node.childrenCount}></Badge>
                            <br/>
                            <small>
                            {
                                node.childrenCount > 1 ?
                                <>Unidades Abaixo</>
                                :
                                <>Unidade Abaixo</>
                            }
                                
                            </small>
                        </span>
                        : null
                    }
                    
                    {
                        node.children.length < 1 && node.childrenCount > 0 ?
                        <button id={node.data.id} className='add-toggler' data-code={node.data.internal_code} onClick={(e) => {setSearch(e.currentTarget.getAttribute('data-code')); getJobTree()}} >
                            <FontAwesomeIcon className='font150 p-node-toggler-icon add-toggle-icon' icon={solid('plus-circle')} />
                        </button>
                        :null
                    }
                </div>
            </div>
        );
    };

    const showTree = useMemo(() => {

        if (tree) {
            return <OrganizationChart className='container-fluid' value={tree} selectionMode="single" selection={selection} onSelectionChange={(e) => {setSelection(e.data); setParent(e.data.data.id); setJobTitle(e.data.data.title); setShowSidebar(true)}} nodeTemplate={nodeTemplate} />
        }
        else {
            return false
        }
        
    }, [tree])

    function editItem() {

        return navigate('/settings/workstation-management/add-update/', {state: {selection}})
        
    }

    function addItem() {

        return navigate('/settings/workstation-management/add-update/', {state: {parent, jobTitle}})
        
    }

    useEffect(() => {

        getWorksheet()

    }, [])

    function getWorksheet() {

        let data = {
            key: 'templates/Department.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheet(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='col text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Departamentos</span>
                        </div>
                        <div className="row m-3 p-3 justify-content-start">

                            <ul className='nav nav-tabs'>
                                <li className='nav-item'>
                                    <a href='/settings/workstation-management/' className='nav-link text-green'>
                                        Lista
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <span className='nav-link active'>
                                        Organograma
                                    </span>
                                </li>
                            </ul>
                            <div className='col bg-white border-clean border-top-0 pb-5 ps-3'>
                                
                                <div className="pt-4 d-flex mb-4">
                                    <div className='col'>
                                        <span className='font120 fw-bold '>
                                            Departamentos
                                        </span>
                                        <button id='dbm-addJob' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                        </button>
                                        <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addJob">
                                            <li>
                                                <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport">
                                                    Adicionar Múltiplos Departamentos
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    <div className='col'>
                                        <div className='d-flex'>

                                            <div className='p-inputgroup'>
                                                <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Departamento pelo Nome ou Código' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                <button className='btn btn-success button-search-radius' onClick={getJobTree}>
                                                    <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                </button>
                                            </div>
                                            {
                                                search ?
                                                <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                    Limpar
                                                </button>
                                                : null
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                    <hr className='hr-border' />
                                </div>

                                <p className='text-end'><strong>{itemsCount}</strong> Departamentos cadastradas</p>
                                {
                                    showTree ?
                                    <div className='pt-3 bg-org rounded'>
                                        {showTree}
                                    </div>
                                    : null
                                }
                                
                            </div>
                            <div className="card flex justify-content-center">
                                <Sidebar className="w-50" visible={showSidebar} position="right" onHide={() => {setShowSidebar(false); setSelection('')}}>
                                    {
                                        selection ?
                                        <>
                                        {
                                        <div>
                                            <p className='lead fw-bold text-default'>
                                                {selection.data.title} <small className='text-grey fw-normal'>({selection.data.internal_code})</small>
                                            </p>
                                            
                                            <div>
                                                <button className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={editItem} >
                                                    <FontAwesomeIcon icon={solid('edit')} /> Editar
                                                </button>
                                                
                                                <button className='btn btn-sm btn-outline-success btn-rounded px-3 m-1' onClick={addItem}>
                                                    <FontAwesomeIcon icon={solid('plus-circle')} /> Adicionar Departamento Abaixo
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className='py-2'>
                                                <strong>Departamentos Abaixo: </strong>
                                                {
                                                    selection.childrenCount > 0 ?
                                                    <span>{selection.childrenCount}</span>
                                                    :
                                                    <span>Nenhuma</span>
                                                }
                                            </div>
                                        </div>
                                        }
                                        </>
                                        :null
                                    }
                                </Sidebar>
                            </div>
                        </div>

                        <div id="formImport" className="modal fade" ref={modalRef}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Importar Múltiplos Departamentos</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        
                                        <div className='mb-3'>
                                            <p>
                                                Utilize e planilha modelo para importar Departamentos 
                                            </p>
                                            <a href={worksheet} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                Planilha Modelo
                                            </a>
                                        </div>
                                        <hr/>
                                        <FormImportDeptXls hideModal={hideModal} />
                                    </div>
                                    <div className='modal-footer'>
                                        <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </ProtectedRoute>
    )
}
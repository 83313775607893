import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router";

const ProtectedRoute = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const companyId = localStorage.getItem('companyId')

    useEffect(() => {
        
        if (!companyId) {
            setIsLoggedIn(false)
        }

    }, []);

    if (isLoggedIn) {
        return children;
    } else {
        return <Navigate to={`/?next=${window.location.pathname}`} />;
    }
}

export default ProtectedRoute;
import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useFormik } from 'formik';
import companyService from '../../../services/data/company.service';
import ProtectedRoute from '../../../routes/protected';
import SideBarCompanyManagement from '../../../components/sidebars/sidebarCompanyManagement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom';
import { Editor } from 'primereact/editor';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function AddUpdateInternalJob() {

    const navigate = useNavigate()

    const location = useLocation();
    const [props, setProps] = useState('');
    const toast = useRef(null);
    const modalRef = useRef()

    const headerEditor = renderHeaderEditor();

    const groupPage = 1

    const [objId, setObjId] = useState('')
    
    const [parent, setParent] = useState('') // mesmo pk do objId mas apenas para adicionar novo
    const [jobTitle, setJobTitle] = useState('') // para mostrar qual cargo será superior ao novo
    const [reinitialize, setReinitialize] = useState(false)

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [requirements, setRequirements] = useState('')
    
    const [benefitsList, setBenefitsList] = useState([]) // base para autocomplete
    const [benefits, setBenefits] = useState([])

    const [competenciesList, setCompetenciesList] = useState([]) // base para autocomplete
    const [competencies, setCompetencies] = useState([])

    const [groupsList, setGroupsList] = useState([]) // base para autocomplete
    const [groups, setGroups] = useState([])

    const [permList, setPermList] = useState([]) // base para autocomplete
    const [permissions, setPermissions] = useState([])


    useEffect(() => {
        
        getObj()
        getGroups()
        getPerms()
        getBenefits()
        getCompetencies()
    }, []);

    useMemo(() => {

        if (location && location.state) {
            if (location.state.selection) {

                setReinitialize(true)

                setProps(location.state.selection)
                if (location.state.selection.data) {
                    setObjId(location.state.selection.data.id)
                } else {
                    setObjId(location.state.selection.id)
                }

                if (!title) {
                    getObj()
                }
                
            }
            if (location.state.parent) {
                setParent(location.state.parent)
            }
            if (location.state.jobTitle) {
                setJobTitle(location.state.jobTitle)
            }
        }
    }, [location])

    function getObj() {
        if (objId) {
            companyService.getInternalJobTitle(objId).then((response) => {
            
                setTitle(response.data.title)
                setDescription(response.data.description)
                setRequirements(response.data.requirements)

                //setBenefits
                if (response.benefits.length > 0) {
                    let _ben = response.benefits.map((item) => item.title)
                    setBenefits(_ben)
                }
                //setCompetencies
                if (response.competencies.length > 0) {
                    let _com = response.competencies.map((item) => item.title)
                    setCompetencies(_com)
                }
                //setGroups
                if (response.groups.length > 0) {
                    let _group = response.groups.map((item) => item.name)
                    setGroups(_group)
                }
    
                //setPermissions
                if (response.permissions.length > 0) {
                    let _perm = response.permissions.map((item) => item.name)
                    setPermissions(_perm)
                }
            }).catch((error) => {
                console.log(error.response)
            });
        }
    }

    function getCompetencies() {
        companyService.listCompetencies().then((response) => {
            setCompetenciesList(response.results.map((item) => item.title))
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getBenefits() {
        companyService.listBenefits().then((response) => {
            setBenefitsList(response.results.map((item) => item.title))
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getGroups() {
        companyService.listCompanyGroup(groupPage).then((response) => {
            setGroupsList(response.results.map((item) => item.name))
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function getPerms() {
        companyService.listPermissions().then((response) => {
            setPermList(response.map((item) => item.name))
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function delJob() {
        companyService.deleteInternalJobTitle(objId).then(() => {

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Cargo apagado com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                return navigate('/settings/internal-job-management/')
              }, 3000);
            
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const formik = useFormik({
        enableReinitialize: reinitialize,
        initialValues: {
            title: title,
            description: description,
            requirements: requirements,
            benefits: benefits,
            competencies: competencies,
            groups: groups,
            permissions: permissions
        },
        onSubmit: values => {

            let data;

            if (objId) {
                data = {
                    company_id: localStorage.getItem("companyId"),
                    title: values.title,
                    description: values.description,
                    requirements: values.requirements,
                    benefits: benefits,
                    competencies: competencies,
                    groups: groups,
                    permissions: permissions,
                    id: objId,
                }

                companyService.updateInternalJobTitle(data).then(() => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Atualizado com sucesso', life: 4000 });
                    //getObj()

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });

            } else {
                data = {
                    company_id: localStorage.getItem("companyId"),
                    title: values.title,
                    description: values.description,
                    requirements: values.requirements,
                    parent_id: parent,
                    benefits: benefits,
                    competencies: competencies,
                    groups: groups,
                    permissions: permissions,
                }

                companyService.createInternalJobTitle(data).then((response) => {
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Cargo criado com sucesso', life: 4000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
                });
            }
            
        },
    });

    return (
        <ProtectedRoute>
            <div className="container-fluid">
                
                <Toast ref={toast} position="top-right" />

                <div className='row flex-nowrap'>
                    <SideBarCompanyManagement />
                    <div className="col p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Editar Cargo</span>
                        </div>
                        <div className="row p-3 justify-content-center">
                            
                            <div className='col-xl-6 mx-2'>
                                <a href="/settings/internal-job-management/" className="nav-link my-3">
                                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                                </a>
                                <div className="p-3 bg-white rounded border-clean">

                                    {
                                        jobTitle ?
                                        <p className='alert alert-warning'>
                                            Adicionar cargo abaixo de <strong>{jobTitle}</strong>
                                        </p>
                                        :
                                        null
                                    }
                                
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='mb-3'>
                                            <label className="form-label fw-bold">Nome do Cargo <span className="text-danger">*</span></label>
                                            <input name="title" className='form-control' {...formik.getFieldProps('title')} />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label fw-bold">Descrição <span className="text-danger">*</span></label>
                                            <Editor
                                                name="description"
                                                value={description}
                                                style={{ height: '180px' }}
                                                headerTemplate={headerEditor}
                                                onTextChange={(e) => {
                                                    formik.setFieldValue('description', e.htmlValue);
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label fw-bold">Requisitos <span className="text-danger">*</span></label>
                                            <Editor
                                                name="requirements"
                                                value={requirements}
                                                style={{ height: '180px' }}
                                                headerTemplate={headerEditor}
                                                onTextChange={(e) => {
                                                    formik.setFieldValue('requirements', e.htmlValue);
                                                }}
                                            />
                                        </div>

                                        <div className='mt-3'>
                                            <label className="form-label fw-bold">
                                                Competências { competencies.length > 0 ? <>({competencies.length})</> : null}
                                            </label>
                                            <div className='p-inputgroup'>
                                                <MultiSelect filter placeholder="Selecionar" value={competencies} onChange={(e) => setCompetencies(e.value)} options={competenciesList} display="chip" className="rounded" />
                                            </div>
                                            
                                        </div>

                                        <div className='mt-3'>
                                            <label className="form-label fw-bold">
                                                Benefícios { benefits.length > 0 ? <>({benefits.length})</> : null}
                                            </label>
                                            <div className='p-fluid p-inputgroup'>
                                                <MultiSelect filter placeholder="Selecionar" value={benefits} onChange={(e) => setBenefits(e.value)} options={benefitsList} display="chip" className="rounded" />
                                            </div>
                                            
                                        </div>
                                    
                                        <div className='mt-4'>
                                            <label className="form-label fw-bold">
                                                Grupos e Permissões
                                            </label>
                                            <hr className='mt-0'/>
                                            <div>
                                                <label className="form-label fw-bold">
                                                    Grupos { groups.length > 0 ? <>({groups.length})</> : null}
                                                </label>
                                                <div className='p-inputgroup'>
                                                    <MultiSelect filter placeholder="Selecionar" value={groups} onChange={(e) => setGroups(e.value)} options={groupsList} display="chip" className="rounded" />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <label className="form-label fw-bold mb-0">
                                                    Permissões { permissions.length > 0 ? <>({permissions.length})</> : null}
                                                </label>
                                                <p className='mb-1'>Adicionar permissões além das permissões nos grupos</p>
                                                <div className='p-inputgroup'>
                                                    <MultiSelect filter placeholder="Selecionar" value={permissions} onChange={(e) => setPermissions(e.value)} options={permList} display="chip" className="rounded" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" mx-auto btnMargin">
                                            <button className="btn btn-sm btn-success btn-rounded px-4" type="submit">
                                                {
                                                    objId ?
                                                    <span>Atualizar</span>
                                                    :
                                                    <span>Adicionar</span>
                                                }
                                            </button>
                                        </div>
                                    </form>
                                
                                </div>
                                {
                                    objId ?
                                    <>
                                    <div className="text-end btnMargin">
                                        <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Cargo</button>
                                    </div>

                                    <div id="formDel" className="modal fade" ref={modalRef}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Apagar Cargo</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    {
                                                        props && props.childrenCount && props.childrenCount > 0 ?
                                                        <div className='text-center'>
                                                            <Message summary='true' severity="warn" text="Não é possível apagar Cargos que possuem outros cargos abaixo" />
                                                        </div>
                                                        :
                                                        <>
                                                        <p>
                                                            Tem certeza de que deseja apagar este Cargo?
                                                        </p>
                                                        <button onClick={delJob} className="btn btn-sm btn-danger btn-rounded px-4">Sim, Apagar</button>
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, { useEffect, useRef, useState, useMemo } from "react";
import ProtectedRoute from "../../routes/protected";
import SideBarMessage from "../../components/sidebars/sidebarMessage";
import userData from "../../context/user";
import { Toast } from 'primereact/toast';
import companyService from "../../services/data/company.service";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import 'moment/locale/pt-br'
import http from "../../services/http";
import { useLocation } from 'react-router-dom';


export default function MessagesSent() {

    const {user} = userData()
    const toast = useRef(null);
    const location = useLocation();
    const [items, setItems] = useState([])
    const [messageCount, setMessageCount] = useState(0)
    const [currentItem, setCurrentItem] = useState('')

    useEffect(() => {

        if (user) {
            listMessages()
        }

    }, [user])

    useEffect(() => {

        if (location && location.state) {
            if (location.state.currentItem) {
                setCurrentItem(location.state.currentItem)
            }
        }
    }, [location])

    function listMessages() {

        let data = {
            sender__uid: user.uid
        }
        companyService.listMessage(data).then((response) => {
            setItems({
                next: response.next,
                results: response.results
            })
            setMessageCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function nextPage() {
        let data = {
            sender__uid: user.uid
        }

        http.post(items.next, data).then((response) => {
            setItems(
                {
                    next: response.data.next,
                    results: items.results.concat(response.data.results)
                }
            )
        }).catch((error) => {
            console.log(error.response)
        });
    }

    return (
        <ProtectedRoute>
            <div className="container-fluid">

                <Toast ref={toast} position="top-right" />

                <div className="row">
                    <SideBarMessage />
                    <div className="col p-0">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Caixa de Mensagens</span>
                        </div>
                        <div className="row fullHeight">
                            <div className="col-3 bg-white ms-1" style={{height: '100%', overflow: 'auto'}}>
                                <div className="py-4">
                                    <div className="mb-2">
                                        <span className="fw-bold">{messageCount}</span> { messageCount === 1 ? 'Mensagem' : 'Mensagens' }
                                    </div>
                                    <hr/>
                                    {
                                        items && items.results ?
                                        <>
                                        <InfiniteScroll
                                            dataLength={items.results.length}
                                            next={nextPage}
                                            hasMore={items.next ? true : false}
                                            loader={<p>Carregando...</p>}
                                            endMessage={
                                                <p className="mt-2" style={{ textAlign: 'center' }}>
                                                <b>Não há mais mensagens</b>
                                                </p>
                                            }
                                            >
                                            <table className='table table-borderless thover border-top'>
                                                <tbody>
                                                
                                            {
                                                items.results.map((item, index) => 
                                                <tr key={index} className={`cursorPointer border-bottom align-middle ${ currentItem && currentItem.id === item.id ? 'active' : null} `} onClick={() => setCurrentItem(item)} >
                                                    
                                                    <td>
                                                        <div className="text-green fw-bold pb-2">
                                                            {item.title}
                                                        </div>
                                                        <div className="row px-0 lh-sm">
                                                            <div className="col">
                                                                <small className="text-grey">
                                                                    <span className="fw-semibold">Para:</span><br/>{item.recipient.fullName}
                                                                </small>
                                                            </div>
                                                            <div className="col text-end">
                                                                <small className="text-grey">
                                                                    <span className="fw-semibold text-white">Em:</span><br/>{moment(item.created_at).fromNow()}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                    
                                                </tr>
                                                )
                                            }
                                                </tbody>
                                            </table>
                                            </InfiniteScroll>
                                        </>
                                        :
                                        <>
                                        Sem mensagens ainda
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="flex-column bg-white ms-1 col py-4">
                                {
                                    currentItem ?
                                    <>
                                    <span dangerouslySetInnerHTML={{__html: currentItem.body}}></span>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, { useState, useRef } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import http from '../../services/http';
import { useParams, Link } from 'react-router-dom'
import { Toast } from 'primereact/toast';


export default function PasswordChange() {

    const { uidb64, token } = useParams()
    const [emailOk, setEmailok] = useState(false)

    const toast = useRef(null);

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirm: ''

        },
        validationSchema: Yup.object({
            password: Yup.string().matches(
                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
                'Sua senha precisa ter 8 caracteres no mínimo, além de caracteres especiais, letras e números'
            ),
            password_confirm: Yup.string().oneOf([Yup.ref("password"), null], "Senhas digitadas não conferem"),
        }),
        onSubmit: values => {
            var data = {
                password: values.password,
                password_confirm: values.password_confirm
            }

            http.post('password/change/' + uidb64 + '/' + token + '/', data).then( (response) => {

                setEmailok(true)
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: response.data.message, life: 4000 });

            })
            
        }
    });


    return (
        <div className="row mt-5">
            
            <Toast ref={toast} position="top-right" />

            <div className="col-md-4 offset-md-4">
                <form className="row g-2 divBorder formMarginBottom" onSubmit={formik.handleSubmit}>
                    <h1 className="text-center">Recuperar Senha</h1>

                    {
                        emailOk ?
                        <div className="noLogin text-center">
                            <div className="d-grid mx-auto col-4">
                                <Link className="btn btn-outline-success btn-rounded" to={'/'}>Login</Link>
                            </div>
                        </div>
                        : null
                    }

                    <div className="col-12">
                        <label className="form-label">Insira Nova Senha</label>
                        <input type="password" name="password" className="form-control" required {...formik.getFieldProps('password')} />
                        {formik.errors.password ? (<span name="password" className="text-vermelho">{formik.errors.password}</span>) : null}
                    </div>
                    <div className="col-12">
                        <label className="form-label">Confirmar Nova Senha</label>
                        <input type="password" name="password_confirm" className="form-control" required {...formik.getFieldProps('password_confirm')} />
                        {formik.errors.password_confirm ? (<span name="password_confirm" className="text-vermelho">{formik.errors.password_confirm}</span>) : null}
                    </div>
                    <div className="d-grid mx-auto btnMargin">
                        <button className="btn btn-success btn-rounded" type="submit">Confirmar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
import React, { useEffect, useState, useMemo, useRef } from "react";
import ProtectedRoute from '../../routes/protected';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';


export default function ImportGoalsToTemplate() {
    
    const { id } = useParams() //review process ID
    const location = useLocation();
    const navigate = useNavigate();
    const [object, setObject] = useState('')
    const [session, setSession] = useState('')
    const [reviewsList, setReviewsList] = useState('')
    const toast = useRef(null)
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)

    const [currentObject, setCurrentObject] = useState('')
    const [objList, setObjList] = useState([]) // options
    const [selectedGoals, setSelectedGoals] = useState([])

    useEffect(() => {

        listReviews()

    }, [page])

    function listReviews() {
        let data = {
            company_id: localStorage.getItem("companyId"),
            //created_by: false
        }

        performanceService.listPerformanceReview(page, data).then((response) => {
            if (response.count > 0){
                
                let res = response.results.map((item) => {
                    return {
                        name: item.title,
                        code: item.id
                    }
                })

                setReviewsList(res)

            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: "Nenhum item encontrado", life: 4000 });
            }
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        })
    }

    useMemo(() => {

        if (location && location.state) {

            if (location.state.object) {
                setObject(location.state.object)
            }
            if (location.state.session) {
                setSession(location.state.session)
            }
        }

    }, [location])

    useEffect(() => {

        if (currentObject) {

            let data = {
                company_id: localStorage.getItem("companyId"),
                id: currentObject
            }

            performanceService.listPerformanceReviewGoals(data).then((response) => {

                if (response.session && response.session.goals.length > 0) {
                    setObjList(response.session.goals)
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: 'Esta Avaliação não possui Objetivos a importar', life: 4000 });
                }

                
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            })

        }

    }, [currentObject])

    function checifInList(id) {

        let _item = selectedGoals.filter(item => item.id === id)[0]

        if (_item) {
            return true
        }
        return false

    }

    function addToList(item) {

        let inpt = document.getElementById(`check_${item.id}`)

        // check new checked state
        if (inpt.checked) {
            setSelectedGoals([...selectedGoals, item])
        } else {
            removeFromList(item.id)
        }

    }

    function removeFromList(id) {

        const newList = selectedGoals.filter(item => item.id !== id)
        if (newList){
            setSelectedGoals(newList)
        }
    }

    function rmCheck(id) {
        let inpt = document.getElementById(`check_${id}`)
        if (inpt){
            inpt.checked = false
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            
        },
        onSubmit: () => {
            let data = {
                company_id: localStorage.getItem("companyId"),
                goals: selectedGoals.map(item => item.id),
                id: object.id
            }

            if (session) {
                data.session_id = session
            }
            
            performanceService.updatePerformanceTemplateContent(data).then(() => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo(s) adicionado(s) com sucesso', life: 3000 });

                formik.resetForm()

                setTimeout(() => {
                    formik.resetForm()

                    return navigate(`/performance-template/${id}/process/`)
                }, 3000);

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
            }).finally(() => {
                setLoading(false)
            })
            
        },
    });

    const load = () => {
        setLoading(true);
        formik.submitForm()
    };

    return (

        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />
            <div className="container-fluid">
                <div className='row flex-nowrap paddingBottom200'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5 text-decoration-none text-white" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>

                <div className='d-flex my-4 mx-5 bg-white contentShadowFull rounded-top' style={{minHeight: '500px'}}>

                    <div className='flex-column col-5 paddingBottom200'>

                        <div className="p-4">
                            <div className="border-bottom">
                                <p className="font110 fw-bold mb-0">Importar de Outras Avaliações</p>
                                <p>Escolha na lista abaixo os objetivos para adicionar ao processo</p>
                            </div>
                            <div className='p-inputgroup mt-3'>
                                <Dropdown filter placeholder="Selecionar" value={currentObject} optionLabel="name" optionValue="code" options={reviewsList} onChange={(e) => {setCurrentObject(e.value)} } className="rounded" />
                            </div>
                            {
                                objList.length > 0 ?
                                <>
                                <div className="py-3 ps-2 pe-4" style={{'height': '400px'}}>
                                    <div className='mt-3 mb-1'>Mostrando <strong>{objList.length}</strong> itens</div>
                                        <div className="overflow-auto py-3 ps-2 pe-2 border rounded bg-light" style={{'height': '400px'}}>
                                        {
                                            objList.map((item) =>
                                            <div className="input-group mb-1" key={item.id}>
                                                <div className="input-group-text bg-green-light">
                                                    <input id={`check_${item.id}`} checked={ checifInList(item.id) ? 'checked' : null } type="checkbox" className="form-check-input mt-0" onClick={() => addToList(item)} />
                                                </div>
                                                <input type="text" className="form-control" defaultValue={item.title} />                                        
                                            </div>
                                            )
                                        }
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                        </div>

                    </div>

                    <div className='flex-column col bg-light'>
                    <div className="p-4">
                            <p className="font110 fw-bold border-bottom pb-3">Objetivos Selecionados</p>
                            {
                                selectedGoals ?
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className='text-end mt-3 mb-1'><strong>{selectedGoals.length}</strong> itens adicionados</div>
                                        {
                                            selectedGoals.map((item) =>
                                            <div className="input-group mb-1" key={item.id}>
                                                <input type="text" className="form-control" defaultValue={item.title} />   
                                                <div className="input-group-text bg-light" role="button" onClick={() => {rmCheck(item.id); removeFromList(item.id)}}>
                                                    <FontAwesomeIcon className='text-vermelho font085' icon={solid('trash-can')} /> 
                                                </div>
                                            </div>
                                            )
                                        }
                                        {
                                            selectedGoals.length > 0 ?
                                            <>
                                            <hr/>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="btnMargin">
                                                    <Button size="small" className="px-3" rounded label="Adicionar" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
                                                </div>
                                            </form>
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                                : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>

        </ProtectedRoute>

    )
}
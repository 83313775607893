import React, {useState, useEffect, useRef} from 'react';
import FormCreateGoal from './forms/formCreateGoal';
import performanceService from '../../services/data/performance.service';
import companyService from '../../services/data/company.service';
import { useNavigate } from 'react-router-dom';
import FormCreateJobPerformance from './forms/formCreateJobPerformance';
import FormCreateAssessmentQuestion from './forms/formCreateAssessmentQuestion';


export default function ContentTemplate(props) {

    const object = props.object
    const [orgGoals, setOrgGoals] = useState([]) //goals organizacionais vigentes para poder fazer o related_goal
    const [categoryList, setCategoryList] = useState('') // para enviar para o form de criar jobperformance
    const navigate = useNavigate();

    useEffect(() => {
        listOrgGoals()
        listCategories()
    }, [])

    function listOrgGoals() {
        let data = {
            goal_type: 'OG',
            status: ['AT']
        }

        performanceService.listGoalsByCategory(data).then((response) => {
            
            const newObj = Object.entries(response.results).map(([key, value]) => ({
                category: key,
                items: value.map((vl) => {
                    return {
                        id: vl.id,
                        title: vl.title
                    }
                })
            }))
            setOrgGoals(newObj)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'competencies'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    return (
        <div className='col-12 paddingBottom200'>
            <div className='d-flex'>
                
                <div className='col'>
                    <div className='p-3 pt-0 mb-5'>
                        <div className=''>
                            <table className='table'>
                                <tbody>
                                {
                                    object.goals.length > 0 ?
                                    <>
                                    <tr className='border-bottom bg-green-light align-middle'>
                                        <td className='fw-bold'>Objetivos ({object.goals.length})</td>
                                    </tr>
                                    {
                                            object.goals.map((item, index) =>
                                                <tr key={index} className=''>
                                                    <td className="align-middle">
                                                        <span className='text-green fw-bold'>{index+1} - {item.title}</span>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: item.metric }}></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    null
                                }
                                {
                                    object.competencies.length > 0 ?
                                    <>
                                    <tr className='border-bottom bg-green-light align-middle'>
                                        <td className='fw-bold'>Competências ({object.competencies.length})</td>
                                    </tr>
                                    {
                                            object.competencies.map((item, index) =>
                                                <tr key={index} className=''>
                                                    <td className="align-middle">
                                                        <span className='text-green fw-bold'>{index+1} - {item.competency.title}</span>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: item.description }}></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    null
                                }
                                {
                                    object.questions.length > 0 ?
                                    <>
                                    <tr className='border-bottom bg-green-light align-middle'>
                                        <td className='fw-bold'>Perguntas ({object.questions.length})</td>
                                    </tr>
                                    {
                                            object.questions.map((item, index) =>
                                                <tr key={index} className=''>
                                                    <td className="align-middle">
                                                        <span className='text-green fw-bold'>{index+1}.</span>
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                    :
                                    null
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                
            </div>

            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddGoal' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Adicionar Objetivo</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        <FormCreateGoal templateId={object.id} startDate={object.start_date} endDate={object.end_date} related_goals={orgGoals} listItems={props.updateObject} />
                    </div>
                </div>
            </div>

            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddComp' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Adicionar Competência</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        <FormCreateJobPerformance categoryList={categoryList} templateId={object.id} listItems={props.updateObject} />
                    </div>
                </div>
            </div>

            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvasAddQuestion' tabIndex={-1}>
                <div className='offcanvas-header'>
                    <h6 className='offcanvas-title'>Adicionar Pergunta</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div className='offcanvas-body'>
                    <div className='p-3 bg-light rounded'>
                        <FormCreateAssessmentQuestion templateId={object.id} listItems={props.updateObject} />
                    </div>
                </div>
            </div>
            
        </div>
    )

}
import React, {useState, useEffect, useRef} from 'react';
import ProtectedRoute from '../../../routes/protected';
import SideBarSettings from '../../../components/sidebars/sidebarSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import employeeService from '../../../services/data/employee.service';
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from 'primereact/toast';
import { Chip } from 'primereact/chip';
import FormImportEmployeesXls from '../../../components/settings/forms/formImportEmployeeXls';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import VerifyPerms from '../../../context/userAccess';
import companyService from '../../../services/data/company.service';


export default function EmployeeList() {

    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [search , setSearch] = useState('')
    const [items, setItems] = useState([]); // autocomplete
    const [groupedItems, setGroupedItems] = useState([])
    const [itemsCount, setItemsCount] = useState(0);
    const modalRef = useRef()
    const toast = useRef(null);
    const [worksheet, setWorksheet] = useState('')

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useEffect(() => {
        listItems()
    }, [page]);

    function listItems() {
        employeeService.listCompanyEmployee(page).then((response) => {
            setItemsList(response)
            setItemsCount(response.count)
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.status, life: 4000 });
        });
    }

    const searchItems = (event) => {
        if (event.query.length > 2) {
            
            employeeService.autoCompleteEmployee(event.query).then((response) => {
                let resultTitle = response.map((item) => item.user)
                setItems([...resultTitle])

                setGroupedItems(response.map((item) => item))

            })
            .catch((error) => {
                console.log(error.response.data)
            });
        }
    }

    function filterItems() {

        let filtered = groupedItems.find(x => x.user === search)

        employeeService.listCompanyEmployee(page, filtered.id).then((response) => {
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function cleanSearch() {
        setSearch('')
        listItems() 
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    useEffect(() => {

        getWorksheet()

    }, [])

    function getWorksheet() {

        let data = {
            key: 'templates/Employees.xlsx'
        }

        companyService.downloadObject(data).then((response) => {
            setWorksheet(response.data)
        }).catch((error) => {
            console.log(error.response)
        });

    }

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <SideBarSettings />
                    <div className="col-xs-10 col-sm-9 col-md-9 col-xl-10 p-0 formMarginBottom">
                        <div className='text-bg-green p-3'>
                            <span className="fs-6 fw-bold">Gestão de Colaboradores</span>
                        </div>
                        <div className="row m-3 p-3 justify-content-start">
                            <div className='col bg-white border-clean pb-5 ps-3'>
                                {
                                    VerifyPerms(['view_userprofile', 'add_userprofile', 'change_userprofile', 'delete_userprofile']) ?
                                    <>
                                    <div className="pt-4 d-flex mb-4">
                                        <div className='col'>
                                            <span className='font120 fw-bold '>
                                                Colaboradores
                                            </span>
                                            <button id='dbm-addUser' className="btn btn-sm no-caret dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                                            </button>
                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dbm-addUser">
                                                <li>
                                                    <a href="/settings/users-management/add/"  className="dropdown-item text-default">
                                                        Adicionar Colaborador
                                                    </a>
                                                </li>
                                                <li>
                                                <button className="dropdown-item text-default" data-bs-toggle="modal" data-bs-target="#formImport">
                                                        Adicionar Múltiplos Colaboradores
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex'>

                                                <div className='p-inputgroup'>
                                                    <AutoComplete inputClassName='input-search-radius' placeholder='Buscar Colaborador pelo Nome, Email ou CPF' value={search} suggestions={items} completeMethod={searchItems} onChange={(e) => setSearch(e.value)} />
                                                    <button className='btn btn-success button-search-radius' onClick={filterItems}>
                                                        <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                                    </button>
                                                </div>
                                                {
                                                    search ?
                                                    <button className='btn btn-sm btn-link fw-bold font1em text-grey text-decoration-none' onClick={(e) => cleanSearch()}>
                                                        Limpar
                                                    </button>
                                                    : null
                                                }
                                                
                                            </div>
                                            
                                        </div>
                                        <hr className='hr-border' />
                                    </div>

                                    <p className='text-end'><strong>{itemsCount}</strong> Colaboradores cadastrados</p>

                                    {
                                        itemsList && itemsList.results && itemsList.results.length > 0  ?
                                        <>
                                        <table className='table table-sm thover table-borderless text-default borderBottom mt-3'>
                                            <thead className='borderBottom'>
                                                <tr>
                                                    <th>
                                                        Colaboradores
                                                    </th>
                                                    <th className='text-center'>
                                                        Posição
                                                    </th>
                                                    <th className='text-center'>
                                                        Superior Imediato
                                                    </th>
                                                    <th className='text-center'>
                                                        Grupo
                                                    </th>
                                                    <th className='text-center'>
                                                        Permissões
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { 
                                                itemsList.results.map((item, index) => 
                                                <tr key={index}>
                                                    {
                                                        item.isRoot ?
                                                        <>
                                                        <td className="align-middle">
                                                            {item.user.first_name} {item.user.last_name}
                                                            <br/>
                                                            <small className='text-grey'>{item.user.email}</small>
                                                        </td>
                                                        <td className="align-middle text-center" colSpan='5'>
                                                            <Chip label='Usuário Principal' />
                                                        </td>
                                                        </>
                                                        :
                                                        <>
                                                        <td className="align-middle">
                                                            {item.user.first_name} {item.user.last_name}
                                                            <br/>
                                                            <small className='text-grey'>{item.user.email}</small>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                        {
                                                            item.jobposition ?
                                                            <>
                                                            {item.jobposition.title} ({item.jobposition.internal_code})
                                                            </>
                                                            :
                                                            <>-</>
                                                        }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                        {
                                                            item.parent ?
                                                            <>
                                                            {item.parent.title}
                                                            </>
                                                            :
                                                            <>-</>
                                                        }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {
                                                                item.groups.length > 0 ?
                                                                <>
                                                                {
                                                                    item.groups.map((g) => 
                                                                    <li key={g.id} className='list-group-item'>{g.name}</li>
                                                                )}
                                                                </>
                                                                : <>-</>
                                                            }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {
                                                                item.permissions.length > 0?
                                                                <>{item.permissions.length}</>
                                                                :
                                                                <>-</>
                                                            }
                                                        </td>
                                                        <td>
                                                            <a href={`/settings/users-management/${item.id}/edit/`} className="btn btn-xs float-end" >
                                                                <FontAwesomeIcon className='text-green font095' icon={solid('edit')} />
                                                            </a>
                                                        </td>
                                                        </>
                                                    }
                                                </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                        <div className='float-end'>
                                            <nav>
                                                <ul className='pagination pagination-sm'>
                                                    <li className='page-item'>
                                                        { 
                                                            itemsList.previous ? 
                                                            <span onClick={subPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                            </span>
                                                        }
                                                    </li>
                                                    <li className='page-item'>
                                                        {
                                                            itemsList.next ?
                                                            <span onClick={addPage} className='page-link text-default'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                            :
                                                            <span className='page-link text-grey disabled'>
                                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                            </span>
                                                        }
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        </>
                                        :
                                        <p>Nenhum item criado ainda</p>
                                    }

                                    <div id="formImport" className="modal fade" ref={modalRef}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Importar Múltiplos Colaboradores</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    
                                                    <div className='mb-3'>
                                                        <p>
                                                            Utilize e planilha modelo para importar Colaboradores 
                                                        </p>
                                                        <a href={worksheet} target='_blank' rel="noopener noreferrer" className='btn btn-sm btn-success'>
                                                            Planilha Modelo
                                                        </a>
                                                    </div>
                                                    <hr/>
                                                    <FormImportEmployeesXls hideModal={hideModal} />
                                                </div>
                                                <div className='modal-footer'>
                                                    <button type="button" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <p className='alert alert-warning mt-3'>Você não tem permissão para acessar esta página</p>
                                }
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
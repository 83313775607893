import React, {useState, useEffect, useMemo, useRef} from 'react';
import ProtectedRoute from '../../routes/protected';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import performanceService from '../../services/data/performance.service';
import moment from "moment";
import 'moment/locale/pt-br'
import { Toast } from 'primereact/toast';
import { Rating } from 'primereact/rating';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import employeeService from '../../services/data/employee.service';
import SkeletonTeam from './skeletonTeam';
import UsePerms from '../../context/userAccess';
import companyData from '../../context/company';

ChartJS.register(ArcElement, Tooltip, Legend);


export default function MyTeamPerformance() {

    const VerifyPerms = UsePerms()
    const {rootUser} = companyData()

    const [pageLoad, setPageLoad] = useState(true)

    const [userEmployee, setUserEmployee] = useState('')

    const toast = useRef(null);
    const [itemsList, setItemsList] = useState([]);
    const [current, setCurrent] = useState('')
    const [dueDate, setDueDate] = useState('')
    const [page, setPage] = useState(1)

    const [chartData, setChartData] = useState(null)
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    const navigate = useNavigate();

    useEffect(() => {

        getUser()
        
    }, [])

    useEffect(() => {

        listItems()
        
    }, [page])

    function getUser() {
        employeeService.getUserEmployee().then((response) => {    
            setUserEmployee(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listItems() {
        
        let data = {
            company_id: localStorage.getItem("companyId")
        }
        performanceService.myTeamPerformance(page, data).then((response) => {    
            setItemsList(response)
        }).catch((error) => {
            console.log(error.response)
        }).finally(() => {
            setPageLoad(false)
        })

    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    useMemo(() => {

        if (itemsList) {

            let data = {
                company_id: localStorage.getItem("companyId")
            }
            performanceService.myTeamPerformanceChart(data).then((response) => {    
                setChartData(response)
            }).catch((error) => {
                console.log(error.response)
            });
        }

    }, [itemsList])

    const datasetChart = useMemo(() => {

        let res;
        if (chartData) {
            res = {
                labels: chartData.labels,
                datasets: [
                    {
                        data: chartData.data,
                        backgroundColor: [
                            'rgb(239, 98, 132)',
                            'rgb(157, 116, 204)',
                            'rgb(54, 162, 235)',
                            'rgb(81, 168, 177)',
                            'rgb(244, 176, 66)',
                          ],
                        hoverOffset: 4,
                        cutout: "70%",
                    }
                ]
            }
        }

        return res

    }, [chartData])

    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        family: "'Nunito Sans', Helvetica, Arial, sans-serif",
                        size: 11
                    },
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                }
            },
            layout: {
                padding: {
                    top: 0
                }
            },
            scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }]
              }
        },
    };

    function sendMailStartPerformance() {

        let data = {
            company_id: localStorage.getItem("companyId"),
            id: current.lastPerformance.nextStep.id
        }

        if (dueDate) {
            data.due_date = dueDate
        }

        performanceService.messageTakePerformanceReview(data).then(() => {    
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `Processo enviado com sucesso`, life: 5000 });
            listItems()
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        });
        
    }

    function ratingVal(val) {
        const lista = {
            'IN' : 1,
            'BE' : 2,
            'DE' : 3,
            'AE' : 4,
            'SE' : 5
        }
        return lista[val]
    }

    function goFormPerformance(assessment) {
        return navigate(`/create/performance-review/`, {state: {assessmentType: assessment}})
    }

    function seeEmpSummary(e) {

        const empId = e.currentTarget.getAttribute('data-value')

        return navigate(`/career/summary/${empId}/`, {replace: true})
        
    }

    return (
        <ProtectedRoute>
            
            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> Performance
                        </a>
                    </div>
                </div>
                <div className='my-4 mx-5 py-3 rounded-top' style={{minHeight: '500px'}}>

                    {
                        pageLoad ?
                        <SkeletonTeam />
                        :
                        <>
                        <ul className='nav nav-tabs px-3'>
                            <li className='nav-item'>
                                <span className='nav-link active'>
                                    Minha Equipe
                                </span>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/team/performance/matrix/' className='nav-link text-green'>
                                    Matriz
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a href='/my/performance/list/' className='nav-link text-green'>
                                    Lista de Avaliações
                                </a>
                            </li>
                        </ul>
                        <div className='bg-white py-4 rounded'>

                            {
                                itemsList && itemsList.results ? 
                                <>
                                <div className='row'>
                                    <div className='col-5'>
                                    {
                                        VerifyPerms(['add_performancereview']) || rootUser ?
                                        <div className='pb-2'>
                                            <div className='dropdown dropend' role="group">
                                                <button className='btn btn-sm btn-success btn-rounded dropdown-toggle px-3 font1em' data-bs-toggle="dropdown" aria-expanded="false">
                                                    Criar Para Minha Equipe 
                                                </button>
                                                <ul className='dropdown-menu border-clean headerBorder font1em mt-1' aria-labelledby="dropdownOptn">
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => goFormPerformance('PD')}>
                                                            Plano de Desenvolvimento
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => goFormPerformance('BD')}>
                                                            Avaliação 90º
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => goFormPerformance('AD')}>
                                                            Avaliação 180º
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => goFormPerformance('SD')}>
                                                            Avaliação 270º
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span role='button' className='dropdown-item text-default' onClick={() => goFormPerformance('RD')}>
                                                            Reunião 1:1
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                        <table className='table table-border thover mt-3'>
                                            <thead className='bg-green-light'>
                                                <tr>
                                                    <th>Pessoa</th>
                                                    <th className='text-center' data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-title="Risco de saída" >Retenção</th>
                                                    <th className='text-center' data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-title="Impacto da saída" >Impacto</th>
                                                    <th className='text-center'>Avaliação</th>
                                                    <th className='text-center'>Alto Potencial</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                itemsList.results.map((item, index) => 
                                                    <tr key={index} className='align-middle' >
                                                        <td>
                                                            <span className='fw-bold'>{item.user.name}</span>
                                                        </td>
                                                        <td className='text-center' data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-title={ item.lastPerformance && item.lastPerformance.retention ? `${ item.lastPerformance.retention === 'NS' || item.lastPerformance.retention === 'PQ' ? 'Baixo Risco' : item.lastPerformance.retention === 'AR' || item.lastPerformance.retention === 'CR' ? 'Alto Risco' : 'Moderado'}` : null}>
                                                        {
                                                            item.lastPerformance && item.lastPerformance.retention ?
                                                            <span className={`${item.lastPerformance.retention === 'NS' || item.lastPerformance.retention === 'PQ' ? 'dot-sm bg-success' : item.lastPerformance.retention === 'AR' || item.lastPerformance.retention === 'CR' ? 'dot-sm bg-danger' : 'dot-sm bg-warning'}`}>
                                                            </span>
                                                            :
                                                            <><span className='dot-sm border'></span></>
                                                        }
                                                        </td>
                                                        <td className='text-center' data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-title={ item.lastPerformance && item.lastPerformance.loss_impact ? `${ item.lastPerformance.loss_impact === 'NS' || item.lastPerformance.loss_impact === 'PQ' ? 'Baixo Impacto' : item.lastPerformance.loss_impact === 'AI' || item.lastPerformance.loss_impact === 'CR' ? 'Alto Impacto' : 'Moderado'}` : null}>
                                                        {
                                                            item.lastPerformance && item.lastPerformance.loss_impact ?
                                                            <span className={`${item.lastPerformance.loss_impact === 'NS' || item.lastPerformance.loss_impact === 'PQ' ? 'dot-sm bg-success' : item.lastPerformance.loss_impact === 'AI' || item.lastPerformance.loss_impact === 'CR' ? 'dot-sm bg-danger' : 'dot-sm bg-warning'}`}>
                                                            </span>
                                                            :
                                                            <><span className='dot-sm border'></span></>
                                                        }
                                                        </td>
                                                        <td className='text-center'>
                                                        {
                                                            item.lastPerformance && item.lastPerformance.manager_evaluation ?
                                                            <div className='align-items-center' data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-title={`${item.lastPerformance.manager_evaluation === 'IN'? 'Insatisfatório' : item.lastPerformance.manager_evaluation === 'BE'? 'Precisa Melhorar' : item.lastPerformance.manager_evaluation === 'DE'? 'Dentro da Expectativa' : item.lastPerformance.manager_evaluation === 'AE'? 'Acima da Expectativa' : 'Se Superou' }`}>
                                                                <Rating value={ratingVal(item.lastPerformance.manager_evaluation)} readOnly cancel={false} stars={5} />
                                                            </div>
                                                            :
                                                            <><Rating value={0} readOnly cancel={false} stars={5} /></>
                                                        }
                                                        </td>
                                                        <td className='text-center'>
                                                        {
                                                            item.lastPerformance && item.lastPerformance.potential ?
                                                            <>
                                                            {item.lastPerformance.potential === 'AP' ? <Rating value={1} readOnly cancel={false} stars={1} /> : <><Rating value={0} readOnly cancel={false} stars={1} /></> }
                                                            </>
                                                            :
                                                            <><Rating value={0} readOnly cancel={false} stars={1} /></>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        chartData ?
                                        <div className='col-5 ms-auto'>
                                            <div className='p-3'>
                                                <Doughnut data={datasetChart} options={chartOptions} />
                                            </div>
                                            
                                        </div>
                                        : null
                                    }
                                </div>
                                </>
                                :
                                null
                            }
                            <div className='row'>
                            {
                                itemsList && itemsList.results ? 
                                <>
                                <div className='col'>
                                    <div className='float-end mt-2'>
                                        <nav>
                                            <ul className='pagination pagination-sm'>
                                                <li className='page-item'>
                                                    { 
                                                        itemsList.previous ? 
                                                        <span onClick={subPage} className='page-link text-default' role="button">
                                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                        </span>
                                                        :
                                                        <span className='page-link text-grey disabled'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                                        </span>
                                                    }
                                                </li>
                                                <li className='page-item'>
                                                    {
                                                        itemsList.next ?
                                                        <span onClick={addPage} className='page-link text-default' role="button">
                                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                        </span>
                                                        :
                                                        <span className='page-link text-grey disabled'>
                                                            <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                                        </span>
                                                    }
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <table className='table table-border thover mt-3'>
                                        <thead className='bg-green-light'>
                                            <tr>
                                                <th colSpan={2}>Pessoa</th>
                                                <th className='text-center'>Mais Recente</th>
                                                <th className='text-center'>Data</th>
                                                <th className='text-center'>Auto Avaliação</th>
                                                <th className='text-center'>Avaliação Gestor</th>
                                                <th className='text-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            itemsList.results.map((item, index) => 
                                                <tr key={index} className='align-middle' >
                                                    <td width="20px" data-value={item.id} role='button' onClick={seeEmpSummary}>
                                                    {
                                                        item.user.photo ?
                                                        <Avatar image={item.user.photo} shape="circle" />
                                                        :
                                                        <Avatar label={NameInitials( item.user.name )} style={{ backgroundColor: '#9cc5ca', color: '#ffffff' }} shape="circle" />
                                                    }
                                                    </td>
                                                    <td data-value={item.id} role='button' onClick={seeEmpSummary}>
                                                        <span className='fw-bold'>{item.user.name}</span>
                                                        <p className='text-grey mb-0'>{item.jobposition.title}</p>
                                                    </td>
                                                    <td className='text-center'>
                                                    {
                                                        item.lastPerformance ?
                                                        <>
                                                        <a href={`/performance-review/${item.lastPerformance.id}/process/`} className='text-green fw-bold text-decoration-none'>{item.lastPerformance.title}</a>
                                                        <br/>
                                                        <small className='text-grey'><span className='fw-bold'>Status:</span> {item.lastPerformance.statusDisplay}</small>
                                                        </>
                                                        :
                                                        <>-</>
                                                    }
                                                    </td>
                                                    <td className='text-center'>
                                                    {
                                                        item.lastPerformance ?
                                                        <>
                                                        {moment(item.lastPerformance.start_date).format("DD-MMM-YYYY")} - {moment(item.lastPerformance.end_date).format("DD-MMM-YYYY")} 
                                                        </>
                                                        :
                                                        <>-</>
                                                    }
                                                    </td>
                                                    <td className='text-center'>
                                                    {
                                                        item.lastPerformance ?
                                                        <>
                                                        {
                                                            item.lastPerformance.nextStep && item.lastPerformance.nextStep.step_type === 'AA' && item.lastPerformance.nextStep.status === 'NI' ?
                                                            <>
                                                            <button onClick={() => {setCurrent(item); setDueDate(item.lastPerformance.nextStep.due_date)}} className='btn btn-sm btn-success btn-rounded px-3 font1em' data-bs-toggle="offcanvas" data-bs-target="#offCanvas">
                                                                Enviar
                                                            </button>
                                                            </>
                                                            :
                                                            <>
                                                            {
                                                                item.lastPerformance && item.lastPerformance.employee_evaluation_display ?
                                                                <>
                                                                {item.lastPerformance.employee_evaluation_display}
                                                                </>
                                                                :
                                                                <>
                                                                {item.lastPerformance.autoAva.statusDisplay}
                                                                </>
                                                            }
                                                            </>
                                                        }
                                                        </>
                                                        :
                                                        <>-</>
                                                    }
                                                    </td>
                                                    <td className='text-center'>
                                                    {
                                                        item.lastPerformance ?
                                                        <>
                                                        {
                                                            item.lastPerformance.manager_evaluation_display ?
                                                            <>
                                                            {item.lastPerformance.manager_evaluation_display}
                                                            </>
                                                            :
                                                            <>
                                                            {item.lastPerformance.gestorAva.statusDisplay}
                                                            </>
                                                        }
                                                        </>
                                                        :
                                                        <>-</>
                                                    }
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            item.lastPerformance && item.lastPerformance.nextStep ?
                                                            <>
                                                            {
                                                                !item.lastPerformance.messageSentNextStep ?
                                                                <>
                                                                <button onClick={() => {setCurrent(item); setDueDate(item.lastPerformance.nextStep.due_date)}} className='btn btn-sm btn-success font095 btn-rounded px-3' data-bs-toggle="offcanvas" data-bs-target="#offCanvas">
                                                                    Enviar
                                                                </button>
                                                                </>
                                                                :
                                                                <>
                                                                {
                                                                    item.lastPerformance.nextStep.action_for === userEmployee.id ?
                                                                    <a href={`/performance-review/step/${item.lastPerformance.nextStep.id}/take/`} target='_blank' className='btn btn-success btn-sm font095 btn-rounded px-3'>
                                                                        Acessar {item.lastPerformance.nextStep.title}
                                                                    </a>
                                                                    :
                                                                    null
                                                                }
                                                                
                                                                </>
                                                            }
                                                            </>
                                                            : 
                                                            <>-</>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                </>
                                :
                                <p>Não há colaboradores em sua equipe ainda</p>
                            }
                            </div>

                            <div className='offcanvas offcanvas-end offcanvas-size-xl' data-bs-backdrop="static" id='offCanvas' tabIndex={-1}>
                                <div className='offcanvas-header'>
                                    <h6 className='offcanvas-title'>Enviar</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => {setCurrent(''); setDueDate('')}}></button>
                                </div>
                                <div className='offcanvas-body'>
                                    <div className='p-3 bg-light rounded'>
                                        {
                                            current ?
                                            <>
                                            <p>Tem certeza de que deseja enviar para <span className='fw-bold'>{current.user.name}</span> a avaliação <span className='fw-bold'>{current.lastPerformance.title}</span> ?</p>
                                            <div className='mb-3'>
                                                <label className="form-label fw-bold">Prazo <span className="text-danger">*</span></label>
                                                <input className="form-control" type="date" required="required" autoComplete="off" min={current.lastPerformance.start_date} max={current.lastPerformance.end_date} value={dueDate} onChange={(e) => {setDueDate(e.target.value) }} />
                                            </div>
                                            <button className='btn btn-sm btn-success btn-rounded px-3' onClick={() => sendMailStartPerformance()}>
                                                Sim, enviar
                                            </button>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>
        </ProtectedRoute>
    )
}
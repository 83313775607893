import React, {useRef} from 'react';
import companyService from '../../../services/data/company.service';
import { Toast } from 'primereact/toast';


export default function FormAddCategory(props) {
    
    const toast = useRef(null);

    function addCategory() {
            
        const formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("title", document.getElementById("categoryTitle").value);
        formData.append("model", props.model);

        companyService.createCategory(formData).then((response) => {
            props.listCategories()
            props.handleShowCategory()
            if (props.setCategory) {
                props.setCategory(response.id)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data, life: 4000 });
        });

    }

    return (
        <div className='divBorder'>

            <Toast ref={toast} position="top-right" />

            <div className="col">
                <label className="form-label">Nome da Categoria <span className="text-danger">*</span></label>
                <input id='categoryTitle' type="text" className="form-control text-capitalize" required />
            </div>

            <div className="btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" onClick={addCategory}>
                    Adicionar
                </button>
            </div>
        </div>
    )
}
import React, {useRef} from "react";
import companyService from "../../../services/data/company.service";
import { Toast } from 'primereact/toast';


export default function FormImportBenefitsXls(props) {

    const toast = useRef(null);
    

    function onFileChange(e) {
            
        const formData = new FormData();
        formData.append("wsheet", e.target.files[0]);
        formData.append("company_id", localStorage.getItem("companyId"));

        companyService.importXlsBenefits(formData).then(() => {
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Planilha enviada com sucesso. Você receberá um email quando a importação for finalizada', life: 5000 });
            setTimeout(() => {
                props.hideModal()
                e.target.value = ''
            }, 5000);
        })
        .catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data['detail'], life: 4000 });
            setTimeout(() => {
                props.hideModal()
                e.target.value = ''
            }, 4000);
        });
    }

    return (
        <div className="mt-2">
            
            <Toast ref={toast} position="top-right" />

            <label className="fw-bold">Buscar Planilha</label>
            <input type="file" className="form-control" onChange={onFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
        </div>
    )
}
import React, {useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { renderHeaderEditor } from '../../../helpers/editorToolbar';
import { Editor } from 'primereact/editor';
import performanceService from '../../../services/data/performance.service';
import FormAddCategory from '../../settings/forms/formAddCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import companyService from '../../../services/data/company.service';


export default function FormCreateJobPerformance(props) {
    
    const toast = useRef(null);
    const headerEditor = renderHeaderEditor();
    const [showCategory, setShowCategory] = useState(false)
    const [category, setCategory] = useState('')
    const [categoryList, setCategoryList] = useState('') 
    const [templateId, setTemplateID] = useState('') // performance template

    useMemo(() => {

        if (props.categoryList) {
            setCategoryList(props.categoryList)
        }
        if (props.templateId) {
            setTemplateID(props.templateId)
        }
    }, [props])

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'competencies'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategoryList(response.results)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    
    const handleShowCategory = () => {
        setShowCategory(!showCategory)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            category: category,
            description: ''
        },
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                category_id: values.category,
                description: values.description,
            }

            if (props.assessment) {
                data.assessment_id = props.assessment
            }

            if (props.session) {
                data.session_id = props.session
            }

            if (templateId) {
                data.template = true
            }

            performanceService.createJobPerformance(data).then((response) => {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Competência criada com sucesso', life: 3000 });

                if (props.listItems) {
                    props.listItems()
                }

                if (templateId) {
                    //add competency to performance template
                    let dataTemp = {
                        company_id: localStorage.getItem("companyId"),
                        competencies: [response.id],
                        id: templateId
                    }
                    if (props.session) {
                        dataTemp.session_id = props.session
                    }
                    performanceService.updatePerformanceTemplateContent(dataTemp)
                }

                formik.resetForm()


            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: error.response.data[0], life: 4000 });
            });

        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />
        
            <div className="col">
                <label className="form-label">Título da Competência <span className="text-danger">*</span></label>
                <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Categoria</label>
                <button className="btn btn-sm" onClick={(e) => handleShowCategory(e)}>
                    <FontAwesomeIcon className='text-green font150' icon={solid('plus-circle')} />
                </button>
                
                {
                    showCategory ?
                    <>
                    <div className='mt-3 p-2 bg-light'>
                        <FormAddCategory model='competencies' setCategory={setCategory} listCategories={listCategories} handleShowCategory={handleShowCategory} />
                    </div>
                    <hr/>
                    </>
                    :
                    null
                }
                {
                    categoryList ?
                    <select className='form-select mb-3' {...formik.getFieldProps('category')}>
                        <option value=''>Sem Categoria</option>
                        {
                            categoryList.map((item, index) =>
                            <option key={index} value={item.id}>{item.title}</option>
                            )
                        }
                    </select>
                    :
                    null
                }
            </div>

            <div className="my-3">
                <label className="form-label">Descrever como a competência deve ser avaliada <span className="text-danger">*</span></label>
                <Editor
                    name="description"
                    style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    onTextChange={(e) => {
                        formik.setFieldValue('description', e.htmlValue);
                    }}
                />
            </div>
            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                    Adicionar
                </button>
            </div>
        </form>
    )
}
import React, { useState, createContext, useContext, useEffect } from "react";
import companyService from "../services/data/company.service";
import { ProgressSpinner } from 'primereact/progressspinner';


const DomainContext = createContext();

export function DomainProvider({children}) {

    const [domain, setDomain] = useState('')

    useEffect(() => {
        checkCompanyDomain()
    }, [])

    function checkCompanyDomain() {
        const _domain = window.location.origin.split(':')[1].replace('//', '')
        companyService.getCompanyDomains(_domain).then( (response) => {
            setDomain(response)
        })
        
    }

    if (!domain) {
        return (
        <div className="fullHeight d-flex justify-content-center align-items-center">
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
        )
    }
 
    return (
        <DomainContext.Provider value={{domain}}>
            {children}
        </DomainContext.Provider>
    )
}

export default function domainData() {
    return useContext(DomainContext);
}
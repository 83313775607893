import axios from "axios";
import tokenService from "./token.service";

const baseURL = `${process.env.REACT_APP_BASE_URL}`

const http = axios.create({
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    withCredentials: true,
    baseURL: baseURL
});

// create interceptor for acess token and refresh
http.interceptors.request.use(
    (config) => {
        const token = tokenService.getLocalAccessToken()
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)

http.interceptors.response.use(
    
    (response) => {
        return response
    },

    async (error) => {

        const originalConfig = error.config;

        //console.log('http error, ', error)

        if (error.response && error.response.status === 403 && error.response.data.code === 'token_not_valid') {

            let user = JSON.parse(localStorage.getItem("user"));
            
            if (user) {

                const data = {
                    refresh: tokenService.getLocalRefreshToken()
                }
    
                try {
                    const response = await axios.post(baseURL + 'login/token/refresh/', data)
                    tokenService.updateLocalAccessToken(user.refresh, response.data.access);
                    return http(originalConfig)
                }
                catch (error) {

                    if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
                        localStorage.clear();

                        window.location.href = '/'
                    }

                    throw error
                    
                }

            }

            return Promise.reject(error)

        }
        
        return Promise.reject(error)
    }
)

export default http;
import React, {useEffect, useState} from "react";
import ProtectedRoute from '../../routes/protected';
import userData from "../../context/user";
import companyData from "../../context/company";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import carreiras from '../../assets/images/icons/carreiras.png'
import configs from '../../assets/images/icons/configs.png'
import metas from '../../assets/images/icons/metas.png'
import performance from '../../assets/images/icons/performance.png'
import indicadores from '../../assets/images/icons/indicadores.png'
import treinamento from '../../assets/images/icons/treinamento.png'
import remuneracao from '../../assets/images/icons/remuneracao.png'
import sucessao from '../../assets/images/icons/sucessao.png'
import recrutamento from '../../assets/images/icons/recrutamento.png'
import admissao from '../../assets/images/icons/admissao.png'
import desligamento from '../../assets/images/icons/desligamento.png'
import organograma from '../../assets/images/icons/organograma.png'
import CompanyLogoDefault from '../../assets/images/company-logo-default.png'
import UsePerms from "../../context/userAccess";
import companyService from "../../services/data/company.service";
import moment from "moment";
import 'moment/locale/pt-br'
import { useNavigate } from 'react-router-dom';
import http from '../../services/http';

export default function Painel() {

    const VerifyPerms = UsePerms()
    const {user} = userData()
    const navigate = useNavigate()
    const {company, updateCompany, rootUser, userPosition, canAccess} = companyData()
    const [isManager, setIsManager] = useState(false)
    const [messageList, setMessageList] = useState('')
    const [messageCount, setMessageCount] = useState(0)

    useEffect(() => {

        if (!company) {
            updateCompany()
        }
        
    }, []);

    useEffect(() => {

        if (user) {
            listMessages()
        }

    }, [user])

    useEffect(() => {

        // verifica se o user tem subordinados para poder acessar "Minha Equipe" nos módulos
        if (!rootUser && userPosition && userPosition.childrenCount > 0) {
            setIsManager(true)
        }

        if (rootUser) {
            setIsManager(true)
        }
        
    }, [userPosition, rootUser]);

    function listMessages() {

        let data = {
            recipient__uid: user.uid
        }
        companyService.listMessage(data).then((response) => {
            setMessageList(response)
            setMessageCount(response.count)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function seeMessage(item) {
        return navigate(`/messages/inbox/`, {state: {currentItem: item}})
    }

    async function redirectToRecruitment() {
        try {
            const response = await http.get(`${process.env.REACT_APP_BASE_URL}auth/ats/login/?next=recruitment`);
            const redirectUrl = response.data.url;
            window.location.href = redirectUrl;
        } catch (error) {
            console.error('Erro ao obter URL de redirecionamento:', error);
        }
    }
    
    async function redirectToAdmission() {
        try {
            const response = await http.get(`${process.env.REACT_APP_BASE_URL}auth/ats/login/?next=admission`);
            const redirectUrl = response.data.url;
            window.location.href = redirectUrl;
        } catch (error) {
            console.error('Erro ao obter URL de redirecionamento:', error);
        }
    }

    return (

        <ProtectedRoute>
            <div className="bg-oval">
                <div className="container">
                    <div className="row formMarginBottom">
                        <div className="col py-3 ">

                            <div className="row mt-3">

                                <div className="col-12 text-default rounded bg-white border-clean">
                                    <div className="pt-3 pb-2 my-2">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">
                                                    {
                                                        company.logo ?
                                                        <>
                                                        <img src={`${company.logo}`} alt="logo" className="img-thumbnail img-fluid rounded-circle border-0" width="50" />
                                                        </>  
                                                        : 
                                                        <>
                                                        <img src={CompanyLogoDefault} alt="logo" className="img-fluid rounded border-0" width="50" />
                                                        </>
                                                    }
                                                    </td>
                                                    <td className="align-middle">
                                                        <div className="font1em ps-2"><span className="fw-bolder h6">{ company.name }</span><br/>Seja bem vindo(a) <span className="fw-bolder">{user.first_name} {user.last_name}</span>!</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            
                            </div>
                            
                            <div className="row mt-3">

                                <div className="col-md-4 my-2">
                                    <div className="bg-white rounded border-clean p-3">
                                        <div className="fs-6 text-default fw-bold pt-2">
                                            <FontAwesomeIcon icon={solid('inbox')} className="text-green me-2" /> Mensagens <span className="float-end badge rounded-pill bg-warning">{messageCount}</span>
                                            <hr />
                                        </div>

                                        {
                                            messageCount > 0 ?
                                            <>
                                            <table className='table table-borderless thover border-top'>
                                                <tbody>
                                            {
                                                messageList.results.slice(0, 5).map((item, index) => 
                                                <tr key={index} className='cursorPointer border-bottom align-middle' onClick={() => { seeMessage(item)}} >
                                                    
                                                    <td>
                                                        <div className="text-green pb-2">
                                                            <span className={`${ item.read_at ? 'fw-normal' : 'fw-bold'}`}>{item.title}</span>
                                                        </div>
                                                        <div className="row px-0 lh-sm">
                                                            <div className="col">
                                                                <small className="text-grey">
                                                                    <span className="fw-semibold">De:</span><br/>{item.sender.fullName}
                                                                </small>
                                                            </div>
                                                            <div className="col text-end">
                                                                <small className="text-grey">
                                                                    <span className="fw-semibold text-white">Em:</span><br/>{moment(item.created_at).fromNow()}
                                                                </small>
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                    
                                                </tr>
                                                )
                                            }
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <a href="/messages/inbox/" className="btn btn-sm btn-success btn-rounded px-4 font095" >Ver todas</a>
                                            </div>
                                            </>
                                            :
                                            <>
                                            <p>Sem mensagens ainda</p>
                                            </>
                                        }

                                        
                                    </div>
                                </div>

                                <div className="col my-2">
                                    <div className="container text-center">

                                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 justify-content-center bg-white rounded border-clean pt-3">

                                            <div className="col card border-0">
                                                <div className="card-body text-center dropdown-center">
                                                    <a href="#" id="dpm-carreira" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={carreiras} width='60' />
                                                        <div className="fw-bold pt-2">Carreira</div>
                                                    </a>
                                                    <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-carreira">
                                                        <li>
                                                            <a href="/my/career/summary/"  className="dropdown-item text-default">
                                                                Minha Carreira
                                                            </a>
                                                        </li>
                                                        {/* 
                                                        <li>
                                                            <a href="/my/career/resume/"  className="dropdown-item text-default">
                                                                Meu Currículo
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="/job-portal/"  className="dropdown-item text-default">
                                                                Ver Vagas
                                                            </a>
                                                        </li>
                                                        */}
                                                        
                                                    </ul>
                                                </div>
                                            </div>

                                            {
                                                canAccess ?
                                                <>
                                                {
                                                    canAccess.recruiting ?
                                                    <>
                                                    <div className="col card border-0">
                                                        <div className="card-body text-center">
                                                            <a href="#" id="dpm-recrut" className="no-caret text-decoration-none text-default" onClick={() => { redirectToRecruitment()}}>
                                                                <img src={recrutamento} width='60' />
                                                                <div className="fw-bold pt-2">Recrutamento</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="col card border-0 filterGrey" style={{opacity: '0.6'}}>
                                                        <div className="card-body text-center">
                                                            <span className="no-caret text-decoration-none text-default">
                                                                <img src={recrutamento} width='60' />
                                                                <div className="fw-bold pt-2">Recrutamento</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    </>
                                                }

                                                {
                                                    canAccess.onboarding ?
                                                    <>
                                                    <div className="col card border-0">
                                                        <div className="card-body text-center">
                                                            <a href="#" id="dpm-adminss" onClick={() => { redirectToAdmission()}} className="no-caret text-decoration-none text-default">
                                                                <img src={admissao} width='60' />
                                                                <div className="fw-bold pt-2">Admissão</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="col card border-0 filterGrey" style={{opacity: '0.6'}}>
                                                        <div className="card-body text-center">
                                                            <span className="no-caret text-decoration-none text-default">
                                                                <img src={admissao} width='60' />
                                                                <div className="fw-bold pt-2">Admissão</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    </>
                                                }

                                                {
                                                    canAccess.goals ?
                                                    <>
                                                    <div className="col card border-0">
                                                        <div className="card-body text-center dropdown-center">
                                                            <a href="#" id="dpm-metas" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <img src={metas} width='60' />
                                                                <div className="fw-bold pt-2">Metas e Objetivos</div>
                                                            </a>
                                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-metas">
                                                                <li>
                                                                    <a href="/my/goals/"  className="dropdown-item text-default">
                                                                        Minhas Metas
                                                                    </a>
                                                                </li>
                                                                {
                                                                    isManager ?
                                                                    <li>
                                                                        <a href="/my/team/goals/"  className="dropdown-item text-default">
                                                                            Minha Equipe
                                                                        </a>
                                                                    </li>
                                                                    : null
                                                                }
                                                                
                                                                {
                                                                    VerifyPerms(['view_goalplan']) ?
                                                                    <>
                                                                    <li>
                                                                        <a href="/goal/plan/"  className="dropdown-item text-default">
                                                                            Metas Organizacionais
                                                                        </a>
                                                                    </li>
                                                                    </>
                                                                    : null
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="col card border-0 filterGrey" style={{opacity: '0.6'}}>
                                                        <div className="card-body text-center">
                                                            <span className="no-caret text-decoration-none text-default">
                                                                <img src={metas} width='60' />
                                                                <div className="fw-bold pt-2">Metas Organizacionais</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    </>
                                                }

                                                {
                                                    canAccess.performance ?
                                                    <>
                                                    <div className="col card border-0">
                                                        <div className="card-body text-center dropdown-center">
                                                            <a href="#" id="dpm-perfor" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <img src={performance} width='60' />
                                                                <div className="fw-bold pt-2">Performance</div>
                                                            </a>
                                                            <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-perfor">
                                                                {
                                                                    VerifyPerms(['add_performancereview']) || rootUser ?
                                                                    <>
                                                                    <li>
                                                                        <a href="/create/performance-review-all/"  className="dropdown-item text-default">
                                                                            <FontAwesomeIcon icon={solid('chevron-right')} className="me-1" /> Criar Para Gestor
                                                                        </a>
                                                                    </li>
                                                                    {
                                                                        VerifyPerms(['add_performancetemplate']) || rootUser ?
                                                                        <li>
                                                                            <a href="/create/performance-template/"  className="dropdown-item text-default">
                                                                                <FontAwesomeIcon icon={solid('chevron-right')} className="me-1" /> Criar Ciclo (Múltiplos)
                                                                            </a>
                                                                        </li>
                                                                        : null
                                                                    }
                                                                    <li><hr style={{borderTop: '1px solid #2d60655f', height:'0', margin:'1em 0'}} /></li>
                                                                    </>
                                                                    : null
                                                                }
                                                                {
                                                                    isManager ?   
                                                                    <li>
                                                                        <a href="/my/team/performance/"  className="dropdown-item text-default">
                                                                            Minha Equipe
                                                                        </a>
                                                                    </li>
                                                                    : null
                                                                }
                                                                <li>
                                                                    <a href="/my/performance/list/"  className="dropdown-item text-default">
                                                                        Lista de Avaliações
                                                                    </a>
                                                                </li>
                                                                {
                                                                        VerifyPerms(['add_performancematrix']) || rootUser ?
                                                                        <li>
                                                                            <a href="/performance/general/matrix/"  className="dropdown-item text-default">
                                                                                Matriz 9Box
                                                                            </a>
                                                                        </li>
                                                                        : null
                                                                    }
                                                                {/*
                                                                <li>
                                                                    <a href="/my/career/performance/"  className="dropdown-item text-default">
                                                                        Minha Performance
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"  className="dropdown-item text-default">
                                                                        Solicitar Feedback
                                                                    </a>
                                                                </li>
                                                                */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="col card border-0 filterGrey" style={{opacity: '0.6'}}>
                                                        <div className="card-body text-center">
                                                            <span className="no-caret text-decoration-none text-default">
                                                                <img src={performance} width='60' />
                                                                <div className="fw-bold pt-2">Performance</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    </>
                                                }
                                                </>
                                                :
                                                <>
                                                Carregando...
                                                </>
                                            }
                                            
                                            {
                                                VerifyPerms(['view_companybi']) ?
                                            
                                                <div className="col card border-0">
                                                    <div className="card-body text-center">
                                                        <a href="/company/reports/" className="text-decoration-none text-default">
                                                            <img src={indicadores} width='60' />
                                                            <p className="fw-bold pt-2">Indicadores</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            <div className="col card border-0">
                                                <div className="card-body text-center">
                                                    <a href="/my/org-chart/" className="text-decoration-none text-default">
                                                        <img src={organograma} width='60' />
                                                        <p className="fw-bold pt-2">Organograma</p>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col card border-0">
                                                <div className="card-body text-center dropdown-center">
                                                    <a href="#" id="dpm-config" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={configs} width='60' />
                                                        <div className="fw-bold pt-2">Configurações</div>
                                                    </a>
                                                    <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-config">
                                                        <li>
                                                            <a href="/settings/edit-user/"  className="dropdown-item text-default">
                                                                Meus Dados
                                                            </a>
                                                        </li>
                                                        {
                                                            VerifyPerms(['view_companygroup', 'add_companygroup', 'change_companygroup', 'delete_companygroup']) ?

                                                            <li>
                                                                <a href="/settings/perms-management/"  className="dropdown-item text-default">
                                                                    Grupos e Permissões
                                                                </a>
                                                            </li>

                                                            : null
                                                        }
                                                        {
                                                            VerifyPerms(['view_userprofile', 'add_userprofile', 'change_userprofile', 'delete_userprofile']) ?

                                                            <li>
                                                                <a href="/settings/users-management/"  className="dropdown-item text-default">
                                                                    Colaboradores
                                                                </a>
                                                            </li>

                                                            : null
                                                        }
                                                        {
                                                            VerifyPerms(['change_company', 'change_companyconfig']) ?
                                                            <li>
                                                                <a href="/settings/edit-company/"  className="dropdown-item text-default">
                                                                    Gestão Empresa
                                                                </a>
                                                            </li>
                                                            : null
                                                            
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* 

                                            <div className="col card border-0">
                                                <div className="card-body text-center dropdown-center">
                                                    <a href="#" id="dpm-trein" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={treinamento} width='60' />
                                                        <div className="fw-bold pt-2">Treinamentos</div>
                                                    </a>
                                                    <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-trein">
                                                        <li>
                                                            <a href="#"  className="dropdown-item text-default">
                                                                Meus Treinamentos
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#"  className="dropdown-item text-default">
                                                                Minha Equipe
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#"  className="dropdown-item text-default">
                                                                Gestão de Trilhas
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col card border-0">
                                                <div className="card-body text-center dropdown-center">
                                                    <a href="#" id="dpm-remu" className="no-caret text-decoration-none text-default dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={remuneracao} width='60' />
                                                        <div className="fw-bold pt-2">Remuneração</div>
                                                    </a>
                                                    <ul className="dropdown-menu border headerBorder font1em" aria-labelledby="dpm-remu">
                                                        <li>
                                                            <a href="#"  className="dropdown-item text-default">
                                                                Minha Remuneração
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#"  className="dropdown-item text-default">
                                                                Minha Equipe
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col card border-0">
                                                <div className="card-body text-center">
                                                    <a href="#" className="text-decoration-none text-default">
                                                        <img src={sucessao} width='60' />
                                                        <p className="fw-bold pt-2">Sucessão</p>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col card border-0">
                                                <div className="card-body text-center">
                                                    <a href="#" className="text-decoration-none text-default">
                                                        <img src={desligamento} width='60' />
                                                        <p className="fw-bold pt-2">Desligamento</p>
                                                    </a>
                                                </div>
                                            </div>

                                            */}

                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </ProtectedRoute>

    )
}
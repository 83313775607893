import React, { useEffect, useState, createContext, useContext } from "react";
import companyService from "../services/data/company.service";
import employeeService from "../services/data/employee.service";
import { Navigate } from "react-router";


const CompanyContext = createContext();

export function CompanyProvider({children}) {

    const [company, setCompany] = useState({})
    const [userPerms, setUserPerms] = useState([])
    const [rootUser, setRootUser] = useState(false)
    const [userPosition, setUserPosition] = useState([])
    const [canAccess, setCanAccess] = useState({})

    function updateCompany() {

        const companyId = localStorage.getItem('companyId')
        
        companyService.getCompany(companyId).then( (data) => {
            setCompany(data)
            
        }).catch(() => {
            return <Navigate to="/home/" />;
        })
    }

    function updatePerms() {

        if (company && company.id) {
            employeeService.listEmployeePerms().then( (data) => {
                setUserPerms(data.permissions.map((item) => item.codename))
                setRootUser(data.isRoot)          
            }).catch((error) => {
                console.log(error.response)
            })
        }
    }

    function getUserPosition() {
        if (company && company.id) {
            
            companyService.getUserJobPosition().then( (data) => {
                if (data) {
                    setUserPosition(data)
                }      
            }).catch((error) => {
                console.log(error.response)
            })
        }
    }

    useEffect(() => {
        updateCompany()
    }, []);

    useEffect(() => {
        if (company && company.id) {
            updatePerms()
            getUserPosition()
        }
    }, [company])

    useEffect(() => {

        if (company && company.access) {
            setCanAccess(company.access)
        }

    }, [company])

    return (
        <CompanyContext.Provider value={{company, updateCompany, userPerms, updatePerms, rootUser, userPosition, canAccess}}>
            {children}
        </CompanyContext.Provider>
    )
}

export default function companyData() {
    return useContext(CompanyContext);
}
import http from "../http"

class UserService {

    async getUser() {
        const rqst = await http.get('get/user/')
        return rqst.data
    }

    async logoutUser() {
        const rqst = await http.get('rest/logout/')
        return rqst.data
    }

    async updateUser(data) {
        const rqst = await http.put('/user/update/', data)
        return rqst.data
    }
}

export default new UserService()
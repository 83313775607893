import http from "../http"

class CareerService {

    async getEmployeeSummary(data) {
        const rqst = await http.post('/employee/summary/', data)
        return rqst.data
    }

    async createUserCareerSummary(data) {
        const rqst = await http.post('/employee/career/summary/create/', data)
        return rqst.data
    }

    async getUserCareerSummary(data) {
        const rqst = await http.post('/employee/career/summary/get/', data)
        return rqst.data
    }

    async updateUserCareerSummary(data) {
        const rqst = await http.post('/employee/career/summary/update/', data)
        return rqst.data
    }

    async createUserEducation(data) {
        const rqst = await http.post('/employee/career/education/create/', data)
        return rqst.data
    }

    async listUserEducation(page, data) {
        const rqst = await http.post('/employee/career/education/list/?page=' + page, data)
        return rqst.data
    }

    async listUserEducationCourse(page, data) {
        const rqst = await http.post('/employee/career/education/course/list/?page=' + page, data)
        return rqst.data
    }

    async updateUserEducation(data) {
        const rqst = await http.post('/employee/career/education/update/', data)
        return rqst.data
    }

    async deleteUserEducation(data) {
        const rqst = await http.post('/employee/career/education/delete/', data)
        return rqst.data
    }

    async createUserWorkExperience(data) {
        const rqst = await http.post('/employee/career/experience/create/', data)
        return rqst.data
    }

    async listUserWorkExperience(page, data) {
        const rqst = await http.post('/employee/career/experience/list/?page=' + page, data)
        return rqst.data
    }

    async updateUserWorkExperience(data) {
        const rqst = await http.post('/employee/career/experience/update/', data)
        return rqst.data
    }

    async deleteUserWorkExperience(data) {
        const rqst = await http.post('/employee/career/experience/delete/', data)
        return rqst.data
    }

    async createUserLanguage(data) {
        const rqst = await http.post('/employee/career/idiom/create/', data)
        return rqst.data
    }

    async listUserLanguage(page, data) {
        const rqst = await http.post('/employee/career/idiom/list/?page=' + page, data)
        return rqst.data
    }

    async updateUserLanguage(data) {
        const rqst = await http.post('/employee/career/idiom/update/', data)
        return rqst.data
    }

    async deleteUserLanguage(data) {
        const rqst = await http.post('/employee/career/idiom/delete/', data)
        return rqst.data
    }

    async createUserSkill(data) {
        const rqst = await http.post('/employee/career/skill/create/', data)
        return rqst.data
    }

    async listUserSkill(page, data) {
        const rqst = await http.post('/employee/career/skill/list/?page=' + page, data)
        return rqst.data
    }

    async updateUserSkill(data) {
        const rqst = await http.post('/employee/career/skill/update/', data)
        return rqst.data
    }

    async deleteUserSkill(data) {
        const rqst = await http.post('/employee/career/skill/delete/', data)
        return rqst.data
    }

    async createUserWebsite(data) {
        const rqst = await http.post('/employee/career/website/create/', data)
        return rqst.data
    }

    async listUserWebsite(page, data) {
        const rqst = await http.post('/employee/career/website/list/?page=' + page, data)
        return rqst.data
    }

    async updateUserWebsite(data) {
        const rqst = await http.post('/employee/career/website/update/', data)
        return rqst.data
    }

    async deleteUserWebsite(data) {
        const rqst = await http.post('/employee/career/website/delete/', data)
        return rqst.data
    }
}

export default new CareerService()
import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import performanceService from '../../services/data/performance.service'
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import companyService from '../../services/data/company.service'
import employeeService from '../../services/data/employee.service';
import SkeletonProcess from '../../pages/performance/skeletonProcess';
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';


export default function ParticipantsSelect(props) {

    const [pageLoad, setPageLoad] = useState(true)
    const [filterLoad, setFilterLoad] = useState(false)
    const toast = useRef(null);
    const [itemsList, setItemsList] = useState([]);
    const [page, setPage] = useState(1)
    const [clear, setClear] = useState(false)

    const [subCompaniesList, setSubCompaniesList] = useState([]) // lista de subCompanies
    const [subcompany, setSubcompany] = useState('')

    const [deptosList, setDeptosList] = useState([]) // lista de departamentos
    const [deptos, setDeptos] = useState([])

    const [selected, setSelected] = useState([])


    useEffect(() => {
        listItems()
    }, [page])

    useEffect(() => {
        listSubCompanies()
        listDeptos()
    }, [])

    function listItems() {
        let data = {
            company_id: localStorage.getItem("companyId"),
        }

        employeeService.listManagers(page, data).then((response) => {
            if (response.count > 0){
                setItemsList(response)
            } else {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: "Nenhum item encontrado", life: 4000 });
            }
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
        }).finally(() => {
            setPageLoad(false)
        })
    }

    function listSubCompanies() {

        const getItem = ''

        companyService.autoCompleteSubCompany(getItem).then((response) => {
        
            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })
            setSubCompaniesList(res)

        })
        .catch((error) => {
            console.log(error.response.data)
        });

    }

    function listDeptos() {
        companyService.listDepartmentSimple().then((response) => {

            let res = response.map((item) => {
                return {
                    title: item.title,
                    value: item.id
                }
            })
            setDeptosList(res)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function addPage() {
        setPage(page + 1)
    }

    function subPage() {
        setPage(page - 1)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
        },
        onSubmit: values => {

            setFilterLoad(true)
            setClear(true)

            let data = {
                company_id: localStorage.getItem("companyId"),
                user: values.title,
            }

            if (subcompany) {
                data.jobposition__subcompany = subcompany
            }
            
            if (deptos.length > 0) {
                data.jobposition__department = deptos
            }
            
            employeeService.listManagers(1, data).then((response) => {
                if (response.count > 0){
                    setItemsList(response)
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: "Nenhum item encontrado", life: 4000 });
                }
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 })
            }).finally(() => {
                setFilterLoad(false)
            })

        }
    })

    function clearItems() {
        formik.resetForm();
        setSubcompany('')
        setDeptos([])
    }

    function checkItem(val) {

        var manager = document.getElementById(val)
        var children = document.getElementsByName(`child_${val}`)
        let childrenList = []

        if (manager.checked) {
            
            for (var i = 0; i < children.length; i++) {    
                children[i].checked = true
                childrenList.push(children[i].value)
            }
    
            let data = {
                manager: val,
                children: childrenList
            }
            setSelected([...selected, data])

        } else {

            for (var i = 0; i < children.length; i++) {    
                children[i].checked = false
            }

            const sl = selected.filter(e => e.manager !== val)
            setSelected(sl)
        }
    
    }

    function removeItem(manager_id, emp_id) {

        var emp = document.getElementById(emp_id)
        const newArray = [...selected]
        const sl = newArray.findIndex(e => e.manager === manager_id)

        if (newArray[sl].children.length === 1) {
            newArray.splice(sl, 1)
            setSelected(newArray)
        } else {

            if (!emp.checked) {
                //remove
                const ch = newArray[sl].children.filter(e => e !== emp_id)
                newArray[sl].children = ch
                setSelected(newArray)
    
            } else {
                newArray[sl].children.push(emp_id)
                setSelected(newArray)
            }
        }

    }

    function checifInList(id) {

        let _item = selected.filter(item => item.manager === id)[0]

        if (_item) {
            return true
        }
        return false

    }

    function checifInChildren(manager_id, emp_id) {

        try {

            let sl = selected.findIndex(e => e.manager === manager_id)
            const _item = selected[sl].children.filter(e => e === emp_id)[0]

            if (_item) {
                return true
            }
            return false

        } catch {
            return false
        }

    }

    function checkAll(e) {
        var ckChoices = document.getElementsByName('managerChoices')

        for (var i = 0; i < ckChoices.length; i++) {
            ckChoices[i].checked = e.target.checked
        }

        if (!e.target.checked) {
            setSelected([])
        } else {

            if (itemsList && itemsList.results && itemsList.results.length > 0) {
                const results = itemsList.results.map((item) => {
                    return {
                        manager: item.id,
                        children: item.children.map((ch) => ch.id)
                    }
                })

                setSelected(results)
            }
        }
        
    }

    function confirmParticipants() {

        let data ={
            company_id: localStorage.getItem("companyId"),
            participants: selected,
            id: props.id
        }

        performanceService.addParticipantsPerformanceTemplate(data).then((response) => {

            props.setObject(response)

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Participantes adicionados com sucesso', life: 3000 });

            setTimeout(() => {
                props.hideModal()
            }, [3000])
            
        }).catch((error) => {
            toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 })
        })
    }

    return (
        <div className='row'>
            <Toast ref={toast} position="top-right" />
            <div className='col-3'>
                <p className='fw-bold mt-3'>Filtros</p>
                <form onSubmit={formik.handleSubmit} className='bg-light p-3 rounded'>
                    <div className="col mb-3">
                        <label className="form-label">Nome</label>
                        <input name='title' type="text" className="form-control" {...formik.getFieldProps('title')} />
                    </div>
                    <div className="col mb-3">
                        <label className="form-label">Organização</label>
                        <div className='p-inputgroup'>
                            <Dropdown filter placeholder="Selecionar" value={subcompany} optionLabel="title" options={subCompaniesList} onChange={(e) => {setSubcompany(e.value); formik.setFieldValue('subcompany', e.value);} } className="rounded" />
                        </div>
                    </div>
                    <div className="col mb-3">
                        <label className="form-label">Departamento</label>
                        {
                            deptos.length > 0 ?
                            <span className='ms-1 fw-bold'>({deptos.length})</span>
                            : null
                        }
                        <div className='p-inputgroup'>
                            <MultiSelect filter optionLabel='title' optionValue='value' placeholder="Selecionar" value={deptos} onChange={(e) => {setDeptos(e.value);}} options={deptosList} display="chip" className="rounded" />
                        </div>
                    </div>
                    <div className="btnMargin">
                        <button className="btn btn-success btn-sm btn-rounded px-4" type="submit">
                            Filtrar
                        </button>
                        {
                            clear ?
                            <span role='button' className='fw-bold float-end mt-2' onClick={() => {{clearItems(); listItems()}}}>Limpar</span>
                            :null
                        }
                        
                    </div>
                </form>
            </div>
            <div className='col-9'>
                {
                    filterLoad || pageLoad ?
                    <SkeletonProcess />
                    :
                    <>
                    {
                        selected.length > 0?
                        <>
                        <div className='alert alert-warning'>
                            <label className='fw-bold pe-3'>{selected.length} Selecionados</label>
                            <button className='btn btn-sm btn-rounded btn-success px-3' onClick={() => confirmParticipants()}>
                                Confirmar
                            </button>
                        </div>
                        </>
                        : null
                    }
                    {
                        itemsList && itemsList.results && itemsList.results.length > 0 ?
                        <>
                        <table className='table text-default borderBottom mt-3'>
                            <thead className='borderBottom'>
                                <tr>
                                    <th colSpan='3'>
                                        Gestor
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="p-3 bg-light border-start border-end">
                                    <td colSpan="3">
                                        <div className="input-group">
                                            <div className="input-group-text bg-transparent border-0">
                                                <input className='form-check-input mt-0' type="checkbox" onClick={checkAll} />
                                            </div>
                                            <label className='form-check-label pt-1'>Selecionar Todos</label>
                                        </div>
                                        
                                    </td>
                                </tr>
                            {
                                itemsList.results.map((item, index) =>
                                <>
                                    <tr key={index} className='border-top border-end'>
                                        <td className="border-start border-end" width='10px' rowSpan='2'>
                                            <div className="input-group">
                                                <div className="input-group-text bg-transparent border-0">
                                                    <input id={item.id} name='managerChoices' checked={ checifInList(item.id) ? 'checked' : null } role='button' type="checkbox" className="form-check-input mt-0" value={item.id} onClick={(e) => checkItem(e.target.value)} />
                                                </div>                                     
                                            </div>
                                        </td>
                                        <td className="align-middle" width='20px'>
                                            <span>
                                            {
                                                item.user.photo ?
                                                <Avatar title={item.user.full_name} image={item.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                :
                                                <Avatar title={item.user.full_name} label={NameInitials( item.user.full_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle'/>
                                            }
                                            </span>
                                        </td>
                                        <td className="align-middle">
                                            <span className='fw-bold text-decoration-none'>
                                                {item.user.full_name}
                                            </span>
                                        </td>
                                    </tr>
                                    {
                                        item.children.length > 0 ?
                                        <tr className='border-top border-end'>
                                            <td colSpan='3'>
                                                <table className='table table-borderless'>
                                                    <tbody>
                                                    {
                                                        item.children.map((emp, idx) => 
                                                        <tr key={idx}>
                                                            <td className="align-middle" width='10px'>
                                                                <div className="input-group">
                                                                    <div className="input-group-text bg-transparent border-0">
                                                                        <input checked={ checifInChildren(item.id, emp.id) ? 'checked' : null } onClick={() => removeItem(item.id, emp.id)} id={emp.id} name={`child_${item.id}`} role='button' type="checkbox" className="form-check-input mt-0" value={emp.id} />
                                                                    </div>                                     
                                                                </div>
                                                            </td>
                                                            <td className="align-middle" width='20px'>
                                                                {
                                                                    emp.user.photo ?
                                                                    <Avatar title={emp.user.full_name} image={emp.user.photo} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                                                                    :
                                                                    <Avatar title={emp.user.full_name} label={NameInitials( emp.user.full_name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle'/>
                                                                }
                                                            </td>
                                                            <td className="align-middle">
                                                                <span className='fw-bold text-decoration-none'>
                                                                    {emp.user.full_name}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        : null
                                    }
                                    </>
                                )
                            }
                            </tbody>
                        </table>
                        <div className='float-end'>
                            <nav>
                                <ul className='pagination pagination-sm'>
                                    <li className='page-item'>
                                        { 
                                            itemsList.previous ? 
                                            <span onClick={subPage} className='page-link text-default'>
                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                            </span>
                                            :
                                            <span className='page-link text-grey disabled'>
                                                <FontAwesomeIcon className='' icon={solid('angle-left')} />
                                            </span>
                                        }
                                    </li>
                                    <li className='page-item'>
                                        {
                                            itemsList.next ?
                                            <span onClick={addPage} className='page-link text-default'>
                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                            </span>
                                            :
                                            <span className='page-link text-grey disabled'>
                                                <FontAwesomeIcon className='' icon={solid('angle-right')} />
                                            </span>
                                        }
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        </>

                        :
                        <>
                        Nenhum item criado ainda
                        </>
                    }
                    </>
                }
            </div>
        </div>
    )
}
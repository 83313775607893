import React, {useEffect, useRef, useState} from 'react';
import http from '../../services/http';
import { useFormik } from 'formik';
import CorreiosService from '../../helpers/correios.service'
import checkCPFouCNPJ from '../../helpers/checkCPFouCNPJ'
import { useNavigate } from "react-router-dom";
import userData from '../../context/user';
import companyData from '../../context/company'
import { Toast } from 'primereact/toast';
import { InputMask } from "primereact/inputmask";
import { Button } from 'primereact/button';


export default function RegisterCompany() {
    
    const { updateCompany } = companyData()
    const navigate = useNavigate();
    const {user} = userData()
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        
        if (localStorage.getItem('companyId')) {
            navigate('/register/company/logo/')
        }
        if (!user) {
            navigate('/')
        }
        
    }, []);

    const load = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 20000);
    };

    const validate = values => {

        const errors = {};

        if (values.legal_id && values.legal_id.length > 13) {

            var chId = checkCPFouCNPJ(values.legal_id)

            if (chId) {
                return true
            }
            var idText = 'CNPJ'

            if(values.size === '0') {
                idText = 'CPF'
            }
            errors.legal_id = `${idText} inválido`

        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            legal_id: '',
            phone: '',
            website: 'https://',
            zipcode: '',
            address: '',
            adddressnumber: '',
            adddresscomp: '',
            neighbourhood: '',
            state: '',
            city: '',
            size: ''

        },
        validate,
        onSubmit: values => {

            var data = {
                name: values.name,
                legal_id: values.legal_id,
                phone: values.phone,
                website: values.website,
                zipcode: values.zipcode,
                address: values.address,
                adddressnumber: values.adddressnumber,
                adddresscomp: values.adddresscomp,
                neighbourhood: values.neighbourhood,
                state: values.state,
                city: values.city,
                size: values.size
            }

            http.post('company/create/', data).then( (response) => {

                localStorage.setItem('companyId', response.data.id)
                updateCompany()
                return navigate('/register/company/logo/')

            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
            })
        },
    });

    return (

        <div className="row mt-5">

            <Toast ref={toast} position="top-right" />

            <div className="col-lg-4 offset-lg-4">
                <h1>
                    Olá <span className="text-green">{ user.first_name }</span>!
                </h1>
                <hr className='borderNone'/>
                <form className="row g-2 divBorder formMarginBottom" onSubmit={formik.handleSubmit}>
                    <h4 className="text-center">Cadastrar Empresa</h4>

                    <div className="mb-3">
                        <label className="form-label">Nome da Empresa</label>
                        <input type="text" name="name" className="form-control" required {...formik.getFieldProps('name')} />
                    </div>

                    <div className="mb-3 col-md-6">
                        <label className="form-label">Tamanho da Empresa</label>
                        <select className="form-select" name="size" required {...formik.getFieldProps('size')} >
                            <option disabled value="">Selecione</option>
                            <option value="0">Sou Autônomo</option>
                            <option value="1">1-10 Funcionários</option>
                            <option value="2">11-50 Funcionários</option>
                            <option value="3">51-200 Funcionários</option>
                            <option value="4">201-500 Funcionários</option>
                            <option value="5">501-1.000 Funcionários</option>
                            <option value="6">1.001-5.000 Funcionários</option>
                            <option value="7">5.001-10.000 Funcionários</option>
                            <option value="8">+ 10.000 Funcionários</option>
                        </select>
                    </div>
                    
                    {
                        formik.values.size === '0' ?
                        <div className="mb-3 col-md-6">
                            <label className="form-label">CPF</label>
                            <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}}  value={formik.values.legal_id} onChange={(e) => formik.setFieldValue('legal_id', e.target.value)} mask="999.999.999-99" placeholder="999.999.999-99" />
                            {formik.errors.legal_id ? (<span name="legal_id" className="text-vermelho">{formik.errors.legal_id}</span>) : null}
                        </div>
                        :
                        <div className="mb-3 col-md-6">
                            <label className="form-label">CNPJ</label>
                            <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}} value={formik.values.legal_id} onChange={(e) => formik.setFieldValue('legal_id', e.target.value)} mask="99.999.999/9999-99" placeholder="99.999.999/9999-99" />
                            {formik.errors.legal_id ? (<span name="legal_id" className="text-vermelho">{formik.errors.legal_id}</span>) : null}
                        </div>
                    }

                    <div className="mb-3 col-md-6">
                            <label className="form-label">Telefone</label>
                            <InputMask className='form-control' style={{'padding': '0.37rem 0.75rem'}} mask='(99) 9999-9999?9' required value={formik.values.phone} onChange={(e) => formik.setFieldValue('phone', e.target.value)} />
                        </div>

                    <div className="mb-3 col-md-6">
                        <label className="form-label">Website</label>
                        <input type="url" name="website" className="form-control" required {...formik.getFieldProps('website')} />
                        {formik.errors.website ? (<span name="website" className="text-vermelho">{formik.errors.website}</span>) : null}
                    </div>

                    <div className="mb-3 col-md-4">
                        <label className="form-label">CEP</label>
                        <InputMask mask='99999-999'value={formik.values.zipcode} className="form-control" required onChange={
                            e => {

                                formik.setFieldValue('zipcode', e.target.value);

                                if (!(e.target.value).includes('_') && e.target.value.length > 0) {

                                    formik.handleChange(e)

                                    var data = {
                                        zipcode: e.target.value
                                    }

                                    CorreiosService.getZipcode(data).then((response) => {
                        
                                        formik.setFieldValue("address", response.data['endereco'])
                                        formik.setFieldValue("neighbourhood", response.data['bairro'])
                                        formik.setFieldValue("city", response.data['cidade'])
                                        formik.setFieldValue("state", response.data['estado'])
                            
                                    }).catch((error) => {
                                        console.log('Error ', error.data)
                                    })

                                }

                            }
                        } />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Endereço</label>
                        <input type="text" name="address" className="form-control" required {...formik.getFieldProps('address')} />
                    </div>

                    <div className="row">
                        <div className="mb-3 col-md-4">
                            <label className="form-label">Nº</label>
                            <input type="number" name="adddressnumber" className="form-control" required {...formik.getFieldProps('adddressnumber')} />
                        </div>

                        <div className="mb-3 col-md-8">
                            <label className="form-label">Complemento (opcional)</label>
                            <input type="text" name="adddresscomp" className="form-control" {...formik.getFieldProps('adddresscomp')} />
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label className="form-label">Bairro</label>
                        <input type="text" name="neighbourhood" className="form-control" required {...formik.getFieldProps('neighbourhood')} />
                    </div>

                    <div className="mb-3 col-md-8">
                        <label className="form-label">Cidade</label>
                        <input type="text" name="city" className="form-control" required {...formik.getFieldProps('city')} />
                    </div>

                    <div className="mb-3 col-md-4">
                        <label className="form-label">Estado</label>
                        <select className="form-select" name="state" required {...formik.getFieldProps('state')} >
                            <option disabled value="">Selecione</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>

                    <div className="d-grid mx-auto btnMargin">
                        <Button className="btn btn-success btn-rounded" label="Cadastrar" icon="pi pi-check" type="submit" loading={loading} onClick={(e) => {{load(); formik.submitForm()}}}  />
                    </div>
                </form>
            </div>
        </div>
    )
}
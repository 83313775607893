import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormCreateGoalStatus from '../../components/performance/forms/formCreateGoalStatus';


export default function GoalEditStatus() {
    
    const { id } = useParams()
    const toast = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    const [object, setObject] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [lastStatus, setLastStatus] = useState('NI')

    useEffect(() => {
        getObject()
    }, [])

    function getObject() {
        performanceService.getGoal(id).then((response) => {
            setObject(response)

            if (response.goalLastStatus.status) {
                setLastStatus(response.goalLastStatus.status)
            }

        }).catch((error) => {
            console.log(error.response)
        });
    }

    useMemo(() => {

        if (!employeeId){
            if (location && location.state && location.state.employeeId) {
                setEmployeeId(location.state.employeeId)
            }
        }

    }, [location])


    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className='px-3 col-6'>
                        <div className='fs-6 text-default mb-4'>
                            Alterar Status
                        </div>
                        <FormCreateGoalStatus goal={id} employee={employeeId} lastStatus={lastStatus} />
                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar } from 'primereact/avatar';
import NameInitials from '../../helpers/nameInitials';
import userData from '../../context/user';


export default function CareerHeader(props) {

    const {user} = userData()
    const userEmployee = props.Employee

    return (

        <div className='row flex-nowrap'>
            <div className='col text-bg-green p-3'>
                <a href='/painel/' className="fs-6 fw-bold ps-5 text-decoration-none text-white">
                    <FontAwesomeIcon className='pe-2' icon={solid('arrow-left')} />
                </a>
                {
                    userEmployee && userEmployee.user.name ?
                    <>
                    {
                        userEmployee.user.photo ?
                        <>
                        <Avatar className="position-relative align-bottom me-1" image={userEmployee.user.photo} shape='circle' />
                        </>
                        :
                        <>
                        <Avatar label={NameInitials( userEmployee.user.name )} style={{ backgroundColor: '#42c0fb', color: '#ffffff', fontWeight: 'bold' }} shape='circle' />
                        </>
                    }
                    </>
                    :
                    null
                }
                
                {
                    user ?
                    <span className='ps-1 fs-6 fw-bold'>
                        {
                            userEmployee ?
                            <>{userEmployee.user.name}</>
                            : null
                        }
                    </span>
                    :
                    null
                }
            </div>
        </div>
    )
}
import React, {useRef, useState, useMemo} from 'react';
import { useFormik } from 'formik';
import careerService from '../../services/data/career.service';
import { Toast } from 'primereact/toast';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'


export default function FormAddWebsite(props) {

    const toast = useRef(null);
    const userId = props.userId
    const modalRef = useRef()
    const [website, setWebsite] = useState('')
    const [link, setLink] = useState('')

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    useMemo(() => {

        getItem()

    }, [props])

    function getItem() {
        if (props.object) {
            setWebsite(props.object.website)
            setLink(props.object.link)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            website: website,
            link: link
        },
        onSubmit: values => {

            var data = {
                company_id: localStorage.getItem("companyId"),
                user_id: userId,
                website: values.website,
                link: values.link
            }

            if (props.object) {

                data.id = props.object.id

                careerService.updateUserWebsite(data).then(() => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Link atualizado com sucesso', life: 3000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });

            } else {
            
                careerService.createUserWebsite(data).then(() => {

                    props.updateData()
                    
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Link adicionado com sucesso', life: 3000 });

                }).catch((error) => {
                    toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 4000 });
                });
            }
        },
    });

    function deleteItem() {
        var data = {
            id: props.object.id
        }
        careerService.deleteUserWebsite(data).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Item apagado com sucesso', life: 3000 });

            props.updateData()

            setTimeout(() => {
                hideModal()
                props.showBar(false)
            }, 3000);
        })
    }

    return (
        <>
        <Toast ref={toast} position="top-right" />
        <form onSubmit={formik.handleSubmit}>

            <label className="fw-bold font110">Links e Websites</label>

            <div className="col mt-3">
                <label className="form-label">Título do Website <span className="text-danger">*</span></label>
                <input name='website' type="text" className="form-control text-capitalize" {...formik.getFieldProps('website')} />
            </div>

            <div className="col mt-3">
                <label className="form-label">Link <span className="text-danger">*</span></label>
                <br/><small>Links precisam iniciar com http ou https</small>
                <input name='link' type="url" className="form-control" {...formik.getFieldProps('link')} />
            </div>

            <div className="d-grid mx-auto btnMargin">
                <button className="btn btn-success btn-sm btn-rounded" type="submit">
                {
                    props.object ?
                    <>Atualizar</>
                    :
                    <>Adicionar</>
                }
                </button>
            </div>
        </form>

        {
            props.object ?
            <>
            <div className="text-end btnMargin">
                <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar</button>
            </div>

            <div id="formDel" className="modal fade" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apagar</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Tem certeza de que deseja apagar este website?
                            </p>
                            <p>
                                {formik.values.website}
                            </p>
                            <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            : null
        }
        </>
    )
}
import React, {useState, useEffect, useRef, useMemo} from 'react';
import ProtectedRoute from '../../routes/protected';
import performanceService from '../../services/data/performance.service';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import FormCreateGoal from '../../components/performance/forms/formCreateGoal';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import companyService from '../../services/data/company.service';


export default function GoalEdit() {
    
    const { id } = useParams()
    const toast = useRef(null)
    const navigate = useNavigate();
    const [object, setObject] = useState('')
    const [categories, setCategories] = useState('')
    const modalRef = useRef()
    const location = useLocation();
    const [relatedGoalList, setRelatedGoalList] = useState('')

    useEffect(() => {
        getObject()
        listCategories()
    }, [])

    useMemo(() => {

        if (location && location.state && location.state.relatedGoalList) {
            setRelatedGoalList(location.state.relatedGoalList)
        }

    }, [location])

    function getObject() {
        performanceService.getGoal(id).then((response) => {
            setObject(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    function listCategories() {
        let _data = {
            company_id: localStorage.getItem("companyId"),
            model: 'goalplan'
        }
        companyService.listCategory(1, _data).then((response) => {
            setCategories(response)
        }).catch((error) => {
            console.log(error.response)
        });
    }

    const hideModal = () => {
        const modalEl = modalRef.current
        const bsModal= bootstrap.Modal.getInstance(modalEl)
        bsModal.hide()
    }

    function deleteItem() {
        performanceService.deleteGoal(id).then(() => {
            
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Objetivo apagado com sucesso', life: 3000 });

            setTimeout(() => {
                hideModal()
                navigate(-1)
            }, 3000);
        })
    }

    return (
        <ProtectedRoute>

            <Toast ref={toast} position="top-right" />

            <div className="container-fluid">
                <div className='row flex-nowrap'>
                    <div className='col text-bg-green p-3'>
                        <span className="fs-6 fw-bold ps-5" role='button' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='' icon={solid('arrow-left')} /> {object.title}
                        </span>
                    </div>
                </div>
                <div className='d-flex-auto my-4 mx-5 pt-3 bg-white contentShadow rounded-top paddingBottom200' style={{minHeight: '500px'}}>
                    <div className='px-3 col-6'>
                        <div className='fs-6 text-default mb-4'>
                            Editar Objetivo
                        </div>
                        <FormCreateGoal related_goals={relatedGoalList} obj={object} categoriesList={categories} listCategories={listCategories} goalPlan={object.goal_plan} />
                        <hr/>
                        <div className="text-end btnMargin">
                            <button className="btn btn-sm font1em text-danger" data-bs-toggle="modal" data-bs-target="#formDel">Apagar Objetivo</button>
                        </div>

                        <div id="formDel" className="modal fade" ref={modalRef}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Apagar Objetivo</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            Tem certeza de que deseja apagar este Objetivo?
                                        </p>
                                        <button className="btn btn-sm btn-danger btn-rounded px-4"  onClick={deleteItem}>Sim, Apagar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ProtectedRoute>
    )
}
import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import performanceService from '../../../services/data/performance.service';
import * as Yup from 'yup';
import companyService from '../../../services/data/company.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import TypeSteps from '../typeSteps';


export default function FormCreatePerformanceReview(props) {
    
    const toast = useRef(null);

    const [title, setTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('AT')
    const [assessmentType, setAssessmentType] = useState('')
    const assessList = [
        { name: 'Plano de Desenvolvimento', code: 'PD' },
        { name: 'Avaliação 90º', code: 'BD' },
        { name: 'Avaliação 180º', code: 'AD' },
        { name: 'Avaliação 270º', code: 'SD' },
        { name: 'Reunião 1:1', code: 'RD' }
    ];
    
    const [employeeList, setEmployeeList] = useState(props.employeeList) //lista para escolher

    const [employees, setEmployees] = useState('') // para ter o ID e buscar no emplist para ter tbm competencias
    const [employeesFinal, setEmployeesFinal] = useState([]) // para enviar pra API

    const [empName, setEmpName] = useState('')
    const [competencies, setCompetencies] = useState([])

    const [step, setStep] = useState(0)
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const load = () => {
        setLoading(true);
        formik.submitForm()
    };

    useEffect(() => {

        listEmployees()
        
    }, [])

    useMemo(() => {

        if (props.assessment_type) {

            let assess = assessList.filter(
                x => x.code === props.assessment_type
            )
            setAssessmentType(assess[0])
            setStep(1)
        }

    }, [props])

    function listEmployees() {
        
        companyService.getUserTeamJobPosition().then((response) => {    
            setEmployeeList(response.results.map((item) => {
                return {
                    name: `${item.user.first_name} ${item.user.last_name}`,
                    value: item.id,
                    internaljobtitle: item.jobposition.internaljobtitle.id,
                    competencies: item.jobposition.internaljobtitle.competencies.map((item) => item.id)
                }
            }))
        }).catch((error) => {
            console.log(error.response)
        });

    }

    // esse para envio multiplos avaliados 
    useMemo(() => {

        let emp;
        if (employees) {

            emp = employeeList.filter( (item) => {
                for (let i = 0; i < employees.length; i++) {
                    if (item.value === employees[i]) {
                        return item
                    }
                }
            })

            setEmployeesFinal(emp)

        }
        
        if (emp && emp.length == 1) {

            setEmpName(emp[0].name)
            setCompetencies(emp[0].competencies)

        } else {
            setEmpName('')
            setCompetencies([])
        }
        
    }, [employees])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: title,
            start_date: startDate,
            end_date: endDate,
            status: status,
            assessment_type: assessmentType,
            result_to_matrix: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Favor preencher o campo Título"),
            start_date: Yup.date().required("Favor preencher o campo Data de Início e de Término"),
            end_date: Yup.date().min(Yup.ref("start_date"), "Data de término tem de ser maior que a data de início"),
        }),
        onSubmit: values => {

            let data = {
                company_id: localStorage.getItem("companyId"),
                title: values.title,
                start_date: values.start_date,
                end_date: values.end_date,
                status: values.status,
                assessment_type: values.assessment_type.code,
                employee: employeesFinal,
                competencies: competencies,
                result_to_matrix: values.result_to_matrix
            }

            performanceService.createPerformanceReview(data).then((response) => {
    
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: `${response.title} criado com sucesso`, life: 5000 });
                
                setTimeout(() => {
                    setLoading(false);
                    return navigate(`/performance-review/${response.id}/process/`)
                }, 3000);

                
            }).catch((error) => {
                toast.current.show({ severity: 'warn', summary: 'Ops', detail: JSON.stringify(error.response.data), life: 6000 });
                setLoading(false);
            });

            
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>

            <Toast ref={toast} position="top-right" />

            {
                step == 0 ?
                // setar template
                <>
                <div className="col mb-3">
                    <label className="form-label fw-bold">Escolher Template <span className="text-danger">*</span></label>
                    <div className='p-inputgroup'>
                        <Dropdown placeholder="Selecionar" value={assessmentType} optionLabel="name" options={assessList} onChange={(e) => {setAssessmentType(e.value)} } className="rounded" />
                    </div>
                    <div className='mt-3'>
                        <TypeSteps type={assessmentType.code} />
                    </div>
                </div>
                </>
                : null
            }
            {
                assessmentType && step === 0 ?
                <span className='btn btn-success btn-sm btn-rounded px-4' onClick={() => {setStep(1)}}>
                        Avançar
                    </span>
                : null
            }
            {
                step == 1 ?
                <>
                {
                    employeeList && employeeList.length > 0 ?
                    <>
                    <span role="button" className="nav-link mt-2 mb-3" onClick={() => setStep(0)}>
                        <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                    </span>
                    <p>Template: <span className='fw-bold'>{assessmentType.name}</span></p>
                    <div className="col mb-3">
                        <label className="form-label fw-bold mb-0">Enviar para <span className="text-danger">*</span></label>
                        <p className='mb-1'>Selecionar pessoa de sua equipe</p>
                        <div className='p-inputgroup'>
                            <MultiSelect filter optionLabel="name" placeholder="Selecionar" value={employees} onChange={(e) => setEmployees(e.value)} options={employeeList} display="chip" className="rounded" />
                        </div>
                    </div>
                    </>
                    :
                    <p>Carregando equipe</p>
                }
                {
                    employees ?
                    <>
                    {
                        employees.length > 1 ?
                        <p>Enviando para <span className='fw-bold'>{employees.length}</span> pessoas</p>
                        : null
                    }
                    <span className='btn btn-success btn-sm btn-rounded px-4' onClick={() => {setStep(2)}}>
                        Avançar
                    </span>
                    </>
                    : null
                }
                </>
                :
                null
            }
            {
                step == 2 ?
                <>
                <span role="button" className="nav-link mt-2 mb-3" onClick={() => setStep(1)}>
                    <span className="text-green fw-bold"><FontAwesomeIcon icon={solid('chevron-left')} /> Voltar</span>
                </span>
                {
                    empName ?
                    <p className='mb-0'>Enviando para: <span className='fw-bold'>{empName}</span></p>
                    :
                    <p className='mb-0'>Enviando para <span className='fw-bold'>{employees.length}</span> pessoas</p>
                }
                <p>Template: <span className='fw-bold'>{assessmentType.name}</span></p>
                <div className="col mb-3">
                    <label className="form-label fw-bold">Título <span className="text-danger">*</span></label>
                    <input name='title' type="text" className="form-control text-capitalize" required {...formik.getFieldProps('title')} />
                    {formik.errors.title ? (<span name="title" className="text-vermelho">{formik.errors.title}</span>) : null}
                </div>

                <div className='row mb-4 px-0'>
                    <div className='col'>
                        <label className="form-label fw-bold">Data de Início <span className="text-danger">*</span></label>
                        <input className="form-control" name='start_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('start_date')} />
                        {formik.errors.start_date ? (<span name="start_date" className="text-vermelho">{formik.errors.start_date}</span>) : null}
                    </div>

                    <div className='col'>
                        <label className="form-label fw-bold">Data de Término <span className="text-danger">*</span></label>
                        <input className="form-control" name='end_date' type="date" required="required" autoComplete="off" {...formik.getFieldProps('end_date')} />
                        {formik.errors.end_date ? (<span name="end_date" className="text-vermelho">{formik.errors.end_date}</span>) : null}
                    </div>
                </div>

                <div className="form-check form-switch mb-4">
                    <label className="form-switch-label">Considerar Resultado na Matriz 9Box?</label>
                    <input className="form-check-input" type="checkbox" role="switch" name='result_to_matrix' aria-checked={formik.values.result_to_matrix} checked={formik.values.result_to_matrix} {...formik.getFieldProps('result_to_matrix')} />
                </div>

                <div className="d-grid mx-auto btnMargin">
                    <Button rounded label="Criar" type='submit' icon="pi pi-check" loading={loading} onClick={load} />
                </div>
                </>
                : null
            }
        </form>
    )
}